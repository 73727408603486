/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {PRNGHmacSHA256} from "@/assets/ext/crypto/PRNGHmacSHA256";

export class KDFSha256 {
    static KEY_LENGTH = 64;

    /**
     * gen
     *
     * @param {Uint8Array} seed
     *
     * @returns {Uint8Array} key
     */
    static async gen(seed) {
        let prng = new PRNGHmacSHA256(seed);
        await prng.init();
        // length : HashMacKey length (32) + AES256key length (32)
        let key = await prng.bytes(KDFSha256.KEY_LENGTH);
        let macKey = key.slice(0, 32);
        let aesKey = key.slice(32);
        return ({aesKey: aesKey, macKey: macKey});
    }
}
