/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
function isEmojiCodepoint(codePoint) {
    if (codePoint >= 0x1f600 && codePoint <= 0x1f64f) {
        return true;
    }
    if (codePoint >= 0x1f300 && codePoint <= 0x1f5ff) {
        return true;
    }
    if (codePoint >= 0x1f680 && codePoint <= 0x1f6ff) {
        return true;
    }
    if (codePoint >= 0x1f1e6 && codePoint <= 0x1f1ff) {
        return true;
    }
    if (codePoint >= 0x2600 && codePoint <= 0x26ff) {
        return true;
    }
    if (codePoint >= 0x2700 && codePoint <= 0x27bf) {
        return true;
    }
    if (codePoint >= 0xe0020 && codePoint <= 0xe007f) {
        return true;
    }
    if (codePoint >= 0xfe00 && codePoint <= 0xfe0f) {
        return true;
    }
    if (codePoint >= 0x1f900 && codePoint <= 0x1f9ff) {
        return true;
    }
    if (codePoint >= 127000 && codePoint <= 127600) {
        return true;
    }
    if (codePoint >= 9100 && codePoint <= 9300) {
        return true;
    }
    if (codePoint >= 8400 && codePoint <= 8447) {
        return true;
    }
    return codePoint === 0x200d;
}

/**
 * @param{string} string
 * @param{number} emojiNumber (optional): if -1 detect emoji only string, else detect up to emojiNumber
 * @returns {boolean}
 */
export function isEmojiOnlyString(string, emojiNumber = -1) {
    if (!string || string.length === 0) {
        // console.log("Invalid string received");
        return false;
    }

    let i = 0;
    let emojiCount = 0;
    while (i < string.length) {
        if (!isEmojiCodepoint(string.codePointAt(i))) {
            // console.log("Found invalid code point: ", i, string.codePointAt(i));
            return false;
        }
        i++;
        // handle unicode plane
        if (string.codePointAt(i) >= 0x0 && string.codePointAt(i) <= 0x10ffff) {
            i++;
        }
        // handle variation char
        if ((string.codePointAt(i) >= 0xfe00 && string.codePointAt(i) <= 0xfe0f) || (string.codePointAt(i) >= 0xe0100 && string.codePointAt(i) <= 0xe01ef)) {
            i++;
        }
        // handle glue
        if (string.codePointAt(i) === 0x200d) {
            i++;
        }
        else {
            emojiCount++;
            if (emojiNumber !== -1 && emojiCount > emojiNumber) {
                return false;
            }
        }
    }
    return true;
}
