/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
import {handleAttachmentDoneDownloading} from "@/assets/ext/attachments";

export function receive_download_attachment_chunk(colissimo) {
	if(!colissimo.receiveDownloadAttachmentChunk) {
		return;
	}
	let fyleId = colissimo.receiveDownloadAttachmentChunk.fyleId;
	let chunkNumber = colissimo.receiveDownloadAttachmentChunk.chunkNumber;
	let chunk = colissimo.receiveDownloadAttachmentChunk.chunk;
	let realFyle = globals.data.realFyles[fyleId];
	if(!realFyle) {
		console.log("receive_download_attachment_chunk: realFyle not found")
		return; //will be marked as failed later in the procedure
	}
	realFyle.addChunk(parseInt(chunkNumber, 10), chunk); //need a number for sorting later

	// if we just received all chunks and attachment done was already received, then begin processing attachmentDone
	if(realFyle.receivedSize === realFyle.size
		&& realFyle.receivedAttachmentDoneDownloading) {
		console.log("attachment download succeeded");
		//success, all chunks received
		handleAttachmentDoneDownloading(fyleId, true);
	}
}
