/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted, options);

let options = {
    theme:'outline',
    duration: 5_000,
    position: 'top-right',
    fullWidth:false,
    fitToScreen:false,
    action : {text:"OK",
        onClick : (e, toastObject) => {
        toastObject.goAway(0);
    }}
}

let optionsError = {
    type:'error',
    theme:'outline',
    duration: 5_000,
    position: 'top-right',
    fullWidth:false,
    fitToScreen:false,
    action : {text:"OK",
        onClick : (e, toastObject) => {
        toastObject.goAway(0);
    }}
}

Vue.toasted.register('notif_new_message',
    (content) => {
        if(content.length > 80) {
            return content.substring(0,80)+"...";
        } else {
            return content;
        }
    },
    options
);

Vue.toasted.register('notif_file_already_attached',
    (content) => {
        return content;
    },
    options
);

Vue.toasted.register('attachment_type_not_supported',
    (content) => {
        return content;
    },
    optionsError
);

Vue.toasted.register('download_failed',
    (content) => {
        return content;
    },
    optionsError
);
