/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
export class MACHmacSha256 {
    static OUTPUT_LENGTH = 32;

    /**
     * @param {Uint8Array} rawKey
     * @param {Uint8Array} payload
     * @returns {Uint8Array} digest
     */
    static async digest(rawKey, payload) {
        const macKey = await crypto.subtle.importKey("raw", rawKey, {name:"HMAC", hash:{name:"SHA-256"}}, true, ["sign"]);
        const signedPayload = new Uint8Array(await crypto.subtle.sign("HMAC", macKey, payload));
        return (signedPayload);
    }
}
