/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {PRNGHmacSHA256} from "@/assets/ext/crypto/PRNGHmacSHA256";
import {pow} from "@/assets/ext/crypto/tools/bigIntTools";

export class SAS {
    /**
     *
     * @param {Uint8Array} seedA
     * @param {Uint8Array} seedB
     * @param {Uint8Array} rawPublicKeyB
     * @param {number} numberOfDigit
     * @returns {String} sasString
     */
    static async computeSimple(seedA, seedB, rawPublicKeyB, numberOfDigit) {
        const toHash = new Uint8Array(seedA.length + rawPublicKeyB.length);
        toHash.set(rawPublicKeyB);
        toHash.set(seedA, rawPublicKeyB.length);
        const hashed = new Uint8Array(await crypto.subtle.digest("SHA-256", toHash));

        const xor = new Uint8Array(Math.min(hashed.length, seedB.length));
        for (let i=0; i<xor.length; i++) {
            xor[i] = seedB[i] ^ hashed[i];
        }

        const prng = new PRNGHmacSHA256(xor);
        await prng.init();
        // eslint-disable-next-line no-undef
        const max = pow(10n, BigInt(numberOfDigit));
        const sas = await prng.bigInt(max);
        let sasString = sas.toString();

        while (sasString.length < numberOfDigit) {
            sasString = "0" + sasString;
        }
        return (sasString);
    }
}
