/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
import {updateCurrentSettings} from "@/assets/ext/settings";

export function settings(colissimo) {
	if(!colissimo.settings){
		return;
	}
	let settings = JSON.parse(colissimo.settings.settings);
	globals.parameters.receivedSettings = settings;
	//retrieve and update settings accordingly
	updateCurrentSettings(settings.language, settings.theme, settings.sendOnEnter, settings.notificationSound, settings.showNotifications);
}
