/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";
import * as events from "@/assets/ext/events";
import {relay} from "@/assets/ext/messages/messageSender";

export function connection_ping(colissimo) {
	if (!colissimo.connectionPing) {
		return;
	}
	else if (colissimo.connectionPing.pong) {
		document.dispatchEvent(events.PongReceived);
	}
	else if (colissimo.connectionPing.ping) {
		const pong = protobuf.ConnectionPing.create({pong: true});
		const pongColissimo = protobuf.Colissimo.create({
			type: protobuf.ColissimoType.CONNECTION_PING,
			connectionPing: pong
		});
		relay(pongColissimo);
		document.dispatchEvent(events.PingReceived);
	}

}
