/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
import {handleAttachmentDoneDownloading} from "@/assets/ext/attachments";
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";

export function receive_download_attachment_done(colissimo) {
	if(!colissimo.receiveDownloadAttachmentDone){
		return;
	}
	let fyleId = colissimo.receiveDownloadAttachmentDone.fyleId;
	let success = colissimo.receiveDownloadAttachmentDone.success;

	let realFyle = globals.data.realFyles[fyleId];
	if (!realFyle) {
		return ;
	}

	realFyle.receivedAttachmentDoneDownloading = true;

	if (realFyle.receivedSize === realFyle.size) { //if all chunks were received, proceed
		console.log("attachment download succeeded");
		handleAttachmentDoneDownloading(fyleId,success); //process attachment fully received
	} else {
		//Timeout : we received an attachment done, but not all chunks were received : either they were slow or there was an error.
		//If they are just slow, RECEIVE_DOWNLOAD_ATTACHMENT_CHUNK will call handleAttachmentDoneDownloading when they are all here, otherwise this timeout will indicate a failure.
		setTimeout(() => {
			if (realFyle.status !== protobuf.AttachmentStatus.DOWNLOAD_FINISHED) {
				console.log("attachment download failed");
				handleAttachmentDoneDownloading(fyleId, false); //not a success
			}
		}, 15000, fyleId); //15 seconds to receive all chunks, otherwise declare this a failure (give it time for slow connections)
	}
}
