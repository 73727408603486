/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
/**
 * @param {bigint} bn
 * @returns {bigint}
 */
function square(bn) {
    return (bn * bn);
}

function pow(an, pn) {
    let res = 1n;
    for (let i=0n; i<pn; i++) {
        res = res * an;
    }
    return res;
}
/**
 * Returns the bitlength of a number
 *
 * @param {BigInt} a
 * @returns {number} - the bit length
 */
function bitLength (a) {
    if (a === 1n) { return 1 }
    let bits = 1
    do {
        bits++
    } while ((a >>= 1n) > 1n)
    return bits
}

/**
 * @typedef {Object} egcdReturn A triple (g, x, y), such that ax + by = g = gcd(a, b).
 * @property {BigInt} g
 * @property {BigInt} x
 * @property {BigInt} y
 */
/**
 * An iterative implementation of the extended euclidean algorithm or extended greatest common divisor algorithm.
 * Take positive integers a, b as input, and return a triple (g, x, y), such that ax + by = g = gcd(a, b).
 *
 * @param {bigint} a
 * @param {bigint} b
 *
 * @returns {egcdReturn} A triple (g, x, y), such that ax + by = g = gcd(a, b).
 */
function eGcd (a, b) {
    if (a <= 0n || b <= 0n) throw new RangeError('a and b MUST be > 0') // a and b MUST be positive

    let x = 0n
    let y = 1n
    let u = 1n
    let v = 0n

    while (a !== 0n) {
        const q = b / a
        const r = b % a
        const m = x - (u * q)
        const n = y - (v * q)
        b = a
        a = r
        x = u
        y = v
        u = m
        v = n
    }
    return {
        g: b,
        x: x,
        y: y
    }
}

/**
 * Modular inverse.
 *
 * @param {bigint} a The number to find an inverse for
 * @param {bigint} n The modulo
 *
 * @returns {bigint|NaN} the inverse modulo n or NaN if it does not exist
 */
function modInv (a, n) {
    try {
        const egcd = eGcd(toZn(a, n), n)
        if (egcd.g !== 1n) {
            return NaN // modular inverse does not exist
        } else {
            return toZn(egcd.x, n)
        }
    } catch (error) {
        return NaN
    }
}

/**
 * Finds the smallest positive element that is congruent to a in modulo n
 * @param {bigint} a An integer
 * @param {bigint} n The modulo
 *
 * @returns {bigint} The smallest positive representation of a in modulo n
 */
function toZn (a, n) {
    if (n <= 0n) { return NaN }

    a %= n
    return (a < 0n) ? a + n : a
}

/**
 * Test bit.
 *
 * @param {bigint} bigInt
 * @param {bigint} bitNumber
 *
 * @returns {boolean}
 */
function testBit(bigInt, bitNumber) {
    return ((bigInt & (1n << bitNumber)) !== 0n);
}

/**
 * Convert Uint8Array to BigInt
 * @param {Uint8Array} bytes
 * @returns {BigInt} bn
 */
function bytesToBigInt(bytes) {
    let bi = 0n;

    bytes.forEach(b => {
        // eslint-disable-next-line no-undef
        bi = (bi << 8n) + BigInt(b);
    });
    return (bi);
}

/**
 * Convert BigInt to Uint8Array
 * @param {BigInt} bn
 * @param {number} l
 * @returns {Uint8Array} bytes
 */
function bigIntToBytes(bn, l) {
    let b = new Uint8Array(l);
    for (let i = 0; i < l; i++) {
        b[l - i - 1] = Number(bn & 255n);
        bn >>= 8n;
    }
    if (bn !== 0n) {
        return null;
    }
    return (b);
}

module.exports = {
    bitLength,
    eGcd,
    modInv,
    toZn,
    testBit,
    bytesToBigInt,
    bigIntToBytes,
    square,
    pow
}
