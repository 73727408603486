<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div class="message-item_inbound">
        <div class="message-item_inbound_wrapper">
            <div class="message message-item_inbound--background" :id="'message-item_inbound'+message.id">
<!--			hidden ephemeral message				-->
				<div v-if='message.readOnce === true || message.visibilityDuration !== ""'>
                    <div class="message-item_content_body text--color message-item_ephemeral">{{ $t("ephemeral.labelMessageEphemere") }}</div>
                    <div class="ephemeral" v-html="getLabelEphemeral()"></div>
                </div>
<!--			shown message				-->
				<div v-else class="message-item_content">
                    <div class="message-item_content_sender-name" v-html="senderName"></div>
                    <div v-show="message.replyAuthor !== ''" class="message-item_replying-message main-background">
                        <div class="message-item_replying-message_content" :style="{borderLeftColor : replyMessage.color}" @click="scrollToMessage(replyMessage.id)">
                            <span class="message-item_replying-message_author" :style="{color : replyMessage.color}" v-html="replyMessage.author"></span>
                            <div class="message-item_replying-message_content_row">
                                <span class="text--color message-item_replying-message_body" v-html="replyMessage.body"></span>
                                <span class="text--color reply-attachment--background message-item_replying-message_attachments" v-show="replyMessage.attachments > 0">
                                {{$tc('attachments.labelLastMessageNoTextAttachments', replyMessage.attachments)}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="message-item_content_body text--color" v-html="getContentBody()"></div>

                    <div class="text--color message-audio_attachments--grid"
                        v-if="this.audioAttachments.length !== 0">
                        <div v-for="attachment in this.audioAttachments" :key="attachment">
                            <AudioAttachment
                                :attachment="oc.globals.data.attachments[attachment]"
                                :realFyle="oc.globals.data.realFyles[oc.globals.data.attachments[attachment].fyleId]"
                                :key="attachment">
                            </AudioAttachment>
                        </div>
                    </div>

                    <div class="text--color"
                        :class="this.nonAudioAttachments.length > 3 ? 'message-item_attachments--grid':'message-item_attachments--flex'"
                        v-if="this.nonAudioAttachments.length !== 0">
                        <div v-for="attachment in this.nonAudioAttachments" :key="attachment">
                            <div v-if="isAnImage(attachment)">
                                <ImageAttachment
                                    :attachment="oc.globals.data.attachments[attachment]"
                                    :realFyle="oc.globals.data.realFyles[oc.globals.data.attachments[attachment].fyleId]"
                                    :key="attachment">
                                </ImageAttachment>
                            </div>
                            <div v-else>
                                <FileAttachment 
                                    :attachment="oc.globals.data.attachments[attachment]" 
                                    :realFyle="oc.globals.data.realFyles[oc.globals.data.attachments[attachment].fyleId]" 
                                    :key="attachment">
                                </FileAttachment>
                            </div>
                        </div>
                        <div v-for="number in message.totalAttachmentCount - message.listAttachments.length" :key="number">
                            <AttachmentPlaceholder></AttachmentPlaceholder>
                        </div>
                    </div>
                </div>
                <div :class="message.edited !== oc.protobuf.EditedStatus.EDITED_NONE ? 'message-item_info--space' : 'message-item_info--flex-end'">
                    <svg width="54" height="18" class="message-item_info_edited" v-show="message.edited !== oc.protobuf.EditedStatus.EDITED_NONE">
                        <rect x=2 y=2 width="50" height="15" rx="8" ry="8" :class="message.edited === oc.protobuf.EditedStatus.EDITED_UNSEEN ? 'edited-unseen-svg' : 'edited-seen-svg'"/>
                        <text x="50%" y="58%" font-size="9" text-anchor="middle" dominant-baseline="middle" :class="message.edited === oc.protobuf.EditedStatus.EDITED_UNSEEN ? 'edited-unseen' : 'edited-seen'">
                            {{$t('message.labelEdited')}}
                        </text>
                    </svg> 
                    <div class="message-item_info_timestamp text--color">{{getTimeFromTimestamp(message.timestamp)}}</div>
                </div>
            </div>
            <div class="reactions-pane">
                <div v-for="emoji in message.parseReactions()" :key="emoji.emoji+message.id+emoji.number" class="reaction" v-bind:class="{ 'owned-reaction': emoji.owned }">
                    <div class="emoji">
                      {{emoji.emoji}}
                      <span class="emoji-number" v-show="emoji.number !== 1">{{emoji.number}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="message.wiped === false && message.remotelyDeleted === false" class="tooltip-emoji svg--color">
            <i class="reply far fa-smile reply--color" @click="openEmojis" :title="$t('message.labelAddReaction')"></i>
        </div>
        <div class="tooltip-reply svg--color" v-show='message.readOnce === false && message.visibilityDuration === "" && !message.wiped && !message.remotelyDeleted'>
            <i class="reply fa fa-reply reply--color" @click="replyTo(message.id)" :title="$t('message.labelReply')"></i>
        </div>
        <div class="tooltip-date-and-time text--color tooltip-background">
            <span>{{getShortDateFromTimestamp(message.timestamp)}}</span>
        </div>
    </div>
</template>

<script>
import FileAttachment from '@/components/FileAttachment';
import ImageAttachment from '@/components/ImageAttachment';
import AudioAttachment from "@/components/AudioAttachment";
import AttachmentPlaceholder from "@/components/AttachmentPlaceholder";
import {getShortDateFromTimestamp, getTimeFromTimestamp} from '@/assets/ext/time.js';
import oc from '@/assets/ext/client.js';
import {replyTo} from '@/assets/ext/draft';
import {isEmojiOnlyString} from '@/assets/ext/emoji';
import {isAudioFile} from "@/assets/ext/audio";

export default {
    name: 'InboundMessageItem',
    components: {AudioAttachment, FileAttachment, ImageAttachment, AttachmentPlaceholder},
    props : {
        message : {
            type:Object,
            required:true
        },
        senderName : {
            type:String,
            required:false
        },
        replyMessage : {
            type:Object,
            required:false
        }
    },
    data () {
        return {
            oc:oc,
            getShortDateFromTimestamp:getShortDateFromTimestamp,
            getTimeFromTimestamp:getTimeFromTimestamp,
            replyTo:replyTo,
            regexImage:RegExp('^image/.*$'), //regex for defining images (openable in browser)
            regexURL : RegExp('https?://[^\\s]+', "g"),
            longPress : null,
            mouseDownEvent : null
        }
    },
    computed: {
        nonAudioAttachments : function () {
            return this.message.listAttachments.filter(attachment => !this.isAudio(attachment))
        },
        audioAttachments : function () {
            return this.message.listAttachments.filter(attachment => this.isAudio(attachment))
        },
    },
    // mounted(){
        /**
         * Long press on message => add emoji reaction
         */
        // $(() => {
        //     $("#message-item_inbound"+this.message.id).mouseup(() => {
        //         if(this.longPress){
        //             clearTimeout(this.longPress);
        //             this.longPress = null;
        //             this.mouseDownEvent = null;
        //         } 
        //         return false;
        //     }).mousedown((e) => {
        //         this.mouseDownEvent = e;
        //         this.longPress = window.setTimeout(() => { 
        //             this.longPress = null;
        //             let picker = document.getElementById("float-emoji-picker");/**TODO check existence */
        //             picker.style.display = "block";
        //             picker.style.top = this.mouseDownEvent.pageY - 30;
        //             picker.style.left = this.mouseDownEvent.pageX - 100; /**TODO consider border of screen*/
        //             document.getElementById("message-id").innerText = this.message.id;
        //             },500);
        //         return false; 
        //     }).mousemove(() => {
        //         if(this.longPress){
        //             clearTimeout(this.longPress);
        //             this.longPress=null;
        //             this.mouseDownEvent = null;
        //         }
        //         return false;
        //     });//also cancel on mousemove
        // })
    // },
    methods : {
        openEmojis(e){
            if (document.getElementById("float-emoji-picker")) {
                let picker = document.getElementById("float-emoji-picker");
                // show or hide, depending on current state
                picker.style.display = picker.style.display === "none" ? "block" : "none";
                picker.style.top = e.pageY - 60;
                picker.style.left = e.pageX - 100; /**TODO consider border of screen*/
                document.getElementById("message-id").innerText = this.message.id;
            }
        },
        /**
         * Method called when clicking on a reply in a message or in the draft : scrolls to original message if it has been loaded in conversation, otherwise does nothing.
         * @param {number} id
         */
        scrollToMessage(id) {
            if(!id || id == 0){
                return;
            }
            if(oc.globals.data.messagesByDiscussion.get(oc.globals.currentDiscussion).has(id)){
                document.getElementById(id).scrollIntoView({behavior: "smooth", block: "end"});
            }
        },
        /**
         * Returns content of the message, with URLs in their appropriate tags. Regex can and should be changed URLs to detect more accurately.
         * @returns {string}
         */
        getContentBody() {
			if (this.message.wiped || this.message.remotelyDeleted) {
				return this.getLabelWipedMessage();
			}
			let toDisplay = this.message.contentBody;
            if(isEmojiOnlyString(toDisplay)){ //only emojis
                return '<div style="font-size:45px">'+ this.message.contentBody+'</div>';
            }
            //search for URLs
            toDisplay = toDisplay.replace(this.regexURL, function(url) {
                return '<a target="_blank" rel="noopener,noreferrer" href="' + url + '">' + url + '</a>';
            });
            return toDisplay.trim();
        },
        /**
         * Determines whether specific mimeType corresponds to an 'image' openable in Gallery.
         * @returns {boolean}
         */
        isAnImage(attachment) {
            return this.regexImage.test(oc.globals.data.realFyles[oc.globals.data.attachments[attachment].fyleId].mime);
        },
        /**
         * Determines whether specific mimeType corresponds to an audio file.
         * @returns {boolean}
         */
        isAudio(attachment) {
          return isAudioFile(oc.globals.data.realFyles[oc.globals.data.attachments[attachment].fyleId].mime);
        },
        /**
         * Check if current message have at least one audio file as an attachment
         * @returns {boolean}
         */
        messageHaveAudioAttachment() {
            if (!this.message.listAttachments || !this.message.listAttachments.length) {
                return false;
            }
            for (let i = 0; i < this.message.listAttachments.length; i++) {
                if (this.isAudio(this.message.listAttachments[i])) {
                    return true;
                }
            }
            return false;
        },
        /**
         * Returns appropriate label for ephemeral messages according to their state, determined by properties readOnce, visibilityDuration and existenceDuration.
         * @returns {boolean}
         */
        getLabelEphemeral() {
            if(this.message.readOnce){
                if(this.message.visibilityDuration !== ""){ //if both visibility and existance, message for visibility
                    return this.$i18n.t('ephemeral.labelReadOnceAndVisibility', {time:this.message.visibilityDuration}); 
                } else if(this.message.existenceDuration !== ""){
                    return this.$i18n.t('ephemeral.labelReadOnceAndExistence',{time:this.message.existenceDuration}); 
                } else { //just read once
                    return this.$i18n.t('ephemeral.labelReadOnce');
                }
            } else if(this.message.visibilityDuration !== ""){
                return this.$i18n.t('ephemeral.labelVisibility', {time:this.message.visibilityDuration});
            } else if(this.message.existenceDuration !== ""){
                return this.$i18n.t('ephemeral.labelExistence',{time:this.message.existenceDuration});
            } else return "";
            //if message is wipeOnRead (inbound readable on App, not here), no further label
        },
		/**
		 * Returns appropriate label for wiped messages according to their state, determined by properties wiped and remotelyDeleted.
		 * @returns {string}
		 */
		getLabelWipedMessage() {
			if (this.message.wiped) {
				return this.$i18n.t('wiped.wiped');
			}
			else if (this.message.remotelyDeleted) {
				return this.$i18n.t('wiped.remotelyDeleted');
			}
			return "";
		}
	}
}
</script>

<style scoped>

.message-item_inbound {
    display:flex;
    flex-direction:row;
    align-items: center;
}

@media screen and (min-width: 1300px) {
    .message-item_inbound:hover .tooltip-date-and-time {
        display: flex;
    }
}

.message-item_inbound:hover .reply {
    display: flex;
}

.message-item_content_sender-name {
    margin-bottom:2px;
}

.message-item_content {
    margin-right:18px;
}

.message-item_content_body {
    word-wrap: break-word;
    white-space: pre-wrap; /*break lines when necessary */
}

.message-item_replying-message {
    border-radius: 5px;
    margin-bottom: 5px;
}

.message-item_replying-message_content_row {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 4px;
}

.message-item_replying-message_content {
    display: flex;
    flex-direction: column;
    padding:6px;
    border-left: 5px solid;
    border-radius: 5px;
}

.message-item_replying-message_author {
    font-weight: bold;
    color:var(--light-grey);
    word-wrap: break-word;
}

.message-item_replying-message_body {
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    padding-right: 3px;
}

.message-item_content_body + a {
    word-break: break-all;
}

.message-item_replying-message_attachments {
    border-radius:5px;
    padding:2px;
    color:var(--all-white);
    font-size: 10px;
    margin-left: 10px;
    height: fit-content; /* older Safari, Opera, Chrome, Edge */
    height: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    height:-moz-fit-content; /* Firefox */
    font-style: italic;
}

.message-audio_attachments--grid {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 1fr; /** 1 column */
    padding-top:3px;
    overflow-x: auto;
}

.message-item_attachments--grid {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 1fr 1fr 1fr; /** 3 columns */
    padding-top:3px;
    overflow-x: auto;
}


@media screen and (max-width:900px) {
    /**Grid becomes flex when screen too small */
    .message-item_attachments--grid {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding-top: 3px;
        overflow-x: auto;
    }
}

.message-item_attachments--flex {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-top: 3px;
    width : inherit;
    overflow-x: auto;
}

.message-item_info_timestamp {
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    align-items: center;
    
}

.message-item_info--space {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: flex-end;
    padding : 5px 5px 0 0;
    font-size: 12px;
}

.message-item_info--flex-end {
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    align-items: flex-end;
    padding : 5px 5px 0 0;
    font-size: 12px;
}

.tooltip-date-and-time {
    display: none;
    justify-content: center;
    padding:5px;
    border-radius: 5px;
    margin-left:1%;
    height: fit-content; /* older Safari, Opera, Chrome, Edge */
    height: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    height:-moz-fit-content; /* Firefox */
}

.tooltip-date-and-time span {
    font-size: 12px;
}

.tooltip-reply, .tooltip-emoji {
    /*display: none;*/
    padding:5px;
    width:30px;
    height:30px;
    min-width: 30px;
}

.reply {
    cursor: pointer;
    width:30px;
    height:30px;
    align-items: center;
    display: none;
    justify-content: center;
}

.reply:hover {
    border-radius: 50%;
    background-color: var(--item-highlight);
}

.message {
    margin : 10px 0 2px 0;
    padding: 7px 8px 5px 7px;
    min-width:150px;
    max-width: 500px;
    border-radius:5px;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align:left;
    width: -moz-available;
    width: -webkit-fill-available;
}

.message-item_ephemeral {
    font-style:italic;
}

.ephemeral {
    color : var(--light-red);
    padding-top:6px;
    font-size: 15px;
    text-align: center;
}

.edited-seen {
    fill:var(--green);
}

.edited-unseen {
    fill:var(--edited-color);
}

.edited-unseen-svg{
    fill:var(--green);
}

.edited-seen-svg{
    fill:transparent;
    stroke-linecap:"round";
    stroke-width: 1.5;
    stroke:var(--green);
}

.message-item_inbound_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
}

.reactions-pane {
    display: flex;
    justify-content: flex-start;
    margin : 0 0 5px 0;
    flex-wrap: wrap;
    font-size: 24px;
    color: var(--text-color);
}

.reaction {
    align-items: center;
    border: 1px solid var(--reaction-border);
    border-radius: 10px;
    padding: 1px 2px 0 2px;
    margin: 1px 2px 0 0;
    display: inline;
    background-color: var(--reaction-background);
}

.emoji {
    display: flex;
    align-items: center;
}

.emoji-number {
    font-size:14px;
    margin-left:1px;
}

.owned-reaction {
	background-color: var(--owned-reaction-background);
}

</style>
