/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";

export function notif_message_sent(colissimo) {
	if(!colissimo.notifMessageSent){
		return;
	}
	let localId = colissimo.notifMessageSent.localId;
	//compare this localId to the one saved in lastMessageSent. We can only send 1 message at a time so this localId has to be the same otherwise it's an error
	if(localId === globals.data.lastMessageSent.id) {
		globals.data.lastMessageSent = null; //it is the right id, so no more lastMessageSent. User will be able to send another message
	} else {
		console.log("ERROR : received message sent confirmation for message different than last sent message.");
		//in this case, user cannot write further messages. In case this happens, user has to reload page and relaunch webclient because something went wrong
	}
}
