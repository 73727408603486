/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
import {subscribeToDiscussion} from "@/assets/ext/messages/messageSender";
import {getThumbnail} from "@/assets/ext/images";
import * as events from "@/assets/ext/events";
import {Discussion} from "@/assets/datatypes/Discussion.js";
import {updateFavicon} from "@/assets/ext/messages/handlers/notif_discussion_updated";

export function request_discussions_response(colissimo) {
	if(!colissimo.requestDiscussionsResponse) {
		return;
    }
    if(!globals.data.discussions){
        globals.data.discussions = new Map();
    }

	//re-subscribe to current discussion if there is a current discussion
	//This condition is true if we are here after a reconnection if user had a discussion open
	if (globals.currentDiscussion) {
		subscribeToDiscussion(globals.currentDiscussion, globals.constants.STEP_NB_REQUEST_MESSAGES);
    }
    let newDiscussions = new Array();
	colissimo.requestDiscussionsResponse.discussions.forEach(function(discussion){
        newDiscussions.push(discussion.id);
        if(globals.data.discussions.has(discussion.id)){ //for reloads, fetch previous draft before creating new discussion draft
            globals.data.discussions.get(discussion.id).updateFromProtobuf(discussion);
        } else {
            globals.data.discussions.set(discussion.id, new Discussion(
                discussion.id,
                discussion.title,
                discussion.contactIdentity,
                discussion.groupOwnerAndUid,
                discussion.unreadMessagesCount,
                discussion.lastMessage,
                discussion.photoURL,
                discussion.discussionTimestamp,
                0,
                false,
                null,
                null //either null or saved draft
            ));
        }
        getThumbnail(discussion.photoURL); //immediately ask for the discussion thumbnail
    });
    
    //globals.data.discussions was not emptied before receiving this new batch
    //this loop will remove discussions that are not present in the new batch anymore
    for(let id of Array.from(globals.data.discussions.keys())){
        if(!newDiscussions.includes(id)){ //not in list of new discussions, delete it
            globals.data.discussions.delete(id);
        }
    }

    document.dispatchEvent(events.RequestDiscussionResponse); //handled in Body.vue
    updateFavicon();
}
