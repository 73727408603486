/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import ToggleButton from 'vue-js-toggle-button';
import App from '@/App.vue';
import VueI18n from 'vue-i18n';
import router from '@/router';
import {messages, dateTimeFormats} from '@/plugins/i18n';
import '@/assets/bootstrap-theme.min.css';
import '@/assets/bootstrap.css';
import '@/plugins/toasts';
import {PongReceived} from "@/assets/ext/events";

Vue.use(BootstrapVue);
Vue.use(ToggleButton);
Vue.use(VueI18n);

Vue.config.productionTip = false;

export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
    dateTimeFormats
});

new Vue({
    render: h => h(App),
    i18n,
    router,
    components: { App},
    template: '<App/>'
}).$mount('#app');

document.addEventListener(PongReceived.type, function () {
    console.log("App answered with a pong");
});