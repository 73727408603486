/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from '@/assets/ext/globals';
import {requestSaveDraftMessage, requestDeleteDraftMessage} from "@/assets/ext/messages/messageSender";
// import {findDraftAttachmentInListFromLocalId} from "@/assets/ext/attachments";
const protobuf = require("@/assets/ext/protobuf/protobuf.js").olvid;
import {Draft} from "@/assets/datatypes/Draft.js";

/**
 * Adds reply message identified by id in the draft for the current discussion.
 * Triggered by user clicking on replying icon next to message. We always have the message we are replying to in the cache.
 * @param {number} id 
 */
export function replyTo(id) {
    let replyingMessage = findMessageFromId(id);
    if(replyingMessage == null){ //we don't have this message, can't reply
        return;
    }
    if(!globals.data.discussions.has(globals.currentDiscussion)){
        return;
    }
    if(!globals.data.discussions.get(globals.currentDiscussion).draft){
        let draft = new Draft( -1, globals.currentDiscussion, "", "", "", id, replyingMessage.senderIdentifier, replyingMessage.senderName, replyingMessage.contentBody, replyingMessage.totalAttachmentCount);
        globals.data.discussions.get(globals.currentDiscussion).draft = draft;
    } else {
        globals.data.discussions.get(globals.currentDiscussion).draft.changeReplyMessage(replyingMessage); 
    }
    globals.variables.replyMessageId = id; //update for rendering in SendMessage
    if(document.getElementById('send-message_message_text')){ //if the input div is visible, then focus on it when replying.
        document.getElementById('send-message_message_text').focus();
    }
}

/**
 * Removes reply from draft of current discussion.
 * Always for current discussion (triggered by click on screen).
 */
export function stopReplying() {
    if(!globals.data.discussions.has(globals.currentDiscussion)){
        return;
    }
    if(!globals.data.discussions.get(globals.currentDiscussion).draft){
        return;
    }
    globals.data.discussions.get(globals.currentDiscussion).draft.stopReplying();
    globals.variables.replyMessageId = 0; //update for rendering in SendMessage
}

/**
 * Save draft for discussion identified by discussionId. Saves draft locally only (in local list of draft messages).
 * @param {number} discussionId
 */
export function saveDraft(discussionId) {
    if(!document.getElementById("send-message_message_text")){
        return; //input div doesn't exist, can't save draft
    }
    let currentDraft = document.getElementById("send-message_message_text").innerText; 
    if(!globals.data.discussions.has(globals.currentDiscussion)){
        return;
    }
    if(!globals.data.discussions.get(discussionId).draft){
        let draft = new Draft( -1, discussionId, currentDraft, "", "", 0, "", "", "", 0);
        globals.data.discussions.get(discussionId).draft = draft;
    } else {
        globals.data.discussions.get(discussionId).draft.contentBody = currentDraft;
    }
}

/**
 * Saves draft on the application and deletes draft on the webclient side : used to switch between the two platforms mid-message.
 * @param {number} discussionId 
 */
export function sendDraftToApp(discussionId) {
    if(!document.getElementById("send-message_message_text")){
        return; //input div doesn't exist, can't save draft
    }
    if(!globals.data.discussions.has(discussionId)){
        return;
    }
    let currentDraft = document.getElementById("send-message_message_text").innerText;
    let currentlySavedDraft = globals.data.discussions.get(discussionId).draft;
    let draftFiles = globals.data.discussions.get(discussionId).draftAttachments;
    if(currentlySavedDraft){ //there is a draft (body or reply)
        if((!currentDraft || currentDraft === "") && currentlySavedDraft.replyMessageId === 0 && (!draftFiles || draftFiles.length < 1)){ //new body is null and there is no reply, delete draft
            requestDeleteDraftMessage(discussionId);
        } else { //message has either a body a reply, or attachments
            requestSaveDraftMessage(discussionId, currentDraft.trim(), currentlySavedDraft.replyMessageId);
        }
        //not keeping draft in webclient
        globals.data.discussions.get(discussionId).draft = null;
    } else {
        if(!(!currentDraft || currentDraft === "") ) { //do not save if just a reply message
            requestSaveDraftMessage(discussionId, currentDraft.trim(), 0); //we can put 0 : no draft means no reply
            //no current draft so no need to delete it
        }
    }
}

/**
 * Returns the Message identified by given Id from the global list of messages.
 * @param {number} id 
 * @returns {Object} 
 */
function findMessageFromId(id) {
    let correspondingMessage = null;
    globals.data.messagesByDiscussion.forEach((messages) => {
        messages.forEach((message) => {
            if(message.id === id){
                correspondingMessage = message;
            }
        });
    });
    return correspondingMessage;
}

/**
 * Checks whether there are attachments still being added into the draft (uploading) at this moment.
 * @returns {boolean}
 */
export function areAttachmentsBeingAddedToDraft() {
    let currentDraftAttachments = globals.data.discussions.get(globals.currentDiscussion).draftAttachments;
    if(currentDraftAttachments) {
        let nbAttachments = currentDraftAttachments.length;
        for(let i=0 ; i< nbAttachments ; i++){
            if(currentDraftAttachments[i].status === protobuf.DraftAttachmentStatus.LOADING){ //if we find an attachment still loading, return
                return true;
            }
        }
    }
    return false;
}

/**
 * Cancels all currently uploading draft attachments for all discussions. Used when leaving page.
 * Declaring attachments as FAILED will stop process of sending the chunks.
 */
export function cancelAllDraftAttachmentUploads() {
    for (let [key] of globals.data.discussions) {
        for(let attachment of globals.data.discussions.get(key).draftAttachments) {
            if(attachment.status === protobuf.DraftAttachmentStatus.LOADING){
                // let attachment = findDraftAttachmentInListFromLocalId(file.localId);
                //declare upload as FAILED here, this will stop the function sending the chunks and update render of attachment on screen.
                attachment.status = protobuf.DraftAttachmentStatus.FAILED;
            } 
        }
    }
}

/**
 * Adds a new DraftAttachment in the current list of draft attachments for a given discussion. 
 * @param {number} discussionId 
 * @param {DraftAttachment} draftAttachment 
 */
export function addToDraftAttachmentList(discussionId, draftAttachment) {
    if(globals.data.discussions.has(discussionId)) {
        globals.data.discussions.get(discussionId).addDraftAttachment(draftAttachment);
    }
}

/**
 * Remove DraftAttachment identified by localId from list of attachments for given discussion.
 * @param {number} discussionId 
 * @param {number} localId 
 */
export function removeDraftAttachmentInListFromLocalId(discussionId, localId) {
    if(globals.data.discussions.has(discussionId)) { //discussion has draft attachment
        globals.data.discussions.get(discussionId).removeFromDraftAttachmentsUsingLocalId(localId);
    }
}