<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div class="attachment">
        <div v-if="attachment.downloadFailed()" class="attachment_actions" :class="globals.data.thumbnails[realFyle.path] ? 'thumbnail' : 'icon'">
            <i @click="downloadAttachment()" class="attachment_action--image--download fa fa-sync" aria-hidden="true"></i>
        </div>
        <div v-if="attachment.isDownloading()" class="attachment_actions" :class="globals.data.thumbnails[realFyle.path] ? 'thumbnail' : 'icon'">
            <div class="spinner-border spinner-border-sm attachment_action--image--loading" role="status" aria-hidden="true">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        
        <div class="attachment_image" @click="openAttachment()" :title="attachment.name">
            <div v-if="globals.data.thumbnails[realFyle.path]">
                <img
                    :src="getThumbnailFromURL()"
                    alt="thumbnail"
                    class="attachment_image_thumbnail">
                <div v-show="realFyle.notDownloaded() && realFyle.mime === 'image/gif'" class="gif-image-logo">GIF</div>
            </div>

            <div v-else class="attachment_image_icon">
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    viewBox="0 0 24 24" >
                <path d="M21,19V5c0,-1.1 -0.9,-2 -2,-2H5c-1.1,0 -2,0.9 -2,2v14c0,1.1 0.9,2 2,2h14c1.1,0 2,-0.9 2,-2zM8.5,13.5l2.5,3.01L14.5,12l4.5,6H5l3.5,-4.5z" />
                </svg>
                <p class="text--color attachment_image_name" :title="attachment.name">{{attachment.name}}</p>
            </div>
        </div>
        
    </div>
</template>

<script>
import {globals} from "@/assets/ext/globals";
import {requestDownloadAttachment} from "@/assets/ext/messages/messageSender";
import {NotifShowGallery} from "@/assets/ext/events";
import {isGifImage} from "@/assets/ext/images";
export default {
    name: 'ImageAttachment',
    props: {
        attachment : {
            type:Object,
            required: true
        },
        realFyle : {
            type:Object,
            required:true
        }
    },
    data () {
        return {
            // using for gif silent downloads
            silentDownloading: false,
            globals: globals
        }
    },
    methods : {
        /**
         * Starts downloading attachment process by changing status and calling appropriate method.
         */
        downloadAttachment() {
            if(this.realFyle.isADisplayableImage()){
                let index = globals.data.imagesForGallery.indexOf(this.attachment.localId);
                document.getElementById('index-fyle-on-show').innerText = index;
            }
            // do not restart download if already silent downloading
            if (!this.silentDownloading) {
                this.realFyle.updateStatusDownload();
                this.$set(globals.data.realFyles, this.realFyle.fyleId, this.realFyle); //to update status
                this.attachment.updateStatusDownload();
                requestDownloadAttachment(this.realFyle.fyleId, this.realFyle.size);
            }
        },

        /**
         * Silently launches the download of the image : realFyle status is changedm but not attachment no UI not updated.
         */
        silentDownloadAttachment() {
            // do not restart download if already silent downloading
            if (this.silentDownloading) {
                return ;
            }
            this.silentDownloading = true;
            this.realFyle.updateStatusDownload();
            requestDownloadAttachment(this.realFyle.fyleId, this.realFyle.size);
        },
        /**
         * Adds prefix for displaying base64 Strings in HTML tag <img> src.
         * @returns {string} 
         */
        getThumbnailFromURL() {
            if (isGifImage(this.realFyle.mime)
                && this.realFyle.size < globals.constants.MAX_GIF_SIZE_FOR_AUTO_DOWNLOAD) {
                this.silentDownloadAttachment();
            }
            return "data:image/png;base64,"+globals.data.thumbnails[this.realFyle.path];
        },
        /**
         * Redirects click to correct function : open Gallery if image, open File in new Tab otherwise (if possible).
         */
        openAttachment() {
            if(this.realFyle.availableForDownload()) { //not downloaded
                this.downloadAttachment();
            } else if(this.realFyle.isDownloaded()) { //downloaded
                this.attachment.updateStatusDownloadDone(true); //in case this wasn't the attachement that launched the download
                this.zoomIn();
            }//do nothing if currently downloading
        },
        /**
         * Zooms in on an image : opens it in custom Gallery by changing appropriate CSS properties.
         */
        zoomIn() {
            if(this.realFyle.content == null || this.realFyle.mime == null){
                return;
            }
            if(this.realFyle.isADisplayableImage()){
                let index = globals.data.imagesForGallery.indexOf(this.attachment.localId);
                document.getElementById('index-fyle-on-show').innerText = index;
                const event = new CustomEvent(NotifShowGallery.type, {
                    detail: {
                        localId: this.attachment.localId
                    }
                });
                //handled in Gallery.vue
                document.dispatchEvent(event);
            } else { //act like a file
                if(this.realFyle.urlInWebClient != null) {
                    var win = window.open(this.realFyle.urlInWebClient, '_blank', 'noopener,noreferrer');
                    win.focus();
                } else {
                    if (confirm(this.$i18n.t('attachments.labelDownloadAgain'))) {
                        this.downloadAttachment();
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

.attachment {
    margin-left:5px;
    margin-right:5px;
    position: relative;
    width: inherit;
    height: inherit;
}

.attachment_image svg {
    fill:var(--transparent-light-grey);
}

.attachment_actions {
    cursor: pointer;
    position: absolute;
    width: 70px;
    height: 70px;
    left: calc(50% - 35px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.icon {
    top: calc(50% - 40px);
}

.thumbnail {
    top: calc(50% - 35px);
}

.attachment_action--image--download{
    cursor: pointer;
    color: var(--light-blue);
    font-size: 2rem;
}

.attachment_action--image--loading {
    cursor: pointer;
    color: var(--light-blue);
    width: 2rem;
    height: 2rem;
    border-width: 6px;
}

.attachment_image_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    height: 120px;
}

.attachment_image_name {
    width: fit-content;
    max-width: 85px;
    margin:0;
    height: fit-content; /* older Safari, Opera, Chrome, Edge */
    height: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    height:-moz-fit-content; /* Firefox */
    font-size: 10px;
    cursor: default;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.attachment_image_thumbnail {
    height:120px;
    width:120px;
    border-radius: 12px;
    object-fit: cover;
    cursor: pointer;
}

.fa-download g g path {
    stroke: var(--black);
    stroke-width: 10;
}

.fa-sync g g path {
    stroke: var(--black);
    stroke-width: 10;
}

.gif-image-logo {
    background-color:var(--lighter-grey);
    color:var(--black);
    font-size: 10px;
    font-weight: bold;
    border-radius: 5px;
    width: fit-content; /* older Safari, Opera, Chrome, Edge */
    width: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    width:-moz-fit-content; /* Firefox */
    height: fit-content; /* older Safari, Opera, Chrome, Edge */
    height: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    height:-moz-fit-content; /* Firefox */
    position: absolute;
    padding:2px 5px 2px 5px;
    top: 10px;
    right: 20px;
}

</style>