/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";
import {findDraftAttachmentInListFromLocalId} from "@/assets/ext/attachments";
import {removeDraftAttachmentInListFromLocalId} from "@/assets/ext/draft";

export function notif_upload_result(colissimo) {
	if(!colissimo.notifUploadResult){
		return;
	}
	let resultCode = colissimo.notifUploadResult.resultCode;
	let draftAttachment = findDraftAttachmentInListFromLocalId(colissimo.notifUploadResult.localId);
	if(!draftAttachment){
		return;
	}
	switch (resultCode) { //App sends back a result code to describe result of upload
		case 0: //fail : internal error or not all chunks received in time
			draftAttachment.status = protobuf.DraftAttachmentStatus.FAILED;
			break;
		case 1 : //success without EXIF (meaning file has not been altered so identifiable by its SHA256)
			draftAttachment.progress=100;
			//will be marked as ready when receiving NOTIF_NEW_DRAFT_ATTACHMENT, true sign that attachment actually in draft
			break;
		case 2 : //success with EXIF modification
			//The attachment's SHA256 has been altered in App, and we identify draft attachments by their SHA256 when they arrive in NOTIF_NEW_DRAFT_ATTACHMENT.
			//The App will send a NOTIF_NEW_DRAFT_ATTACHMENT containing this attachment later, but because the sha256 isn't the same and localId is not in the colissimo, we won't be able to associate the received attachment and this one.
			//We need to delete this draft attachment for the time being so that it can be added again with the NOTIF_NEW_DRAFT_ATTACHMENT colissimo (same process than receiving a draft attachment that was added directly from the App).
			draftAttachment.progress=100;
			removeDraftAttachmentInListFromLocalId(draftAttachment.discussionId, draftAttachment.localId); //remove from file, will be added by message NOTIF_NEW_DRAFT_ATTACHMENT
			break;
		default: //default, declare as failed
			draftAttachment.status = protobuf.DraftAttachmentStatus.FAILED;
			break;
	}
}
