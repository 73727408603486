/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/

import {globals} from "@/assets/ext/globals";
import {getThumbnail} from "@/assets/ext/images";
import {Discussion} from "@/assets/datatypes/Discussion.js";
import {updateFavicon} from "@/assets/ext/messages/handlers/notif_discussion_updated";
import * as events from "@/assets/ext/events";

export function notif_new_discussion(colissimo) {
	if (!colissimo.notifNewDiscussion) {
		return;
	}

	// just to be sure ...
	if(!globals.data.discussions){
		globals.data.discussions = new Map();
	}

	// create new discussions and store them in a temporary list
	let newDiscussions = new Array();
	colissimo.notifNewDiscussion.discussions.forEach(discussion => {
		newDiscussions.push(discussion.id);
		if(globals.data.discussions.has(discussion.id)){ //for reloads, fetch previous draft before creating new discussion draft
			globals.data.discussions.get(discussion.id).updateFromProtobuf(discussion);
		} else {
			globals.data.discussions.set(discussion.id, new Discussion(
				discussion.id,
				discussion.title,
				discussion.contactIdentity,
				discussion.groupOwnerAndUid,
				discussion.unreadMessagesCount,
				discussion.lastMessage,
				discussion.photoURL,
				discussion.discussionTimestamp,
				0,
				false,
				null,
				null //either null or saved draft
			));
		}
		getThumbnail(discussion.photoURL); //immediately ask for the discussion thumbnail
	});

	updateFavicon();

	// notify Body.vue that discussions were added
	const event = new CustomEvent(events.NotifNewDiscussion.type, {
		detail: {
			discussionIds: newDiscussions
		}
	});
	document.dispatchEvent(event);//handled in Body.vue

}
