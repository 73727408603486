/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
function concatenateUint8Array(a, b) {
    const array = new Uint8Array(a.length + b.length);
    array.set(a);
    array.set(b, a.length);
    return (array);
}

/**
 * Compare two Uint8Arrays and return a boolean
 * @param {Uint8Array} b1
 * @param {Uint8Array} b2
 * @returns {boolean} isEqual
 */
function areUint8ArrayEquals(b1, b2) {
    if (b1.length !== b2.length) {
        return false;
    }
    for (let i=0; i<b1.length; i++) {
        if (b1[i] !== b2[i]) {
            return false;
        }
    }
    return true;
}

module.exports = {
    concatenateUint8Array,
    areUint8ArrayEquals
}