/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
import * as events from "@/assets/ext/events";

export function notif_delete_discussion(colissimo) {
	if(!colissimo.notifDeleteDiscussion){
		return;
	}
	let discussion = colissimo.notifDeleteDiscussion.discussion;
	if(!discussion) {
		return;
	}
	//delete all local data referencing this discussion
	if (globals.data && globals.data.discussions) {
		globals.data.discussions.delete(discussion.id);
	}
	if (globals.data && globals.data.messagesByDiscussion) {
		globals.data.messagesByDiscussion.delete(discussion.id);
	}
	//Notify Body.vue to update list of discussions on screen and close the discussion if it was open.
	const event = new CustomEvent(events.NotifDeleteDiscussion.type, {
		detail: {
			discussionId:discussion.id
		}
	});
	document.dispatchEvent(event);//handled in Body.vue
}
