/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {PRNGHmacSHA256} from "@/assets/ext/crypto/PRNGHmacSHA256";
import {areUint8ArrayEquals} from "@/assets/ext/crypto/tools/uint8ArrayTools";

export class CommitmentWithSha256 {
    static COMMITMENT_RANDOM_LENGTH = 32;

    /**
     * Create a commitment and decommitment pair from an tag and a value as input
     * @param {Uint8Array} tag
     * @param {Uint8Array} value
     * @returns {Object} commitment, decommitment attributes as Uint8Array
     */
    static async commit(tag, value) {
        const prng = new PRNGHmacSHA256(null);
        await prng.init();

        const e = prng.bytes(CommitmentWithSha256.COMMITMENT_RANDOM_LENGTH);
        const decommitment = new Uint8Array(value.length + CommitmentWithSha256.COMMITMENT_RANDOM_LENGTH);
        decommitment.set(value);
        decommitment.set(e, value.length);

        const input = new Uint8Array(tag.length + value.length + CommitmentWithSha256.COMMITMENT_RANDOM_LENGTH);
        input.set(tag);
        input.set(decommitment, tag.length);

        const commitment = new Uint8Array(await crypto.subtle.digest("SHA-256", input));
        return ({commitment: commitment, decommitment: decommitment});
    }

    /**
     * Try to open a decommitment. Throw an exception if
     * @throws if unable to verify decommitment
     * @param {Uint8Array} tag
     * @param {Uint8Array} commitment
     * @param {Uint8Array} decommitment
     * @returns {Uint8Array} decommitment
     */
    static async open(tag, commitment, decommitment) {
        const input = new Uint8Array(tag.length + decommitment.length);
        input.set(tag);
        input.set(decommitment, tag.length);

        const commitment2 = new Uint8Array(await crypto.subtle.digest("SHA-256", input));
        if (areUint8ArrayEquals(commitment, commitment2)) {
            return (decommitment.slice(0, -1 * CommitmentWithSha256.COMMITMENT_RANDOM_LENGTH));
        }
        else {
            throw 'Unable to verify decommitment';
        }
    }
}
