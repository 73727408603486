<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div id="app-body" class="main-background">
        <div id="blur"></div>
        <div id="modal-phone-disconnected" class="blueGradient">
            <div id="modal-phone-disconnected_content">
                <span v-html="$i18n.t('modals.phoneDisconnectedLabel')"></span>
                <div id="modal-phone-disconnected_content_button"><button class="button-leave" @click="leavePage()">{{ $t("modals.labelLeaveWebClient") }}</button></div>
            </div>
        </div>
        <div id="modal-webapp-disconnected" class="blueGradient">
            <div id="modal-webapp-disconnected_content">
                <span v-html="$i18n.t('modals.webappDisconnectedLabel')"></span>
                <div id="modal-webapp-disconnected_content_button"><button class="button-leave" @click="leavePage()" >{{ $t("modals.labelLeaveWebClient") }}</button></div>
            </div>
        </div>
        <div id="modal-leave" class="blueGradient">
            <div id="modal-leave_content">
                <span>{{ $t('modals.labelLogOutConfirmation') }}</span>
                <div id="modal-leave_content_buttons"><button class="button-leave" @click="leavePage()">{{ $t("modals.labelLeaveWebClient") }}</button><button class="button-stay" @click="closeLeaveModal()">{{ $t("modals.labelStayWebClient") }}</button></div>
            </div>
        </div>
        <Gallery/>
        <!-- Main content of the component -->
        <div id="split">
            <div id="page_header" class="blueGradient"> 
                <i id="logout" class="fas fa-sign-out-alt fa-flip-horizontal" @click="logout()" :title="$t('instructions.labelLogOut')"></i>
                <img id="logo-olvid--small" :src="images.olvid" alt="Olvid"/>
                <div id="global-actions">
                    <a :href="$t('help.link')" target="_blank"><i id="button-help" class="fa fa-question-circle" :title="$t('help.labelHelp')"></i></a>
                    <i id="button-settings" class="fas fa-cog" @click="openSettings()"  :title="$t('settings.labelSettings')"></i>
                    <i id="button-sync" @click="reloadPageContent()" class="fa fa-sync" :title="$t('instructions.labelReload')"></i>
                </div>
            </div>
            <div id="split_left">
                <DiscussionList :discL="discussionsSorted" ref="discussionList"/>
            </div>
            <div id="split_right">
                <Conversation v-if="discussionClicked" :listMessagesInfo="listMessagesInfo" ref="conversation"/>
                <div v-else id="select-conversation-label" class="text--color">
                    {{ $t("conversation.selectADiscussionLabel") }}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import DiscussionList from '@/components/DiscussionList.vue';
import Conversation from '@/components/Conversation.vue';
import Gallery from '@/components/Gallery.vue';
import oc from '@/assets/ext/client.js';
import {saveDraft, cancelAllDraftAttachmentUploads} from '@/assets/ext/draft';
import router from '@/router';

export default {
	name: 'Body',
    components: {
        DiscussionList,
        Conversation,
        Gallery
    },
    data () {
        return {
            images : {
                olvid : require("@/assets/images/logo-olvid.png"),
            },
            //prop for Conversation : when a value in this changes, Conversation is re-rendered
            listMessagesInfo : {
                messagesDiscussions:[], //list of all messages for selected discussion, eventually updated
                lastReceivedType:null, //type of last message received for selected discussion
                //the three following values should be set in each event listener concerning messages (new message, delete message, new batch)
                //for scrolling
                shouldScrollToBottom:null, //was discussion clicked for this change to happen
                isResultOfDelete:null, //is this update because something (message or attachment) was deleted 
                receivedBatchOldMessages:null //received a batch of old messages
            },
            oc:oc,
            saveDraft:saveDraft,
            router:router,
            discussionClicked:false,
            discussionsSorted : [], //list of sorted discussions to pass to DiscussionList 
            sortedMessagesDiscussions: null, //keep Map (discussionId => Array (sorted messages) ) to switch between conversations without reloading
            shouldScrollToBottom:false,
            isResultOfDelete:false,
            pingInterval : null,
        }
    },
    methods : {
        /**
         * Sorts an array of discussions according to their timestamps (descending order - latest timestamp first).
         * @param {Object} a
         * @param {Object} b
         * @returns {boolean}
         */
        sortDiscussionByTimestamp(a, b) {
            if (a.discussionTimestamp === b.discussionTimestamp) {
                return 0;
            } else if (a.discussionTimestamp == null) {
                return 1;
            } else if (b.discussionTimestamp == null) {
                return -1;
            }
            return b.discussionTimestamp - a.discussionTimestamp;
        },

        /**
         * Sorts an array of messages according to their sort index.
         * @param {Object} a
         * @param {Object} b
         * @returns {boolean}
         */
        sortMessageBySortIndex(a,b) {
            return (a.sortIndex - b.sortIndex);
        },

        /**
         * Fills up array discussionsSorted to pass as prop to DiscussionList. Re-renders list of discussions when discussionsSorted changes.
         * Sorts discussion by timestamp of its last message : when a discussion receives a new message it's immediately placed at top of list.
         */
        fillDiscussion() {
            if (!oc.globals.data.discussions || oc.globals.data.discussions.length === 0) {
                console.warn("BODY ERROR : No discussions found");
                return;
            }
            this.discussionsSorted = [];
            const sortedElements = Array.from(oc.globals.data.discussions.values());
            sortedElements.sort(this.sortDiscussionByTimestamp);
            for (let element of sortedElements) {
                this.discussionsSorted.push(element);
            }
        },

        /**
         * Fills up and updates sortedMessagesDiscussions for currentDiscussion. Sets listMessagesInfo with latest information to be passed as prop to Conversation.vue for updating and scrolling.
         * Argument lastMessage is the last message recevied for this conversation. It is not always the last message of the conversation (eg. asking for older messages). used to situatethe data this event is in response to (older message, newer message).
         * Argument may not be needed because of message batching.
         * @param {Message} lastMessage
         */
        fillConversation(lastMessage) {
            const discussionId = oc.globals.currentDiscussion;
            if (!discussionId || !oc.globals.data.messagesByDiscussion || !oc.globals.data.messagesByDiscussion.has(discussionId)) {
                // console.warn("BODY ERROR : No discussion selected");
                return;
            }
            let tempObject;
            if(!oc.globals.data.messagesByDiscussion.get(discussionId) || oc.globals.data.messagesByDiscussion.get(discussionId).size === 0){ //no messages in conversation
                tempObject = {messagesDiscussions:[], 
                                lastReceivedType:null, 
                                lastMessageReceived:null, 
                                shouldScrollToBottom:this.shouldScrollToBottom, 
                                isResultOfDelete:this.isResultOfDelete, 
                                receivedBatchOldMessages:this.receivedBatchOldMessages};
            } else {
                const sortedMessages = Array.from(oc.globals.data.messagesByDiscussion.get(discussionId).values());
                sortedMessages.sort(this.sortMessageBySortIndex); //sort all messages by timestamps
                this.sortedMessagesDiscussions.set(discussionId,sortedMessages); //save sorted array for eventual later use (refillConversation)
                tempObject = {messagesDiscussions:sortedMessages, 
                                lastReceivedType:sortedMessages[sortedMessages.length - 1].status - 1, 
                                lastMessageReceived:lastMessage, 
                                shouldScrollToBottom:this.shouldScrollToBottom, 
                                isResultOfDelete:this.isResultOfDelete, 
                                receivedBatchOldMessages:this.receivedBatchOldMessages};
            }
            this.listMessagesInfo = Object.assign({},tempObject); //assign forces re-render
        },

        /**
         * Method called when leaving WebClient for good : after user clicked on 'Leave' in modal waiting for reconnection.
         * Redirects to home page.
         */ 
        async leavePage() {
            oc.globals.favico.reset();//clear favicon notification badge
            if(oc.globals.status === oc.globals.STATUS_READY) {
                await oc.messageSender.sendBye();
            } else if(oc.globals.status === oc.globals.STATUS_RECONNECTING){
                if(oc.websocket.timeout){
                    clearTimeout(oc.websocket.timeout);
                }
            }
            if(oc.globals.variables.customUrl){
                router.push({name:'connecting', query: {serverUrl:oc.globals.variables.customUrl}}).catch( () => {});
            } else {
                router.push({name:'connecting'}).catch( () => {});
            }
        },
        /**
         * Method called when clicking on the sync button. Reloads all discussions and subscribes again.
         */
        reloadPageContent() {
            //save draft of current conversation before reloading
            let currDisc = oc.globals.currentDiscussion;
            if(currDisc && this.discussionClicked) { //a discussion is currently open
                saveDraft(oc.globals.currentDiscussion);
            }
            oc.globals.data.lastMessageSent = null;
            this._closeConversation();
            oc.messageSender.requestDiscussions();
            document.getElementById('discussion-list_loading-spinner').style.display = "inline-block";
            document.getElementById("discussion-list_search-bar").style.display = "none";
            document.getElementById("no-discussions").style.display = "none";
            oc.globals.favico.reset();//clear favicon notification badge
        },

        /**
         * Close modal when option Stay has been clicked when attempting to leave web client.
         */
        closeLeaveModal() {
            document.getElementById("modal-leave").style.display = "none";
            document.getElementById("blur").style.display="none";
        },

        /*Handling events methods : these are used with addEventListeners on the document*/

        /**
         * Handler for colissimo REFRESH (when identity changed on device)
         */
        async _syncRequired() {
            this.reloadPageContent()
        },
        /**
         * Handler for listener on colissimo REQUEST_DISCUSSIONS_RESPONSE.
         */
        async _requestDiscussionResponse() {
            await this.fillDiscussion();
            document.getElementById("scrollable-discussion-list").style.display = "block";
            document.getElementById("discussion-list_loading-spinner").style.display = "none";
            document.getElementById("discussion-list_search-bar").style.display = "flex";

            //either request 0 if no discussion found ; request 2 discussions maximum (can be changed)
            let requestNumber = this.discussionsSorted.length >= 2 ? 2 : this.discussionsSorted.length;
            for(let i = 0; i < requestNumber; i++) {
                // set requested message number for requested discussion, do not remove old data, they will be updated by REQUEST_MESSAGE_RESPONSE
                // this.discussionsSorted[i].incrementNumberRequested();
                oc.messageSender.subscribeToDiscussion(this.discussionsSorted[i].id, oc.globals.constants.STEP_NB_REQUEST_MESSAGES); 
            }
        },
        /**
         * Handler for listener on colissimo NOTIF_DISCUSSION_UPDATED. Handles notifications in case of new messages.
         * @param {event} e
         */
        _notifDiscussionUpdated(e) {
            if (e.detail.discussion.lastMessage != null && 
                e.detail.discussion.lastMessage.status === oc.protobuf.MessageStatus.STATUS_UNREAD && 
                (e.detail.discussion.lastMessage.type === oc.protobuf.MessageType.INBOUND_MESSAGE || 
                e.detail.discussion.lastMessage.type === oc.protobuf.MessageType.TYPE_INBOUND_EPHEMERAL_MESSAGE) && 
                (e.detail.discussion.id !== oc.globals.currentDiscussion || !oc.globals.variables.isWindowActive)) {
                    if(oc.globals.parameters.notificationsSoundEnabled){ //play notification sound if not muted
                        let audio = document.getElementById('notif-sound');
                        if(audio){
                            audio.play().catch(()=>{}); //play will fail if user didn't interact with document before
                        }
                    }
                    if(oc.globals.parameters.showNotificationOnScreen){
                        this.$toasted.global.notif_new_message(e.detail.discussion.title + ": " + e.detail.discussion.lastMessage.contentBody);
                    }
            }

            if(e.detail.discussion.lastMessage != null && e.detail.discussion.lastMessage.status === oc.protobuf.MessageStatus.STATUS_UNREAD){ //active on screen, mark it as read if last message is new
                if(oc.globals.parameters.isWindowActive && this.$refs.conversation.secondToLastisVisible()){//not great accessing this method via ref
                    oc.messageSender.markDiscussionAsRead(e.detail.discussion.id);
                }
            }  
            this.fillDiscussion();
        },
        /**
         * Handler for listener on colissimo NOTIF_NEW_DISCUSSION. Handles notifications in case of new discussions.
         */
        _notifNewDiscussion() {
            // only re render, there is nothing else to do
            this.fillDiscussion();
        },
        /**
         * Handler for listener on colissimo REQUEST_MESSAGES_RESPONSE.
         * @param {event} e
         */
        _requestMessageResponse(e) {
            // discussion first batch, scroll to bottom
            if (oc.globals.currentDiscussion && oc.globals.data.discussions.has(oc.globals.currentDiscussion)) {
                if(!oc.globals.data.discussions.get(oc.globals.currentDiscussion).receivedFirstBatch){
                    this.shouldScrollToBottom=true; //scroll to bottom for first batch.
                    this.receivedBatchOldMessages = false;
                } else { //not the first batch for this discussion
                    this.shouldScrollToBottom = false;
                    this.receivedBatchOldMessages = true;
                }
            }
            this.isResultOfDelete=false;
            this.fillConversation(e.detail.lastMessage);
            if(document.getElementById('conversation_initial-spinner')){
                document.getElementById('conversation_initial-spinner').style.display = "none";
            }
        },

        /**
         * Handler for listener on colissimo NOTIF_NEW_MESSAGE. 
         * @param {event} e
         */
        _notifNewMessage(e) {
            this.shouldScrollToBottom=false; //not scrolling to bottom when receiving a new message
            this.isResultOfDelete=false;
            this.receivedBatchOldMessages = false;
            this.fillConversation(e.detail.lastMessage);
        },

        /**
         * Handler for listener on colissimo NOTIF_DELETE_MESSAGE. Updates visible list of messages if necessary.
         * @param {event} e
         */
        _notifDeleteMessage() {
            this.isResultOfDelete = true;
            this.shouldScrollToBottom=false;
            this.receivedBatchOldMessages = false;
            this.fillConversation(null) //don't need lastMessage in this context
        },
        /**
         * Handler for listener on colissimo NOTIF_DELETE_DISCUSSION. Updates list of discussions and hide conversation until other discussion clicked.
         * @param {event} e
         */
        _notifDeleteDiscussion(e) {
            let deletedDiscId = e.detail.discussionId;
            if(deletedDiscId === oc.globals.currentDiscussion) {
                this.discussionClicked = false;
                oc.globals.currentDiscussion = null;
            }
            this.fillDiscussion();
        },     
        /**
         * Handles clicking on a discussion from DiscussionList. Updates displayed conversation and marks selected discussion as read if necessary.
         */
        clickDiscussion() {
            this.discussionClicked=true; //afficher la conversation
            this.shouldScrollToBottom=true;
            this.isResultOfDelete=false;
            this.receivedBatchOldMessages = false;

            if(!this.sortedMessagesDiscussions.has(oc.globals.currentDiscussion)) { //first time clicking
                // document.getElementById('conversation_initial-spinner').style.display = "flex";
                this.sortedMessagesDiscussions.set(oc.globals.currentDiscussion, new Array());
                this.fillConversation();
                if(oc.globals.data.discussions.get(oc.globals.currentDiscussion).unreadMessagesCount > 0){
                    oc.messageSender.markDiscussionAsRead(oc.globals.currentDiscussion); //mark as read when clicking
                }
            } else {//already clicked and updated since
                this.fillConversation();
                if(oc.globals.data.discussions.get(oc.globals.currentDiscussion).unreadMessagesCount > 0){
                    oc.messageSender.markDiscussionAsRead(oc.globals.currentDiscussion); //mark as read when clicking
                }
            }
        },
        /**
         * Opens Settings modal.
         */
        openSettings() {
            document.getElementById('global-settings').style.display="block";
        },
        /**
         * Opens custom Modal for confirmation before leaving web client.
         */
        async logout() {
            cancelAllDraftAttachmentUploads();
            document.getElementById("modal-leave").style.display = "flex"; //show modal and answer processed in Body.vue
            document.getElementById("blur").style.display = "flex";
        },

        /**
         * Closes current conversation and resets data accordingly. Used when reloading webclient, so cleaning variables at the same time.
         */
        _closeConversation() {
            this.discussionClicked = null;
            this.discussionsSorted=[]
            this.sortedMessagesDiscussions = new Map();
            this.$refs.discussionList.showByIndex = null;
            this.$refs.discussionList.clickedDiscussion = null;
            document.getElementById('discussion-list_loading-spinner').style.display = "inline-block";
            document.getElementById("discussion-list_search-bar").style.display = "none";
            document.getElementById("no-discussions").style.display = "none";
        },
    },

    created() {
        //if state is incoherent (eg reload)
        if(oc.globals.status !== oc.globals.STATUS_READY) {
            this.leavePage();
            return;
        }
        oc.messageSender.requestDiscussions();
        this.sortedMessagesDiscussions = new Map();
        //Event Listeners : all event listeners are in Body and child components are updated accordingly
        // reload page
        document.addEventListener(oc.events.SyncRequired.type, this._syncRequired);
        // discussion
        document.addEventListener(oc.events.RequestDiscussionResponse.type, this._requestDiscussionResponse);
        document.addEventListener(oc.events.NotifNewDiscussion.type, this._notifNewDiscussion);
        document.addEventListener(oc.events.NotifDiscussionUpdated.type, this._notifDiscussionUpdated);
        // messages
        document.addEventListener(oc.events.RequestMessageResponse.type, this._requestMessageResponse);
        document.addEventListener(oc.events.NotifNewMessage.type, this._notifNewMessage);
        //deleted elements
        document.addEventListener(oc.events.NotifDeleteMessage.type, this._notifDeleteMessage);
        document.addEventListener(oc.events.NotifDeleteDiscussion.type, this._notifDeleteDiscussion);
        document.addEventListener(oc.events.NotifCloseConversation.type, this._closeConversation);

        /**
         * Handles clicking on a discussion. Emitted from DiscussionList. 
         * Handles first time clicking, refilling a non-updated conversation, filling and marking as read an updated conversation.
         */
        this.$root.$on('clickDiscussion', this.clickDiscussion);

        //ping App every 5 minutes to keep connection alive 
        this.pingInterval = setInterval(() => {
            oc.messageSender.pingApp();
        }, oc.globals.constants.PING_INTERVAL);
    },
     
    beforeDestroy() {
        document.removeEventListener(oc.events.SyncRequired.type, this._syncRequired);
        document.removeEventListener(oc.events.RequestDiscussionResponse.type, this._requestDiscussionResponse);
        document.removeEventListener(oc.events.NotifDiscussionUpdated.type, this._notifDiscussionUpdated);
        document.removeEventListener(oc.events.NotifNewMessage.type, this._notifNewMessage);
        document.removeEventListener(oc.events.RequestMessageResponse.type, this._requestMessageResponse);
        document.removeEventListener(oc.events.NotifDeleteMessage.type, this._notifDeleteMessage);
        document.removeEventListener(oc.events.NotifNewDiscussion.type, this._notifNewDiscussion);
        document.removeEventListener(oc.events.NotifDeleteDiscussion.type, this._notifDeleteDiscussion);
        document.removeEventListener(oc.events.NotifCloseConversation.type, this._closeConversation);
        this.$off('clickDiscussion', this.clickDiscussion);
        clearInterval(this.pingInterval);
    }
}
</script>

<style scoped>

#app-body {
    min-height:100%;
    border:none;
}

#page_header {
    position: fixed;
    top:0;
    left:0;
    width:inherit;
    flex-shrink: 0;
    font-size: 1.4rem;
    color:var(--all-white);
    height:50px; /**This height should match padding in Conversation.vue and DiscussionList.vue for messages-wrapper and scrollable-discussion-list respectively */
    text-decoration:none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index:10;
}

#logo-olvid--small {
    height:30px;
}

#global-actions {
    margin-right:20px;
}

i {
    margin:0 10px 0 10px;
    cursor: pointer;
}

#button-settings, #button-help, #logout {
    color: var(--all-white);
    font-size: 1.6rem;
}
          
#split {
    width:100%;
    min-height:100vh;
}

#split_left {
    width:30%;
    float:left;
    top:0;
    height: 100%;
    min-height: 100%;
    box-shadow: 5px 0 5px -5px var(--regular-grey);
    z-index: 2;
    position: relative;
}

#split_right {
    width:70%;
    float:right;
    top:0;
    height: 100%;
    min-height: 100%;
}

#select-conversation-label {
    font-size:35px;
    position: absolute;
    top: 50%;
    margin-top: -48px;
    width: inherit;
    text-align: center;
}

#blur {
    display:none;
    position: fixed;
    width: 100%;
    height:100%;
    background-color: var(--dark-grey);
    z-index: 500; /**above everything else except modals*/
}

@supports (backdrop-filter: blur(15px)) or (-webkit-backdrop-filter:blur(15px)) {
  #blur {
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter:blur(15px);
    backdrop-filter: blur(15px);
  }
}

/*Text modals CSS */

#modal-phone-disconnected, #modal-webapp-disconnected, #modal-leave{
    color: var(--all-white);
    display: none;
    z-index: 1000; /**should be the highest z-index */
    position: absolute;
    width: 440px;
    height: 252px;
    left: calc(50% - 220px);
    top: calc(50% - 126px);
    justify-content: center;
    align-items: center;
}

#modal-phone-disconnected_content, #modal-webapp-disconnected_content, #modal-leave_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 22px;
    border-radius: 10px;
    z-index:1000;
}

button {
    color:var(--all-white);
    width:100px;
    height:60px;
    border: 1px solid var(--all-white);
    margin-left:10px;
    margin-top:10px;
    background-color: inherit;
    transition-duration: 0.4s;
}

.button-leave:hover {
    background-color: var(--transparent-red);
    color:var(--all-white);
    cursor: pointer;
}
.button-stay:hover {
    background-color: var(--transparent-green);
    color:var(--all-white);
    cursor: pointer;
}

#modal-leave_content_buttons, #modal-phone-disconnected_content_button, #modal-webapp-disconnected_content_button{
    display: flex;
    flex-direction: row;
    margin-top:30px;
    justify-content: space-around;
}

.toast-style{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30%!important;
    max-height:100px!important;
}

@media screen and (max-width: 1500px) {
    #split_left {
        width: 35%;
    }
    #split_right {
        width: 65%;
    }
}

</style>