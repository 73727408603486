/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {areUint8ArrayEquals} from "@/assets/ext/crypto/tools/uint8ArrayTools";
import {globals} from '@/assets/ext/globals';
import * as Base64 from "@/assets/ext/libs/Base64";
import {websocket} from "@/assets/ext/websocket";
import {sendAttachmentDone} from "@/assets/ext/messages/messageSender";
import {isAnImageForGallery, isGifImage, registerNewImageAndRemoveOldest} from "@/assets/ext/images";
import Vue from 'vue';
import * as events from "@/assets/ext/events";
import * as crypto from "@/assets/ext/crypto.js";
import {i18n} from "@/main";
import {isAudioFile} from "@/assets/ext/audio";

const protobuf = require("@/assets/ext/protobuf/protobuf.js").olvid;

/**
 * Finds and returns the DraftAttachment corresponding to given sha256 in the list of drafts attachments for the Discussion identified by discussionId.
 * @param {number} discussionId 
 * @param {Uint8Array} sha256
 * @returns {DraftAttachment} Returns DraftAttachment if found, null otherwise
 */
export function findAttachmentInListFromSha256(discussionId, sha256) {
    let attachments = globals.data.discussions.get(discussionId).draftAttachments;
    if(!attachments){ //this discussion has no attachments
        globals.data.discussions.get(discussionId).draftAttachments = []; //initialize it
        return false;
    }
    //compare their sha256 byte by byte
    let correspondingFile = null;
    attachments.forEach(file => {
        if(areUint8ArrayEquals(sha256, file.sha256)) { //if same is still true, then sha256 matched
            correspondingFile = file;
        }    
    });
    return correspondingFile; //returns either null or a DraftAttachment object if found
}

/**
 * Finds and returns DraftAttachment corresponding to given localId in the list of drafts attachments for all discussions.
 * @param {number} localId 
 * @returns {DraftAttachment} Returns DraftAttachment if found, null otherwise
 */
export function findDraftAttachmentInListFromLocalId(localId) {
    let foundAttachment = null;
    //search in all discussions   if needed, discussionId will be retrieved in DraftAttachment object
    globals.data.discussions.forEach(discussion => {
        if(!discussion.draftAttachments){ //this discussion has no attachments
            return false;
        }
        //compare their localId
        discussion.draftAttachments.forEach(attachment => {
            if(attachment.localId === localId){
                foundAttachment = attachment;
            }
        });
    });
    return foundAttachment;
}

/**
 * Returns the status of the attachment identified by its localId if it exists, otherwise returns -1.
 * @param {number} localId 
 * @returns {number} code corresponding to a DratAttachmentStatus, or -1 if attachment not found
 */
function findAttachmentStatusFromLocalId(localId) {
    let status = -1;
    //search in all discussions 
    globals.data.discussions.forEach(discussion => {
        if(!discussion.draftAttachments){ //this discussion has no attachments
            return false;
        }
        //compare their localId
        discussion.draftAttachments.forEach(attachment => {
            if(attachment.localId === localId){
                status=attachment.status;
            }
        });
    });
    return status;
}

/**
 * Finds the localId assigned to a certain attachment, an attachment being identified by the couple fyleId/messageId.
 * @param {Object} attachment 
 * @returns {number}
 */
export function findLocalIdOfAttachment(attachment) {
    let finalLocalId = -1;
    for(const localId in globals.data.attachments) {
        let a = globals.data.attachments[localId];
        if(a.fyleId === attachment.fyleId && a.messageId === attachment.messageId) {
            finalLocalId = localId;
        }
    }
    return parseInt(finalLocalId,10); //localId used with type Number
}

/**----------------------Uploading & Downloading Attachments----------------------- */

/**
 * Calculates the number of chunks of globally defined CHUNK_SIZE_SENDING needed for given File to be sent.
 * @param {File} file
 * @returns {number} 
 */
export function calculateNbChunks(file) {
    let size = file.size; //in bytes
    let nbChunks = 0;
    let start = 0;
    let end;

    end = start + globals.constants.CHUNK_SIZE_SENDING;
    while(end <= size) {
      start = end;
      end = start + globals.constants.CHUNK_SIZE_SENDING;
      nbChunks += 1;
    }
    if(start !== size){ //if end > size then 1 more chunk for remaining bytes
      nbChunks += 1;
    }
    return nbChunks;
}

/**
 * This method is responsible of sending by chunks the Attachment corresponding to the given localId.
 * Will read the corresponding real File, create and fill the chunks, and give them to the appropriate function to be sent through websocket.
 * @param {number} localId 
 */
export function sendAttachmentByChunks(localId) {

    let draftAttachment = findDraftAttachmentInListFromLocalId(localId);
    // TODO potential null de-referencing ?
    let file = draftAttachment.correspondingFile;
    if(file == null){ //if the attachment doesn't exist in list, do nothing
        return;
    }
    let fileReader = new FileReader();

    //this is executed when the FileReader has finished reading the given file and it is ready to be processed
    //await is used throughout this function in order to sent chunks synchronously, increasing the chance that they will arrive in order
    //await is especially important before sending an AttachmentDone, because we want to be sure the App had the time to receive all chunks
    fileReader.onloadend = async () => {
        let start = 0;
        let index = 0;
        let nbChunks = calculateNbChunks(file);

        let percentByChunk = 100 / Math.max((Math.floor(file.size / globals.constants.CHUNK_SIZE_SENDING)), 1);
        await continueAttachmentUpload(draftAttachment, fileReader, file, start, index, nbChunks, percentByChunk)
    }

    fileReader.readAsArrayBuffer(file); //read File from computer
}

/**
 * We now check before sending attachments chunks that websocket connection is not over loaded. This allow to send other colissimo
 * even when sending huge draft attachments.
 * If connection is over loaded we setTimeout with the end of the upload to continue upload later.
 * @param {DraftAttachment} draftAttachment
 * @param {FileReader} fileReader
 * @param {File} file
 * @param {number} start
 * @param {number} index
 * @param {number} nbChunks
 * @param {number} percentByChunk
 * @returns {Promise<void>}
 */
async function continueAttachmentUpload(draftAttachment, fileReader, file, start, index, nbChunks, percentByChunk) {
    if (websocket.connection.bufferedAmount > 10 * globals.constants.CHUNK_SIZE_SENDING) {
        setTimeout(() => {
            continueAttachmentUpload(draftAttachment, fileReader, file, start, index, nbChunks, percentByChunk)
        }, 1)
        return
    }
    while(start + globals.constants.CHUNK_SIZE_SENDING <= file.size) {
        //if upload had to be cut short because webclient quit or any other reason, stop uploading
        //status changed in function cancelAllDraftAttachmentUploads if user initiated or disconnection.
        let status = findAttachmentStatusFromLocalId(draftAttachment.localId);
        if(status === -1 || status === protobuf.DraftAttachmentStatus.FAILED){
            return;
        }
        let payload = new Int8Array(fileReader.result.slice(start, start + globals.constants.CHUNK_SIZE_SENDING))
        if((nbChunks > 20 && !(index % 16)) || nbChunks <= 20) {
            let percent = Math.min(index*percentByChunk, 99); //final percent to display
            await sendChunk(draftAttachment,percent, draftAttachment.localId, start, index, payload);
        } else {
            await sendChunk(draftAttachment,null, draftAttachment.localId, start, index, payload);
        }
        index++;
        start += globals.constants.CHUNK_SIZE_SENDING;

        if (websocket.connection.bufferedAmount > 10 * globals.constants.CHUNK_SIZE_SENDING) {
            setTimeout(() => {
                continueAttachmentUpload(draftAttachment, fileReader, file, start, index, nbChunks, percentByChunk)
            }, 1)
            return
        }
    }
    if(start !== file.size) { //in previous loop, if attachment size = multiple of chunk size, we will arrive here with start == file.size
        await sendChunk(draftAttachment, 99, draftAttachment.localId, start, index, new Uint8Array(fileReader.result.slice(start, file.size)));
    }
    sendAttachmentDone(draftAttachment.localId);
}

/**
 * Sends a chunk to App by filling a SendAttachmentChunk Colissimo containing the required information and the chunk.
 * Updates the percentage of the file that has been sent for visual indication.
 * Sends the message in the websocket itself so that we have access to the last function before the message is sent in websocket queue ;
 * that way, we have a progress bar growing at a reasonable speed, only updating after a colissimo has effectively been sent.
 * Progress bar can only be truly accurate if we receive a feedback once the App has effectively received the chunk.
 * @param {Object} attachment
 * @param {?number} percent
 * @param {number} localId
 * @param {number} offset
 * @param {number} chunkNumber
 * @param {Uint8Array} chunk
 */
async function sendChunk(attachment, percent, localId, offset, chunkNumber, chunk) {
    const sendAttachmentChunk = protobuf.SendAttachmentChunk.create({
        localId:localId,
        offset : offset,
        chunkNumber:chunkNumber,
        chunk : chunk
    });
    const sendAttachmentChunkColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.SEND_ATTACHMENT_CHUNK,
        sendAttachmentChunk: sendAttachmentChunk
    });
    const encodedColissimo = protobuf.Colissimo.encode(sendAttachmentChunkColissimo).finish();
    let encryptedColissimo = await crypto.encryptMessage(encodedColissimo);
    if (!encryptedColissimo) {
        console.log("Unable to encrypt colissimo, ignoring");
        return;
    }
    const jsonMessage = {
        action: "relay",
        colissimo: Base64.bytesToBase64(encryptedColissimo),
    };
    websocket.send(JSON.stringify(jsonMessage));
    if(percent != null){ //percent is null if we don't want to update the progress for rendering
        attachment.progress = percent;
    }
}

/**
 * This method is called after an attachment has been received by chunks from App and the Done message has been received.
 * Orders the received chunks, stores them in a Map ((int)index => (byte[])chunk) and concatenates all the ordered chunks in one big Uint8Array.
 * @param {number} fyleId
 * @returns {Uint8Array} Returns Uint8Array of all the image bytes if sorting&concatenating succeeded, null otherwise
 */
export function orderAndConcatenateReceivedAttachment(fyleId) {
    if (!globals.data.realFyles[fyleId] || !globals.data.realFyles[fyleId].chunks) {
        return null;
    }
    let offset = 0;
    //chunks is a Map containing all the chunks
    let realFyle = globals.data.realFyles[fyleId];
    //create Uint8Array to store final content
    let image = new Uint8Array(realFyle.size);
    //only the indexes of each chunk (numbers)
    let indexes = Array.from(realFyle.chunks.keys());
    //sort in ascending order
    indexes.sort(function(a, b) {
        return a - b;
    });

    indexes.forEach(index => {
        let chunk = realFyle.chunks.get(index); //get this chunk by index
        image.set(chunk, offset);
        offset += chunk.byteLength; 
    });
    //image is now one big Uint8Array with all chunks
    //offset now equals total length of image
    if(offset !== realFyle.size) { //if the total amount of bytes in image doesn't equal announced size, then some bytes are missing
      return null;
    }
    return image;
}

/**
 * Handles an attachment fully received. Called either when SendAttachmentDone received and all chunks were already received ; or directly from SendAttachmentChunk when all chunks are received
 * and AttachmentDone had already been received before all chunks arrived.
 * @param {number} fyleId 
 * @param {?boolean} success 
 */
export function handleAttachmentDoneDownloading(fyleId, success) {

    let realFyle = globals.data.realFyles[fyleId];
    if(realFyle == null){
        return false;
    }

    realFyle.receivedAttachmentDoneDownloading = true;

    if(success) { //if App indicated success, process image and eventually detect a fail
        if(isAnImageForGallery(realFyle.mime)) {
            if(realFyle.chunks.size === 1){ //if there is only 1 chunk
                //directly assign it
                if(realFyle.chunks.get(0).byteLength !== realFyle.size) {
                    success = false;
                } else {
                    let image = realFyle.chunks.get(0);
                    realFyle.content = Base64.bytesToBase64(image);
                    realFyle.updateStatusDownloadDone(true);
                    registerNewImageAndRemoveOldest(fyleId);
                }
            } else { //multiple chunks received for this attachment
                let image = orderAndConcatenateReceivedAttachment(fyleId);
                if(image != null) {
                    realFyle.content = Base64.bytesToBase64(image);
                    realFyle.updateStatusDownloadDone(true);
                    registerNewImageAndRemoveOldest(fyleId);
                } else {
                    success = false;
                }
            }

            // if it's a gif image push image in thumbnails
            if (success && isGifImage(realFyle.mime)) {
                Vue.set(globals.data.thumbnails, realFyle.path, realFyle.content);
            }
        }
        else if (isAudioFile(realFyle.mime)) {
            let file = orderAndConcatenateReceivedAttachment(fyleId);
            if(file == null) {
                console.log("unable to concatenate chunks for audio attachment");
                success = false;
            } else {
                //create Blob to get an url in browser
                realFyle.updateStatusDownloadDone(true);
                let blob = new Blob([file], {type: realFyle.mime});
                realFyle.urlInWebClient = window.URL.createObjectURL(blob);
                Vue.set(globals.data.realFyles, realFyle.fyleId, realFyle);
            }
        }
        else { //is a non displayable file, download it (not keeping it in JS, just keep URL of Blob)
            let file = orderAndConcatenateReceivedAttachment(fyleId);
            if(file == null) {
                console.log("unable to concatenate chunks for non image attachment");
                success = false;
            } else {
                //create Blob and HTML structure needed for downloading file to Downloads on Computer
                realFyle.updateStatusDownloadDone(true);
                let blob = new Blob([file],{type: realFyle.mime});
                let url = window.URL.createObjectURL(blob);
                realFyle.urlInWebClient = url;

                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                let downloadName;
                if(!realFyle.name){
                    downloadName = "unknown";
                } else {
                    downloadName = realFyle.name;
                }
                a.download = downloadName;
                a.click(); //download it on computer, only keep Blob URL here to access it
            }
        }
    }
    //includes fails found while processing the success scenario
    if(!success) {
        console.log("handleAttachmentDoneDownloading: download failed");
        realFyle.updateStatusDownloadDone(false);
        Vue.toasted.global.download_failed(i18n.t('attachments.labelDownloadFailed'));
    } else {
        //handling Gallery
        //index-fyle-on-show keeps track of what file if currently showing or expected to show in a download situation
        if(document.getElementById("index-fyle-on-show") && document.getElementById("index-fyle-on-show") !== -1) { //-1 means no image selected to be shown so do nothing
            let localId = globals.data.imagesForGallery[parseInt(document.getElementById("index-fyle-on-show").innerText,10)];
            let clickedAttachmentDiscussionId = -1;
            if(globals.data.attachments[localId]) {
                clickedAttachmentDiscussionId  = globals.data.attachments[localId].discussionId;
            }
            //if it's an image, in the right discussion, and the index of the fyle to show corresponds to downloaded file, show it
            //last condition in case user clicked on other attachment between download start and now
            if(isAnImageForGallery(realFyle.mime) && clickedAttachmentDiscussionId === globals.currentDiscussion && fyleId === globals.data.attachments[localId].fyleId) {
                // do not auto-open gallery for gif images
                if (!isGifImage(realFyle.mime)) {
                    const event = new CustomEvent(events.NotifShowGallery.type, {
                        detail: {
                            localId: localId
                        }
                    });
                    //handled in Gallery.vue
                    document.dispatchEvent(event);
                }
            }
        }
    }
    // empty chunks
    realFyle.chunks = new Map();
    Vue.set(globals.data.realFyles, fyleId, realFyle);
    updateStatusOfAttachmentsForFyle(realFyle, success);
}

/**
 * Updates the status of attachments that references this fyleId.
 * This will update UI for targeted attachments.
 * @param {realFyle} realFyle
 * @param {boolean} success
 */
function updateStatusOfAttachmentsForFyle(realFyle, success){
    let fyleId = realFyle.fyleId;
    if(isAudioFile(realFyle.mime) || isGifImage(realFyle.mime)){ //if it's audio or a gif, propagate success to all other because display will change
        for(let attachment in globals.data.attachments){
            //search for all audio files linked to this fyleId
            //if it's a success, then update all these audio files as a success
            //otherwise, only update the one that laucnhed the download (isDownloading)
            if(globals.data.attachments[attachment].fyleId === fyleId && 
                (success === true || globals.data.attachments[attachment].isDownloading())){ 
                globals.data.attachments[attachment].updateStatusDownloadDone(success);
            }
        }
    } else {
        for(let attachment in globals.data.attachments){
            //search for the attachment that launched the download
            if(globals.data.attachments[attachment].isDownloading() && globals.data.attachments[attachment].fyleId === fyleId){
                globals.data.attachments[attachment].updateStatusDownloadDone(success);
            }
        }
    }
}
