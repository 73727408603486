/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
// import * as events from "@/assets/ext/events";
// import {Message} from "@/assets/datatypes/Message";

export function notif_update_message(colissimo) {
	if(!colissimo.notifUpdateMessage){
		return;
	}
	let message = colissimo.notifUpdateMessage.message;
	if(!globals.data.messagesByDiscussion) {
        return;
	}
	if (!globals.data.messagesByDiscussion.has(message.discussionId) || !globals.data.messagesByDiscussion.get(message.discussionId).has(message.id)) {
        // globals.data.messagesByDiscussion.set(message.discussionId, new Map());
        return; //TODO if receiving an update on non existing message, skip or create new message ?
    }
    
    globals.data.messagesByDiscussion.get(message.discussionId).get(message.id).updateFromProtobuf(message);
    // if(globals.currentDiscussion === message.discussionId) {
	// 	const event = new CustomEvent(events.NotifNewMessage.type, {
	// 		detail: {
	// 			lastMessage: message //lastMessage will provide all necessary information (e.g. for scrolling)
	// 		}
	// 	});
	// 	document.dispatchEvent(event);//handled in Body.vue
	// }
}
