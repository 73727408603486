/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
/**
 * Calculates the hue associated with a byte array. Will always return the same hue for the same byte array.
 * @param {byte[]} bytes
 * @returns {number}
 */
function hueFromBytes(bytes) {
    if(bytes == null){
        return;
    }
    // let realBytes = this.unpack(bytes);
    let result = 1;
    for (let i = 0; i < bytes.length; i++) {
        let element = bytes[i];
        result = (31 * result | 0) + element | 0;
    }
    return (((result & 255) * 360) / 256 | 0);
}

/**
 * Assigns a background color for discussion default icon. 
 * Returns a string containing the final color, determined from either the groupOwnerAndUid for groups or the contactIdentity for single users. 
 * @param {number} id
 * @returns {string} the color in HSL format for CSS
 */
export function getDiscussionThumbnailBackgroundColor(id) {
    let discussion = globals.data.discussions.get(id); 
    if(!discussion){
        return;
    }
    if((!discussion.contactIdentity || discussion.contactIdentity.length === 0) && (!discussion.groupOwnerAndUid || discussion.groupOwnerAndUid.length === 0)){
        //if this discussion is locked, grey background, don't save in list and igonre the fact that it could have had a color before
        return `var(--transparent-light-grey)`;
    }
    
    let hue;
    if(discussion.groupOwnerAndUid.length != 0) { //a group
        hue = hueFromBytes(discussion.groupOwnerAndUid);
    } else {
        hue = hueFromBytes(discussion.contactIdentity);
    }
    return `hsl(${hue}, 60%, 70%)`;
}

/**
 * Assigns a color for the discussion initial/icon. This color is darker than the one assigned in getDiscussionThumbnailBackgroundColor, but is exactly the same hue.
 * @param {number} id
 * @returns {string} the color in HSL format for CSS
 */
export function getDiscussionThumbnailInitialColor(id) {
    let discussion = globals.data.discussions.get(id); 
    if(!discussion){
        return;
    }
    let hue;
    if(discussion.groupOwnerAndUid.length != 0) { //a group
        hue = hueFromBytes(discussion.groupOwnerAndUid);
    } else {
        hue = hueFromBytes(discussion.contactIdentity);
    }
    return `hsl(${hue}, 50%, 50%)`;
}

/**
* Gets color corresponding to given senderId to render in conversation messages/replies. This color is determined by senderId and will always be the same for the same senderId.
* @param {byte[]} senderId
* @returns {string} the color in HSL format for CSS
*/
export function getColorForSenderIdentifier(senderId) {
   let hue = hueFromBytes(senderId);
   return `hsl(${hue}, 50%, 50%)`;
}
