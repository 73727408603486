<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div>
        <Header/>
        <div class="container">
            <div id="container-protocol" class="secondary-background">
                <div id="protocol_content">
                    <div id="spinner" class="spinner-border spinner-border-sm text--color" role="status" aria-hidden="true">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p class="text--color">{{ $t('instructions.labelProtocol') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import oc from '@/assets/ext/client.js';
import router from '@/router';
import Header from '@/components/Header.vue';
import {PRNGHmacSHA256} from "@/assets/ext/crypto/PRNGHmacSHA256";
import {KemPublicKey} from "@/assets/ext/crypto/kemSuite/keys/KemPublicKey";
import {handleProtocolError} from "@/assets/ext/error";
import {CommitmentWithSha256} from "@/assets/ext/crypto/CommitmentWithSha256";
import {deriveAuthEncKey} from "@/assets/ext/crypto/authenc/AuthEncKeyDerivator";
import {SAS} from "@/assets/ext/crypto/SAS";

export default {
    name: 'ProtocolCard',
    components : {
        Header
    },
    data () {
        return{
            oc : oc,
            router:router,
            firstMessageEvent: null
        }
    },
    methods : {
        async handlerConnectionAppIdentifierPkKemCommitSeed_registerCorresponding(event) {
            try {
                if (!event.detail.identifier || !event.detail.publicKey || !event.detail.commit) {
                    throw 'Event details are not valid';
                }
                document.addEventListener(oc.events.CorrespondingRegistered.type, this.handlerConnectionAppIdentifierPkKemCommitSeed);
                oc.messageSender.registerCorresponding(event.detail.identifier);
                oc.globals.correspondingIdentifier = event.detail.identifier;
                this.firstMessageEvent = event;
                document.removeEventListener(oc.events.ConnectionAppIdentifierPkKemCommitSeed.type, this.handlerConnectionAppIdentifierPkKemCommitSeed_registerCorresponding)
            }
            catch (e) {
                document.removeEventListener(oc.events.ConnectionAppIdentifierPkKemCommitSeed.type, this.handlerConnectionAppIdentifierPkKemCommitSeed_registerCorresponding)
                document.removeEventListener(oc.events.CorrespondingRegistered.type, this.handlerConnectionAppIdentifierPkKemCommitSeed);
                console.log("Exception raised during protocol: handlerConnectionAppIdentifierPkKemCommitSeed_registerCorresponding", e);
                handleProtocolError();
            }
        },

        async handlerConnectionAppIdentifierPkKemCommitSeed() {
            try {
                // might raise exception
                oc.globals.connection.appKemPublicKey = new KemPublicKey(this.firstMessageEvent.detail.publicKey);
                let prng = new PRNGHmacSHA256(null);
                await prng.init();
                let keyAndCipher = await oc.globals.connection.appKemPublicKey.kemEncrypt(prng);

                oc.globals.connection.webKey = keyAndCipher.key;
                oc.globals.connection.webSeed = crypto.getRandomValues(new Uint8Array(32));
                oc.globals.connection.commit = this.firstMessageEvent.detail.commit;

                let connectionBrowserKemSeed = oc.protobuf.ConnectionBrowserKemSeed.create({
                kemCipher: keyAndCipher.ciphertext,
                seed: oc.globals.connection.webSeed
                });

                let connectionColissimo = oc.protobuf.ConnectionColissimo.create({
                type: oc.protobuf.ConnectionColissimoType.CONNECTION_BROWSER_KEM_SEED,
                ConnectionBrowserKemSeed: connectionBrowserKemSeed
                });

                oc.messageSender.connection(connectionColissimo);
                document.removeEventListener(oc.events.CorrespondingRegistered.type, this.handlerConnectionAppIdentifierPkKemCommitSeed);
                document.addEventListener(oc.events.ConnectionAppDecommitment.type, this.handlerConnectionAppDecommitment);
            }
            catch (e) {
                document.removeEventListener(oc.events.CorrespondingRegistered.type, this.handlerConnectionAppIdentifierPkKemCommitSeed);
                document.removeEventListener(oc.events.ConnectionAppDecommitment.type, this.handlerConnectionAppDecommitment);
                console.log("Exception raised during protocol: handlerConnectionAppIdentifierPkKemCommitSeed", e);
                handleProtocolError();
            }
        },

        async handlerConnectionAppDecommitment(event) {
            try {
                if (!event.detail || !event.detail.decommitment) {
                    throw 'No decommitment in event detail';
                }
                let cipherTextAndSeed = await CommitmentWithSha256.open(oc.globals.connection.appKemPublicKey.getCompactKey(), oc.globals.connection.commit, event.detail.decommitment);
                let cipherText = cipherTextAndSeed.slice(0, -32);
                oc.globals.connection.appSeed = cipherTextAndSeed.slice(-32);
                oc.globals.connection.appKey = await oc.globals.connection.webKemKeyPair.kemDecrypt(cipherText);
                let keys = await deriveAuthEncKey(oc.globals.connection.appKey, oc.globals.connection.webKey);
                if (keys == null) {
                    console.log("Unable to derive AuthEnc key");
                    return;
                }
                try {
                    oc.globals.aesKey = await crypto.subtle.importKey("raw", keys.aesKey, {name: "AES-CTR"}, true, ["encrypt", "decrypt"]);
                    oc.globals.macKey = await crypto.subtle.importKey("raw", keys.macKey, {
                        name: "HMAC",
                        hash: {name: "SHA-256"}
                    }, true, ["sign", "verify"]);
                } catch (e) {
                    console.log("Unable to parse derived keys");
                    handleProtocolError();
                    return;
                }

                // generate sas code
                const sasCode = await SAS.computeSimple(oc.globals.connection.appSeed, oc.globals.connection.webSeed, oc.globals.connection.webKemKeyPair.getCompactPublicKey(), 4);
                oc.globals.connection.sas = sasCode;
                oc.globals.updateStatus(oc.globals.STATUS_SHOW_SAS);
                document.removeEventListener(oc.events.ConnectionAppDecommitment.type, this.handlerConnectionAppDecommitment);
            }
            catch (e) {
                console.log("Exception raised during protocol: handlerConnectionAppDecommitment", e);
                handleProtocolError();
                document.removeEventListener(oc.events.ConnectionAppDecommitment.type, this.handlerConnectionAppDecommitment);
            }
        }
    },
    created() {
        if(oc.globals.status !== oc.globals.STATUS_PROTOCOL_IN_PROGRESS) {
            oc.globals.updateStatus(oc.globals.STATUS_INVALID_STATE);
            return;
        }
        document.addEventListener(oc.events.ConnectionAppIdentifierPkKemCommitSeed.type, this.handlerConnectionAppIdentifierPkKemCommitSeed_registerCorresponding);
        setTimeout(() => {
            if (oc.globals.status === oc.globals.STATUS_PROTOCOL_IN_PROGRESS) {
                handleProtocolError();
                return;
            }
            console.log("Protocol timeout OK")
        }, 20000);
    },
    beforeDestroy() {
        document.removeEventListener(oc.events.ConnectionAppIdentifierPkKemCommitSeed.type, this.handlerConnectionAppIdentifierPkKemCommitSeed_registerCorresponding);
    }
}
</script>

<style scoped>

#container-protocol {
    display: flex;
    flex-direction: column;
    padding : 3%;
    width: 850px;
    height:50vh;
    position: absolute;
    top: 22%;
    margin-left:auto;
    margin-right:auto;
    border-radius:5px;
    align-items: center;
}

#protocol_content {
    position: absolute;
    margin: auto;
    width: fit-content;
    min-height : 180px;
    top: calc(50% - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

#spinner {
    width:60px;
    height:60px;
    margin-bottom: 40px;
}

@media screen and (max-width: 1000px) and (min-height:500px) {
    #container-protocol {
        width: 600px;
    }
}

@media screen and (max-width: 1000px) and (max-height: 790px) {
    #container-protocol {
        max-height: 68vh;
    }
}

@media screen and (max-height: 500px) and (max-width:1200px){
    #container-protocol {
        max-height: 50vh;
        top:32%!important;
        width: 600px;
    }
}

@media screen and (max-height: 500px) and (min-width:1500px){
    #container-protocol {
        top:5%;
        height: 80%;
    }
}

@media screen and (max-height:700px) {
    #container-protocol {
        width: 600px;
        height: 50%;
        top:25%;
    }
    #protocol_content {
        min-width: 600px;
        min-height : 50%;
    }
}

@media screen and (max-height: 500px) and (max-width:1500px) and (min-width:1200px){
    #container-protocol {
        top:5%;
        height: 80%;
        width: 600px;
        max-height: 70vh;
    }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
    #container-protocol {
        width: 400px;
        height: 50%;
        top:5%;
    }
    #protocol_content {
        min-width: 400px;
        min-height : 50%;
    }
}

@media screen and (min-height:850px) {
    #container-protocol {
        top: 18%;
    }
}

</style>