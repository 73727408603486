<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div id="header">
        <div id="banner" class="blueGradient">
            <div id="banner_logo-and-extension">
                <img id="logo-olvid-white" :src="images.olvid" alt="Olvid"/>
                <p id="web-extension">Web</p>
            </div>
            <div class="banner_options">
                <a :href="$t('help.link')" target="_blank"><i id="button-help" class="fa fa-question-circle" :title="$t('help.labelHelp')"></i></a>
                <i id="button-settings" class="fas fa-cog" @click="openSettings()" :title="$t('settings.labelSettings')"></i>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Header',
    data () {
        return {
            images : {
                olvid : require("@/assets/images/logo-olvid.png"),
            },
            serverConnectionStates : ["Disconnected", "Connected to server", "Ready"],
        }
    },
    methods: {
        openSettings() {
            document.getElementById('global-settings').style.display="block";
        }
    }
}
</script>

<style scoped>

#banner {
    height:230px;
    display:flex;
    flex-direction: row;
    padding-left:2%;
    box-shadow: 0 0.2rem 0.25rem var(--transparent-black);
}
    
#logo-olvid-white {
    width: 200px;
}

#banner_logo-and-extension {
    margin: 37px 0 0 23px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: fit-content; /* older Safari, Opera, Chrome, Edge */
    height: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    height:-moz-fit-content; /* Firefox */
}

#web-extension {
    font-size: 26px;
    color: var(--all-white);
    margin: 0 0 -5px 10px;
}

.banner_options {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    margin-right: 3%;
    margin-top: 2%;/**10px;*/
    color:var(--all-white);
    font-size: 24px;
    padding:1%;
    line-height:35px;
}

#button-settings, #button-help {
    color: var(--all-white);
    font-size: 2.4rem;
    cursor: pointer;
    margin-left: 20px;
}

@media screen and (max-height: 480px) {
    .banner_options {
        font-size: 15px ;
        line-height: 18px;
    }
    #banner {
        min-height: 50%;
        height: 50%;
    }
}

@media screen and (max-height: 500px) and (min-width:1200px){
    #web-extension {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    #banner {
        height: 150px;
    }
}

</style>

