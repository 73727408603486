<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div class="attachment">
        <div class="attachment_file_icon">
            <svg xmlns="http://www.w3.org/2000/svg"
                width="120"
                height="120"
                viewBox="0 0 24 24" >
                <path d="M6,2c-1.1,0 -1.99,0.9 -1.99,2L4,20c0,1.1 0.89,2 1.99,2L18,22c1.1,0 2,-0.9 2,-2L20,8l-6,-6L6,2zM13,9L13,3.5L18.5,9L13,9z" />
            </svg>
        </div>
    </div>

</template>

<script>
export default {
    name:'AttachmentPlaceholder'
}
</script>

<style scoped>
.attachment {
    margin-left:5px;
    margin-right:5px;
    position: relative;
    width: inherit;
    height: inherit;
}

.attachment_file_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.attachment_file_icon svg {
    fill:var(--transparent-light-grey);
}
</style>