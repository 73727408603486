/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";
export class LocalAttachment {
	constructor(fyleId, localId, messageId, discussionId, timestamp, name, status) {
		this.fyleId = fyleId;
		this.localId = localId;
		this.messageId = messageId;
		this.discussionId = discussionId;
		this.timestamp = timestamp;
        this.name = name;
        this.status = status; //this is a localJS status ; will be the same as the underlying fyle ; // one of protobuf.AttachmentStatus
    }
    updateStatusDownload() {
        this.status = protobuf.AttachmentStatus.DOWNLOADING;
    }
    updateStatusDownloadDone(success) {
        if(success){
            this.status = protobuf.AttachmentStatus.DOWNLOAD_FINISHED;
        } else {
            this.status = protobuf.AttachmentStatus.DOWNLOAD_FAILED;
        }
    }

    isDownloaded() {
        return this.status === protobuf.AttachmentStatus.DOWNLOAD_FINISHED;
    }

    isDownloading(){
        return this.status === protobuf.AttachmentStatus.DOWNLOADING;
    }

    downloadFailed() {
        return this.status === protobuf.AttachmentStatus.DOWNLOAD_FAILED; 
    }

    readyForDownload() {
        return this.status === protobuf.AttachmentStatus.READY_FOR_DOWNLOAD; 
    }

    availableForDownload(){
        return this.status === protobuf.AttachmentStatus.READY_FOR_DOWNLOAD || this.status === protobuf.AttachmentStatus.DOWNLOAD_FAILED;    
    }
    
    notDownloaded() {
        return this.status !== protobuf.AttachmentStatus.DOWNLOAD_FINISHED;
    }
}