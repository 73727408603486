/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
import * as events from "@/assets/ext/events";

export function notif_delete_message(colissimo) {
	if(!colissimo.notifDeleteMessage) {
		return;
	}
	let message = colissimo.notifDeleteMessage.message;
	if (!message || !globals.data.messagesByDiscussion.has(message.discussionId)) {
		return;
	}
	let currentMessages = globals.data.messagesByDiscussion.get(message.discussionId);
	if(!currentMessages){
		return;
	}
	currentMessages.delete(message.id);//delete from Map
	if(!currentMessages || currentMessages.size < 1){ //last message in conversation
		globals.data.messagesByDiscussion.set(message.discussionId, new Map());
	} else { //otherwise, reassign Map with freshly deleted message
		globals.data.messagesByDiscussion.set(message.discussionId, currentMessages);
	}
	if(message.discussionId === globals.currentDiscussion) {
		//if this message is in the current discussion, refresh vue by sending this event
		document.dispatchEvent(events.NotifDeleteMessage);//handled in Body.vue
	}
}
