/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {websocket} from "@/assets/ext/websocket";
const {StatusUpdated} = require("@/assets/ext/events");
const {KemKeyPair} = require("@/assets/ext/crypto/kemSuite/keys/KemKeyPair");
const {Uuid} = require("@/assets/ext/crypto/tools/Uuid");
import Favico from 'favico.js-slevomat';

export let globals = {
    async init() {
        this.constants.serverUrl = "wss://" + window.location.host + "/server";
        this.connection.webKemKeyPair = new KemKeyPair(KemKeyPair.ALGORITHM_CURVE_25519);
        await this.connection.webKemKeyPair.awaitInitialisation();

        // connection identifier is used to register on webclient relay server, and as olvidSessionCookie to ensure
        // load balancer stickiness
        this.connectionIdentifier = Uuid.new().replace(/-/g, ""); // we remove - to save space in qr code
        // set empty value for session cookies (will be set when connecting to server)
        this.olvidSessionCookie = ""; // we do not use it because we actually use connection identifier to save space in QRCode
        this.awsSessionCookie = "";
    },

    async reset() {
        if (websocket.connection) {
            websocket.connection.onclose = null;
            websocket.connection.close();
            websocket.connection = null;
        }

        // create error if it does not exist (0 => no errors)
        if (!this.error)
            this.error = 0;

         // Data stored by connection protocol
        this.connection = {};
        this.correspondingIdentifier = null;
        this.aesKey = null;
        this.macKey = null;
         // do not use this.STATUS_DISCONNECTED because it results in an undefined value
        this.status = 0;
        //clean globals for new connection ; this session is over
        this.resetData();
        //special case : we don't necessarily want to forget the thumbnails if we're not closing the connection (reconnecting), so keep it outside resetData
        this.data.thumbnails = {}; //clean thumbnails
        this.data.lastMessageSent = null;
        await this.init();
    },

    resetData() {
        this.currentDiscussion = null;
        //empty discussions, ready to change user and reload new ones
        this.data.discussions = new Map();
        //empty this to start off with new user (even if user is the same, we still need to subscribe again because the App disconnected so all the listeners were lost when the service closed)
        this.data.messagesByDiscussion = new Map();
        //thumbnails : we want to keep it for reconnections, clear when closing connection
        this.data.realFyles = {};
        this.data.attachments = {};
        this.data.imagesForGallery = [];
        this.data.downloadedImageIds = [];
    },

    // not connected to websocket server
    STATUS_DISCONNECTED: 0,
    // connected and register it's connectionIdentifier on websocket server (ready for establishment protocol)
    STATUS_CONNECTED_TO_SERVER: 1,
    // protocol in progress
    STATUS_PROTOCOL_IN_PROGRESS: 2,
    //show sas code on screen
    STATUS_SHOW_SAS: 3,
    // establishment protocol succeeded
    STATUS_READY: 4,
    // lost connection to server, trying to reconnect
    STATUS_RECONNECTING: 5,
    // app unexpectedly disconnected from server, waiting for her to reconnect
    STATUS_WAITING_FOR_RECONNECTION: 6,
    // received a bye message from app, or user ask for closing session
    STATUS_CLOSING_SESSION: 7,
    // used when an invalid status is encountered, it imply to go back to connecting
    STATUS_INVALID_STATE: 8,

    updateStatus: function(newStatus) {
        switch (newStatus) {
            case (this.STATUS_DISCONNECTED):
            case (this.STATUS_CONNECTED_TO_SERVER):
            case (this.STATUS_PROTOCOL_IN_PROGRESS):
            case (this.STATUS_SHOW_SAS):
            case (this.STATUS_READY):
            case (this.STATUS_RECONNECTING):
            case (this.STATUS_WAITING_FOR_RECONNECTION):
            case (this.STATUS_CLOSING_SESSION):
            case (this.STATUS_INVALID_STATE):
                // log only if status is defined (status is undefined when reloading page)
                if (newStatus === this.STATUS_INVALID_STATE && globals.status) {
                    console.log("updating from " + this.status + " to INVALID_STATE")
                }
                this.status = newStatus;
                document.dispatchEvent(StatusUpdated);
                break ;
            default:
                console.log("Invalid status, unable to update: " + newStatus);
                break ;
        }
    },

    statusAsString: function(status) {
        switch (status) {
            case (this.STATUS_DISCONNECTED):
                return "disconnected";
            case (this.STATUS_CONNECTED_TO_SERVER):
                return "connected to server";
            case (this.STATUS_PROTOCOL_IN_PROGRESS):
                return "protocol in progress";
            case (this.STATUS_SHOW_SAS):
                return "show sas";
            case (this.STATUS_READY):
                return "ready";
            case (this.STATUS_RECONNECTING):
                return "reconnecting";
            case (this.STATUS_WAITING_FOR_RECONNECTION):
                return "waiting for reconnection";
            case (this.STATUS_CLOSING_SESSION):
                return "closing session";
            case (this.STATUS_INVALID_STATE):
                return "invalid state";
            default:
                return "unknown state (" + status + ")";
        }
    },

    favico : new Favico({
        animation : 'none' //possible choices : fade, popFade, slide, pop or none.
    }),
    
    // local indexes used for data
    index: {
        //Locally generated Ids for messages and attachments
        localAttachmentId : 0, //counter for local attachmentId : this local Id will identify an attachment handle same attachment being in two different messages (same fyleId appears multiple times)
        localMessageId:0, //counter for local messages : messages are kept in a buffer until application notifies of their arrival.
    },

    // global variables
    variables: {
        customUrl : false, //custom url given by user when entering the page (false if none, value of url otherwise)
        isWindowActive : true, //keep track of whether or not the window is currently visible for notifications
        replyMessageId : 0, //messageId of message we are currently replying to (0 means no reply)
    },

    //parameters in webclient : variables that can be changed by user or will be changed during use of the webclient.
    parameters: {
        sendOnEnter : true, //default behavior : send message when hitting enter
        showNotificationOnScreen : true,
        darkModeEnabled:false, //default light theme
        darkModeSysPref:true, //default use system preference
        darkModeAppPref : false,
        notificationsSoundEnabled : true, //notification sound :  default on
        isLanguageBrowserDefault : true,
        isLanguageAppDefault : false,
        showSaveDraftButton : true,
        receivedSettings : null, //keep settings received by App in this variable
    },

    constants: {
        VERSION : 0,
        DEBUG_MODE: false,
        // name of cookie pair used for sticky sesion in load balancer
        OLVID_SESSION_COOKIE_NAME: "olvidSession", // cookie manually set
        AWS_SESSION_COOKIE_NAME: "AWSALBAPP-0", // cookie set by aws load balancer
        //shall be the same as defined in WebClientService in android app
        //numbers limited by AWS (https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html)
        CHUNK_SIZE_SENDING: 24_576,
        //step number of messages to request when loading older messages
        STEP_NB_REQUEST_MESSAGES:20,
        //supported languages for web app
        SUPPORTED_LANGUAGES : ['en', 'fr'],
        SUPPORTED_LANGUAGES_FULL_NAME : ['English', 'Français'],
        //number max of images to keep in cache ; set to 100 arbitrarily
        MAX_NB_IMAGES_CACHED : 100,
        PING_INTERVAL : 30_000, //5 minutes
        // max size to silent download
        MAX_GIF_SIZE_FOR_AUTO_DOWNLOAD : 1_000_000,
        MAX_AUDIO_SIZE_FOR_AUTO_DOWNLOAD : 1_000_000,
    },

    //keeps data received by application and necessary to show discussions/messages/attachments...
    data: {
        // Mapping discussion_id as int to protobuf Discussion instance
        discussions: new Map(), // discussionId => Discussion (protobuf)
        // Mapping discussion_id as int to a Map, mapping message_id as an int to a protobuf Message instance
        messagesByDiscussion: new Map(), // discussionId => Map ( messageId => datatypes/Message )
        //Mapping relativePath to their thumbnail as Base64 String for rendering
        //contains attachment and profile photo thumbnails, using path on android app as keys
        thumbnails:{}, // path (string) => bas64 content (string)
        //global list of "physical" attachments for webapp : associating real fyleId to corresponding Attachment Object
        realFyles : {}, // fyleId (int) => RealFyle instances
        //global list of attachments for webapp : associate localId to attachment (localId, fyleId, messageId, discussionId). Different Attachments can have the same fyleId.
        attachments : {}, // localId => datatypes.LocalAttachment
        //list of images that can be visualized in the gallery, keep only localId and get image in realFyles
        imagesForGallery: [],  // [localId]
        //array of fyleIds of downloaded images. Keep track of downloaded images and easily remove some to respect cache size.
        downloadedImageIds : [],
        //the localId of the last sent message. Null if message was sent and confirmation received. Can only send a message if this is null, otherwise means we're waiting for confirmation receipt.
        lastMessageSent : null, //{id , content, discussion} 
    },
};
