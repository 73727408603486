<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div id="discussion-list" class="main-background">
        <div id="discussion-list_loading-spinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
            <span class="sr-only">Loading...</span>
        </div>
        <div id="discussion-list-content">
            <div id="discussion-list_search-bar" v-show="discussionList != null">
                <i class="fas fa-search"></i>
                <input autocomplete="off" type="search" id="search-discussions" class="secondary-background text--color" :placeholder="$t('discussion.labelSearchInDiscussions')">
            </div>
            <div id="no-discussions" class="text--color">{{$t('discussion.labelNoDiscussions')}}</div>
            <div id="scrollable-discussion-list">
                <div class="discussion_item" v-for="disc in discussionList" :key="disc.id" :id="disc.id">
                    <a @click="selectDiscussion(disc.id)" :class="{'discussion_item--highlight':disc.id === clickedDiscussion}">
                        <span v-if="disc.unreadMessagesCount > 0" class="notif-dot-number" :style="disc.unreadMessagesCount > 99 ? 'width:25px;line-height:25px;' : ''">{{disc.unreadMessagesCount}}</span>
                        <span v-if="disc.unreadMessagesCount > 0" class="notif-dot" :class="{'notif-dot--highlight':disc.id === clickedDiscussion}"></span>
                        <div v-if="oc.globals.data.thumbnails[disc.photoURL]">
                            <img :src="getThumbnailFromURL(disc.photoURL)" alt="thumbnail" class="discussion_item_thumbnail">
                        </div>
                        <div v-else>
                            <div class="discussion_item_thumbnail" :style="{backgroundColor : getDiscussionThumbnailBackgroundColor(disc.id)}">
                                <div v-if="disc.groupOwnerAndUid.length == 0 && disc.contactIdentity.length == 0" class="discussion_item_thumbnail_locked"><i class="fa fa-lock"></i></div>
                                <div v-else-if="disc.groupOwnerAndUid.length != 0" class="discussion_item_thumbnail_group" :style="{color : getDiscussionThumbnailInitialColor(disc.id)}"><i class="fa fa-users"></i></div>
                                <div v-else class="discussion_item_thumbnail_initial" :style="{color : getDiscussionThumbnailInitialColor(disc.id)}">{{disc.title.substring(0,1).toUpperCase()}}</div>
                            </div>
                        </div>
                        <div class="discussion_item_text">
                            <div :class="{'unread-messages' : disc.unreadMessagesCount > 0}">
                                <div class="discussion_item_name discussion-text--color" v-html="disc.title"></div>
                                <div class="discussion_item_last-message text--color" v-html="getDiscussionInfoMessage(disc)"></div> 
                                <div class="discussion_item_timestamp text--color" v-if="disc.lastMessage != null">{{getShortDateFromTimestamp(disc.lastMessage.timestamp)}}</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import oc from '@/assets/ext/client.js';
import {getShortDateFromTimestamp} from '@/assets/ext/time.js';
import {getDiscussionThumbnailBackgroundColor, getDiscussionThumbnailInitialColor} from '@/assets/ext/colors.js';
import router from '@/router';
import $ from 'jquery';

export default {
	name: 'DiscussionList',
    props: {
        discL : {
            type : Array,
            required : true
        }
    },
	data () {
        return {
            images: {
                icon_person:require("@/assets/images/icon_person.jpg"),
            },
            showByIndex:null,
            oc:oc,
            router:router,
            discussionList : null,
            getShortDateFromTimestamp:getShortDateFromTimestamp,
            getDiscussionThumbnailBackgroundColor:getDiscussionThumbnailBackgroundColor,
            getDiscussionThumbnailInitialColor:getDiscussionThumbnailInitialColor,
            clickedDiscussion:null,
            darkMode : (document.documentElement.getAttribute('data-theme') === 'dark'),
            regexEscape : RegExp('[{}<>`]'),
            isSearching:false
        }
    },
    watch : {
        discL : function() {
            this.searchInDiscussions();
        }
    },
    methods: {
        /**
         * Reacts to user clicking on a discussion. Sets current discussion, subscribes to discussion and notifies parent (Body.vue) to render correct messages.
         * @param {number} discussionId
         */
        selectDiscussion(discussionId) {
            if(this.isSearching){
                document.getElementById('search-discussions').value = "";
                this.discussionList = this.discL; //this will take some time, we can't scroll immediately after this
                setTimeout(() => { this.scrollToDiscussion(discussionId) }, 500);//TODO nextTick is not slow enough, temporary solution
                this.isSearching = false;
            }

            this.clickedDiscussion = parseInt(discussionId);
            oc.globals.currentDiscussion = parseInt(discussionId);
            this.$nextTick(this.hideSpinnerAndHideNoMessages); //hide both spinner and 'no messages' in anticipation of opening new conversation. Will be displayed again if necessary later.
            if (!oc.globals.data.messagesByDiscussion || !oc.globals.data.discussions) {
                console.warn("DISCUSSIONLIST : MessagesByDiscussion or discussions unset");
                return ;
            }
            // if (!oc.globals.data.messagesByDiscussion.has(discussionId) || oc.globals.data.messagesByDiscussion.get(discussionId) == null) {
            if(oc.globals.data.discussions.get(discussionId).numberMessagesRequested === 0){ //have some messages already been requested
                oc.globals.data.messagesByDiscussion.set(discussionId, new Map());
                // oc.globals.data.discussions.get(discussionId).incrementNumberRequested(); //init, ask for 20 messages
                oc.messageSender.subscribeToDiscussion(discussionId, oc.globals.constants.STEP_NB_REQUEST_MESSAGES);
                this.$nextTick(this.showSpinner); //show initial spinner
            }
            this.$root.$emit('clickDiscussion'); //sent to parent (Body) to render conversation
        },
        /**
         * Method called when clicking on a discussion while searching. Clicking will reset list of discussions and scroll to selected discussion.
         */
        scrollToDiscussion(discussionId) {
            if(!discussionId){
                return;
            }
            if(document.getElementById(discussionId)){
                var target = document.getElementById(discussionId);
                target.parentNode.scrollTop = target.offsetTop;
            }
        },
        /**
         * Shows initial spinner in Conversation.vue. Called when first clicking on a discussion.
         */
        showSpinner(){
            if(document.getElementById('conversation_initial-spinner')){
                document.getElementById('conversation_initial-spinner').style.display="flex";
            }
        },
        hideSpinnerAndHideNoMessages() {
            if(document.getElementById('conversation_initial-spinner')){
               document.getElementById('conversation_initial-spinner').style.display="none";
            }
            if(document.getElementById('no-messages')){
               document.getElementById('no-messages').style.display="none"; 
            }
        },
            
        /**
         * Creates src-ready image for thumbnail from the retreived base64 content.
         * @param {string} url
         * @returns {string}
         */
        getThumbnailFromURL(url) {
            return "data:image/png;base64,"+oc.globals.data.thumbnails[url];
        },

        /**
         * Returns the message to show in discussion info : either last message if it exists, or string "n attachment(s)" if last message is an attachment without text, or empty string.
         * @param {Object} discussion
         * @returns {string}
         */
        getDiscussionInfoMessage(discussion) {
            if(discussion.lastMessage == null) {
                return " ";
            }
            let content = "";
            if(discussion.lastMessage.type !== oc.protobuf.MessageType.INBOUND_MESSAGE && discussion.lastMessage.type !== oc.protobuf.MessageType.OUTBOUND_MESSAGE) {//protocol message
                content += '<i>';
                if(discussion.lastMessage.type === oc.protobuf.MessageType.GROUP_MEMBER_JOINED) {//protocol message
                    content += this.$i18n.t('protocolMessages.protocolMessageMemberJoined', {name:discussion.lastMessage.senderName});
                } else if (discussion.lastMessage.type === oc.protobuf.MessageType.GROUP_MEMBER_LEFT) {
                    content += this.$i18n.t('protocolMessages.protocolMessageMemberLeft', {name:discussion.lastMessage.senderName});
                } else if (discussion.lastMessage.type === oc.protobuf.MessageType.LEFT_GROUP) {
                    content += this.$i18n.t('protocolMessages.protocolMessageYouLeftGroup'); 
                } else if (discussion.lastMessage.type === oc.protobuf.MessageType.CONTACT_DELETED) {
                    content += this.$i18n.t('protocolMessages.protocolMessageContactDeleted');
                } else if(discussion.lastMessage.type === oc.protobuf.MessageType.TYPE_INBOUND_EPHEMERAL_MESSAGE) {
                    content += discussion.lastMessage.contentBody; 
                } else if (discussion.lastMessage.type === oc.protobuf.MessageType.TYPE_DISCUSSION_SETTINGS_UPDATE) {
                    if(discussion.lastMessage.senderName != null){
                        content += this.$i18n.t('protocolMessages.labelChangedDiscussionSettings', {name:discussion.lastMessage.senderName}); 
                    } else {
                        content += this.$i18n.t('protocolMessages.labelChangedDiscussionSettingsNoName'); 
                    }
                } else if (discussion.lastMessage.type === oc.protobuf.MessageType.TYPE_DISCUSSION_REMOTELY_DELETED) {
                    content += this.$i18n.t('systemMessages.discussionRemotelyDeleted');
                } else if (discussion.lastMessage.type === oc.protobuf.MessageType.TYPE_PHONE_CALL) {
                    content += this.$i18n.t('systemMessages.phoneCall');
                } else if (discussion.lastMessage.type === oc.protobuf.MessageType.TYPE_NEW_PUBLISHED_DETAILS) {
                    if (discussion.groupOwnerAndUid.length !== 0) {
                        content += this.$i18n.t('systemMessages.groupDetailsUpdated');
                    } else {
                        content += this.$i18n.t('systemMessages.contactDetailsUpdated');
                    }
                } else if (discussion.lastMessage.type === oc.protobuf.MessageType.TYPE_CONTACT_INACTIVE_REASON) {
                    if (discussion.lastMessage.body === "revoked") {
                        content += this.$i18n.t('systemMessages.contactInactiveRevoked');
                    } else {
                        content += this.$i18n.t('systemMessages.contactInactiveBlocked');
                    }
                }
                content += '</i>';
                return content;
            }
            content += this.getDiscussionLastMessageSenderName(discussion);
            if (discussion.lastMessage.contentBody != null && discussion.lastMessage.contentBody !== "") {
                content += discussion.lastMessage.contentBody;
            } else if (discussion.lastMessage.totalAttachmentCount > 0) {
                content +=  this.$i18n.tc('attachments.labelLastMessageNoTextAttachments', discussion.lastMessage.totalAttachmentCount);
            } else {
                content += " ";
            }
            return content;
        },
        /**
         * Returns the name of the person who send the last message in the conversation if it's a group conversation.
         * @param {Object} discussion
         * @returns {string}
         */
        getDiscussionLastMessageSenderName(discussion) {
            if(discussion.groupOwnerAndUid.length !== 0) {
                return discussion.lastMessage.senderName + " : ";
            } else {
                return "";
            }
        },
        /**
         * Retrieves current string in search bar and shows only discussions containing that string in their title on the screen.
         * Re assignes the new list to this.discussionList to update view.
         */
        searchInDiscussions() {
            let searchString = document.getElementById('search-discussions').value;
            if(!searchString || searchString === ""){
                this.discussionList = this.discL;
                return;
            }
            let disc;
            let fittingDiscussions = new Array();
            for(disc of this.discL) {
                let title = disc.title;
                if((title.toUpperCase()).includes(searchString.toUpperCase())){
                    fittingDiscussions.push(disc);
                }
            }
            if(fittingDiscussions.length === 0){
                document.getElementById('no-discussions').style.display = "inline-block";
            } else {
                document.getElementById('no-discussions').style.display = "none";
            }
            this.discussionList = fittingDiscussions;
            this.isSearching = true;
        }
    },
    mounted(){
        if(document.getElementById("search-discussions")){
            document.getElementById("search-discussions").addEventListener("keyup", this.searchInDiscussions);
        }
        $('input[type=search]').on('search', this.searchInDiscussions);
        document.getElementById("scrollable-discussion-list").scrollTop = 0;
    },
    beforeDestroy() {
        if(document.getElementById("search-discussions")){
            document.getElementById("search-discussions").removeEventListener("keyup", this.searchInDiscussions);
        }
    }
}
</script>

<style scoped>

#discussion-list-content {
    display: flex;
    flex-direction: column;
    margin-top:60px;
    height: inherit;
}


#discussion-list {
    color:var(--black);
    height:100%;
    min-height:102vh;
    position: relative;
    z-index: 2;
}

#discussion-list_search-bar {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
}

#search-discussions {
    width: 95%;
    height:22px;
    border-radius : 10px;
    padding: 0 5px 0 25px;
    border : 1px solid var(--transparent-light-grey);
}

#search-discussions:focus {
    border-color : var(--regular-grey);  
}

.fa-search {
    position:absolute;
    font-size: 0.9rem;
    left:15px;
    color : var(--regular-grey);  
}

#no-discussions {
    position: relative;
    display:none;
    top: 13%;
    width:fit-content;
    width: -webkit-fit-content;
    margin: 0 auto;
}

#discussion-list_menu_settings_icon {
    margin-right:10px ;
    color:var(--almost-white);
    font-size: 1.6rem;
    cursor: pointer;
}

#discussion-list_loading-spinner {
    width:50px;
    height:50px;
    margin-top:100px;
    color : var(--light-blue);
}

#scrollable-discussion-list {
    display: none;
    overflow-y: scroll;
    height: calc(100% - 120px);
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scroll-snap-type: y proximity;
    overscroll-behavior-y: contain;
    position: relative;
    margin-top:10px;
}

#scrollable-discussion-list::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.discussion_item a {
    text-align:left;
    font-size:larger;
    height:75px;
    line-height:19px;
    text-decoration:none;
    padding:12px 12px 12px 12px;
    transition:0.3s;
    display:flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

/** Apply to all children with class discussion_item in scrollable-discussion-list except last one and only when not hovering.
* Defines line between discussions in list. */
#scrollable-discussion-list div.discussion_item:not(:last-child):not(:hover)::after {
    width: 85%;
    height: 1px;
    content: "";
    background-color: var(--item-highlight);
    opacity: 0.4;
    display: inline-block;
    margin-bottom: -1px;
}

.discussion_item a:hover {
	background-color : var(--item-highlight);
	cursor:pointer;
}

.discussion_item_thumbnail {
    height:60px;
    width:60px;
    border-radius: 50%;
    margin-right:10px;
    border-radius: 55%;
    margin-top: 8px;
}
.discussion_item_thumbnail_initial {
    font-size: 34px;
    position: absolute;
    width:60px;
    height:60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.discussion_item_thumbnail_group {
    font-size: 34px;
    width:60px;
    height:60px;
    position: absolute;
    color:var(--group-yellow);
    display: flex;
    justify-content: center;
    align-items: center; 
}

.discussion_item_thumbnail_locked {
    font-size: 34px;
    width:60px;
    height:60px;
    position: absolute;
    color:var(--regular-grey);
    display: flex;
    justify-content: center;
    align-items: center;
}

.discussion_item_text {
    width: -moz-available;          /* For Mozilla */
    width: -webkit-fill-available;  /* For Chrome, Opera */
    width: stretch;                 /* Others */
    overflow: hidden;
}

.discussion_item_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top:10px;
    line-height: 1.2;
}

.discussion_item_last-message {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding : 5px 0 5px 0;
}

.discussion_item_timestamp {
    font-size: 14px;
    float: right;
}

.unread-messages {
    font-weight: bold;
}

.notif-dot-number {
    background-color: var(--red);
    border-radius: 50%;
    width: 19px;
    line-height: 19px;
    position: absolute;
    top: 20px;
    right: 26px;
    color: var(--all-white);
    font-size: 15px;
    text-align: center;
    padding:1px;
}

.notif-dot {
    background-color: var(--red);
    border-radius: 50%;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 20px;
    left: 55px;
    border: 2px solid var(--main-background-color);
}

.discussion_item a:hover .notif-dot, .notif-dot--highlight {
    border: 2px solid var(--item-highlight);
}

@media screen and (max-width: 776px) {
    .discussion_item_timestamp {
        display: none;
    }
}


</style>