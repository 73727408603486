<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div id="modal-gallery">
        <p id="index-fyle-on-show"></p>
        <div id="modal-gallery_content">
            <div id="gallery-actions">
                <div @click="saveImage()" id="save-image"><i class="fas fa-download"></i>{{$t('attachments.labelDownload')}}</div>
                <div @click="closeGallery()" id="modal-gallery_content_close">&times;</div>
            </div>
            <span id="modal-gallery_content_image-name"></span>
            <i @click="galleryLeft()" id="chevron-left" class="fa fa-chevron-left modal-gallery_content_chevron"></i>
            <div class="spinner-border spinner-border-sm" id="modal-gallery_loading" role="status" aria-hidden="true">
                <span class="sr-only">Loading...</span>
            </div>
            <img id="modal-gallery_content_image" alt="image">
            <i @click="galleryRight()" id="chevron-right" class="fa fa-chevron-right modal-gallery_content_chevron"></i>
        </div>
    </div>
</template>

<script>
import oc from '@/assets/ext/client.js';

export default {
    name : 'Gallery',
    methods : {
        /**
         * Detects a click outside of the shown image and not on other elements (chevrons and close arrow).
         * @param {event} event
         */
        closeGalleryOnClickOutsideImage(event){
            let pictureHeight = document.getElementById('modal-gallery_content_image').clientHeight;
            let pictureWidth = document.getElementById('modal-gallery_content_image').clientWidth; 
            let closeHeight = 60;
            let chevronWidth = 80;//document.getElementById('chevron-left').clientWidth;
            let nameHeight = 20;
            let saveHeight = 30;
            let posX = event.clientX;
            let posY = event.clientY;
            let limitleft = (window.innerWidth/2) - (pictureWidth/2);
            let limitright = (window.innerWidth/2) + (pictureWidth/2);
            let limittop = (window.innerHeight/2) - (pictureHeight/2) - nameHeight;// + closeHeight;
            let limitbottom = (window.innerHeight/2) + (pictureHeight/2) + closeHeight + saveHeight;
            if(posY < closeHeight || posX < chevronWidth || posX > (window.innerWidth - chevronWidth)){
                //either in the gallery options div at the top, or on one of the chevrons
                return;
            }
            if( posX < limitleft ||  //between chevron left and left border of image
                posX > limitright || //between right border of image and chevron right
                posY < limittop || //between top border of image and bottom border of closing section
                posY > limitbottom){ //between bottom border of image and bottom of screen
                this.closeGallery();
            }
        },
        /**
         * Close Gallery modal.
         */
        closeGallery() {
            document.getElementById('index-fyle-on-show').innerText = -1; //no more fyle on show, ignore openGalleryOn
            document.getElementById("modal-gallery").style.display="none";
        },
        /**
         * Handler for listener on NotifShowGallery.
         * Open Gallery showing attachment identified by its id.
         * @param {number} e
         */
        _openGalleryOn(e) {
            document.getElementById('modal-gallery_loading').style.display = "none";
            let index = oc.globals.data.imagesForGallery.indexOf(e.detail.localId);
            let attachment  = oc.globals.data.attachments[e.detail.localId];
            if(index != -1) {
                if(document.getElementById("index-fyle-on-show").innerText != index) {
                    return;
                }
                this.openGallery(index, attachment);
            }
        },
        /**
         * Open Gallery by changing CSS Style properties and injecting image data ; also save index of current fyle on show.
         * @param {number} index
         * @param {Object} attachment
         */
        openGallery(index, attachment) {
            let realFyle = oc.globals.data.realFyles[attachment.fyleId];
            document.getElementById("index-fyle-on-show").innerText = index;
            document.getElementById("modal-gallery").style.display = "flex";
            document.getElementById("modal-gallery_content_image").src = 'data:' + realFyle.mime + ';base64,'+ realFyle.content;
            document.getElementById("modal-gallery_content_image").style.minWidth="0";
            document.getElementById("modal-gallery_content_image-name").innerText = attachment.name;

            document.getElementById('save-image').href = 'data:' + realFyle.mime + ';base64,'+ realFyle.content;
            document.getElementById('save-image').download = attachment.name || 'unknown';
            document.getElementById('save-image').style.display = "block";

            if(this.numberImagesForCurrentDiscussionInGallery() > 1){
                document.getElementById("chevron-left").style.display="flex";
                document.getElementById("chevron-right").style.display="flex";
            } else {
                document.getElementById("chevron-left").style.display="none";
                document.getElementById("chevron-right").style.display="none";
            }
        },
        /**
         * Reaction to click on right chevron in Gallery. Switch to show following image in imagesForGallery list.
         */
        galleryRight() {
            let index = parseInt(document.getElementById("index-fyle-on-show").innerText,10);
            let newIndex = index;
            do {
                newIndex = (newIndex + 1) % oc.globals.data.imagesForGallery.length;
                if(newIndex === index) {//back to original index : avoid infinite loop
                    return;
                }
            } while (oc.globals.data.attachments[oc.globals.data.imagesForGallery[newIndex]].discussionId != oc.globals.currentDiscussion);

            let attachment = oc.globals.data.attachments[oc.globals.data.imagesForGallery[newIndex]];
            document.getElementById('modal-gallery_loading').style.display = 'none';
            let realFyle = oc.globals.data.realFyles[attachment.fyleId];
            //is this image downloaded (content not null)
            if(realFyle.content != null) {
                document.getElementById("modal-gallery_content_image").src = 'data:' + realFyle.mime + ';base64,' + realFyle.content;
                document.getElementById("modal-gallery_content_image").style.minWidth="0";
                document.getElementById("modal-gallery_content_image-name").innerText = attachment.name;
                document.getElementById("index-fyle-on-show").innerText = newIndex;

                document.getElementById('save-image').style.display = "block";
                document.getElementById('save-image').href = 'data:' + realFyle.mime + ';base64,'+ realFyle.content;
                document.getElementById('save-image').download = attachment.name || 'unknown';
            //else if this picture is not downloaded but has a thumbnail, show thumbnail and start download
            } else if (realFyle.path in oc.globals.data.thumbnails) {
                this.showThumbnailAndStartDownload(attachment, newIndex);
            } else { //not downloaded and no thumbnail, skip this picture for now
                this.galleryRight();
            }
        },

        /**
         * Reaction to click on left chevron in Gallery. Switch to show precedent image in imagesForGallery list.
         */
        galleryLeft() {
            let index = parseInt(document.getElementById("index-fyle-on-show").innerText,10);
            let newIndex = index;
            do {
                newIndex -= 1;
                if(newIndex < 0) {
                    newIndex = oc.globals.data.imagesForGallery.length - 1;
                }
                if(newIndex === index) {//back to original index : avoid infinite loop
                    return;
                }
            } while (oc.globals.data.attachments[oc.globals.data.imagesForGallery[newIndex]].discussionId != oc.globals.currentDiscussion);

            let attachment = oc.globals.data.attachments[oc.globals.data.imagesForGallery[newIndex]];
            document.getElementById('modal-gallery_loading').style.display = 'none';
            let realFyle = oc.globals.data.realFyles[attachment.fyleId];
            //is this image downloaded (content not null)
            if(realFyle.content != null) {
                document.getElementById("modal-gallery_content_image").src = 'data:' + realFyle.mime + ';base64,' + realFyle.content;
                document.getElementById("modal-gallery_content_image").style.minWidth="0";
                document.getElementById("modal-gallery_content_image-name").innerText = attachment.name;
                document.getElementById("index-fyle-on-show").innerText = newIndex;
                document.getElementById('save-image').download = attachment.name || 'unknown';
            //else if this picture is not downloaded but has a thumbnail, show thumbnail and start download
            } else if (realFyle.path in oc.globals.data.thumbnails) {
                this.showThumbnailAndStartDownload(attachment, newIndex);
            } else { //not downloaded and no thumbnail, skip this picture for now
                this.galleryLeft();
            }
        },

        /**
         * Called when image has a thumbnail but not downloaded yet. Show thumbnail in Gallery and request attachment.
         * @param {Object} attachment
         * @param {number} newIndex
         */
        showThumbnailAndStartDownload(attachment, newIndex) {
            let realFyle = oc.globals.data.realFyles[attachment.fyleId];
            document.getElementById("modal-gallery_content_image").src = 'data:' + realFyle.mime + ';base64,' + oc.globals.data.thumbnails[realFyle.path];
            document.getElementById("modal-gallery_content_image").style.minWidth="50%";
            document.getElementById('modal-gallery_loading').style.display="block";
            document.getElementById("modal-gallery_content_image-name").innerText = attachment.name;
            document.getElementById("index-fyle-on-show").innerText = newIndex;
            document.getElementById('save-image').style.display = "none";

            if(!(realFyle.isDownloading())) { //not already downloading
                realFyle.updateStatusDownload();
                attachment.updateStatusDownload();
                this.$set(oc.globals.data.realFyles, realFyle.fyleId, realFyle); //to update status
                oc.messageSender.requestDownloadAttachment(realFyle.fyleId, realFyle.size);
            }
        },
        /**
         * Returns number of images that belong to current discussion and can be shown in gallery. Used to show or not chevrons.
         * @returns {number}
         */
        numberImagesForCurrentDiscussionInGallery() {
            let count = 0;
            oc.globals.data.imagesForGallery.forEach(imageId => {
                if(oc.globals.data.attachments[imageId].discussionId === oc.globals.currentDiscussion){
                    count++;
                }
            });
            return count;
        },

        /**
         * Reacts to event 'keydown' on document. Calls galleryRight or galleryLeft depending on pressed key.
         * @param {Object} e
         */
        handleArrowClicked(e){
            if(!document.getElementById('modal-gallery') || !document.getElementById('modal-gallery').style.display || document.getElementById('modal-gallery').style.display === 'none'){ //if gallery isn't open, skip
                return;
            }
            if (e.keyCode === 39) {  //ArrowRight
                this.galleryRight();
                e.preventDefault();
            } else if(e.keyCode === 37) { //ArrowLeft
                this.galleryLeft();
                e.preventDefault();
            } else if (e.keyCode === 27) { //Escape => closeGallery
                this.closeGallery();
                e.preventDefault();
            }
        },
        /**
         * Method called when clicking on Save button. Will save image on computer automatically.
         */
        saveImage() {
            let index = parseInt(document.getElementById("index-fyle-on-show").innerText,10);
            if(index === -1){
                return;
            }
            let attachment = oc.globals.data.attachments[oc.globals.data.imagesForGallery[index]];
            if(!attachment){
                return;
            }
            let realFyle = oc.globals.data.realFyles[attachment.fyleId];
            if(realFyle == null || realFyle.mime == null || realFyle.content == null){
                return;
            }
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = 'data:' + realFyle.mime + ';base64,'+ realFyle.content;
            let downloadName;
            if(!attachment.name){
                downloadName = "unknown";
            } else {
                downloadName = attachment.name;
            }
            a.download = downloadName;
            a.click(); //download image on computer
        }
    },
    mounted () {
        document.addEventListener(oc.events.NotifShowGallery.type, this._openGalleryOn);
        document.addEventListener(oc.events.NotifCloseGallery.type, this.closeGallery);
        document.addEventListener("keydown", this.handleArrowClicked);
        document.getElementById('modal-gallery_content').addEventListener('click', this.closeGalleryOnClickOutsideImage);
    },
    beforeDestroy() {
        document.removeEventListener(oc.events.NotifShowGallery.type, this._openGalleryOn);
        document.removeEventListener(oc.events.NotifCloseGallery.type, this.closeGallery);
        document.removeEventListener("keydown", this.handleArrowClicked);
    }
}
</script>

<style scoped>

#modal-gallery {
    display: none;
    z-index: 200;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    text-align: right;
}

#modal-gallery_content {
    color:var(--black);
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 22px;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--transparent-gallery-background);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter:blur(5px);
}

#modal-gallery_content_image-name {
    text-align: center;
    font-size: 18px;
    color: var(--all-white);
}

#gallery-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

#save-image {
    color:var(--almost-white);
    padding: 10px;
    font-size: 18px;
    border-radius: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    margin : 5px 35px 0 0;
}

#save-image > i {
    padding-right:5px;
}

#save-image:hover {
    background-color: var(--regular-grey);
}

#modal-gallery_content_close {
    padding : 10px 10px 10px 0;
    margin : 5px 20px 0 0;
    font-size: 30px;
    height:30px;
    color:var(--all-white);
    cursor: pointer;
    z-index:205; /*above chevron area */
}

#modal-gallery_content_image {
    max-width: 90%;
    max-height: 90%;
    margin: auto;
    pointer-events: none;
    z-index: 200;
}

.modal-gallery_content_chevron {
    color: var(--light-grey);
    position: absolute;
}

#modal-gallery_loading {
    display: none;
    color: var(--regular-grey);
    width: 60px;
    height: 60px;
    border-width: 6px;
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - -30px);
    z-index:202;
}

#chevron-right, #chevron-left {
    color: var(--all-white);
    cursor: pointer;
    display: none;
    height: 100%;
    width: 80px;
    bottom: 0;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    z-index: 202;
}

#chevron-right {
    right:0;
}

#chevron-left {
    left:0;  
}

#index-fyle-on-show {
    display: none;
}

path {
    stroke: var(--dark-grey);
    stroke-width: 1px;
    stroke-linejoin: round;
}

</style>