/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.olvid = (function() {

    /**
     * Namespace olvid.
     * @exports olvid
     * @namespace
     */
    var olvid = {};

    /**
     * ColissimoType enum.
     * @name olvid.ColissimoType
     * @enum {number}
     * @property {number} COLISSIMO_TYPE_DEFAULT=0 COLISSIMO_TYPE_DEFAULT value
     * @property {number} CONNECTION_PING=1 CONNECTION_PING value
     * @property {number} BYE=2 BYE value
     * @property {number} SETTINGS=3 SETTINGS value
     * @property {number} REFRESH=4 REFRESH value
     * @property {number} REQUEST_DISCUSSIONS=20 REQUEST_DISCUSSIONS value
     * @property {number} REQUEST_DISCUSSIONS_RESPONSE=21 REQUEST_DISCUSSIONS_RESPONSE value
     * @property {number} REQUEST_MESSAGES=22 REQUEST_MESSAGES value
     * @property {number} REQUEST_MESSAGES_RESPONSE=23 REQUEST_MESSAGES_RESPONSE value
     * @property {number} REQUEST_MARK_DISCUSSION_AS_READ=24 REQUEST_MARK_DISCUSSION_AS_READ value
     * @property {number} REQUEST_UPDATE_MESSAGE=25 REQUEST_UPDATE_MESSAGE value
     * @property {number} REQUEST_ADD_REACTION_TO_MESSAGE=26 REQUEST_ADD_REACTION_TO_MESSAGE value
     * @property {number} NOTIF_DISCUSSION_UPDATED=30 NOTIF_DISCUSSION_UPDATED value
     * @property {number} NOTIF_DISCUSSION_DELETED=31 NOTIF_DISCUSSION_DELETED value
     * @property {number} NOTIF_NEW_MESSAGE=32 NOTIF_NEW_MESSAGE value
     * @property {number} NOTIF_NEW_DISCUSSION=49 NOTIF_NEW_DISCUSSION value
     * @property {number} NOTIF_DELETE_MESSAGE=33 NOTIF_DELETE_MESSAGE value
     * @property {number} NOTIF_NEW_DRAFT_ATTACHMENT=34 NOTIF_NEW_DRAFT_ATTACHMENT value
     * @property {number} NOTIF_NEW_ATTACHMENT=35 NOTIF_NEW_ATTACHMENT value
     * @property {number} NOTIF_DELETE_DRAFT_ATTACHMENT=36 NOTIF_DELETE_DRAFT_ATTACHMENT value
     * @property {number} NOTIF_NO_DRAFT_FOR_DISCUSSION=37 NOTIF_NO_DRAFT_FOR_DISCUSSION value
     * @property {number} NOTIF_FILE_ALREADY_EXISTS=38 NOTIF_FILE_ALREADY_EXISTS value
     * @property {number} NOTIF_FILE_ALREADY_ATTACHED=39 NOTIF_FILE_ALREADY_ATTACHED value
     * @property {number} NOTIF_UPLOAD_FILE=40 NOTIF_UPLOAD_FILE value
     * @property {number} NOTIF_UPLOAD_RESULT=41 NOTIF_UPLOAD_RESULT value
     * @property {number} NOTIF_DELETE_DISCUSSION=42 NOTIF_DELETE_DISCUSSION value
     * @property {number} NOTIF_DELETE_ATTACHMENT=43 NOTIF_DELETE_ATTACHMENT value
     * @property {number} NOTIF_UPDATE_MESSAGE=44 NOTIF_UPDATE_MESSAGE value
     * @property {number} NOTIF_UPDATE_ATTACHMENT=45 NOTIF_UPDATE_ATTACHMENT value
     * @property {number} NOTIF_UPDATE_DRAFT_ATTACHMENT=46 NOTIF_UPDATE_DRAFT_ATTACHMENT value
     * @property {number} CREATE_MESSAGE=47 CREATE_MESSAGE value
     * @property {number} NOTIF_MESSAGE_SENT=48 NOTIF_MESSAGE_SENT value
     * @property {number} REQUEST_THUMBNAIL=50 REQUEST_THUMBNAIL value
     * @property {number} REQUEST_THUMBNAIL_RESPONSE=52 REQUEST_THUMBNAIL_RESPONSE value
     * @property {number} SEND_ATTACHMENT_NOTICE=60 SEND_ATTACHMENT_NOTICE value
     * @property {number} SEND_ATTACHMENT_CHUNK=61 SEND_ATTACHMENT_CHUNK value
     * @property {number} SEND_ATTACHMENT_DONE=62 SEND_ATTACHMENT_DONE value
     * @property {number} REQUEST_SAVE_DRAFT_MESSAGE=70 REQUEST_SAVE_DRAFT_MESSAGE value
     * @property {number} REQUEST_DELETE_DRAFT_ATTACHMENT=71 REQUEST_DELETE_DRAFT_ATTACHMENT value
     * @property {number} REQUEST_DOWNLOAD_ATTACHMENT=72 REQUEST_DOWNLOAD_ATTACHMENT value
     * @property {number} REQUEST_DELETE_DRAFT_MESSAGE=73 REQUEST_DELETE_DRAFT_MESSAGE value
     * @property {number} REQUEST_STOP_DRAFT_ATTACHMENT_UPLOAD=74 REQUEST_STOP_DRAFT_ATTACHMENT_UPLOAD value
     * @property {number} RECEIVE_DOWNLOAD_ATTACHMENT_CHUNK=80 RECEIVE_DOWNLOAD_ATTACHMENT_CHUNK value
     * @property {number} RECEIVE_DOWNLOAD_ATTACHMENT_DONE=81 RECEIVE_DOWNLOAD_ATTACHMENT_DONE value
     */
    olvid.ColissimoType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "COLISSIMO_TYPE_DEFAULT"] = 0;
        values[valuesById[1] = "CONNECTION_PING"] = 1;
        values[valuesById[2] = "BYE"] = 2;
        values[valuesById[3] = "SETTINGS"] = 3;
        values[valuesById[4] = "REFRESH"] = 4;
        values[valuesById[20] = "REQUEST_DISCUSSIONS"] = 20;
        values[valuesById[21] = "REQUEST_DISCUSSIONS_RESPONSE"] = 21;
        values[valuesById[22] = "REQUEST_MESSAGES"] = 22;
        values[valuesById[23] = "REQUEST_MESSAGES_RESPONSE"] = 23;
        values[valuesById[24] = "REQUEST_MARK_DISCUSSION_AS_READ"] = 24;
        values[valuesById[25] = "REQUEST_UPDATE_MESSAGE"] = 25;
        values[valuesById[26] = "REQUEST_ADD_REACTION_TO_MESSAGE"] = 26;
        values[valuesById[30] = "NOTIF_DISCUSSION_UPDATED"] = 30;
        values[valuesById[31] = "NOTIF_DISCUSSION_DELETED"] = 31;
        values[valuesById[32] = "NOTIF_NEW_MESSAGE"] = 32;
        values[valuesById[49] = "NOTIF_NEW_DISCUSSION"] = 49;
        values[valuesById[33] = "NOTIF_DELETE_MESSAGE"] = 33;
        values[valuesById[34] = "NOTIF_NEW_DRAFT_ATTACHMENT"] = 34;
        values[valuesById[35] = "NOTIF_NEW_ATTACHMENT"] = 35;
        values[valuesById[36] = "NOTIF_DELETE_DRAFT_ATTACHMENT"] = 36;
        values[valuesById[37] = "NOTIF_NO_DRAFT_FOR_DISCUSSION"] = 37;
        values[valuesById[38] = "NOTIF_FILE_ALREADY_EXISTS"] = 38;
        values[valuesById[39] = "NOTIF_FILE_ALREADY_ATTACHED"] = 39;
        values[valuesById[40] = "NOTIF_UPLOAD_FILE"] = 40;
        values[valuesById[41] = "NOTIF_UPLOAD_RESULT"] = 41;
        values[valuesById[42] = "NOTIF_DELETE_DISCUSSION"] = 42;
        values[valuesById[43] = "NOTIF_DELETE_ATTACHMENT"] = 43;
        values[valuesById[44] = "NOTIF_UPDATE_MESSAGE"] = 44;
        values[valuesById[45] = "NOTIF_UPDATE_ATTACHMENT"] = 45;
        values[valuesById[46] = "NOTIF_UPDATE_DRAFT_ATTACHMENT"] = 46;
        values[valuesById[47] = "CREATE_MESSAGE"] = 47;
        values[valuesById[48] = "NOTIF_MESSAGE_SENT"] = 48;
        values[valuesById[50] = "REQUEST_THUMBNAIL"] = 50;
        values[valuesById[52] = "REQUEST_THUMBNAIL_RESPONSE"] = 52;
        values[valuesById[60] = "SEND_ATTACHMENT_NOTICE"] = 60;
        values[valuesById[61] = "SEND_ATTACHMENT_CHUNK"] = 61;
        values[valuesById[62] = "SEND_ATTACHMENT_DONE"] = 62;
        values[valuesById[70] = "REQUEST_SAVE_DRAFT_MESSAGE"] = 70;
        values[valuesById[71] = "REQUEST_DELETE_DRAFT_ATTACHMENT"] = 71;
        values[valuesById[72] = "REQUEST_DOWNLOAD_ATTACHMENT"] = 72;
        values[valuesById[73] = "REQUEST_DELETE_DRAFT_MESSAGE"] = 73;
        values[valuesById[74] = "REQUEST_STOP_DRAFT_ATTACHMENT_UPLOAD"] = 74;
        values[valuesById[80] = "RECEIVE_DOWNLOAD_ATTACHMENT_CHUNK"] = 80;
        values[valuesById[81] = "RECEIVE_DOWNLOAD_ATTACHMENT_DONE"] = 81;
        return values;
    })();

    olvid.Colissimo = (function() {

        /**
         * Properties of a Colissimo.
         * @memberof olvid
         * @interface IColissimo
         * @property {olvid.ColissimoType|null} [type] Colissimo type
         * @property {olvid.IConnectionPing|null} [connectionPing] Colissimo connectionPing
         * @property {olvid.ISettings|null} [settings] Colissimo settings
         * @property {olvid.IRequestDiscussions|null} [requestDiscussions] Colissimo requestDiscussions
         * @property {olvid.IRequestDiscussionsResponse|null} [requestDiscussionsResponse] Colissimo requestDiscussionsResponse
         * @property {olvid.IRequestMessage|null} [requestMessage] Colissimo requestMessage
         * @property {olvid.IRequestMessageResponse|null} [requestMessageResponse] Colissimo requestMessageResponse
         * @property {olvid.IRequestMarkDiscussionAsRead|null} [requestMarkDiscussionAsRead] Colissimo requestMarkDiscussionAsRead
         * @property {olvid.IRequestUpdateMessage|null} [requestUpdateMessage] Colissimo requestUpdateMessage
         * @property {olvid.IRequestAddReactionToMessage|null} [requestAddReactionToMessage] Colissimo requestAddReactionToMessage
         * @property {olvid.INotifDiscussionUpdated|null} [notifDiscussionUpdated] Colissimo notifDiscussionUpdated
         * @property {olvid.INotifNewMessage|null} [notifNewMessage] Colissimo notifNewMessage
         * @property {olvid.INotifNewDiscussion|null} [notifNewDiscussion] Colissimo notifNewDiscussion
         * @property {olvid.INotifDeleteMessage|null} [notifDeleteMessage] Colissimo notifDeleteMessage
         * @property {olvid.INotifNewDraftAttachment|null} [notifNewDraftAttachment] Colissimo notifNewDraftAttachment
         * @property {olvid.INotifNewAttachment|null} [notifNewAttachment] Colissimo notifNewAttachment
         * @property {olvid.INotifDeleteDraftAttachment|null} [notifDeleteDraftAttachment] Colissimo notifDeleteDraftAttachment
         * @property {olvid.INotifNoDraftForDiscussion|null} [notifNoDraftForDiscussion] Colissimo notifNoDraftForDiscussion
         * @property {olvid.INotifFileAlreadyExists|null} [notifFileAlreadyExists] Colissimo notifFileAlreadyExists
         * @property {olvid.INotifFileAlreadyAttached|null} [notifFileAlreadyAttached] Colissimo notifFileAlreadyAttached
         * @property {olvid.INotifUploadFile|null} [notifUploadFile] Colissimo notifUploadFile
         * @property {olvid.INotifUploadResult|null} [notifUploadResult] Colissimo notifUploadResult
         * @property {olvid.INotifDeleteAttachment|null} [notifDeleteAttachment] Colissimo notifDeleteAttachment
         * @property {olvid.INotifDeleteDiscussion|null} [notifDeleteDiscussion] Colissimo notifDeleteDiscussion
         * @property {olvid.INotifUpdateMessage|null} [notifUpdateMessage] Colissimo notifUpdateMessage
         * @property {olvid.INotifUpdateAttachment|null} [notifUpdateAttachment] Colissimo notifUpdateAttachment
         * @property {olvid.INotifUpdateDraftAttachment|null} [notifUpdateDraftAttachment] Colissimo notifUpdateDraftAttachment
         * @property {olvid.ICreateMessage|null} [createMessage] Colissimo createMessage
         * @property {olvid.INotifMessageSent|null} [notifMessageSent] Colissimo notifMessageSent
         * @property {olvid.IRequestThumbnail|null} [requestThumbnail] Colissimo requestThumbnail
         * @property {olvid.IRequestThumbnailResponse|null} [requestThumbnailResponse] Colissimo requestThumbnailResponse
         * @property {olvid.ISendAttachmentNotice|null} [sendAttachmentNotice] Colissimo sendAttachmentNotice
         * @property {olvid.ISendAttachmentChunk|null} [sendAttachmentChunk] Colissimo sendAttachmentChunk
         * @property {olvid.ISendAttachmentDone|null} [sendAttachmentDone] Colissimo sendAttachmentDone
         * @property {olvid.IRequestSaveDraftMessage|null} [requestSaveDraftMessage] Colissimo requestSaveDraftMessage
         * @property {olvid.IRequestDeleteDraftAttachment|null} [requestDeleteDraftAttachment] Colissimo requestDeleteDraftAttachment
         * @property {olvid.IRequestDownloadAttachment|null} [requestDownloadAttachment] Colissimo requestDownloadAttachment
         * @property {olvid.IRequestDeleteDraftMessage|null} [requestDeleteDraftMessage] Colissimo requestDeleteDraftMessage
         * @property {olvid.IRequestStopDraftAttachmentUpload|null} [requestStopDraftAttachmentUpload] Colissimo requestStopDraftAttachmentUpload
         * @property {olvid.IReceiveDownloadAttachmentChunk|null} [receiveDownloadAttachmentChunk] Colissimo receiveDownloadAttachmentChunk
         * @property {olvid.IReceiveDownloadAttachmentDone|null} [receiveDownloadAttachmentDone] Colissimo receiveDownloadAttachmentDone
         */

        /**
         * Constructs a new Colissimo.
         * @memberof olvid
         * @classdesc Represents a Colissimo.
         * @implements IColissimo
         * @constructor
         * @param {olvid.IColissimo=} [properties] Properties to set
         */
        function Colissimo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Colissimo type.
         * @member {olvid.ColissimoType} type
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.type = 0;

        /**
         * Colissimo connectionPing.
         * @member {olvid.IConnectionPing|null|undefined} connectionPing
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.connectionPing = null;

        /**
         * Colissimo settings.
         * @member {olvid.ISettings|null|undefined} settings
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.settings = null;

        /**
         * Colissimo requestDiscussions.
         * @member {olvid.IRequestDiscussions|null|undefined} requestDiscussions
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestDiscussions = null;

        /**
         * Colissimo requestDiscussionsResponse.
         * @member {olvid.IRequestDiscussionsResponse|null|undefined} requestDiscussionsResponse
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestDiscussionsResponse = null;

        /**
         * Colissimo requestMessage.
         * @member {olvid.IRequestMessage|null|undefined} requestMessage
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestMessage = null;

        /**
         * Colissimo requestMessageResponse.
         * @member {olvid.IRequestMessageResponse|null|undefined} requestMessageResponse
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestMessageResponse = null;

        /**
         * Colissimo requestMarkDiscussionAsRead.
         * @member {olvid.IRequestMarkDiscussionAsRead|null|undefined} requestMarkDiscussionAsRead
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestMarkDiscussionAsRead = null;

        /**
         * Colissimo requestUpdateMessage.
         * @member {olvid.IRequestUpdateMessage|null|undefined} requestUpdateMessage
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestUpdateMessage = null;

        /**
         * Colissimo requestAddReactionToMessage.
         * @member {olvid.IRequestAddReactionToMessage|null|undefined} requestAddReactionToMessage
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestAddReactionToMessage = null;

        /**
         * Colissimo notifDiscussionUpdated.
         * @member {olvid.INotifDiscussionUpdated|null|undefined} notifDiscussionUpdated
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifDiscussionUpdated = null;

        /**
         * Colissimo notifNewMessage.
         * @member {olvid.INotifNewMessage|null|undefined} notifNewMessage
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifNewMessage = null;

        /**
         * Colissimo notifNewDiscussion.
         * @member {olvid.INotifNewDiscussion|null|undefined} notifNewDiscussion
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifNewDiscussion = null;

        /**
         * Colissimo notifDeleteMessage.
         * @member {olvid.INotifDeleteMessage|null|undefined} notifDeleteMessage
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifDeleteMessage = null;

        /**
         * Colissimo notifNewDraftAttachment.
         * @member {olvid.INotifNewDraftAttachment|null|undefined} notifNewDraftAttachment
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifNewDraftAttachment = null;

        /**
         * Colissimo notifNewAttachment.
         * @member {olvid.INotifNewAttachment|null|undefined} notifNewAttachment
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifNewAttachment = null;

        /**
         * Colissimo notifDeleteDraftAttachment.
         * @member {olvid.INotifDeleteDraftAttachment|null|undefined} notifDeleteDraftAttachment
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifDeleteDraftAttachment = null;

        /**
         * Colissimo notifNoDraftForDiscussion.
         * @member {olvid.INotifNoDraftForDiscussion|null|undefined} notifNoDraftForDiscussion
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifNoDraftForDiscussion = null;

        /**
         * Colissimo notifFileAlreadyExists.
         * @member {olvid.INotifFileAlreadyExists|null|undefined} notifFileAlreadyExists
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifFileAlreadyExists = null;

        /**
         * Colissimo notifFileAlreadyAttached.
         * @member {olvid.INotifFileAlreadyAttached|null|undefined} notifFileAlreadyAttached
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifFileAlreadyAttached = null;

        /**
         * Colissimo notifUploadFile.
         * @member {olvid.INotifUploadFile|null|undefined} notifUploadFile
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifUploadFile = null;

        /**
         * Colissimo notifUploadResult.
         * @member {olvid.INotifUploadResult|null|undefined} notifUploadResult
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifUploadResult = null;

        /**
         * Colissimo notifDeleteAttachment.
         * @member {olvid.INotifDeleteAttachment|null|undefined} notifDeleteAttachment
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifDeleteAttachment = null;

        /**
         * Colissimo notifDeleteDiscussion.
         * @member {olvid.INotifDeleteDiscussion|null|undefined} notifDeleteDiscussion
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifDeleteDiscussion = null;

        /**
         * Colissimo notifUpdateMessage.
         * @member {olvid.INotifUpdateMessage|null|undefined} notifUpdateMessage
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifUpdateMessage = null;

        /**
         * Colissimo notifUpdateAttachment.
         * @member {olvid.INotifUpdateAttachment|null|undefined} notifUpdateAttachment
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifUpdateAttachment = null;

        /**
         * Colissimo notifUpdateDraftAttachment.
         * @member {olvid.INotifUpdateDraftAttachment|null|undefined} notifUpdateDraftAttachment
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifUpdateDraftAttachment = null;

        /**
         * Colissimo createMessage.
         * @member {olvid.ICreateMessage|null|undefined} createMessage
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.createMessage = null;

        /**
         * Colissimo notifMessageSent.
         * @member {olvid.INotifMessageSent|null|undefined} notifMessageSent
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.notifMessageSent = null;

        /**
         * Colissimo requestThumbnail.
         * @member {olvid.IRequestThumbnail|null|undefined} requestThumbnail
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestThumbnail = null;

        /**
         * Colissimo requestThumbnailResponse.
         * @member {olvid.IRequestThumbnailResponse|null|undefined} requestThumbnailResponse
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestThumbnailResponse = null;

        /**
         * Colissimo sendAttachmentNotice.
         * @member {olvid.ISendAttachmentNotice|null|undefined} sendAttachmentNotice
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.sendAttachmentNotice = null;

        /**
         * Colissimo sendAttachmentChunk.
         * @member {olvid.ISendAttachmentChunk|null|undefined} sendAttachmentChunk
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.sendAttachmentChunk = null;

        /**
         * Colissimo sendAttachmentDone.
         * @member {olvid.ISendAttachmentDone|null|undefined} sendAttachmentDone
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.sendAttachmentDone = null;

        /**
         * Colissimo requestSaveDraftMessage.
         * @member {olvid.IRequestSaveDraftMessage|null|undefined} requestSaveDraftMessage
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestSaveDraftMessage = null;

        /**
         * Colissimo requestDeleteDraftAttachment.
         * @member {olvid.IRequestDeleteDraftAttachment|null|undefined} requestDeleteDraftAttachment
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestDeleteDraftAttachment = null;

        /**
         * Colissimo requestDownloadAttachment.
         * @member {olvid.IRequestDownloadAttachment|null|undefined} requestDownloadAttachment
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestDownloadAttachment = null;

        /**
         * Colissimo requestDeleteDraftMessage.
         * @member {olvid.IRequestDeleteDraftMessage|null|undefined} requestDeleteDraftMessage
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestDeleteDraftMessage = null;

        /**
         * Colissimo requestStopDraftAttachmentUpload.
         * @member {olvid.IRequestStopDraftAttachmentUpload|null|undefined} requestStopDraftAttachmentUpload
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.requestStopDraftAttachmentUpload = null;

        /**
         * Colissimo receiveDownloadAttachmentChunk.
         * @member {olvid.IReceiveDownloadAttachmentChunk|null|undefined} receiveDownloadAttachmentChunk
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.receiveDownloadAttachmentChunk = null;

        /**
         * Colissimo receiveDownloadAttachmentDone.
         * @member {olvid.IReceiveDownloadAttachmentDone|null|undefined} receiveDownloadAttachmentDone
         * @memberof olvid.Colissimo
         * @instance
         */
        Colissimo.prototype.receiveDownloadAttachmentDone = null;

        /**
         * Creates a new Colissimo instance using the specified properties.
         * @function create
         * @memberof olvid.Colissimo
         * @static
         * @param {olvid.IColissimo=} [properties] Properties to set
         * @returns {olvid.Colissimo} Colissimo instance
         */
        Colissimo.create = function create(properties) {
            return new Colissimo(properties);
        };

        /**
         * Encodes the specified Colissimo message. Does not implicitly {@link olvid.Colissimo.verify|verify} messages.
         * @function encode
         * @memberof olvid.Colissimo
         * @static
         * @param {olvid.IColissimo} message Colissimo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Colissimo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.connectionPing != null && Object.hasOwnProperty.call(message, "connectionPing"))
                $root.olvid.ConnectionPing.encode(message.connectionPing, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                $root.olvid.Settings.encode(message.settings, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.requestDiscussions != null && Object.hasOwnProperty.call(message, "requestDiscussions"))
                $root.olvid.RequestDiscussions.encode(message.requestDiscussions, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.requestDiscussionsResponse != null && Object.hasOwnProperty.call(message, "requestDiscussionsResponse"))
                $root.olvid.RequestDiscussionsResponse.encode(message.requestDiscussionsResponse, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.requestMessage != null && Object.hasOwnProperty.call(message, "requestMessage"))
                $root.olvid.RequestMessage.encode(message.requestMessage, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.requestMessageResponse != null && Object.hasOwnProperty.call(message, "requestMessageResponse"))
                $root.olvid.RequestMessageResponse.encode(message.requestMessageResponse, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.requestMarkDiscussionAsRead != null && Object.hasOwnProperty.call(message, "requestMarkDiscussionAsRead"))
                $root.olvid.RequestMarkDiscussionAsRead.encode(message.requestMarkDiscussionAsRead, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.requestUpdateMessage != null && Object.hasOwnProperty.call(message, "requestUpdateMessage"))
                $root.olvid.RequestUpdateMessage.encode(message.requestUpdateMessage, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.requestAddReactionToMessage != null && Object.hasOwnProperty.call(message, "requestAddReactionToMessage"))
                $root.olvid.RequestAddReactionToMessage.encode(message.requestAddReactionToMessage, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.notifDiscussionUpdated != null && Object.hasOwnProperty.call(message, "notifDiscussionUpdated"))
                $root.olvid.NotifDiscussionUpdated.encode(message.notifDiscussionUpdated, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.notifNewMessage != null && Object.hasOwnProperty.call(message, "notifNewMessage"))
                $root.olvid.NotifNewMessage.encode(message.notifNewMessage, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.notifDeleteMessage != null && Object.hasOwnProperty.call(message, "notifDeleteMessage"))
                $root.olvid.NotifDeleteMessage.encode(message.notifDeleteMessage, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
            if (message.notifNewDraftAttachment != null && Object.hasOwnProperty.call(message, "notifNewDraftAttachment"))
                $root.olvid.NotifNewDraftAttachment.encode(message.notifNewDraftAttachment, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
            if (message.notifNewAttachment != null && Object.hasOwnProperty.call(message, "notifNewAttachment"))
                $root.olvid.NotifNewAttachment.encode(message.notifNewAttachment, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
            if (message.notifDeleteDraftAttachment != null && Object.hasOwnProperty.call(message, "notifDeleteDraftAttachment"))
                $root.olvid.NotifDeleteDraftAttachment.encode(message.notifDeleteDraftAttachment, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
            if (message.notifNoDraftForDiscussion != null && Object.hasOwnProperty.call(message, "notifNoDraftForDiscussion"))
                $root.olvid.NotifNoDraftForDiscussion.encode(message.notifNoDraftForDiscussion, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
            if (message.notifFileAlreadyExists != null && Object.hasOwnProperty.call(message, "notifFileAlreadyExists"))
                $root.olvid.NotifFileAlreadyExists.encode(message.notifFileAlreadyExists, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
            if (message.notifFileAlreadyAttached != null && Object.hasOwnProperty.call(message, "notifFileAlreadyAttached"))
                $root.olvid.NotifFileAlreadyAttached.encode(message.notifFileAlreadyAttached, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
            if (message.notifUploadFile != null && Object.hasOwnProperty.call(message, "notifUploadFile"))
                $root.olvid.NotifUploadFile.encode(message.notifUploadFile, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
            if (message.notifUploadResult != null && Object.hasOwnProperty.call(message, "notifUploadResult"))
                $root.olvid.NotifUploadResult.encode(message.notifUploadResult, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.notifDeleteAttachment != null && Object.hasOwnProperty.call(message, "notifDeleteAttachment"))
                $root.olvid.NotifDeleteAttachment.encode(message.notifDeleteAttachment, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.notifDeleteDiscussion != null && Object.hasOwnProperty.call(message, "notifDeleteDiscussion"))
                $root.olvid.NotifDeleteDiscussion.encode(message.notifDeleteDiscussion, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
            if (message.notifUpdateMessage != null && Object.hasOwnProperty.call(message, "notifUpdateMessage"))
                $root.olvid.NotifUpdateMessage.encode(message.notifUpdateMessage, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
            if (message.notifUpdateAttachment != null && Object.hasOwnProperty.call(message, "notifUpdateAttachment"))
                $root.olvid.NotifUpdateAttachment.encode(message.notifUpdateAttachment, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
            if (message.notifUpdateDraftAttachment != null && Object.hasOwnProperty.call(message, "notifUpdateDraftAttachment"))
                $root.olvid.NotifUpdateDraftAttachment.encode(message.notifUpdateDraftAttachment, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
            if (message.createMessage != null && Object.hasOwnProperty.call(message, "createMessage"))
                $root.olvid.CreateMessage.encode(message.createMessage, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
            if (message.notifMessageSent != null && Object.hasOwnProperty.call(message, "notifMessageSent"))
                $root.olvid.NotifMessageSent.encode(message.notifMessageSent, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
            if (message.notifNewDiscussion != null && Object.hasOwnProperty.call(message, "notifNewDiscussion"))
                $root.olvid.NotifNewDiscussion.encode(message.notifNewDiscussion, writer.uint32(/* id 58, wireType 2 =*/466).fork()).ldelim();
            if (message.requestThumbnail != null && Object.hasOwnProperty.call(message, "requestThumbnail"))
                $root.olvid.RequestThumbnail.encode(message.requestThumbnail, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
            if (message.requestThumbnailResponse != null && Object.hasOwnProperty.call(message, "requestThumbnailResponse"))
                $root.olvid.RequestThumbnailResponse.encode(message.requestThumbnailResponse, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
            if (message.sendAttachmentNotice != null && Object.hasOwnProperty.call(message, "sendAttachmentNotice"))
                $root.olvid.SendAttachmentNotice.encode(message.sendAttachmentNotice, writer.uint32(/* id 70, wireType 2 =*/562).fork()).ldelim();
            if (message.sendAttachmentChunk != null && Object.hasOwnProperty.call(message, "sendAttachmentChunk"))
                $root.olvid.SendAttachmentChunk.encode(message.sendAttachmentChunk, writer.uint32(/* id 71, wireType 2 =*/570).fork()).ldelim();
            if (message.sendAttachmentDone != null && Object.hasOwnProperty.call(message, "sendAttachmentDone"))
                $root.olvid.SendAttachmentDone.encode(message.sendAttachmentDone, writer.uint32(/* id 72, wireType 2 =*/578).fork()).ldelim();
            if (message.requestSaveDraftMessage != null && Object.hasOwnProperty.call(message, "requestSaveDraftMessage"))
                $root.olvid.RequestSaveDraftMessage.encode(message.requestSaveDraftMessage, writer.uint32(/* id 80, wireType 2 =*/642).fork()).ldelim();
            if (message.requestDeleteDraftAttachment != null && Object.hasOwnProperty.call(message, "requestDeleteDraftAttachment"))
                $root.olvid.RequestDeleteDraftAttachment.encode(message.requestDeleteDraftAttachment, writer.uint32(/* id 81, wireType 2 =*/650).fork()).ldelim();
            if (message.requestDownloadAttachment != null && Object.hasOwnProperty.call(message, "requestDownloadAttachment"))
                $root.olvid.RequestDownloadAttachment.encode(message.requestDownloadAttachment, writer.uint32(/* id 82, wireType 2 =*/658).fork()).ldelim();
            if (message.requestDeleteDraftMessage != null && Object.hasOwnProperty.call(message, "requestDeleteDraftMessage"))
                $root.olvid.RequestDeleteDraftMessage.encode(message.requestDeleteDraftMessage, writer.uint32(/* id 83, wireType 2 =*/666).fork()).ldelim();
            if (message.requestStopDraftAttachmentUpload != null && Object.hasOwnProperty.call(message, "requestStopDraftAttachmentUpload"))
                $root.olvid.RequestStopDraftAttachmentUpload.encode(message.requestStopDraftAttachmentUpload, writer.uint32(/* id 84, wireType 2 =*/674).fork()).ldelim();
            if (message.receiveDownloadAttachmentChunk != null && Object.hasOwnProperty.call(message, "receiveDownloadAttachmentChunk"))
                $root.olvid.ReceiveDownloadAttachmentChunk.encode(message.receiveDownloadAttachmentChunk, writer.uint32(/* id 90, wireType 2 =*/722).fork()).ldelim();
            if (message.receiveDownloadAttachmentDone != null && Object.hasOwnProperty.call(message, "receiveDownloadAttachmentDone"))
                $root.olvid.ReceiveDownloadAttachmentDone.encode(message.receiveDownloadAttachmentDone, writer.uint32(/* id 91, wireType 2 =*/730).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Colissimo message, length delimited. Does not implicitly {@link olvid.Colissimo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.Colissimo
         * @static
         * @param {olvid.IColissimo} message Colissimo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Colissimo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Colissimo message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.Colissimo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.Colissimo} Colissimo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Colissimo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.Colissimo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 11: {
                        message.connectionPing = $root.olvid.ConnectionPing.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.settings = $root.olvid.Settings.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.requestDiscussions = $root.olvid.RequestDiscussions.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.requestDiscussionsResponse = $root.olvid.RequestDiscussionsResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.requestMessage = $root.olvid.RequestMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.requestMessageResponse = $root.olvid.RequestMessageResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 24: {
                        message.requestMarkDiscussionAsRead = $root.olvid.RequestMarkDiscussionAsRead.decode(reader, reader.uint32());
                        break;
                    }
                case 25: {
                        message.requestUpdateMessage = $root.olvid.RequestUpdateMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 26: {
                        message.requestAddReactionToMessage = $root.olvid.RequestAddReactionToMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 40: {
                        message.notifDiscussionUpdated = $root.olvid.NotifDiscussionUpdated.decode(reader, reader.uint32());
                        break;
                    }
                case 41: {
                        message.notifNewMessage = $root.olvid.NotifNewMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 58: {
                        message.notifNewDiscussion = $root.olvid.NotifNewDiscussion.decode(reader, reader.uint32());
                        break;
                    }
                case 42: {
                        message.notifDeleteMessage = $root.olvid.NotifDeleteMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 43: {
                        message.notifNewDraftAttachment = $root.olvid.NotifNewDraftAttachment.decode(reader, reader.uint32());
                        break;
                    }
                case 44: {
                        message.notifNewAttachment = $root.olvid.NotifNewAttachment.decode(reader, reader.uint32());
                        break;
                    }
                case 45: {
                        message.notifDeleteDraftAttachment = $root.olvid.NotifDeleteDraftAttachment.decode(reader, reader.uint32());
                        break;
                    }
                case 46: {
                        message.notifNoDraftForDiscussion = $root.olvid.NotifNoDraftForDiscussion.decode(reader, reader.uint32());
                        break;
                    }
                case 47: {
                        message.notifFileAlreadyExists = $root.olvid.NotifFileAlreadyExists.decode(reader, reader.uint32());
                        break;
                    }
                case 48: {
                        message.notifFileAlreadyAttached = $root.olvid.NotifFileAlreadyAttached.decode(reader, reader.uint32());
                        break;
                    }
                case 49: {
                        message.notifUploadFile = $root.olvid.NotifUploadFile.decode(reader, reader.uint32());
                        break;
                    }
                case 50: {
                        message.notifUploadResult = $root.olvid.NotifUploadResult.decode(reader, reader.uint32());
                        break;
                    }
                case 51: {
                        message.notifDeleteAttachment = $root.olvid.NotifDeleteAttachment.decode(reader, reader.uint32());
                        break;
                    }
                case 52: {
                        message.notifDeleteDiscussion = $root.olvid.NotifDeleteDiscussion.decode(reader, reader.uint32());
                        break;
                    }
                case 53: {
                        message.notifUpdateMessage = $root.olvid.NotifUpdateMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 54: {
                        message.notifUpdateAttachment = $root.olvid.NotifUpdateAttachment.decode(reader, reader.uint32());
                        break;
                    }
                case 55: {
                        message.notifUpdateDraftAttachment = $root.olvid.NotifUpdateDraftAttachment.decode(reader, reader.uint32());
                        break;
                    }
                case 56: {
                        message.createMessage = $root.olvid.CreateMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 57: {
                        message.notifMessageSent = $root.olvid.NotifMessageSent.decode(reader, reader.uint32());
                        break;
                    }
                case 60: {
                        message.requestThumbnail = $root.olvid.RequestThumbnail.decode(reader, reader.uint32());
                        break;
                    }
                case 62: {
                        message.requestThumbnailResponse = $root.olvid.RequestThumbnailResponse.decode(reader, reader.uint32());
                        break;
                    }
                case 70: {
                        message.sendAttachmentNotice = $root.olvid.SendAttachmentNotice.decode(reader, reader.uint32());
                        break;
                    }
                case 71: {
                        message.sendAttachmentChunk = $root.olvid.SendAttachmentChunk.decode(reader, reader.uint32());
                        break;
                    }
                case 72: {
                        message.sendAttachmentDone = $root.olvid.SendAttachmentDone.decode(reader, reader.uint32());
                        break;
                    }
                case 80: {
                        message.requestSaveDraftMessage = $root.olvid.RequestSaveDraftMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 81: {
                        message.requestDeleteDraftAttachment = $root.olvid.RequestDeleteDraftAttachment.decode(reader, reader.uint32());
                        break;
                    }
                case 82: {
                        message.requestDownloadAttachment = $root.olvid.RequestDownloadAttachment.decode(reader, reader.uint32());
                        break;
                    }
                case 83: {
                        message.requestDeleteDraftMessage = $root.olvid.RequestDeleteDraftMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 84: {
                        message.requestStopDraftAttachmentUpload = $root.olvid.RequestStopDraftAttachmentUpload.decode(reader, reader.uint32());
                        break;
                    }
                case 90: {
                        message.receiveDownloadAttachmentChunk = $root.olvid.ReceiveDownloadAttachmentChunk.decode(reader, reader.uint32());
                        break;
                    }
                case 91: {
                        message.receiveDownloadAttachmentDone = $root.olvid.ReceiveDownloadAttachmentDone.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Colissimo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.Colissimo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.Colissimo} Colissimo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Colissimo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Colissimo message.
         * @function verify
         * @memberof olvid.Colissimo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Colissimo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 30:
                case 31:
                case 32:
                case 49:
                case 33:
                case 34:
                case 35:
                case 36:
                case 37:
                case 38:
                case 39:
                case 40:
                case 41:
                case 42:
                case 43:
                case 44:
                case 45:
                case 46:
                case 47:
                case 48:
                case 50:
                case 52:
                case 60:
                case 61:
                case 62:
                case 70:
                case 71:
                case 72:
                case 73:
                case 74:
                case 80:
                case 81:
                    break;
                }
            if (message.connectionPing != null && message.hasOwnProperty("connectionPing")) {
                var error = $root.olvid.ConnectionPing.verify(message.connectionPing);
                if (error)
                    return "connectionPing." + error;
            }
            if (message.settings != null && message.hasOwnProperty("settings")) {
                var error = $root.olvid.Settings.verify(message.settings);
                if (error)
                    return "settings." + error;
            }
            if (message.requestDiscussions != null && message.hasOwnProperty("requestDiscussions")) {
                var error = $root.olvid.RequestDiscussions.verify(message.requestDiscussions);
                if (error)
                    return "requestDiscussions." + error;
            }
            if (message.requestDiscussionsResponse != null && message.hasOwnProperty("requestDiscussionsResponse")) {
                var error = $root.olvid.RequestDiscussionsResponse.verify(message.requestDiscussionsResponse);
                if (error)
                    return "requestDiscussionsResponse." + error;
            }
            if (message.requestMessage != null && message.hasOwnProperty("requestMessage")) {
                var error = $root.olvid.RequestMessage.verify(message.requestMessage);
                if (error)
                    return "requestMessage." + error;
            }
            if (message.requestMessageResponse != null && message.hasOwnProperty("requestMessageResponse")) {
                var error = $root.olvid.RequestMessageResponse.verify(message.requestMessageResponse);
                if (error)
                    return "requestMessageResponse." + error;
            }
            if (message.requestMarkDiscussionAsRead != null && message.hasOwnProperty("requestMarkDiscussionAsRead")) {
                var error = $root.olvid.RequestMarkDiscussionAsRead.verify(message.requestMarkDiscussionAsRead);
                if (error)
                    return "requestMarkDiscussionAsRead." + error;
            }
            if (message.requestUpdateMessage != null && message.hasOwnProperty("requestUpdateMessage")) {
                var error = $root.olvid.RequestUpdateMessage.verify(message.requestUpdateMessage);
                if (error)
                    return "requestUpdateMessage." + error;
            }
            if (message.requestAddReactionToMessage != null && message.hasOwnProperty("requestAddReactionToMessage")) {
                var error = $root.olvid.RequestAddReactionToMessage.verify(message.requestAddReactionToMessage);
                if (error)
                    return "requestAddReactionToMessage." + error;
            }
            if (message.notifDiscussionUpdated != null && message.hasOwnProperty("notifDiscussionUpdated")) {
                var error = $root.olvid.NotifDiscussionUpdated.verify(message.notifDiscussionUpdated);
                if (error)
                    return "notifDiscussionUpdated." + error;
            }
            if (message.notifNewMessage != null && message.hasOwnProperty("notifNewMessage")) {
                var error = $root.olvid.NotifNewMessage.verify(message.notifNewMessage);
                if (error)
                    return "notifNewMessage." + error;
            }
            if (message.notifNewDiscussion != null && message.hasOwnProperty("notifNewDiscussion")) {
                var error = $root.olvid.NotifNewDiscussion.verify(message.notifNewDiscussion);
                if (error)
                    return "notifNewDiscussion." + error;
            }
            if (message.notifDeleteMessage != null && message.hasOwnProperty("notifDeleteMessage")) {
                var error = $root.olvid.NotifDeleteMessage.verify(message.notifDeleteMessage);
                if (error)
                    return "notifDeleteMessage." + error;
            }
            if (message.notifNewDraftAttachment != null && message.hasOwnProperty("notifNewDraftAttachment")) {
                var error = $root.olvid.NotifNewDraftAttachment.verify(message.notifNewDraftAttachment);
                if (error)
                    return "notifNewDraftAttachment." + error;
            }
            if (message.notifNewAttachment != null && message.hasOwnProperty("notifNewAttachment")) {
                var error = $root.olvid.NotifNewAttachment.verify(message.notifNewAttachment);
                if (error)
                    return "notifNewAttachment." + error;
            }
            if (message.notifDeleteDraftAttachment != null && message.hasOwnProperty("notifDeleteDraftAttachment")) {
                var error = $root.olvid.NotifDeleteDraftAttachment.verify(message.notifDeleteDraftAttachment);
                if (error)
                    return "notifDeleteDraftAttachment." + error;
            }
            if (message.notifNoDraftForDiscussion != null && message.hasOwnProperty("notifNoDraftForDiscussion")) {
                var error = $root.olvid.NotifNoDraftForDiscussion.verify(message.notifNoDraftForDiscussion);
                if (error)
                    return "notifNoDraftForDiscussion." + error;
            }
            if (message.notifFileAlreadyExists != null && message.hasOwnProperty("notifFileAlreadyExists")) {
                var error = $root.olvid.NotifFileAlreadyExists.verify(message.notifFileAlreadyExists);
                if (error)
                    return "notifFileAlreadyExists." + error;
            }
            if (message.notifFileAlreadyAttached != null && message.hasOwnProperty("notifFileAlreadyAttached")) {
                var error = $root.olvid.NotifFileAlreadyAttached.verify(message.notifFileAlreadyAttached);
                if (error)
                    return "notifFileAlreadyAttached." + error;
            }
            if (message.notifUploadFile != null && message.hasOwnProperty("notifUploadFile")) {
                var error = $root.olvid.NotifUploadFile.verify(message.notifUploadFile);
                if (error)
                    return "notifUploadFile." + error;
            }
            if (message.notifUploadResult != null && message.hasOwnProperty("notifUploadResult")) {
                var error = $root.olvid.NotifUploadResult.verify(message.notifUploadResult);
                if (error)
                    return "notifUploadResult." + error;
            }
            if (message.notifDeleteAttachment != null && message.hasOwnProperty("notifDeleteAttachment")) {
                var error = $root.olvid.NotifDeleteAttachment.verify(message.notifDeleteAttachment);
                if (error)
                    return "notifDeleteAttachment." + error;
            }
            if (message.notifDeleteDiscussion != null && message.hasOwnProperty("notifDeleteDiscussion")) {
                var error = $root.olvid.NotifDeleteDiscussion.verify(message.notifDeleteDiscussion);
                if (error)
                    return "notifDeleteDiscussion." + error;
            }
            if (message.notifUpdateMessage != null && message.hasOwnProperty("notifUpdateMessage")) {
                var error = $root.olvid.NotifUpdateMessage.verify(message.notifUpdateMessage);
                if (error)
                    return "notifUpdateMessage." + error;
            }
            if (message.notifUpdateAttachment != null && message.hasOwnProperty("notifUpdateAttachment")) {
                var error = $root.olvid.NotifUpdateAttachment.verify(message.notifUpdateAttachment);
                if (error)
                    return "notifUpdateAttachment." + error;
            }
            if (message.notifUpdateDraftAttachment != null && message.hasOwnProperty("notifUpdateDraftAttachment")) {
                var error = $root.olvid.NotifUpdateDraftAttachment.verify(message.notifUpdateDraftAttachment);
                if (error)
                    return "notifUpdateDraftAttachment." + error;
            }
            if (message.createMessage != null && message.hasOwnProperty("createMessage")) {
                var error = $root.olvid.CreateMessage.verify(message.createMessage);
                if (error)
                    return "createMessage." + error;
            }
            if (message.notifMessageSent != null && message.hasOwnProperty("notifMessageSent")) {
                var error = $root.olvid.NotifMessageSent.verify(message.notifMessageSent);
                if (error)
                    return "notifMessageSent." + error;
            }
            if (message.requestThumbnail != null && message.hasOwnProperty("requestThumbnail")) {
                var error = $root.olvid.RequestThumbnail.verify(message.requestThumbnail);
                if (error)
                    return "requestThumbnail." + error;
            }
            if (message.requestThumbnailResponse != null && message.hasOwnProperty("requestThumbnailResponse")) {
                var error = $root.olvid.RequestThumbnailResponse.verify(message.requestThumbnailResponse);
                if (error)
                    return "requestThumbnailResponse." + error;
            }
            if (message.sendAttachmentNotice != null && message.hasOwnProperty("sendAttachmentNotice")) {
                var error = $root.olvid.SendAttachmentNotice.verify(message.sendAttachmentNotice);
                if (error)
                    return "sendAttachmentNotice." + error;
            }
            if (message.sendAttachmentChunk != null && message.hasOwnProperty("sendAttachmentChunk")) {
                var error = $root.olvid.SendAttachmentChunk.verify(message.sendAttachmentChunk);
                if (error)
                    return "sendAttachmentChunk." + error;
            }
            if (message.sendAttachmentDone != null && message.hasOwnProperty("sendAttachmentDone")) {
                var error = $root.olvid.SendAttachmentDone.verify(message.sendAttachmentDone);
                if (error)
                    return "sendAttachmentDone." + error;
            }
            if (message.requestSaveDraftMessage != null && message.hasOwnProperty("requestSaveDraftMessage")) {
                var error = $root.olvid.RequestSaveDraftMessage.verify(message.requestSaveDraftMessage);
                if (error)
                    return "requestSaveDraftMessage." + error;
            }
            if (message.requestDeleteDraftAttachment != null && message.hasOwnProperty("requestDeleteDraftAttachment")) {
                var error = $root.olvid.RequestDeleteDraftAttachment.verify(message.requestDeleteDraftAttachment);
                if (error)
                    return "requestDeleteDraftAttachment." + error;
            }
            if (message.requestDownloadAttachment != null && message.hasOwnProperty("requestDownloadAttachment")) {
                var error = $root.olvid.RequestDownloadAttachment.verify(message.requestDownloadAttachment);
                if (error)
                    return "requestDownloadAttachment." + error;
            }
            if (message.requestDeleteDraftMessage != null && message.hasOwnProperty("requestDeleteDraftMessage")) {
                var error = $root.olvid.RequestDeleteDraftMessage.verify(message.requestDeleteDraftMessage);
                if (error)
                    return "requestDeleteDraftMessage." + error;
            }
            if (message.requestStopDraftAttachmentUpload != null && message.hasOwnProperty("requestStopDraftAttachmentUpload")) {
                var error = $root.olvid.RequestStopDraftAttachmentUpload.verify(message.requestStopDraftAttachmentUpload);
                if (error)
                    return "requestStopDraftAttachmentUpload." + error;
            }
            if (message.receiveDownloadAttachmentChunk != null && message.hasOwnProperty("receiveDownloadAttachmentChunk")) {
                var error = $root.olvid.ReceiveDownloadAttachmentChunk.verify(message.receiveDownloadAttachmentChunk);
                if (error)
                    return "receiveDownloadAttachmentChunk." + error;
            }
            if (message.receiveDownloadAttachmentDone != null && message.hasOwnProperty("receiveDownloadAttachmentDone")) {
                var error = $root.olvid.ReceiveDownloadAttachmentDone.verify(message.receiveDownloadAttachmentDone);
                if (error)
                    return "receiveDownloadAttachmentDone." + error;
            }
            return null;
        };

        /**
         * Creates a Colissimo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.Colissimo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.Colissimo} Colissimo
         */
        Colissimo.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.Colissimo)
                return object;
            var message = new $root.olvid.Colissimo();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "COLISSIMO_TYPE_DEFAULT":
            case 0:
                message.type = 0;
                break;
            case "CONNECTION_PING":
            case 1:
                message.type = 1;
                break;
            case "BYE":
            case 2:
                message.type = 2;
                break;
            case "SETTINGS":
            case 3:
                message.type = 3;
                break;
            case "REFRESH":
            case 4:
                message.type = 4;
                break;
            case "REQUEST_DISCUSSIONS":
            case 20:
                message.type = 20;
                break;
            case "REQUEST_DISCUSSIONS_RESPONSE":
            case 21:
                message.type = 21;
                break;
            case "REQUEST_MESSAGES":
            case 22:
                message.type = 22;
                break;
            case "REQUEST_MESSAGES_RESPONSE":
            case 23:
                message.type = 23;
                break;
            case "REQUEST_MARK_DISCUSSION_AS_READ":
            case 24:
                message.type = 24;
                break;
            case "REQUEST_UPDATE_MESSAGE":
            case 25:
                message.type = 25;
                break;
            case "REQUEST_ADD_REACTION_TO_MESSAGE":
            case 26:
                message.type = 26;
                break;
            case "NOTIF_DISCUSSION_UPDATED":
            case 30:
                message.type = 30;
                break;
            case "NOTIF_DISCUSSION_DELETED":
            case 31:
                message.type = 31;
                break;
            case "NOTIF_NEW_MESSAGE":
            case 32:
                message.type = 32;
                break;
            case "NOTIF_NEW_DISCUSSION":
            case 49:
                message.type = 49;
                break;
            case "NOTIF_DELETE_MESSAGE":
            case 33:
                message.type = 33;
                break;
            case "NOTIF_NEW_DRAFT_ATTACHMENT":
            case 34:
                message.type = 34;
                break;
            case "NOTIF_NEW_ATTACHMENT":
            case 35:
                message.type = 35;
                break;
            case "NOTIF_DELETE_DRAFT_ATTACHMENT":
            case 36:
                message.type = 36;
                break;
            case "NOTIF_NO_DRAFT_FOR_DISCUSSION":
            case 37:
                message.type = 37;
                break;
            case "NOTIF_FILE_ALREADY_EXISTS":
            case 38:
                message.type = 38;
                break;
            case "NOTIF_FILE_ALREADY_ATTACHED":
            case 39:
                message.type = 39;
                break;
            case "NOTIF_UPLOAD_FILE":
            case 40:
                message.type = 40;
                break;
            case "NOTIF_UPLOAD_RESULT":
            case 41:
                message.type = 41;
                break;
            case "NOTIF_DELETE_DISCUSSION":
            case 42:
                message.type = 42;
                break;
            case "NOTIF_DELETE_ATTACHMENT":
            case 43:
                message.type = 43;
                break;
            case "NOTIF_UPDATE_MESSAGE":
            case 44:
                message.type = 44;
                break;
            case "NOTIF_UPDATE_ATTACHMENT":
            case 45:
                message.type = 45;
                break;
            case "NOTIF_UPDATE_DRAFT_ATTACHMENT":
            case 46:
                message.type = 46;
                break;
            case "CREATE_MESSAGE":
            case 47:
                message.type = 47;
                break;
            case "NOTIF_MESSAGE_SENT":
            case 48:
                message.type = 48;
                break;
            case "REQUEST_THUMBNAIL":
            case 50:
                message.type = 50;
                break;
            case "REQUEST_THUMBNAIL_RESPONSE":
            case 52:
                message.type = 52;
                break;
            case "SEND_ATTACHMENT_NOTICE":
            case 60:
                message.type = 60;
                break;
            case "SEND_ATTACHMENT_CHUNK":
            case 61:
                message.type = 61;
                break;
            case "SEND_ATTACHMENT_DONE":
            case 62:
                message.type = 62;
                break;
            case "REQUEST_SAVE_DRAFT_MESSAGE":
            case 70:
                message.type = 70;
                break;
            case "REQUEST_DELETE_DRAFT_ATTACHMENT":
            case 71:
                message.type = 71;
                break;
            case "REQUEST_DOWNLOAD_ATTACHMENT":
            case 72:
                message.type = 72;
                break;
            case "REQUEST_DELETE_DRAFT_MESSAGE":
            case 73:
                message.type = 73;
                break;
            case "REQUEST_STOP_DRAFT_ATTACHMENT_UPLOAD":
            case 74:
                message.type = 74;
                break;
            case "RECEIVE_DOWNLOAD_ATTACHMENT_CHUNK":
            case 80:
                message.type = 80;
                break;
            case "RECEIVE_DOWNLOAD_ATTACHMENT_DONE":
            case 81:
                message.type = 81;
                break;
            }
            if (object.connectionPing != null) {
                if (typeof object.connectionPing !== "object")
                    throw TypeError(".olvid.Colissimo.connectionPing: object expected");
                message.connectionPing = $root.olvid.ConnectionPing.fromObject(object.connectionPing);
            }
            if (object.settings != null) {
                if (typeof object.settings !== "object")
                    throw TypeError(".olvid.Colissimo.settings: object expected");
                message.settings = $root.olvid.Settings.fromObject(object.settings);
            }
            if (object.requestDiscussions != null) {
                if (typeof object.requestDiscussions !== "object")
                    throw TypeError(".olvid.Colissimo.requestDiscussions: object expected");
                message.requestDiscussions = $root.olvid.RequestDiscussions.fromObject(object.requestDiscussions);
            }
            if (object.requestDiscussionsResponse != null) {
                if (typeof object.requestDiscussionsResponse !== "object")
                    throw TypeError(".olvid.Colissimo.requestDiscussionsResponse: object expected");
                message.requestDiscussionsResponse = $root.olvid.RequestDiscussionsResponse.fromObject(object.requestDiscussionsResponse);
            }
            if (object.requestMessage != null) {
                if (typeof object.requestMessage !== "object")
                    throw TypeError(".olvid.Colissimo.requestMessage: object expected");
                message.requestMessage = $root.olvid.RequestMessage.fromObject(object.requestMessage);
            }
            if (object.requestMessageResponse != null) {
                if (typeof object.requestMessageResponse !== "object")
                    throw TypeError(".olvid.Colissimo.requestMessageResponse: object expected");
                message.requestMessageResponse = $root.olvid.RequestMessageResponse.fromObject(object.requestMessageResponse);
            }
            if (object.requestMarkDiscussionAsRead != null) {
                if (typeof object.requestMarkDiscussionAsRead !== "object")
                    throw TypeError(".olvid.Colissimo.requestMarkDiscussionAsRead: object expected");
                message.requestMarkDiscussionAsRead = $root.olvid.RequestMarkDiscussionAsRead.fromObject(object.requestMarkDiscussionAsRead);
            }
            if (object.requestUpdateMessage != null) {
                if (typeof object.requestUpdateMessage !== "object")
                    throw TypeError(".olvid.Colissimo.requestUpdateMessage: object expected");
                message.requestUpdateMessage = $root.olvid.RequestUpdateMessage.fromObject(object.requestUpdateMessage);
            }
            if (object.requestAddReactionToMessage != null) {
                if (typeof object.requestAddReactionToMessage !== "object")
                    throw TypeError(".olvid.Colissimo.requestAddReactionToMessage: object expected");
                message.requestAddReactionToMessage = $root.olvid.RequestAddReactionToMessage.fromObject(object.requestAddReactionToMessage);
            }
            if (object.notifDiscussionUpdated != null) {
                if (typeof object.notifDiscussionUpdated !== "object")
                    throw TypeError(".olvid.Colissimo.notifDiscussionUpdated: object expected");
                message.notifDiscussionUpdated = $root.olvid.NotifDiscussionUpdated.fromObject(object.notifDiscussionUpdated);
            }
            if (object.notifNewMessage != null) {
                if (typeof object.notifNewMessage !== "object")
                    throw TypeError(".olvid.Colissimo.notifNewMessage: object expected");
                message.notifNewMessage = $root.olvid.NotifNewMessage.fromObject(object.notifNewMessage);
            }
            if (object.notifNewDiscussion != null) {
                if (typeof object.notifNewDiscussion !== "object")
                    throw TypeError(".olvid.Colissimo.notifNewDiscussion: object expected");
                message.notifNewDiscussion = $root.olvid.NotifNewDiscussion.fromObject(object.notifNewDiscussion);
            }
            if (object.notifDeleteMessage != null) {
                if (typeof object.notifDeleteMessage !== "object")
                    throw TypeError(".olvid.Colissimo.notifDeleteMessage: object expected");
                message.notifDeleteMessage = $root.olvid.NotifDeleteMessage.fromObject(object.notifDeleteMessage);
            }
            if (object.notifNewDraftAttachment != null) {
                if (typeof object.notifNewDraftAttachment !== "object")
                    throw TypeError(".olvid.Colissimo.notifNewDraftAttachment: object expected");
                message.notifNewDraftAttachment = $root.olvid.NotifNewDraftAttachment.fromObject(object.notifNewDraftAttachment);
            }
            if (object.notifNewAttachment != null) {
                if (typeof object.notifNewAttachment !== "object")
                    throw TypeError(".olvid.Colissimo.notifNewAttachment: object expected");
                message.notifNewAttachment = $root.olvid.NotifNewAttachment.fromObject(object.notifNewAttachment);
            }
            if (object.notifDeleteDraftAttachment != null) {
                if (typeof object.notifDeleteDraftAttachment !== "object")
                    throw TypeError(".olvid.Colissimo.notifDeleteDraftAttachment: object expected");
                message.notifDeleteDraftAttachment = $root.olvid.NotifDeleteDraftAttachment.fromObject(object.notifDeleteDraftAttachment);
            }
            if (object.notifNoDraftForDiscussion != null) {
                if (typeof object.notifNoDraftForDiscussion !== "object")
                    throw TypeError(".olvid.Colissimo.notifNoDraftForDiscussion: object expected");
                message.notifNoDraftForDiscussion = $root.olvid.NotifNoDraftForDiscussion.fromObject(object.notifNoDraftForDiscussion);
            }
            if (object.notifFileAlreadyExists != null) {
                if (typeof object.notifFileAlreadyExists !== "object")
                    throw TypeError(".olvid.Colissimo.notifFileAlreadyExists: object expected");
                message.notifFileAlreadyExists = $root.olvid.NotifFileAlreadyExists.fromObject(object.notifFileAlreadyExists);
            }
            if (object.notifFileAlreadyAttached != null) {
                if (typeof object.notifFileAlreadyAttached !== "object")
                    throw TypeError(".olvid.Colissimo.notifFileAlreadyAttached: object expected");
                message.notifFileAlreadyAttached = $root.olvid.NotifFileAlreadyAttached.fromObject(object.notifFileAlreadyAttached);
            }
            if (object.notifUploadFile != null) {
                if (typeof object.notifUploadFile !== "object")
                    throw TypeError(".olvid.Colissimo.notifUploadFile: object expected");
                message.notifUploadFile = $root.olvid.NotifUploadFile.fromObject(object.notifUploadFile);
            }
            if (object.notifUploadResult != null) {
                if (typeof object.notifUploadResult !== "object")
                    throw TypeError(".olvid.Colissimo.notifUploadResult: object expected");
                message.notifUploadResult = $root.olvid.NotifUploadResult.fromObject(object.notifUploadResult);
            }
            if (object.notifDeleteAttachment != null) {
                if (typeof object.notifDeleteAttachment !== "object")
                    throw TypeError(".olvid.Colissimo.notifDeleteAttachment: object expected");
                message.notifDeleteAttachment = $root.olvid.NotifDeleteAttachment.fromObject(object.notifDeleteAttachment);
            }
            if (object.notifDeleteDiscussion != null) {
                if (typeof object.notifDeleteDiscussion !== "object")
                    throw TypeError(".olvid.Colissimo.notifDeleteDiscussion: object expected");
                message.notifDeleteDiscussion = $root.olvid.NotifDeleteDiscussion.fromObject(object.notifDeleteDiscussion);
            }
            if (object.notifUpdateMessage != null) {
                if (typeof object.notifUpdateMessage !== "object")
                    throw TypeError(".olvid.Colissimo.notifUpdateMessage: object expected");
                message.notifUpdateMessage = $root.olvid.NotifUpdateMessage.fromObject(object.notifUpdateMessage);
            }
            if (object.notifUpdateAttachment != null) {
                if (typeof object.notifUpdateAttachment !== "object")
                    throw TypeError(".olvid.Colissimo.notifUpdateAttachment: object expected");
                message.notifUpdateAttachment = $root.olvid.NotifUpdateAttachment.fromObject(object.notifUpdateAttachment);
            }
            if (object.notifUpdateDraftAttachment != null) {
                if (typeof object.notifUpdateDraftAttachment !== "object")
                    throw TypeError(".olvid.Colissimo.notifUpdateDraftAttachment: object expected");
                message.notifUpdateDraftAttachment = $root.olvid.NotifUpdateDraftAttachment.fromObject(object.notifUpdateDraftAttachment);
            }
            if (object.createMessage != null) {
                if (typeof object.createMessage !== "object")
                    throw TypeError(".olvid.Colissimo.createMessage: object expected");
                message.createMessage = $root.olvid.CreateMessage.fromObject(object.createMessage);
            }
            if (object.notifMessageSent != null) {
                if (typeof object.notifMessageSent !== "object")
                    throw TypeError(".olvid.Colissimo.notifMessageSent: object expected");
                message.notifMessageSent = $root.olvid.NotifMessageSent.fromObject(object.notifMessageSent);
            }
            if (object.requestThumbnail != null) {
                if (typeof object.requestThumbnail !== "object")
                    throw TypeError(".olvid.Colissimo.requestThumbnail: object expected");
                message.requestThumbnail = $root.olvid.RequestThumbnail.fromObject(object.requestThumbnail);
            }
            if (object.requestThumbnailResponse != null) {
                if (typeof object.requestThumbnailResponse !== "object")
                    throw TypeError(".olvid.Colissimo.requestThumbnailResponse: object expected");
                message.requestThumbnailResponse = $root.olvid.RequestThumbnailResponse.fromObject(object.requestThumbnailResponse);
            }
            if (object.sendAttachmentNotice != null) {
                if (typeof object.sendAttachmentNotice !== "object")
                    throw TypeError(".olvid.Colissimo.sendAttachmentNotice: object expected");
                message.sendAttachmentNotice = $root.olvid.SendAttachmentNotice.fromObject(object.sendAttachmentNotice);
            }
            if (object.sendAttachmentChunk != null) {
                if (typeof object.sendAttachmentChunk !== "object")
                    throw TypeError(".olvid.Colissimo.sendAttachmentChunk: object expected");
                message.sendAttachmentChunk = $root.olvid.SendAttachmentChunk.fromObject(object.sendAttachmentChunk);
            }
            if (object.sendAttachmentDone != null) {
                if (typeof object.sendAttachmentDone !== "object")
                    throw TypeError(".olvid.Colissimo.sendAttachmentDone: object expected");
                message.sendAttachmentDone = $root.olvid.SendAttachmentDone.fromObject(object.sendAttachmentDone);
            }
            if (object.requestSaveDraftMessage != null) {
                if (typeof object.requestSaveDraftMessage !== "object")
                    throw TypeError(".olvid.Colissimo.requestSaveDraftMessage: object expected");
                message.requestSaveDraftMessage = $root.olvid.RequestSaveDraftMessage.fromObject(object.requestSaveDraftMessage);
            }
            if (object.requestDeleteDraftAttachment != null) {
                if (typeof object.requestDeleteDraftAttachment !== "object")
                    throw TypeError(".olvid.Colissimo.requestDeleteDraftAttachment: object expected");
                message.requestDeleteDraftAttachment = $root.olvid.RequestDeleteDraftAttachment.fromObject(object.requestDeleteDraftAttachment);
            }
            if (object.requestDownloadAttachment != null) {
                if (typeof object.requestDownloadAttachment !== "object")
                    throw TypeError(".olvid.Colissimo.requestDownloadAttachment: object expected");
                message.requestDownloadAttachment = $root.olvid.RequestDownloadAttachment.fromObject(object.requestDownloadAttachment);
            }
            if (object.requestDeleteDraftMessage != null) {
                if (typeof object.requestDeleteDraftMessage !== "object")
                    throw TypeError(".olvid.Colissimo.requestDeleteDraftMessage: object expected");
                message.requestDeleteDraftMessage = $root.olvid.RequestDeleteDraftMessage.fromObject(object.requestDeleteDraftMessage);
            }
            if (object.requestStopDraftAttachmentUpload != null) {
                if (typeof object.requestStopDraftAttachmentUpload !== "object")
                    throw TypeError(".olvid.Colissimo.requestStopDraftAttachmentUpload: object expected");
                message.requestStopDraftAttachmentUpload = $root.olvid.RequestStopDraftAttachmentUpload.fromObject(object.requestStopDraftAttachmentUpload);
            }
            if (object.receiveDownloadAttachmentChunk != null) {
                if (typeof object.receiveDownloadAttachmentChunk !== "object")
                    throw TypeError(".olvid.Colissimo.receiveDownloadAttachmentChunk: object expected");
                message.receiveDownloadAttachmentChunk = $root.olvid.ReceiveDownloadAttachmentChunk.fromObject(object.receiveDownloadAttachmentChunk);
            }
            if (object.receiveDownloadAttachmentDone != null) {
                if (typeof object.receiveDownloadAttachmentDone !== "object")
                    throw TypeError(".olvid.Colissimo.receiveDownloadAttachmentDone: object expected");
                message.receiveDownloadAttachmentDone = $root.olvid.ReceiveDownloadAttachmentDone.fromObject(object.receiveDownloadAttachmentDone);
            }
            return message;
        };

        /**
         * Creates a plain object from a Colissimo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.Colissimo
         * @static
         * @param {olvid.Colissimo} message Colissimo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Colissimo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "COLISSIMO_TYPE_DEFAULT" : 0;
                object.connectionPing = null;
                object.settings = null;
                object.requestDiscussions = null;
                object.requestDiscussionsResponse = null;
                object.requestMessage = null;
                object.requestMessageResponse = null;
                object.requestMarkDiscussionAsRead = null;
                object.requestUpdateMessage = null;
                object.requestAddReactionToMessage = null;
                object.notifDiscussionUpdated = null;
                object.notifNewMessage = null;
                object.notifDeleteMessage = null;
                object.notifNewDraftAttachment = null;
                object.notifNewAttachment = null;
                object.notifDeleteDraftAttachment = null;
                object.notifNoDraftForDiscussion = null;
                object.notifFileAlreadyExists = null;
                object.notifFileAlreadyAttached = null;
                object.notifUploadFile = null;
                object.notifUploadResult = null;
                object.notifDeleteAttachment = null;
                object.notifDeleteDiscussion = null;
                object.notifUpdateMessage = null;
                object.notifUpdateAttachment = null;
                object.notifUpdateDraftAttachment = null;
                object.createMessage = null;
                object.notifMessageSent = null;
                object.notifNewDiscussion = null;
                object.requestThumbnail = null;
                object.requestThumbnailResponse = null;
                object.sendAttachmentNotice = null;
                object.sendAttachmentChunk = null;
                object.sendAttachmentDone = null;
                object.requestSaveDraftMessage = null;
                object.requestDeleteDraftAttachment = null;
                object.requestDownloadAttachment = null;
                object.requestDeleteDraftMessage = null;
                object.requestStopDraftAttachmentUpload = null;
                object.receiveDownloadAttachmentChunk = null;
                object.receiveDownloadAttachmentDone = null;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.olvid.ColissimoType[message.type] === undefined ? message.type : $root.olvid.ColissimoType[message.type] : message.type;
            if (message.connectionPing != null && message.hasOwnProperty("connectionPing"))
                object.connectionPing = $root.olvid.ConnectionPing.toObject(message.connectionPing, options);
            if (message.settings != null && message.hasOwnProperty("settings"))
                object.settings = $root.olvid.Settings.toObject(message.settings, options);
            if (message.requestDiscussions != null && message.hasOwnProperty("requestDiscussions"))
                object.requestDiscussions = $root.olvid.RequestDiscussions.toObject(message.requestDiscussions, options);
            if (message.requestDiscussionsResponse != null && message.hasOwnProperty("requestDiscussionsResponse"))
                object.requestDiscussionsResponse = $root.olvid.RequestDiscussionsResponse.toObject(message.requestDiscussionsResponse, options);
            if (message.requestMessage != null && message.hasOwnProperty("requestMessage"))
                object.requestMessage = $root.olvid.RequestMessage.toObject(message.requestMessage, options);
            if (message.requestMessageResponse != null && message.hasOwnProperty("requestMessageResponse"))
                object.requestMessageResponse = $root.olvid.RequestMessageResponse.toObject(message.requestMessageResponse, options);
            if (message.requestMarkDiscussionAsRead != null && message.hasOwnProperty("requestMarkDiscussionAsRead"))
                object.requestMarkDiscussionAsRead = $root.olvid.RequestMarkDiscussionAsRead.toObject(message.requestMarkDiscussionAsRead, options);
            if (message.requestUpdateMessage != null && message.hasOwnProperty("requestUpdateMessage"))
                object.requestUpdateMessage = $root.olvid.RequestUpdateMessage.toObject(message.requestUpdateMessage, options);
            if (message.requestAddReactionToMessage != null && message.hasOwnProperty("requestAddReactionToMessage"))
                object.requestAddReactionToMessage = $root.olvid.RequestAddReactionToMessage.toObject(message.requestAddReactionToMessage, options);
            if (message.notifDiscussionUpdated != null && message.hasOwnProperty("notifDiscussionUpdated"))
                object.notifDiscussionUpdated = $root.olvid.NotifDiscussionUpdated.toObject(message.notifDiscussionUpdated, options);
            if (message.notifNewMessage != null && message.hasOwnProperty("notifNewMessage"))
                object.notifNewMessage = $root.olvid.NotifNewMessage.toObject(message.notifNewMessage, options);
            if (message.notifDeleteMessage != null && message.hasOwnProperty("notifDeleteMessage"))
                object.notifDeleteMessage = $root.olvid.NotifDeleteMessage.toObject(message.notifDeleteMessage, options);
            if (message.notifNewDraftAttachment != null && message.hasOwnProperty("notifNewDraftAttachment"))
                object.notifNewDraftAttachment = $root.olvid.NotifNewDraftAttachment.toObject(message.notifNewDraftAttachment, options);
            if (message.notifNewAttachment != null && message.hasOwnProperty("notifNewAttachment"))
                object.notifNewAttachment = $root.olvid.NotifNewAttachment.toObject(message.notifNewAttachment, options);
            if (message.notifDeleteDraftAttachment != null && message.hasOwnProperty("notifDeleteDraftAttachment"))
                object.notifDeleteDraftAttachment = $root.olvid.NotifDeleteDraftAttachment.toObject(message.notifDeleteDraftAttachment, options);
            if (message.notifNoDraftForDiscussion != null && message.hasOwnProperty("notifNoDraftForDiscussion"))
                object.notifNoDraftForDiscussion = $root.olvid.NotifNoDraftForDiscussion.toObject(message.notifNoDraftForDiscussion, options);
            if (message.notifFileAlreadyExists != null && message.hasOwnProperty("notifFileAlreadyExists"))
                object.notifFileAlreadyExists = $root.olvid.NotifFileAlreadyExists.toObject(message.notifFileAlreadyExists, options);
            if (message.notifFileAlreadyAttached != null && message.hasOwnProperty("notifFileAlreadyAttached"))
                object.notifFileAlreadyAttached = $root.olvid.NotifFileAlreadyAttached.toObject(message.notifFileAlreadyAttached, options);
            if (message.notifUploadFile != null && message.hasOwnProperty("notifUploadFile"))
                object.notifUploadFile = $root.olvid.NotifUploadFile.toObject(message.notifUploadFile, options);
            if (message.notifUploadResult != null && message.hasOwnProperty("notifUploadResult"))
                object.notifUploadResult = $root.olvid.NotifUploadResult.toObject(message.notifUploadResult, options);
            if (message.notifDeleteAttachment != null && message.hasOwnProperty("notifDeleteAttachment"))
                object.notifDeleteAttachment = $root.olvid.NotifDeleteAttachment.toObject(message.notifDeleteAttachment, options);
            if (message.notifDeleteDiscussion != null && message.hasOwnProperty("notifDeleteDiscussion"))
                object.notifDeleteDiscussion = $root.olvid.NotifDeleteDiscussion.toObject(message.notifDeleteDiscussion, options);
            if (message.notifUpdateMessage != null && message.hasOwnProperty("notifUpdateMessage"))
                object.notifUpdateMessage = $root.olvid.NotifUpdateMessage.toObject(message.notifUpdateMessage, options);
            if (message.notifUpdateAttachment != null && message.hasOwnProperty("notifUpdateAttachment"))
                object.notifUpdateAttachment = $root.olvid.NotifUpdateAttachment.toObject(message.notifUpdateAttachment, options);
            if (message.notifUpdateDraftAttachment != null && message.hasOwnProperty("notifUpdateDraftAttachment"))
                object.notifUpdateDraftAttachment = $root.olvid.NotifUpdateDraftAttachment.toObject(message.notifUpdateDraftAttachment, options);
            if (message.createMessage != null && message.hasOwnProperty("createMessage"))
                object.createMessage = $root.olvid.CreateMessage.toObject(message.createMessage, options);
            if (message.notifMessageSent != null && message.hasOwnProperty("notifMessageSent"))
                object.notifMessageSent = $root.olvid.NotifMessageSent.toObject(message.notifMessageSent, options);
            if (message.notifNewDiscussion != null && message.hasOwnProperty("notifNewDiscussion"))
                object.notifNewDiscussion = $root.olvid.NotifNewDiscussion.toObject(message.notifNewDiscussion, options);
            if (message.requestThumbnail != null && message.hasOwnProperty("requestThumbnail"))
                object.requestThumbnail = $root.olvid.RequestThumbnail.toObject(message.requestThumbnail, options);
            if (message.requestThumbnailResponse != null && message.hasOwnProperty("requestThumbnailResponse"))
                object.requestThumbnailResponse = $root.olvid.RequestThumbnailResponse.toObject(message.requestThumbnailResponse, options);
            if (message.sendAttachmentNotice != null && message.hasOwnProperty("sendAttachmentNotice"))
                object.sendAttachmentNotice = $root.olvid.SendAttachmentNotice.toObject(message.sendAttachmentNotice, options);
            if (message.sendAttachmentChunk != null && message.hasOwnProperty("sendAttachmentChunk"))
                object.sendAttachmentChunk = $root.olvid.SendAttachmentChunk.toObject(message.sendAttachmentChunk, options);
            if (message.sendAttachmentDone != null && message.hasOwnProperty("sendAttachmentDone"))
                object.sendAttachmentDone = $root.olvid.SendAttachmentDone.toObject(message.sendAttachmentDone, options);
            if (message.requestSaveDraftMessage != null && message.hasOwnProperty("requestSaveDraftMessage"))
                object.requestSaveDraftMessage = $root.olvid.RequestSaveDraftMessage.toObject(message.requestSaveDraftMessage, options);
            if (message.requestDeleteDraftAttachment != null && message.hasOwnProperty("requestDeleteDraftAttachment"))
                object.requestDeleteDraftAttachment = $root.olvid.RequestDeleteDraftAttachment.toObject(message.requestDeleteDraftAttachment, options);
            if (message.requestDownloadAttachment != null && message.hasOwnProperty("requestDownloadAttachment"))
                object.requestDownloadAttachment = $root.olvid.RequestDownloadAttachment.toObject(message.requestDownloadAttachment, options);
            if (message.requestDeleteDraftMessage != null && message.hasOwnProperty("requestDeleteDraftMessage"))
                object.requestDeleteDraftMessage = $root.olvid.RequestDeleteDraftMessage.toObject(message.requestDeleteDraftMessage, options);
            if (message.requestStopDraftAttachmentUpload != null && message.hasOwnProperty("requestStopDraftAttachmentUpload"))
                object.requestStopDraftAttachmentUpload = $root.olvid.RequestStopDraftAttachmentUpload.toObject(message.requestStopDraftAttachmentUpload, options);
            if (message.receiveDownloadAttachmentChunk != null && message.hasOwnProperty("receiveDownloadAttachmentChunk"))
                object.receiveDownloadAttachmentChunk = $root.olvid.ReceiveDownloadAttachmentChunk.toObject(message.receiveDownloadAttachmentChunk, options);
            if (message.receiveDownloadAttachmentDone != null && message.hasOwnProperty("receiveDownloadAttachmentDone"))
                object.receiveDownloadAttachmentDone = $root.olvid.ReceiveDownloadAttachmentDone.toObject(message.receiveDownloadAttachmentDone, options);
            return object;
        };

        /**
         * Converts this Colissimo to JSON.
         * @function toJSON
         * @memberof olvid.Colissimo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Colissimo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Colissimo
         * @function getTypeUrl
         * @memberof olvid.Colissimo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Colissimo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.Colissimo";
        };

        return Colissimo;
    })();

    olvid.ConnectionPing = (function() {

        /**
         * Properties of a ConnectionPing.
         * @memberof olvid
         * @interface IConnectionPing
         * @property {boolean|null} [ping] ConnectionPing ping
         * @property {boolean|null} [pong] ConnectionPing pong
         */

        /**
         * Constructs a new ConnectionPing.
         * @memberof olvid
         * @classdesc Represents a ConnectionPing.
         * @implements IConnectionPing
         * @constructor
         * @param {olvid.IConnectionPing=} [properties] Properties to set
         */
        function ConnectionPing(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConnectionPing ping.
         * @member {boolean} ping
         * @memberof olvid.ConnectionPing
         * @instance
         */
        ConnectionPing.prototype.ping = false;

        /**
         * ConnectionPing pong.
         * @member {boolean} pong
         * @memberof olvid.ConnectionPing
         * @instance
         */
        ConnectionPing.prototype.pong = false;

        /**
         * Creates a new ConnectionPing instance using the specified properties.
         * @function create
         * @memberof olvid.ConnectionPing
         * @static
         * @param {olvid.IConnectionPing=} [properties] Properties to set
         * @returns {olvid.ConnectionPing} ConnectionPing instance
         */
        ConnectionPing.create = function create(properties) {
            return new ConnectionPing(properties);
        };

        /**
         * Encodes the specified ConnectionPing message. Does not implicitly {@link olvid.ConnectionPing.verify|verify} messages.
         * @function encode
         * @memberof olvid.ConnectionPing
         * @static
         * @param {olvid.IConnectionPing} message ConnectionPing message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectionPing.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ping != null && Object.hasOwnProperty.call(message, "ping"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.ping);
            if (message.pong != null && Object.hasOwnProperty.call(message, "pong"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.pong);
            return writer;
        };

        /**
         * Encodes the specified ConnectionPing message, length delimited. Does not implicitly {@link olvid.ConnectionPing.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.ConnectionPing
         * @static
         * @param {olvid.IConnectionPing} message ConnectionPing message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectionPing.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConnectionPing message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.ConnectionPing
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.ConnectionPing} ConnectionPing
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectionPing.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.ConnectionPing();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ping = reader.bool();
                        break;
                    }
                case 2: {
                        message.pong = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConnectionPing message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.ConnectionPing
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.ConnectionPing} ConnectionPing
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectionPing.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConnectionPing message.
         * @function verify
         * @memberof olvid.ConnectionPing
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConnectionPing.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ping != null && message.hasOwnProperty("ping"))
                if (typeof message.ping !== "boolean")
                    return "ping: boolean expected";
            if (message.pong != null && message.hasOwnProperty("pong"))
                if (typeof message.pong !== "boolean")
                    return "pong: boolean expected";
            return null;
        };

        /**
         * Creates a ConnectionPing message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.ConnectionPing
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.ConnectionPing} ConnectionPing
         */
        ConnectionPing.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.ConnectionPing)
                return object;
            var message = new $root.olvid.ConnectionPing();
            if (object.ping != null)
                message.ping = Boolean(object.ping);
            if (object.pong != null)
                message.pong = Boolean(object.pong);
            return message;
        };

        /**
         * Creates a plain object from a ConnectionPing message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.ConnectionPing
         * @static
         * @param {olvid.ConnectionPing} message ConnectionPing
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConnectionPing.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.ping = false;
                object.pong = false;
            }
            if (message.ping != null && message.hasOwnProperty("ping"))
                object.ping = message.ping;
            if (message.pong != null && message.hasOwnProperty("pong"))
                object.pong = message.pong;
            return object;
        };

        /**
         * Converts this ConnectionPing to JSON.
         * @function toJSON
         * @memberof olvid.ConnectionPing
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConnectionPing.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConnectionPing
         * @function getTypeUrl
         * @memberof olvid.ConnectionPing
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConnectionPing.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.ConnectionPing";
        };

        return ConnectionPing;
    })();

    olvid.RequestDiscussions = (function() {

        /**
         * Properties of a RequestDiscussions.
         * @memberof olvid
         * @interface IRequestDiscussions
         */

        /**
         * Constructs a new RequestDiscussions.
         * @memberof olvid
         * @classdesc Represents a RequestDiscussions.
         * @implements IRequestDiscussions
         * @constructor
         * @param {olvid.IRequestDiscussions=} [properties] Properties to set
         */
        function RequestDiscussions(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RequestDiscussions instance using the specified properties.
         * @function create
         * @memberof olvid.RequestDiscussions
         * @static
         * @param {olvid.IRequestDiscussions=} [properties] Properties to set
         * @returns {olvid.RequestDiscussions} RequestDiscussions instance
         */
        RequestDiscussions.create = function create(properties) {
            return new RequestDiscussions(properties);
        };

        /**
         * Encodes the specified RequestDiscussions message. Does not implicitly {@link olvid.RequestDiscussions.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestDiscussions
         * @static
         * @param {olvid.IRequestDiscussions} message RequestDiscussions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestDiscussions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RequestDiscussions message, length delimited. Does not implicitly {@link olvid.RequestDiscussions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestDiscussions
         * @static
         * @param {olvid.IRequestDiscussions} message RequestDiscussions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestDiscussions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestDiscussions message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestDiscussions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestDiscussions} RequestDiscussions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestDiscussions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestDiscussions();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestDiscussions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestDiscussions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestDiscussions} RequestDiscussions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestDiscussions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestDiscussions message.
         * @function verify
         * @memberof olvid.RequestDiscussions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestDiscussions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RequestDiscussions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestDiscussions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestDiscussions} RequestDiscussions
         */
        RequestDiscussions.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestDiscussions)
                return object;
            return new $root.olvid.RequestDiscussions();
        };

        /**
         * Creates a plain object from a RequestDiscussions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestDiscussions
         * @static
         * @param {olvid.RequestDiscussions} message RequestDiscussions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestDiscussions.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RequestDiscussions to JSON.
         * @function toJSON
         * @memberof olvid.RequestDiscussions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestDiscussions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestDiscussions
         * @function getTypeUrl
         * @memberof olvid.RequestDiscussions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestDiscussions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestDiscussions";
        };

        return RequestDiscussions;
    })();

    olvid.RequestDiscussionsResponse = (function() {

        /**
         * Properties of a RequestDiscussionsResponse.
         * @memberof olvid
         * @interface IRequestDiscussionsResponse
         * @property {Array.<olvid.IDiscussion>|null} [discussions] RequestDiscussionsResponse discussions
         */

        /**
         * Constructs a new RequestDiscussionsResponse.
         * @memberof olvid
         * @classdesc Represents a RequestDiscussionsResponse.
         * @implements IRequestDiscussionsResponse
         * @constructor
         * @param {olvid.IRequestDiscussionsResponse=} [properties] Properties to set
         */
        function RequestDiscussionsResponse(properties) {
            this.discussions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestDiscussionsResponse discussions.
         * @member {Array.<olvid.IDiscussion>} discussions
         * @memberof olvid.RequestDiscussionsResponse
         * @instance
         */
        RequestDiscussionsResponse.prototype.discussions = $util.emptyArray;

        /**
         * Creates a new RequestDiscussionsResponse instance using the specified properties.
         * @function create
         * @memberof olvid.RequestDiscussionsResponse
         * @static
         * @param {olvid.IRequestDiscussionsResponse=} [properties] Properties to set
         * @returns {olvid.RequestDiscussionsResponse} RequestDiscussionsResponse instance
         */
        RequestDiscussionsResponse.create = function create(properties) {
            return new RequestDiscussionsResponse(properties);
        };

        /**
         * Encodes the specified RequestDiscussionsResponse message. Does not implicitly {@link olvid.RequestDiscussionsResponse.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestDiscussionsResponse
         * @static
         * @param {olvid.IRequestDiscussionsResponse} message RequestDiscussionsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestDiscussionsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussions != null && message.discussions.length)
                for (var i = 0; i < message.discussions.length; ++i)
                    $root.olvid.Discussion.encode(message.discussions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RequestDiscussionsResponse message, length delimited. Does not implicitly {@link olvid.RequestDiscussionsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestDiscussionsResponse
         * @static
         * @param {olvid.IRequestDiscussionsResponse} message RequestDiscussionsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestDiscussionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestDiscussionsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestDiscussionsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestDiscussionsResponse} RequestDiscussionsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestDiscussionsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestDiscussionsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.discussions && message.discussions.length))
                            message.discussions = [];
                        message.discussions.push($root.olvid.Discussion.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestDiscussionsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestDiscussionsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestDiscussionsResponse} RequestDiscussionsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestDiscussionsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestDiscussionsResponse message.
         * @function verify
         * @memberof olvid.RequestDiscussionsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestDiscussionsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussions != null && message.hasOwnProperty("discussions")) {
                if (!Array.isArray(message.discussions))
                    return "discussions: array expected";
                for (var i = 0; i < message.discussions.length; ++i) {
                    var error = $root.olvid.Discussion.verify(message.discussions[i]);
                    if (error)
                        return "discussions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RequestDiscussionsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestDiscussionsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestDiscussionsResponse} RequestDiscussionsResponse
         */
        RequestDiscussionsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestDiscussionsResponse)
                return object;
            var message = new $root.olvid.RequestDiscussionsResponse();
            if (object.discussions) {
                if (!Array.isArray(object.discussions))
                    throw TypeError(".olvid.RequestDiscussionsResponse.discussions: array expected");
                message.discussions = [];
                for (var i = 0; i < object.discussions.length; ++i) {
                    if (typeof object.discussions[i] !== "object")
                        throw TypeError(".olvid.RequestDiscussionsResponse.discussions: object expected");
                    message.discussions[i] = $root.olvid.Discussion.fromObject(object.discussions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RequestDiscussionsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestDiscussionsResponse
         * @static
         * @param {olvid.RequestDiscussionsResponse} message RequestDiscussionsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestDiscussionsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.discussions = [];
            if (message.discussions && message.discussions.length) {
                object.discussions = [];
                for (var j = 0; j < message.discussions.length; ++j)
                    object.discussions[j] = $root.olvid.Discussion.toObject(message.discussions[j], options);
            }
            return object;
        };

        /**
         * Converts this RequestDiscussionsResponse to JSON.
         * @function toJSON
         * @memberof olvid.RequestDiscussionsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestDiscussionsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestDiscussionsResponse
         * @function getTypeUrl
         * @memberof olvid.RequestDiscussionsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestDiscussionsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestDiscussionsResponse";
        };

        return RequestDiscussionsResponse;
    })();

    olvid.RequestThumbnail = (function() {

        /**
         * Properties of a RequestThumbnail.
         * @memberof olvid
         * @interface IRequestThumbnail
         * @property {string|null} [relativeURL] RequestThumbnail relativeURL
         */

        /**
         * Constructs a new RequestThumbnail.
         * @memberof olvid
         * @classdesc Represents a RequestThumbnail.
         * @implements IRequestThumbnail
         * @constructor
         * @param {olvid.IRequestThumbnail=} [properties] Properties to set
         */
        function RequestThumbnail(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestThumbnail relativeURL.
         * @member {string} relativeURL
         * @memberof olvid.RequestThumbnail
         * @instance
         */
        RequestThumbnail.prototype.relativeURL = "";

        /**
         * Creates a new RequestThumbnail instance using the specified properties.
         * @function create
         * @memberof olvid.RequestThumbnail
         * @static
         * @param {olvid.IRequestThumbnail=} [properties] Properties to set
         * @returns {olvid.RequestThumbnail} RequestThumbnail instance
         */
        RequestThumbnail.create = function create(properties) {
            return new RequestThumbnail(properties);
        };

        /**
         * Encodes the specified RequestThumbnail message. Does not implicitly {@link olvid.RequestThumbnail.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestThumbnail
         * @static
         * @param {olvid.IRequestThumbnail} message RequestThumbnail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestThumbnail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.relativeURL != null && Object.hasOwnProperty.call(message, "relativeURL"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.relativeURL);
            return writer;
        };

        /**
         * Encodes the specified RequestThumbnail message, length delimited. Does not implicitly {@link olvid.RequestThumbnail.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestThumbnail
         * @static
         * @param {olvid.IRequestThumbnail} message RequestThumbnail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestThumbnail.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestThumbnail message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestThumbnail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestThumbnail} RequestThumbnail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestThumbnail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestThumbnail();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.relativeURL = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestThumbnail message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestThumbnail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestThumbnail} RequestThumbnail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestThumbnail.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestThumbnail message.
         * @function verify
         * @memberof olvid.RequestThumbnail
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestThumbnail.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.relativeURL != null && message.hasOwnProperty("relativeURL"))
                if (!$util.isString(message.relativeURL))
                    return "relativeURL: string expected";
            return null;
        };

        /**
         * Creates a RequestThumbnail message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestThumbnail
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestThumbnail} RequestThumbnail
         */
        RequestThumbnail.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestThumbnail)
                return object;
            var message = new $root.olvid.RequestThumbnail();
            if (object.relativeURL != null)
                message.relativeURL = String(object.relativeURL);
            return message;
        };

        /**
         * Creates a plain object from a RequestThumbnail message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestThumbnail
         * @static
         * @param {olvid.RequestThumbnail} message RequestThumbnail
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestThumbnail.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.relativeURL = "";
            if (message.relativeURL != null && message.hasOwnProperty("relativeURL"))
                object.relativeURL = message.relativeURL;
            return object;
        };

        /**
         * Converts this RequestThumbnail to JSON.
         * @function toJSON
         * @memberof olvid.RequestThumbnail
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestThumbnail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestThumbnail
         * @function getTypeUrl
         * @memberof olvid.RequestThumbnail
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestThumbnail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestThumbnail";
        };

        return RequestThumbnail;
    })();

    olvid.RequestThumbnailResponse = (function() {

        /**
         * Properties of a RequestThumbnailResponse.
         * @memberof olvid
         * @interface IRequestThumbnailResponse
         * @property {string|null} [relativeURL] RequestThumbnailResponse relativeURL
         * @property {Uint8Array|null} [thumbnail] RequestThumbnailResponse thumbnail
         */

        /**
         * Constructs a new RequestThumbnailResponse.
         * @memberof olvid
         * @classdesc Represents a RequestThumbnailResponse.
         * @implements IRequestThumbnailResponse
         * @constructor
         * @param {olvid.IRequestThumbnailResponse=} [properties] Properties to set
         */
        function RequestThumbnailResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestThumbnailResponse relativeURL.
         * @member {string} relativeURL
         * @memberof olvid.RequestThumbnailResponse
         * @instance
         */
        RequestThumbnailResponse.prototype.relativeURL = "";

        /**
         * RequestThumbnailResponse thumbnail.
         * @member {Uint8Array} thumbnail
         * @memberof olvid.RequestThumbnailResponse
         * @instance
         */
        RequestThumbnailResponse.prototype.thumbnail = $util.newBuffer([]);

        /**
         * Creates a new RequestThumbnailResponse instance using the specified properties.
         * @function create
         * @memberof olvid.RequestThumbnailResponse
         * @static
         * @param {olvid.IRequestThumbnailResponse=} [properties] Properties to set
         * @returns {olvid.RequestThumbnailResponse} RequestThumbnailResponse instance
         */
        RequestThumbnailResponse.create = function create(properties) {
            return new RequestThumbnailResponse(properties);
        };

        /**
         * Encodes the specified RequestThumbnailResponse message. Does not implicitly {@link olvid.RequestThumbnailResponse.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestThumbnailResponse
         * @static
         * @param {olvid.IRequestThumbnailResponse} message RequestThumbnailResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestThumbnailResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.relativeURL != null && Object.hasOwnProperty.call(message, "relativeURL"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.relativeURL);
            if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.thumbnail);
            return writer;
        };

        /**
         * Encodes the specified RequestThumbnailResponse message, length delimited. Does not implicitly {@link olvid.RequestThumbnailResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestThumbnailResponse
         * @static
         * @param {olvid.IRequestThumbnailResponse} message RequestThumbnailResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestThumbnailResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestThumbnailResponse message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestThumbnailResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestThumbnailResponse} RequestThumbnailResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestThumbnailResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestThumbnailResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.relativeURL = reader.string();
                        break;
                    }
                case 2: {
                        message.thumbnail = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestThumbnailResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestThumbnailResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestThumbnailResponse} RequestThumbnailResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestThumbnailResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestThumbnailResponse message.
         * @function verify
         * @memberof olvid.RequestThumbnailResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestThumbnailResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.relativeURL != null && message.hasOwnProperty("relativeURL"))
                if (!$util.isString(message.relativeURL))
                    return "relativeURL: string expected";
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                if (!(message.thumbnail && typeof message.thumbnail.length === "number" || $util.isString(message.thumbnail)))
                    return "thumbnail: buffer expected";
            return null;
        };

        /**
         * Creates a RequestThumbnailResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestThumbnailResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestThumbnailResponse} RequestThumbnailResponse
         */
        RequestThumbnailResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestThumbnailResponse)
                return object;
            var message = new $root.olvid.RequestThumbnailResponse();
            if (object.relativeURL != null)
                message.relativeURL = String(object.relativeURL);
            if (object.thumbnail != null)
                if (typeof object.thumbnail === "string")
                    $util.base64.decode(object.thumbnail, message.thumbnail = $util.newBuffer($util.base64.length(object.thumbnail)), 0);
                else if (object.thumbnail.length >= 0)
                    message.thumbnail = object.thumbnail;
            return message;
        };

        /**
         * Creates a plain object from a RequestThumbnailResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestThumbnailResponse
         * @static
         * @param {olvid.RequestThumbnailResponse} message RequestThumbnailResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestThumbnailResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.relativeURL = "";
                if (options.bytes === String)
                    object.thumbnail = "";
                else {
                    object.thumbnail = [];
                    if (options.bytes !== Array)
                        object.thumbnail = $util.newBuffer(object.thumbnail);
                }
            }
            if (message.relativeURL != null && message.hasOwnProperty("relativeURL"))
                object.relativeURL = message.relativeURL;
            if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
                object.thumbnail = options.bytes === String ? $util.base64.encode(message.thumbnail, 0, message.thumbnail.length) : options.bytes === Array ? Array.prototype.slice.call(message.thumbnail) : message.thumbnail;
            return object;
        };

        /**
         * Converts this RequestThumbnailResponse to JSON.
         * @function toJSON
         * @memberof olvid.RequestThumbnailResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestThumbnailResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestThumbnailResponse
         * @function getTypeUrl
         * @memberof olvid.RequestThumbnailResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestThumbnailResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestThumbnailResponse";
        };

        return RequestThumbnailResponse;
    })();

    olvid.RequestMessage = (function() {

        /**
         * Properties of a RequestMessage.
         * @memberof olvid
         * @interface IRequestMessage
         * @property {number|Long|null} [discussionId] RequestMessage discussionId
         * @property {number|null} [count] RequestMessage count
         */

        /**
         * Constructs a new RequestMessage.
         * @memberof olvid
         * @classdesc Represents a RequestMessage.
         * @implements IRequestMessage
         * @constructor
         * @param {olvid.IRequestMessage=} [properties] Properties to set
         */
        function RequestMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestMessage discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.RequestMessage
         * @instance
         */
        RequestMessage.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RequestMessage count.
         * @member {number} count
         * @memberof olvid.RequestMessage
         * @instance
         */
        RequestMessage.prototype.count = 0;

        /**
         * Creates a new RequestMessage instance using the specified properties.
         * @function create
         * @memberof olvid.RequestMessage
         * @static
         * @param {olvid.IRequestMessage=} [properties] Properties to set
         * @returns {olvid.RequestMessage} RequestMessage instance
         */
        RequestMessage.create = function create(properties) {
            return new RequestMessage(properties);
        };

        /**
         * Encodes the specified RequestMessage message. Does not implicitly {@link olvid.RequestMessage.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestMessage
         * @static
         * @param {olvid.IRequestMessage} message RequestMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.discussionId);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.count);
            return writer;
        };

        /**
         * Encodes the specified RequestMessage message, length delimited. Does not implicitly {@link olvid.RequestMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestMessage
         * @static
         * @param {olvid.IRequestMessage} message RequestMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestMessage message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestMessage} RequestMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.discussionId = reader.int64();
                        break;
                    }
                case 2: {
                        message.count = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestMessage} RequestMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestMessage message.
         * @function verify
         * @memberof olvid.RequestMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count))
                    return "count: integer expected";
            return null;
        };

        /**
         * Creates a RequestMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestMessage} RequestMessage
         */
        RequestMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestMessage)
                return object;
            var message = new $root.olvid.RequestMessage();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            if (object.count != null)
                message.count = object.count | 0;
            return message;
        };

        /**
         * Creates a plain object from a RequestMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestMessage
         * @static
         * @param {olvid.RequestMessage} message RequestMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
                object.count = 0;
            }
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = message.count;
            return object;
        };

        /**
         * Converts this RequestMessage to JSON.
         * @function toJSON
         * @memberof olvid.RequestMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestMessage
         * @function getTypeUrl
         * @memberof olvid.RequestMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestMessage";
        };

        return RequestMessage;
    })();

    olvid.RequestMessageResponse = (function() {

        /**
         * Properties of a RequestMessageResponse.
         * @memberof olvid
         * @interface IRequestMessageResponse
         * @property {number|Long|null} [discussionId] RequestMessageResponse discussionId
         * @property {Array.<olvid.IMessage>|null} [messages] RequestMessageResponse messages
         */

        /**
         * Constructs a new RequestMessageResponse.
         * @memberof olvid
         * @classdesc Represents a RequestMessageResponse.
         * @implements IRequestMessageResponse
         * @constructor
         * @param {olvid.IRequestMessageResponse=} [properties] Properties to set
         */
        function RequestMessageResponse(properties) {
            this.messages = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestMessageResponse discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.RequestMessageResponse
         * @instance
         */
        RequestMessageResponse.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RequestMessageResponse messages.
         * @member {Array.<olvid.IMessage>} messages
         * @memberof olvid.RequestMessageResponse
         * @instance
         */
        RequestMessageResponse.prototype.messages = $util.emptyArray;

        /**
         * Creates a new RequestMessageResponse instance using the specified properties.
         * @function create
         * @memberof olvid.RequestMessageResponse
         * @static
         * @param {olvid.IRequestMessageResponse=} [properties] Properties to set
         * @returns {olvid.RequestMessageResponse} RequestMessageResponse instance
         */
        RequestMessageResponse.create = function create(properties) {
            return new RequestMessageResponse(properties);
        };

        /**
         * Encodes the specified RequestMessageResponse message. Does not implicitly {@link olvid.RequestMessageResponse.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestMessageResponse
         * @static
         * @param {olvid.IRequestMessageResponse} message RequestMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestMessageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.discussionId);
            if (message.messages != null && message.messages.length)
                for (var i = 0; i < message.messages.length; ++i)
                    $root.olvid.Message.encode(message.messages[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RequestMessageResponse message, length delimited. Does not implicitly {@link olvid.RequestMessageResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestMessageResponse
         * @static
         * @param {olvid.IRequestMessageResponse} message RequestMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestMessageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestMessageResponse} RequestMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestMessageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestMessageResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.discussionId = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.messages && message.messages.length))
                            message.messages = [];
                        message.messages.push($root.olvid.Message.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestMessageResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestMessageResponse} RequestMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestMessageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestMessageResponse message.
         * @function verify
         * @memberof olvid.RequestMessageResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestMessageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            if (message.messages != null && message.hasOwnProperty("messages")) {
                if (!Array.isArray(message.messages))
                    return "messages: array expected";
                for (var i = 0; i < message.messages.length; ++i) {
                    var error = $root.olvid.Message.verify(message.messages[i]);
                    if (error)
                        return "messages." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RequestMessageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestMessageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestMessageResponse} RequestMessageResponse
         */
        RequestMessageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestMessageResponse)
                return object;
            var message = new $root.olvid.RequestMessageResponse();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            if (object.messages) {
                if (!Array.isArray(object.messages))
                    throw TypeError(".olvid.RequestMessageResponse.messages: array expected");
                message.messages = [];
                for (var i = 0; i < object.messages.length; ++i) {
                    if (typeof object.messages[i] !== "object")
                        throw TypeError(".olvid.RequestMessageResponse.messages: object expected");
                    message.messages[i] = $root.olvid.Message.fromObject(object.messages[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RequestMessageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestMessageResponse
         * @static
         * @param {olvid.RequestMessageResponse} message RequestMessageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestMessageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.messages = [];
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            if (message.messages && message.messages.length) {
                object.messages = [];
                for (var j = 0; j < message.messages.length; ++j)
                    object.messages[j] = $root.olvid.Message.toObject(message.messages[j], options);
            }
            return object;
        };

        /**
         * Converts this RequestMessageResponse to JSON.
         * @function toJSON
         * @memberof olvid.RequestMessageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestMessageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestMessageResponse
         * @function getTypeUrl
         * @memberof olvid.RequestMessageResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestMessageResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestMessageResponse";
        };

        return RequestMessageResponse;
    })();

    olvid.RequestUpdateMessage = (function() {

        /**
         * Properties of a RequestUpdateMessage.
         * @memberof olvid
         * @interface IRequestUpdateMessage
         * @property {number|Long|null} [messageId] RequestUpdateMessage messageId
         * @property {string|null} [newContent] RequestUpdateMessage newContent
         */

        /**
         * Constructs a new RequestUpdateMessage.
         * @memberof olvid
         * @classdesc Represents a RequestUpdateMessage.
         * @implements IRequestUpdateMessage
         * @constructor
         * @param {olvid.IRequestUpdateMessage=} [properties] Properties to set
         */
        function RequestUpdateMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestUpdateMessage messageId.
         * @member {number|Long} messageId
         * @memberof olvid.RequestUpdateMessage
         * @instance
         */
        RequestUpdateMessage.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RequestUpdateMessage newContent.
         * @member {string} newContent
         * @memberof olvid.RequestUpdateMessage
         * @instance
         */
        RequestUpdateMessage.prototype.newContent = "";

        /**
         * Creates a new RequestUpdateMessage instance using the specified properties.
         * @function create
         * @memberof olvid.RequestUpdateMessage
         * @static
         * @param {olvid.IRequestUpdateMessage=} [properties] Properties to set
         * @returns {olvid.RequestUpdateMessage} RequestUpdateMessage instance
         */
        RequestUpdateMessage.create = function create(properties) {
            return new RequestUpdateMessage(properties);
        };

        /**
         * Encodes the specified RequestUpdateMessage message. Does not implicitly {@link olvid.RequestUpdateMessage.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestUpdateMessage
         * @static
         * @param {olvid.IRequestUpdateMessage} message RequestUpdateMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestUpdateMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.messageId);
            if (message.newContent != null && Object.hasOwnProperty.call(message, "newContent"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.newContent);
            return writer;
        };

        /**
         * Encodes the specified RequestUpdateMessage message, length delimited. Does not implicitly {@link olvid.RequestUpdateMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestUpdateMessage
         * @static
         * @param {olvid.IRequestUpdateMessage} message RequestUpdateMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestUpdateMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestUpdateMessage message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestUpdateMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestUpdateMessage} RequestUpdateMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestUpdateMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestUpdateMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.messageId = reader.int64();
                        break;
                    }
                case 2: {
                        message.newContent = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestUpdateMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestUpdateMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestUpdateMessage} RequestUpdateMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestUpdateMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestUpdateMessage message.
         * @function verify
         * @memberof olvid.RequestUpdateMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestUpdateMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                    return "messageId: integer|Long expected";
            if (message.newContent != null && message.hasOwnProperty("newContent"))
                if (!$util.isString(message.newContent))
                    return "newContent: string expected";
            return null;
        };

        /**
         * Creates a RequestUpdateMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestUpdateMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestUpdateMessage} RequestUpdateMessage
         */
        RequestUpdateMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestUpdateMessage)
                return object;
            var message = new $root.olvid.RequestUpdateMessage();
            if (object.messageId != null)
                if ($util.Long)
                    (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = false;
                else if (typeof object.messageId === "string")
                    message.messageId = parseInt(object.messageId, 10);
                else if (typeof object.messageId === "number")
                    message.messageId = object.messageId;
                else if (typeof object.messageId === "object")
                    message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber();
            if (object.newContent != null)
                message.newContent = String(object.newContent);
            return message;
        };

        /**
         * Creates a plain object from a RequestUpdateMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestUpdateMessage
         * @static
         * @param {olvid.RequestUpdateMessage} message RequestUpdateMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestUpdateMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.messageId = options.longs === String ? "0" : 0;
                object.newContent = "";
            }
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (typeof message.messageId === "number")
                    object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
                else
                    object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber() : message.messageId;
            if (message.newContent != null && message.hasOwnProperty("newContent"))
                object.newContent = message.newContent;
            return object;
        };

        /**
         * Converts this RequestUpdateMessage to JSON.
         * @function toJSON
         * @memberof olvid.RequestUpdateMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestUpdateMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestUpdateMessage
         * @function getTypeUrl
         * @memberof olvid.RequestUpdateMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestUpdateMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestUpdateMessage";
        };

        return RequestUpdateMessage;
    })();

    olvid.RequestMarkDiscussionAsRead = (function() {

        /**
         * Properties of a RequestMarkDiscussionAsRead.
         * @memberof olvid
         * @interface IRequestMarkDiscussionAsRead
         * @property {number|Long|null} [discussionId] RequestMarkDiscussionAsRead discussionId
         */

        /**
         * Constructs a new RequestMarkDiscussionAsRead.
         * @memberof olvid
         * @classdesc Represents a RequestMarkDiscussionAsRead.
         * @implements IRequestMarkDiscussionAsRead
         * @constructor
         * @param {olvid.IRequestMarkDiscussionAsRead=} [properties] Properties to set
         */
        function RequestMarkDiscussionAsRead(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestMarkDiscussionAsRead discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @instance
         */
        RequestMarkDiscussionAsRead.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new RequestMarkDiscussionAsRead instance using the specified properties.
         * @function create
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @static
         * @param {olvid.IRequestMarkDiscussionAsRead=} [properties] Properties to set
         * @returns {olvid.RequestMarkDiscussionAsRead} RequestMarkDiscussionAsRead instance
         */
        RequestMarkDiscussionAsRead.create = function create(properties) {
            return new RequestMarkDiscussionAsRead(properties);
        };

        /**
         * Encodes the specified RequestMarkDiscussionAsRead message. Does not implicitly {@link olvid.RequestMarkDiscussionAsRead.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @static
         * @param {olvid.IRequestMarkDiscussionAsRead} message RequestMarkDiscussionAsRead message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestMarkDiscussionAsRead.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.discussionId);
            return writer;
        };

        /**
         * Encodes the specified RequestMarkDiscussionAsRead message, length delimited. Does not implicitly {@link olvid.RequestMarkDiscussionAsRead.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @static
         * @param {olvid.IRequestMarkDiscussionAsRead} message RequestMarkDiscussionAsRead message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestMarkDiscussionAsRead.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestMarkDiscussionAsRead message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestMarkDiscussionAsRead} RequestMarkDiscussionAsRead
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestMarkDiscussionAsRead.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestMarkDiscussionAsRead();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.discussionId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestMarkDiscussionAsRead message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestMarkDiscussionAsRead} RequestMarkDiscussionAsRead
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestMarkDiscussionAsRead.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestMarkDiscussionAsRead message.
         * @function verify
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestMarkDiscussionAsRead.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            return null;
        };

        /**
         * Creates a RequestMarkDiscussionAsRead message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestMarkDiscussionAsRead} RequestMarkDiscussionAsRead
         */
        RequestMarkDiscussionAsRead.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestMarkDiscussionAsRead)
                return object;
            var message = new $root.olvid.RequestMarkDiscussionAsRead();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RequestMarkDiscussionAsRead message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @static
         * @param {olvid.RequestMarkDiscussionAsRead} message RequestMarkDiscussionAsRead
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestMarkDiscussionAsRead.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            return object;
        };

        /**
         * Converts this RequestMarkDiscussionAsRead to JSON.
         * @function toJSON
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestMarkDiscussionAsRead.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestMarkDiscussionAsRead
         * @function getTypeUrl
         * @memberof olvid.RequestMarkDiscussionAsRead
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestMarkDiscussionAsRead.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestMarkDiscussionAsRead";
        };

        return RequestMarkDiscussionAsRead;
    })();

    olvid.RequestDownloadAttachment = (function() {

        /**
         * Properties of a RequestDownloadAttachment.
         * @memberof olvid
         * @interface IRequestDownloadAttachment
         * @property {number|Long|null} [fyleId] RequestDownloadAttachment fyleId
         * @property {number|Long|null} [size] RequestDownloadAttachment size
         */

        /**
         * Constructs a new RequestDownloadAttachment.
         * @memberof olvid
         * @classdesc Represents a RequestDownloadAttachment.
         * @implements IRequestDownloadAttachment
         * @constructor
         * @param {olvid.IRequestDownloadAttachment=} [properties] Properties to set
         */
        function RequestDownloadAttachment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestDownloadAttachment fyleId.
         * @member {number|Long} fyleId
         * @memberof olvid.RequestDownloadAttachment
         * @instance
         */
        RequestDownloadAttachment.prototype.fyleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RequestDownloadAttachment size.
         * @member {number|Long} size
         * @memberof olvid.RequestDownloadAttachment
         * @instance
         */
        RequestDownloadAttachment.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new RequestDownloadAttachment instance using the specified properties.
         * @function create
         * @memberof olvid.RequestDownloadAttachment
         * @static
         * @param {olvid.IRequestDownloadAttachment=} [properties] Properties to set
         * @returns {olvid.RequestDownloadAttachment} RequestDownloadAttachment instance
         */
        RequestDownloadAttachment.create = function create(properties) {
            return new RequestDownloadAttachment(properties);
        };

        /**
         * Encodes the specified RequestDownloadAttachment message. Does not implicitly {@link olvid.RequestDownloadAttachment.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestDownloadAttachment
         * @static
         * @param {olvid.IRequestDownloadAttachment} message RequestDownloadAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestDownloadAttachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fyleId != null && Object.hasOwnProperty.call(message, "fyleId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fyleId);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.size);
            return writer;
        };

        /**
         * Encodes the specified RequestDownloadAttachment message, length delimited. Does not implicitly {@link olvid.RequestDownloadAttachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestDownloadAttachment
         * @static
         * @param {olvid.IRequestDownloadAttachment} message RequestDownloadAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestDownloadAttachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestDownloadAttachment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestDownloadAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestDownloadAttachment} RequestDownloadAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestDownloadAttachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestDownloadAttachment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fyleId = reader.int64();
                        break;
                    }
                case 2: {
                        message.size = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestDownloadAttachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestDownloadAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestDownloadAttachment} RequestDownloadAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestDownloadAttachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestDownloadAttachment message.
         * @function verify
         * @memberof olvid.RequestDownloadAttachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestDownloadAttachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (!$util.isInteger(message.fyleId) && !(message.fyleId && $util.isInteger(message.fyleId.low) && $util.isInteger(message.fyleId.high)))
                    return "fyleId: integer|Long expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            return null;
        };

        /**
         * Creates a RequestDownloadAttachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestDownloadAttachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestDownloadAttachment} RequestDownloadAttachment
         */
        RequestDownloadAttachment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestDownloadAttachment)
                return object;
            var message = new $root.olvid.RequestDownloadAttachment();
            if (object.fyleId != null)
                if ($util.Long)
                    (message.fyleId = $util.Long.fromValue(object.fyleId)).unsigned = false;
                else if (typeof object.fyleId === "string")
                    message.fyleId = parseInt(object.fyleId, 10);
                else if (typeof object.fyleId === "number")
                    message.fyleId = object.fyleId;
                else if (typeof object.fyleId === "object")
                    message.fyleId = new $util.LongBits(object.fyleId.low >>> 0, object.fyleId.high >>> 0).toNumber();
            if (object.size != null)
                if ($util.Long)
                    (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                else if (typeof object.size === "string")
                    message.size = parseInt(object.size, 10);
                else if (typeof object.size === "number")
                    message.size = object.size;
                else if (typeof object.size === "object")
                    message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RequestDownloadAttachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestDownloadAttachment
         * @static
         * @param {olvid.RequestDownloadAttachment} message RequestDownloadAttachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestDownloadAttachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.fyleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fyleId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.size = options.longs === String ? "0" : 0;
            }
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (typeof message.fyleId === "number")
                    object.fyleId = options.longs === String ? String(message.fyleId) : message.fyleId;
                else
                    object.fyleId = options.longs === String ? $util.Long.prototype.toString.call(message.fyleId) : options.longs === Number ? new $util.LongBits(message.fyleId.low >>> 0, message.fyleId.high >>> 0).toNumber() : message.fyleId;
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size === "number")
                    object.size = options.longs === String ? String(message.size) : message.size;
                else
                    object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
            return object;
        };

        /**
         * Converts this RequestDownloadAttachment to JSON.
         * @function toJSON
         * @memberof olvid.RequestDownloadAttachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestDownloadAttachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestDownloadAttachment
         * @function getTypeUrl
         * @memberof olvid.RequestDownloadAttachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestDownloadAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestDownloadAttachment";
        };

        return RequestDownloadAttachment;
    })();

    olvid.ReceiveDownloadAttachmentChunk = (function() {

        /**
         * Properties of a ReceiveDownloadAttachmentChunk.
         * @memberof olvid
         * @interface IReceiveDownloadAttachmentChunk
         * @property {number|Long|null} [fyleId] ReceiveDownloadAttachmentChunk fyleId
         * @property {number|Long|null} [chunkNumber] ReceiveDownloadAttachmentChunk chunkNumber
         * @property {Uint8Array|null} [chunk] ReceiveDownloadAttachmentChunk chunk
         */

        /**
         * Constructs a new ReceiveDownloadAttachmentChunk.
         * @memberof olvid
         * @classdesc Represents a ReceiveDownloadAttachmentChunk.
         * @implements IReceiveDownloadAttachmentChunk
         * @constructor
         * @param {olvid.IReceiveDownloadAttachmentChunk=} [properties] Properties to set
         */
        function ReceiveDownloadAttachmentChunk(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReceiveDownloadAttachmentChunk fyleId.
         * @member {number|Long} fyleId
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @instance
         */
        ReceiveDownloadAttachmentChunk.prototype.fyleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ReceiveDownloadAttachmentChunk chunkNumber.
         * @member {number|Long} chunkNumber
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @instance
         */
        ReceiveDownloadAttachmentChunk.prototype.chunkNumber = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ReceiveDownloadAttachmentChunk chunk.
         * @member {Uint8Array} chunk
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @instance
         */
        ReceiveDownloadAttachmentChunk.prototype.chunk = $util.newBuffer([]);

        /**
         * Creates a new ReceiveDownloadAttachmentChunk instance using the specified properties.
         * @function create
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @static
         * @param {olvid.IReceiveDownloadAttachmentChunk=} [properties] Properties to set
         * @returns {olvid.ReceiveDownloadAttachmentChunk} ReceiveDownloadAttachmentChunk instance
         */
        ReceiveDownloadAttachmentChunk.create = function create(properties) {
            return new ReceiveDownloadAttachmentChunk(properties);
        };

        /**
         * Encodes the specified ReceiveDownloadAttachmentChunk message. Does not implicitly {@link olvid.ReceiveDownloadAttachmentChunk.verify|verify} messages.
         * @function encode
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @static
         * @param {olvid.IReceiveDownloadAttachmentChunk} message ReceiveDownloadAttachmentChunk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveDownloadAttachmentChunk.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fyleId != null && Object.hasOwnProperty.call(message, "fyleId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fyleId);
            if (message.chunkNumber != null && Object.hasOwnProperty.call(message, "chunkNumber"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.chunkNumber);
            if (message.chunk != null && Object.hasOwnProperty.call(message, "chunk"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.chunk);
            return writer;
        };

        /**
         * Encodes the specified ReceiveDownloadAttachmentChunk message, length delimited. Does not implicitly {@link olvid.ReceiveDownloadAttachmentChunk.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @static
         * @param {olvid.IReceiveDownloadAttachmentChunk} message ReceiveDownloadAttachmentChunk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveDownloadAttachmentChunk.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReceiveDownloadAttachmentChunk message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.ReceiveDownloadAttachmentChunk} ReceiveDownloadAttachmentChunk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveDownloadAttachmentChunk.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.ReceiveDownloadAttachmentChunk();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fyleId = reader.int64();
                        break;
                    }
                case 3: {
                        message.chunkNumber = reader.int64();
                        break;
                    }
                case 4: {
                        message.chunk = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReceiveDownloadAttachmentChunk message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.ReceiveDownloadAttachmentChunk} ReceiveDownloadAttachmentChunk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveDownloadAttachmentChunk.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReceiveDownloadAttachmentChunk message.
         * @function verify
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReceiveDownloadAttachmentChunk.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (!$util.isInteger(message.fyleId) && !(message.fyleId && $util.isInteger(message.fyleId.low) && $util.isInteger(message.fyleId.high)))
                    return "fyleId: integer|Long expected";
            if (message.chunkNumber != null && message.hasOwnProperty("chunkNumber"))
                if (!$util.isInteger(message.chunkNumber) && !(message.chunkNumber && $util.isInteger(message.chunkNumber.low) && $util.isInteger(message.chunkNumber.high)))
                    return "chunkNumber: integer|Long expected";
            if (message.chunk != null && message.hasOwnProperty("chunk"))
                if (!(message.chunk && typeof message.chunk.length === "number" || $util.isString(message.chunk)))
                    return "chunk: buffer expected";
            return null;
        };

        /**
         * Creates a ReceiveDownloadAttachmentChunk message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.ReceiveDownloadAttachmentChunk} ReceiveDownloadAttachmentChunk
         */
        ReceiveDownloadAttachmentChunk.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.ReceiveDownloadAttachmentChunk)
                return object;
            var message = new $root.olvid.ReceiveDownloadAttachmentChunk();
            if (object.fyleId != null)
                if ($util.Long)
                    (message.fyleId = $util.Long.fromValue(object.fyleId)).unsigned = false;
                else if (typeof object.fyleId === "string")
                    message.fyleId = parseInt(object.fyleId, 10);
                else if (typeof object.fyleId === "number")
                    message.fyleId = object.fyleId;
                else if (typeof object.fyleId === "object")
                    message.fyleId = new $util.LongBits(object.fyleId.low >>> 0, object.fyleId.high >>> 0).toNumber();
            if (object.chunkNumber != null)
                if ($util.Long)
                    (message.chunkNumber = $util.Long.fromValue(object.chunkNumber)).unsigned = false;
                else if (typeof object.chunkNumber === "string")
                    message.chunkNumber = parseInt(object.chunkNumber, 10);
                else if (typeof object.chunkNumber === "number")
                    message.chunkNumber = object.chunkNumber;
                else if (typeof object.chunkNumber === "object")
                    message.chunkNumber = new $util.LongBits(object.chunkNumber.low >>> 0, object.chunkNumber.high >>> 0).toNumber();
            if (object.chunk != null)
                if (typeof object.chunk === "string")
                    $util.base64.decode(object.chunk, message.chunk = $util.newBuffer($util.base64.length(object.chunk)), 0);
                else if (object.chunk.length >= 0)
                    message.chunk = object.chunk;
            return message;
        };

        /**
         * Creates a plain object from a ReceiveDownloadAttachmentChunk message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @static
         * @param {olvid.ReceiveDownloadAttachmentChunk} message ReceiveDownloadAttachmentChunk
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiveDownloadAttachmentChunk.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.fyleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fyleId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.chunkNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chunkNumber = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.chunk = "";
                else {
                    object.chunk = [];
                    if (options.bytes !== Array)
                        object.chunk = $util.newBuffer(object.chunk);
                }
            }
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (typeof message.fyleId === "number")
                    object.fyleId = options.longs === String ? String(message.fyleId) : message.fyleId;
                else
                    object.fyleId = options.longs === String ? $util.Long.prototype.toString.call(message.fyleId) : options.longs === Number ? new $util.LongBits(message.fyleId.low >>> 0, message.fyleId.high >>> 0).toNumber() : message.fyleId;
            if (message.chunkNumber != null && message.hasOwnProperty("chunkNumber"))
                if (typeof message.chunkNumber === "number")
                    object.chunkNumber = options.longs === String ? String(message.chunkNumber) : message.chunkNumber;
                else
                    object.chunkNumber = options.longs === String ? $util.Long.prototype.toString.call(message.chunkNumber) : options.longs === Number ? new $util.LongBits(message.chunkNumber.low >>> 0, message.chunkNumber.high >>> 0).toNumber() : message.chunkNumber;
            if (message.chunk != null && message.hasOwnProperty("chunk"))
                object.chunk = options.bytes === String ? $util.base64.encode(message.chunk, 0, message.chunk.length) : options.bytes === Array ? Array.prototype.slice.call(message.chunk) : message.chunk;
            return object;
        };

        /**
         * Converts this ReceiveDownloadAttachmentChunk to JSON.
         * @function toJSON
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiveDownloadAttachmentChunk.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiveDownloadAttachmentChunk
         * @function getTypeUrl
         * @memberof olvid.ReceiveDownloadAttachmentChunk
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiveDownloadAttachmentChunk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.ReceiveDownloadAttachmentChunk";
        };

        return ReceiveDownloadAttachmentChunk;
    })();

    olvid.ReceiveDownloadAttachmentDone = (function() {

        /**
         * Properties of a ReceiveDownloadAttachmentDone.
         * @memberof olvid
         * @interface IReceiveDownloadAttachmentDone
         * @property {number|Long|null} [fyleId] ReceiveDownloadAttachmentDone fyleId
         * @property {boolean|null} [success] ReceiveDownloadAttachmentDone success
         */

        /**
         * Constructs a new ReceiveDownloadAttachmentDone.
         * @memberof olvid
         * @classdesc Represents a ReceiveDownloadAttachmentDone.
         * @implements IReceiveDownloadAttachmentDone
         * @constructor
         * @param {olvid.IReceiveDownloadAttachmentDone=} [properties] Properties to set
         */
        function ReceiveDownloadAttachmentDone(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReceiveDownloadAttachmentDone fyleId.
         * @member {number|Long} fyleId
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @instance
         */
        ReceiveDownloadAttachmentDone.prototype.fyleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ReceiveDownloadAttachmentDone success.
         * @member {boolean} success
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @instance
         */
        ReceiveDownloadAttachmentDone.prototype.success = false;

        /**
         * Creates a new ReceiveDownloadAttachmentDone instance using the specified properties.
         * @function create
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @static
         * @param {olvid.IReceiveDownloadAttachmentDone=} [properties] Properties to set
         * @returns {olvid.ReceiveDownloadAttachmentDone} ReceiveDownloadAttachmentDone instance
         */
        ReceiveDownloadAttachmentDone.create = function create(properties) {
            return new ReceiveDownloadAttachmentDone(properties);
        };

        /**
         * Encodes the specified ReceiveDownloadAttachmentDone message. Does not implicitly {@link olvid.ReceiveDownloadAttachmentDone.verify|verify} messages.
         * @function encode
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @static
         * @param {olvid.IReceiveDownloadAttachmentDone} message ReceiveDownloadAttachmentDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveDownloadAttachmentDone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fyleId != null && Object.hasOwnProperty.call(message, "fyleId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fyleId);
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.success);
            return writer;
        };

        /**
         * Encodes the specified ReceiveDownloadAttachmentDone message, length delimited. Does not implicitly {@link olvid.ReceiveDownloadAttachmentDone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @static
         * @param {olvid.IReceiveDownloadAttachmentDone} message ReceiveDownloadAttachmentDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReceiveDownloadAttachmentDone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReceiveDownloadAttachmentDone message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.ReceiveDownloadAttachmentDone} ReceiveDownloadAttachmentDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveDownloadAttachmentDone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.ReceiveDownloadAttachmentDone();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fyleId = reader.int64();
                        break;
                    }
                case 2: {
                        message.success = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReceiveDownloadAttachmentDone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.ReceiveDownloadAttachmentDone} ReceiveDownloadAttachmentDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReceiveDownloadAttachmentDone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReceiveDownloadAttachmentDone message.
         * @function verify
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReceiveDownloadAttachmentDone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (!$util.isInteger(message.fyleId) && !(message.fyleId && $util.isInteger(message.fyleId.low) && $util.isInteger(message.fyleId.high)))
                    return "fyleId: integer|Long expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            return null;
        };

        /**
         * Creates a ReceiveDownloadAttachmentDone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.ReceiveDownloadAttachmentDone} ReceiveDownloadAttachmentDone
         */
        ReceiveDownloadAttachmentDone.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.ReceiveDownloadAttachmentDone)
                return object;
            var message = new $root.olvid.ReceiveDownloadAttachmentDone();
            if (object.fyleId != null)
                if ($util.Long)
                    (message.fyleId = $util.Long.fromValue(object.fyleId)).unsigned = false;
                else if (typeof object.fyleId === "string")
                    message.fyleId = parseInt(object.fyleId, 10);
                else if (typeof object.fyleId === "number")
                    message.fyleId = object.fyleId;
                else if (typeof object.fyleId === "object")
                    message.fyleId = new $util.LongBits(object.fyleId.low >>> 0, object.fyleId.high >>> 0).toNumber();
            if (object.success != null)
                message.success = Boolean(object.success);
            return message;
        };

        /**
         * Creates a plain object from a ReceiveDownloadAttachmentDone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @static
         * @param {olvid.ReceiveDownloadAttachmentDone} message ReceiveDownloadAttachmentDone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReceiveDownloadAttachmentDone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.fyleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fyleId = options.longs === String ? "0" : 0;
                object.success = false;
            }
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (typeof message.fyleId === "number")
                    object.fyleId = options.longs === String ? String(message.fyleId) : message.fyleId;
                else
                    object.fyleId = options.longs === String ? $util.Long.prototype.toString.call(message.fyleId) : options.longs === Number ? new $util.LongBits(message.fyleId.low >>> 0, message.fyleId.high >>> 0).toNumber() : message.fyleId;
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            return object;
        };

        /**
         * Converts this ReceiveDownloadAttachmentDone to JSON.
         * @function toJSON
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReceiveDownloadAttachmentDone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ReceiveDownloadAttachmentDone
         * @function getTypeUrl
         * @memberof olvid.ReceiveDownloadAttachmentDone
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReceiveDownloadAttachmentDone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.ReceiveDownloadAttachmentDone";
        };

        return ReceiveDownloadAttachmentDone;
    })();

    olvid.RequestAddReactionToMessage = (function() {

        /**
         * Properties of a RequestAddReactionToMessage.
         * @memberof olvid
         * @interface IRequestAddReactionToMessage
         * @property {string|null} [reaction] RequestAddReactionToMessage reaction
         * @property {number|Long|null} [messageId] RequestAddReactionToMessage messageId
         */

        /**
         * Constructs a new RequestAddReactionToMessage.
         * @memberof olvid
         * @classdesc Represents a RequestAddReactionToMessage.
         * @implements IRequestAddReactionToMessage
         * @constructor
         * @param {olvid.IRequestAddReactionToMessage=} [properties] Properties to set
         */
        function RequestAddReactionToMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestAddReactionToMessage reaction.
         * @member {string} reaction
         * @memberof olvid.RequestAddReactionToMessage
         * @instance
         */
        RequestAddReactionToMessage.prototype.reaction = "";

        /**
         * RequestAddReactionToMessage messageId.
         * @member {number|Long} messageId
         * @memberof olvid.RequestAddReactionToMessage
         * @instance
         */
        RequestAddReactionToMessage.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new RequestAddReactionToMessage instance using the specified properties.
         * @function create
         * @memberof olvid.RequestAddReactionToMessage
         * @static
         * @param {olvid.IRequestAddReactionToMessage=} [properties] Properties to set
         * @returns {olvid.RequestAddReactionToMessage} RequestAddReactionToMessage instance
         */
        RequestAddReactionToMessage.create = function create(properties) {
            return new RequestAddReactionToMessage(properties);
        };

        /**
         * Encodes the specified RequestAddReactionToMessage message. Does not implicitly {@link olvid.RequestAddReactionToMessage.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestAddReactionToMessage
         * @static
         * @param {olvid.IRequestAddReactionToMessage} message RequestAddReactionToMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestAddReactionToMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reaction != null && Object.hasOwnProperty.call(message, "reaction"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.reaction);
            if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.messageId);
            return writer;
        };

        /**
         * Encodes the specified RequestAddReactionToMessage message, length delimited. Does not implicitly {@link olvid.RequestAddReactionToMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestAddReactionToMessage
         * @static
         * @param {olvid.IRequestAddReactionToMessage} message RequestAddReactionToMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestAddReactionToMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestAddReactionToMessage message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestAddReactionToMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestAddReactionToMessage} RequestAddReactionToMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestAddReactionToMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestAddReactionToMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.reaction = reader.string();
                        break;
                    }
                case 2: {
                        message.messageId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestAddReactionToMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestAddReactionToMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestAddReactionToMessage} RequestAddReactionToMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestAddReactionToMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestAddReactionToMessage message.
         * @function verify
         * @memberof olvid.RequestAddReactionToMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestAddReactionToMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.reaction != null && message.hasOwnProperty("reaction"))
                if (!$util.isString(message.reaction))
                    return "reaction: string expected";
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                    return "messageId: integer|Long expected";
            return null;
        };

        /**
         * Creates a RequestAddReactionToMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestAddReactionToMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestAddReactionToMessage} RequestAddReactionToMessage
         */
        RequestAddReactionToMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestAddReactionToMessage)
                return object;
            var message = new $root.olvid.RequestAddReactionToMessage();
            if (object.reaction != null)
                message.reaction = String(object.reaction);
            if (object.messageId != null)
                if ($util.Long)
                    (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = false;
                else if (typeof object.messageId === "string")
                    message.messageId = parseInt(object.messageId, 10);
                else if (typeof object.messageId === "number")
                    message.messageId = object.messageId;
                else if (typeof object.messageId === "object")
                    message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RequestAddReactionToMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestAddReactionToMessage
         * @static
         * @param {olvid.RequestAddReactionToMessage} message RequestAddReactionToMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestAddReactionToMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.reaction = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.messageId = options.longs === String ? "0" : 0;
            }
            if (message.reaction != null && message.hasOwnProperty("reaction"))
                object.reaction = message.reaction;
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (typeof message.messageId === "number")
                    object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
                else
                    object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber() : message.messageId;
            return object;
        };

        /**
         * Converts this RequestAddReactionToMessage to JSON.
         * @function toJSON
         * @memberof olvid.RequestAddReactionToMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestAddReactionToMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestAddReactionToMessage
         * @function getTypeUrl
         * @memberof olvid.RequestAddReactionToMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestAddReactionToMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestAddReactionToMessage";
        };

        return RequestAddReactionToMessage;
    })();

    olvid.NotifDiscussionUpdated = (function() {

        /**
         * Properties of a NotifDiscussionUpdated.
         * @memberof olvid
         * @interface INotifDiscussionUpdated
         * @property {olvid.IDiscussion|null} [discussion] NotifDiscussionUpdated discussion
         */

        /**
         * Constructs a new NotifDiscussionUpdated.
         * @memberof olvid
         * @classdesc Represents a NotifDiscussionUpdated.
         * @implements INotifDiscussionUpdated
         * @constructor
         * @param {olvid.INotifDiscussionUpdated=} [properties] Properties to set
         */
        function NotifDiscussionUpdated(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifDiscussionUpdated discussion.
         * @member {olvid.IDiscussion|null|undefined} discussion
         * @memberof olvid.NotifDiscussionUpdated
         * @instance
         */
        NotifDiscussionUpdated.prototype.discussion = null;

        /**
         * Creates a new NotifDiscussionUpdated instance using the specified properties.
         * @function create
         * @memberof olvid.NotifDiscussionUpdated
         * @static
         * @param {olvid.INotifDiscussionUpdated=} [properties] Properties to set
         * @returns {olvid.NotifDiscussionUpdated} NotifDiscussionUpdated instance
         */
        NotifDiscussionUpdated.create = function create(properties) {
            return new NotifDiscussionUpdated(properties);
        };

        /**
         * Encodes the specified NotifDiscussionUpdated message. Does not implicitly {@link olvid.NotifDiscussionUpdated.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifDiscussionUpdated
         * @static
         * @param {olvid.INotifDiscussionUpdated} message NotifDiscussionUpdated message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifDiscussionUpdated.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussion != null && Object.hasOwnProperty.call(message, "discussion"))
                $root.olvid.Discussion.encode(message.discussion, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifDiscussionUpdated message, length delimited. Does not implicitly {@link olvid.NotifDiscussionUpdated.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifDiscussionUpdated
         * @static
         * @param {olvid.INotifDiscussionUpdated} message NotifDiscussionUpdated message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifDiscussionUpdated.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifDiscussionUpdated message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifDiscussionUpdated
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifDiscussionUpdated} NotifDiscussionUpdated
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifDiscussionUpdated.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifDiscussionUpdated();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.discussion = $root.olvid.Discussion.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifDiscussionUpdated message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifDiscussionUpdated
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifDiscussionUpdated} NotifDiscussionUpdated
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifDiscussionUpdated.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifDiscussionUpdated message.
         * @function verify
         * @memberof olvid.NotifDiscussionUpdated
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifDiscussionUpdated.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussion != null && message.hasOwnProperty("discussion")) {
                var error = $root.olvid.Discussion.verify(message.discussion);
                if (error)
                    return "discussion." + error;
            }
            return null;
        };

        /**
         * Creates a NotifDiscussionUpdated message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifDiscussionUpdated
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifDiscussionUpdated} NotifDiscussionUpdated
         */
        NotifDiscussionUpdated.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifDiscussionUpdated)
                return object;
            var message = new $root.olvid.NotifDiscussionUpdated();
            if (object.discussion != null) {
                if (typeof object.discussion !== "object")
                    throw TypeError(".olvid.NotifDiscussionUpdated.discussion: object expected");
                message.discussion = $root.olvid.Discussion.fromObject(object.discussion);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifDiscussionUpdated message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifDiscussionUpdated
         * @static
         * @param {olvid.NotifDiscussionUpdated} message NotifDiscussionUpdated
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifDiscussionUpdated.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.discussion = null;
            if (message.discussion != null && message.hasOwnProperty("discussion"))
                object.discussion = $root.olvid.Discussion.toObject(message.discussion, options);
            return object;
        };

        /**
         * Converts this NotifDiscussionUpdated to JSON.
         * @function toJSON
         * @memberof olvid.NotifDiscussionUpdated
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifDiscussionUpdated.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifDiscussionUpdated
         * @function getTypeUrl
         * @memberof olvid.NotifDiscussionUpdated
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifDiscussionUpdated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifDiscussionUpdated";
        };

        return NotifDiscussionUpdated;
    })();

    olvid.NotifNewMessage = (function() {

        /**
         * Properties of a NotifNewMessage.
         * @memberof olvid
         * @interface INotifNewMessage
         * @property {Array.<olvid.IMessage>|null} [messages] NotifNewMessage messages
         */

        /**
         * Constructs a new NotifNewMessage.
         * @memberof olvid
         * @classdesc Represents a NotifNewMessage.
         * @implements INotifNewMessage
         * @constructor
         * @param {olvid.INotifNewMessage=} [properties] Properties to set
         */
        function NotifNewMessage(properties) {
            this.messages = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifNewMessage messages.
         * @member {Array.<olvid.IMessage>} messages
         * @memberof olvid.NotifNewMessage
         * @instance
         */
        NotifNewMessage.prototype.messages = $util.emptyArray;

        /**
         * Creates a new NotifNewMessage instance using the specified properties.
         * @function create
         * @memberof olvid.NotifNewMessage
         * @static
         * @param {olvid.INotifNewMessage=} [properties] Properties to set
         * @returns {olvid.NotifNewMessage} NotifNewMessage instance
         */
        NotifNewMessage.create = function create(properties) {
            return new NotifNewMessage(properties);
        };

        /**
         * Encodes the specified NotifNewMessage message. Does not implicitly {@link olvid.NotifNewMessage.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifNewMessage
         * @static
         * @param {olvid.INotifNewMessage} message NotifNewMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifNewMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.messages != null && message.messages.length)
                for (var i = 0; i < message.messages.length; ++i)
                    $root.olvid.Message.encode(message.messages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifNewMessage message, length delimited. Does not implicitly {@link olvid.NotifNewMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifNewMessage
         * @static
         * @param {olvid.INotifNewMessage} message NotifNewMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifNewMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifNewMessage message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifNewMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifNewMessage} NotifNewMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifNewMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifNewMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.messages && message.messages.length))
                            message.messages = [];
                        message.messages.push($root.olvid.Message.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifNewMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifNewMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifNewMessage} NotifNewMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifNewMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifNewMessage message.
         * @function verify
         * @memberof olvid.NotifNewMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifNewMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.messages != null && message.hasOwnProperty("messages")) {
                if (!Array.isArray(message.messages))
                    return "messages: array expected";
                for (var i = 0; i < message.messages.length; ++i) {
                    var error = $root.olvid.Message.verify(message.messages[i]);
                    if (error)
                        return "messages." + error;
                }
            }
            return null;
        };

        /**
         * Creates a NotifNewMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifNewMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifNewMessage} NotifNewMessage
         */
        NotifNewMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifNewMessage)
                return object;
            var message = new $root.olvid.NotifNewMessage();
            if (object.messages) {
                if (!Array.isArray(object.messages))
                    throw TypeError(".olvid.NotifNewMessage.messages: array expected");
                message.messages = [];
                for (var i = 0; i < object.messages.length; ++i) {
                    if (typeof object.messages[i] !== "object")
                        throw TypeError(".olvid.NotifNewMessage.messages: object expected");
                    message.messages[i] = $root.olvid.Message.fromObject(object.messages[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifNewMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifNewMessage
         * @static
         * @param {olvid.NotifNewMessage} message NotifNewMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifNewMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.messages = [];
            if (message.messages && message.messages.length) {
                object.messages = [];
                for (var j = 0; j < message.messages.length; ++j)
                    object.messages[j] = $root.olvid.Message.toObject(message.messages[j], options);
            }
            return object;
        };

        /**
         * Converts this NotifNewMessage to JSON.
         * @function toJSON
         * @memberof olvid.NotifNewMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifNewMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifNewMessage
         * @function getTypeUrl
         * @memberof olvid.NotifNewMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifNewMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifNewMessage";
        };

        return NotifNewMessage;
    })();

    olvid.NotifNewDiscussion = (function() {

        /**
         * Properties of a NotifNewDiscussion.
         * @memberof olvid
         * @interface INotifNewDiscussion
         * @property {Array.<olvid.IDiscussion>|null} [discussions] NotifNewDiscussion discussions
         */

        /**
         * Constructs a new NotifNewDiscussion.
         * @memberof olvid
         * @classdesc Represents a NotifNewDiscussion.
         * @implements INotifNewDiscussion
         * @constructor
         * @param {olvid.INotifNewDiscussion=} [properties] Properties to set
         */
        function NotifNewDiscussion(properties) {
            this.discussions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifNewDiscussion discussions.
         * @member {Array.<olvid.IDiscussion>} discussions
         * @memberof olvid.NotifNewDiscussion
         * @instance
         */
        NotifNewDiscussion.prototype.discussions = $util.emptyArray;

        /**
         * Creates a new NotifNewDiscussion instance using the specified properties.
         * @function create
         * @memberof olvid.NotifNewDiscussion
         * @static
         * @param {olvid.INotifNewDiscussion=} [properties] Properties to set
         * @returns {olvid.NotifNewDiscussion} NotifNewDiscussion instance
         */
        NotifNewDiscussion.create = function create(properties) {
            return new NotifNewDiscussion(properties);
        };

        /**
         * Encodes the specified NotifNewDiscussion message. Does not implicitly {@link olvid.NotifNewDiscussion.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifNewDiscussion
         * @static
         * @param {olvid.INotifNewDiscussion} message NotifNewDiscussion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifNewDiscussion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussions != null && message.discussions.length)
                for (var i = 0; i < message.discussions.length; ++i)
                    $root.olvid.Discussion.encode(message.discussions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifNewDiscussion message, length delimited. Does not implicitly {@link olvid.NotifNewDiscussion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifNewDiscussion
         * @static
         * @param {olvid.INotifNewDiscussion} message NotifNewDiscussion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifNewDiscussion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifNewDiscussion message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifNewDiscussion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifNewDiscussion} NotifNewDiscussion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifNewDiscussion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifNewDiscussion();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.discussions && message.discussions.length))
                            message.discussions = [];
                        message.discussions.push($root.olvid.Discussion.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifNewDiscussion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifNewDiscussion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifNewDiscussion} NotifNewDiscussion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifNewDiscussion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifNewDiscussion message.
         * @function verify
         * @memberof olvid.NotifNewDiscussion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifNewDiscussion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussions != null && message.hasOwnProperty("discussions")) {
                if (!Array.isArray(message.discussions))
                    return "discussions: array expected";
                for (var i = 0; i < message.discussions.length; ++i) {
                    var error = $root.olvid.Discussion.verify(message.discussions[i]);
                    if (error)
                        return "discussions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a NotifNewDiscussion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifNewDiscussion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifNewDiscussion} NotifNewDiscussion
         */
        NotifNewDiscussion.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifNewDiscussion)
                return object;
            var message = new $root.olvid.NotifNewDiscussion();
            if (object.discussions) {
                if (!Array.isArray(object.discussions))
                    throw TypeError(".olvid.NotifNewDiscussion.discussions: array expected");
                message.discussions = [];
                for (var i = 0; i < object.discussions.length; ++i) {
                    if (typeof object.discussions[i] !== "object")
                        throw TypeError(".olvid.NotifNewDiscussion.discussions: object expected");
                    message.discussions[i] = $root.olvid.Discussion.fromObject(object.discussions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifNewDiscussion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifNewDiscussion
         * @static
         * @param {olvid.NotifNewDiscussion} message NotifNewDiscussion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifNewDiscussion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.discussions = [];
            if (message.discussions && message.discussions.length) {
                object.discussions = [];
                for (var j = 0; j < message.discussions.length; ++j)
                    object.discussions[j] = $root.olvid.Discussion.toObject(message.discussions[j], options);
            }
            return object;
        };

        /**
         * Converts this NotifNewDiscussion to JSON.
         * @function toJSON
         * @memberof olvid.NotifNewDiscussion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifNewDiscussion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifNewDiscussion
         * @function getTypeUrl
         * @memberof olvid.NotifNewDiscussion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifNewDiscussion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifNewDiscussion";
        };

        return NotifNewDiscussion;
    })();

    olvid.NotifUpdateMessage = (function() {

        /**
         * Properties of a NotifUpdateMessage.
         * @memberof olvid
         * @interface INotifUpdateMessage
         * @property {olvid.IMessage|null} [message] NotifUpdateMessage message
         */

        /**
         * Constructs a new NotifUpdateMessage.
         * @memberof olvid
         * @classdesc Represents a NotifUpdateMessage.
         * @implements INotifUpdateMessage
         * @constructor
         * @param {olvid.INotifUpdateMessage=} [properties] Properties to set
         */
        function NotifUpdateMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifUpdateMessage message.
         * @member {olvid.IMessage|null|undefined} message
         * @memberof olvid.NotifUpdateMessage
         * @instance
         */
        NotifUpdateMessage.prototype.message = null;

        /**
         * Creates a new NotifUpdateMessage instance using the specified properties.
         * @function create
         * @memberof olvid.NotifUpdateMessage
         * @static
         * @param {olvid.INotifUpdateMessage=} [properties] Properties to set
         * @returns {olvid.NotifUpdateMessage} NotifUpdateMessage instance
         */
        NotifUpdateMessage.create = function create(properties) {
            return new NotifUpdateMessage(properties);
        };

        /**
         * Encodes the specified NotifUpdateMessage message. Does not implicitly {@link olvid.NotifUpdateMessage.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifUpdateMessage
         * @static
         * @param {olvid.INotifUpdateMessage} message NotifUpdateMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifUpdateMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.olvid.Message.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifUpdateMessage message, length delimited. Does not implicitly {@link olvid.NotifUpdateMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifUpdateMessage
         * @static
         * @param {olvid.INotifUpdateMessage} message NotifUpdateMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifUpdateMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifUpdateMessage message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifUpdateMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifUpdateMessage} NotifUpdateMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifUpdateMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifUpdateMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.message = $root.olvid.Message.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifUpdateMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifUpdateMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifUpdateMessage} NotifUpdateMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifUpdateMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifUpdateMessage message.
         * @function verify
         * @memberof olvid.NotifUpdateMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifUpdateMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.message != null && message.hasOwnProperty("message")) {
                var error = $root.olvid.Message.verify(message.message);
                if (error)
                    return "message." + error;
            }
            return null;
        };

        /**
         * Creates a NotifUpdateMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifUpdateMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifUpdateMessage} NotifUpdateMessage
         */
        NotifUpdateMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifUpdateMessage)
                return object;
            var message = new $root.olvid.NotifUpdateMessage();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".olvid.NotifUpdateMessage.message: object expected");
                message.message = $root.olvid.Message.fromObject(object.message);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifUpdateMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifUpdateMessage
         * @static
         * @param {olvid.NotifUpdateMessage} message NotifUpdateMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifUpdateMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.message = null;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.olvid.Message.toObject(message.message, options);
            return object;
        };

        /**
         * Converts this NotifUpdateMessage to JSON.
         * @function toJSON
         * @memberof olvid.NotifUpdateMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifUpdateMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifUpdateMessage
         * @function getTypeUrl
         * @memberof olvid.NotifUpdateMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifUpdateMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifUpdateMessage";
        };

        return NotifUpdateMessage;
    })();

    olvid.NotifDeleteMessage = (function() {

        /**
         * Properties of a NotifDeleteMessage.
         * @memberof olvid
         * @interface INotifDeleteMessage
         * @property {olvid.IMessage|null} [message] NotifDeleteMessage message
         */

        /**
         * Constructs a new NotifDeleteMessage.
         * @memberof olvid
         * @classdesc Represents a NotifDeleteMessage.
         * @implements INotifDeleteMessage
         * @constructor
         * @param {olvid.INotifDeleteMessage=} [properties] Properties to set
         */
        function NotifDeleteMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifDeleteMessage message.
         * @member {olvid.IMessage|null|undefined} message
         * @memberof olvid.NotifDeleteMessage
         * @instance
         */
        NotifDeleteMessage.prototype.message = null;

        /**
         * Creates a new NotifDeleteMessage instance using the specified properties.
         * @function create
         * @memberof olvid.NotifDeleteMessage
         * @static
         * @param {olvid.INotifDeleteMessage=} [properties] Properties to set
         * @returns {olvid.NotifDeleteMessage} NotifDeleteMessage instance
         */
        NotifDeleteMessage.create = function create(properties) {
            return new NotifDeleteMessage(properties);
        };

        /**
         * Encodes the specified NotifDeleteMessage message. Does not implicitly {@link olvid.NotifDeleteMessage.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifDeleteMessage
         * @static
         * @param {olvid.INotifDeleteMessage} message NotifDeleteMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifDeleteMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                $root.olvid.Message.encode(message.message, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifDeleteMessage message, length delimited. Does not implicitly {@link olvid.NotifDeleteMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifDeleteMessage
         * @static
         * @param {olvid.INotifDeleteMessage} message NotifDeleteMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifDeleteMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifDeleteMessage message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifDeleteMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifDeleteMessage} NotifDeleteMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifDeleteMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifDeleteMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.message = $root.olvid.Message.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifDeleteMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifDeleteMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifDeleteMessage} NotifDeleteMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifDeleteMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifDeleteMessage message.
         * @function verify
         * @memberof olvid.NotifDeleteMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifDeleteMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.message != null && message.hasOwnProperty("message")) {
                var error = $root.olvid.Message.verify(message.message);
                if (error)
                    return "message." + error;
            }
            return null;
        };

        /**
         * Creates a NotifDeleteMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifDeleteMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifDeleteMessage} NotifDeleteMessage
         */
        NotifDeleteMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifDeleteMessage)
                return object;
            var message = new $root.olvid.NotifDeleteMessage();
            if (object.message != null) {
                if (typeof object.message !== "object")
                    throw TypeError(".olvid.NotifDeleteMessage.message: object expected");
                message.message = $root.olvid.Message.fromObject(object.message);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifDeleteMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifDeleteMessage
         * @static
         * @param {olvid.NotifDeleteMessage} message NotifDeleteMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifDeleteMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.message = null;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = $root.olvid.Message.toObject(message.message, options);
            return object;
        };

        /**
         * Converts this NotifDeleteMessage to JSON.
         * @function toJSON
         * @memberof olvid.NotifDeleteMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifDeleteMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifDeleteMessage
         * @function getTypeUrl
         * @memberof olvid.NotifDeleteMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifDeleteMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifDeleteMessage";
        };

        return NotifDeleteMessage;
    })();

    olvid.NotifNewDraftAttachment = (function() {

        /**
         * Properties of a NotifNewDraftAttachment.
         * @memberof olvid
         * @interface INotifNewDraftAttachment
         * @property {olvid.IDraftAttachment|null} [draftAttachment] NotifNewDraftAttachment draftAttachment
         */

        /**
         * Constructs a new NotifNewDraftAttachment.
         * @memberof olvid
         * @classdesc Represents a NotifNewDraftAttachment.
         * @implements INotifNewDraftAttachment
         * @constructor
         * @param {olvid.INotifNewDraftAttachment=} [properties] Properties to set
         */
        function NotifNewDraftAttachment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifNewDraftAttachment draftAttachment.
         * @member {olvid.IDraftAttachment|null|undefined} draftAttachment
         * @memberof olvid.NotifNewDraftAttachment
         * @instance
         */
        NotifNewDraftAttachment.prototype.draftAttachment = null;

        /**
         * Creates a new NotifNewDraftAttachment instance using the specified properties.
         * @function create
         * @memberof olvid.NotifNewDraftAttachment
         * @static
         * @param {olvid.INotifNewDraftAttachment=} [properties] Properties to set
         * @returns {olvid.NotifNewDraftAttachment} NotifNewDraftAttachment instance
         */
        NotifNewDraftAttachment.create = function create(properties) {
            return new NotifNewDraftAttachment(properties);
        };

        /**
         * Encodes the specified NotifNewDraftAttachment message. Does not implicitly {@link olvid.NotifNewDraftAttachment.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifNewDraftAttachment
         * @static
         * @param {olvid.INotifNewDraftAttachment} message NotifNewDraftAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifNewDraftAttachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.draftAttachment != null && Object.hasOwnProperty.call(message, "draftAttachment"))
                $root.olvid.DraftAttachment.encode(message.draftAttachment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifNewDraftAttachment message, length delimited. Does not implicitly {@link olvid.NotifNewDraftAttachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifNewDraftAttachment
         * @static
         * @param {olvid.INotifNewDraftAttachment} message NotifNewDraftAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifNewDraftAttachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifNewDraftAttachment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifNewDraftAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifNewDraftAttachment} NotifNewDraftAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifNewDraftAttachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifNewDraftAttachment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.draftAttachment = $root.olvid.DraftAttachment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifNewDraftAttachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifNewDraftAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifNewDraftAttachment} NotifNewDraftAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifNewDraftAttachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifNewDraftAttachment message.
         * @function verify
         * @memberof olvid.NotifNewDraftAttachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifNewDraftAttachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.draftAttachment != null && message.hasOwnProperty("draftAttachment")) {
                var error = $root.olvid.DraftAttachment.verify(message.draftAttachment);
                if (error)
                    return "draftAttachment." + error;
            }
            return null;
        };

        /**
         * Creates a NotifNewDraftAttachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifNewDraftAttachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifNewDraftAttachment} NotifNewDraftAttachment
         */
        NotifNewDraftAttachment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifNewDraftAttachment)
                return object;
            var message = new $root.olvid.NotifNewDraftAttachment();
            if (object.draftAttachment != null) {
                if (typeof object.draftAttachment !== "object")
                    throw TypeError(".olvid.NotifNewDraftAttachment.draftAttachment: object expected");
                message.draftAttachment = $root.olvid.DraftAttachment.fromObject(object.draftAttachment);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifNewDraftAttachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifNewDraftAttachment
         * @static
         * @param {olvid.NotifNewDraftAttachment} message NotifNewDraftAttachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifNewDraftAttachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.draftAttachment = null;
            if (message.draftAttachment != null && message.hasOwnProperty("draftAttachment"))
                object.draftAttachment = $root.olvid.DraftAttachment.toObject(message.draftAttachment, options);
            return object;
        };

        /**
         * Converts this NotifNewDraftAttachment to JSON.
         * @function toJSON
         * @memberof olvid.NotifNewDraftAttachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifNewDraftAttachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifNewDraftAttachment
         * @function getTypeUrl
         * @memberof olvid.NotifNewDraftAttachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifNewDraftAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifNewDraftAttachment";
        };

        return NotifNewDraftAttachment;
    })();

    olvid.NotifNewAttachment = (function() {

        /**
         * Properties of a NotifNewAttachment.
         * @memberof olvid
         * @interface INotifNewAttachment
         * @property {olvid.IAttachment|null} [attachment] NotifNewAttachment attachment
         */

        /**
         * Constructs a new NotifNewAttachment.
         * @memberof olvid
         * @classdesc Represents a NotifNewAttachment.
         * @implements INotifNewAttachment
         * @constructor
         * @param {olvid.INotifNewAttachment=} [properties] Properties to set
         */
        function NotifNewAttachment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifNewAttachment attachment.
         * @member {olvid.IAttachment|null|undefined} attachment
         * @memberof olvid.NotifNewAttachment
         * @instance
         */
        NotifNewAttachment.prototype.attachment = null;

        /**
         * Creates a new NotifNewAttachment instance using the specified properties.
         * @function create
         * @memberof olvid.NotifNewAttachment
         * @static
         * @param {olvid.INotifNewAttachment=} [properties] Properties to set
         * @returns {olvid.NotifNewAttachment} NotifNewAttachment instance
         */
        NotifNewAttachment.create = function create(properties) {
            return new NotifNewAttachment(properties);
        };

        /**
         * Encodes the specified NotifNewAttachment message. Does not implicitly {@link olvid.NotifNewAttachment.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifNewAttachment
         * @static
         * @param {olvid.INotifNewAttachment} message NotifNewAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifNewAttachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.attachment != null && Object.hasOwnProperty.call(message, "attachment"))
                $root.olvid.Attachment.encode(message.attachment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifNewAttachment message, length delimited. Does not implicitly {@link olvid.NotifNewAttachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifNewAttachment
         * @static
         * @param {olvid.INotifNewAttachment} message NotifNewAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifNewAttachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifNewAttachment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifNewAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifNewAttachment} NotifNewAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifNewAttachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifNewAttachment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.attachment = $root.olvid.Attachment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifNewAttachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifNewAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifNewAttachment} NotifNewAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifNewAttachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifNewAttachment message.
         * @function verify
         * @memberof olvid.NotifNewAttachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifNewAttachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.attachment != null && message.hasOwnProperty("attachment")) {
                var error = $root.olvid.Attachment.verify(message.attachment);
                if (error)
                    return "attachment." + error;
            }
            return null;
        };

        /**
         * Creates a NotifNewAttachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifNewAttachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifNewAttachment} NotifNewAttachment
         */
        NotifNewAttachment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifNewAttachment)
                return object;
            var message = new $root.olvid.NotifNewAttachment();
            if (object.attachment != null) {
                if (typeof object.attachment !== "object")
                    throw TypeError(".olvid.NotifNewAttachment.attachment: object expected");
                message.attachment = $root.olvid.Attachment.fromObject(object.attachment);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifNewAttachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifNewAttachment
         * @static
         * @param {olvid.NotifNewAttachment} message NotifNewAttachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifNewAttachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.attachment = null;
            if (message.attachment != null && message.hasOwnProperty("attachment"))
                object.attachment = $root.olvid.Attachment.toObject(message.attachment, options);
            return object;
        };

        /**
         * Converts this NotifNewAttachment to JSON.
         * @function toJSON
         * @memberof olvid.NotifNewAttachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifNewAttachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifNewAttachment
         * @function getTypeUrl
         * @memberof olvid.NotifNewAttachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifNewAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifNewAttachment";
        };

        return NotifNewAttachment;
    })();

    olvid.NotifUpdateAttachment = (function() {

        /**
         * Properties of a NotifUpdateAttachment.
         * @memberof olvid
         * @interface INotifUpdateAttachment
         * @property {olvid.IAttachment|null} [attachment] NotifUpdateAttachment attachment
         */

        /**
         * Constructs a new NotifUpdateAttachment.
         * @memberof olvid
         * @classdesc Represents a NotifUpdateAttachment.
         * @implements INotifUpdateAttachment
         * @constructor
         * @param {olvid.INotifUpdateAttachment=} [properties] Properties to set
         */
        function NotifUpdateAttachment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifUpdateAttachment attachment.
         * @member {olvid.IAttachment|null|undefined} attachment
         * @memberof olvid.NotifUpdateAttachment
         * @instance
         */
        NotifUpdateAttachment.prototype.attachment = null;

        /**
         * Creates a new NotifUpdateAttachment instance using the specified properties.
         * @function create
         * @memberof olvid.NotifUpdateAttachment
         * @static
         * @param {olvid.INotifUpdateAttachment=} [properties] Properties to set
         * @returns {olvid.NotifUpdateAttachment} NotifUpdateAttachment instance
         */
        NotifUpdateAttachment.create = function create(properties) {
            return new NotifUpdateAttachment(properties);
        };

        /**
         * Encodes the specified NotifUpdateAttachment message. Does not implicitly {@link olvid.NotifUpdateAttachment.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifUpdateAttachment
         * @static
         * @param {olvid.INotifUpdateAttachment} message NotifUpdateAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifUpdateAttachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.attachment != null && Object.hasOwnProperty.call(message, "attachment"))
                $root.olvid.Attachment.encode(message.attachment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifUpdateAttachment message, length delimited. Does not implicitly {@link olvid.NotifUpdateAttachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifUpdateAttachment
         * @static
         * @param {olvid.INotifUpdateAttachment} message NotifUpdateAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifUpdateAttachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifUpdateAttachment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifUpdateAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifUpdateAttachment} NotifUpdateAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifUpdateAttachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifUpdateAttachment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.attachment = $root.olvid.Attachment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifUpdateAttachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifUpdateAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifUpdateAttachment} NotifUpdateAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifUpdateAttachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifUpdateAttachment message.
         * @function verify
         * @memberof olvid.NotifUpdateAttachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifUpdateAttachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.attachment != null && message.hasOwnProperty("attachment")) {
                var error = $root.olvid.Attachment.verify(message.attachment);
                if (error)
                    return "attachment." + error;
            }
            return null;
        };

        /**
         * Creates a NotifUpdateAttachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifUpdateAttachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifUpdateAttachment} NotifUpdateAttachment
         */
        NotifUpdateAttachment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifUpdateAttachment)
                return object;
            var message = new $root.olvid.NotifUpdateAttachment();
            if (object.attachment != null) {
                if (typeof object.attachment !== "object")
                    throw TypeError(".olvid.NotifUpdateAttachment.attachment: object expected");
                message.attachment = $root.olvid.Attachment.fromObject(object.attachment);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifUpdateAttachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifUpdateAttachment
         * @static
         * @param {olvid.NotifUpdateAttachment} message NotifUpdateAttachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifUpdateAttachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.attachment = null;
            if (message.attachment != null && message.hasOwnProperty("attachment"))
                object.attachment = $root.olvid.Attachment.toObject(message.attachment, options);
            return object;
        };

        /**
         * Converts this NotifUpdateAttachment to JSON.
         * @function toJSON
         * @memberof olvid.NotifUpdateAttachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifUpdateAttachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifUpdateAttachment
         * @function getTypeUrl
         * @memberof olvid.NotifUpdateAttachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifUpdateAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifUpdateAttachment";
        };

        return NotifUpdateAttachment;
    })();

    olvid.NotifDeleteDraftAttachment = (function() {

        /**
         * Properties of a NotifDeleteDraftAttachment.
         * @memberof olvid
         * @interface INotifDeleteDraftAttachment
         * @property {olvid.IDraftAttachment|null} [draftAttachment] NotifDeleteDraftAttachment draftAttachment
         */

        /**
         * Constructs a new NotifDeleteDraftAttachment.
         * @memberof olvid
         * @classdesc Represents a NotifDeleteDraftAttachment.
         * @implements INotifDeleteDraftAttachment
         * @constructor
         * @param {olvid.INotifDeleteDraftAttachment=} [properties] Properties to set
         */
        function NotifDeleteDraftAttachment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifDeleteDraftAttachment draftAttachment.
         * @member {olvid.IDraftAttachment|null|undefined} draftAttachment
         * @memberof olvid.NotifDeleteDraftAttachment
         * @instance
         */
        NotifDeleteDraftAttachment.prototype.draftAttachment = null;

        /**
         * Creates a new NotifDeleteDraftAttachment instance using the specified properties.
         * @function create
         * @memberof olvid.NotifDeleteDraftAttachment
         * @static
         * @param {olvid.INotifDeleteDraftAttachment=} [properties] Properties to set
         * @returns {olvid.NotifDeleteDraftAttachment} NotifDeleteDraftAttachment instance
         */
        NotifDeleteDraftAttachment.create = function create(properties) {
            return new NotifDeleteDraftAttachment(properties);
        };

        /**
         * Encodes the specified NotifDeleteDraftAttachment message. Does not implicitly {@link olvid.NotifDeleteDraftAttachment.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifDeleteDraftAttachment
         * @static
         * @param {olvid.INotifDeleteDraftAttachment} message NotifDeleteDraftAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifDeleteDraftAttachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.draftAttachment != null && Object.hasOwnProperty.call(message, "draftAttachment"))
                $root.olvid.DraftAttachment.encode(message.draftAttachment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifDeleteDraftAttachment message, length delimited. Does not implicitly {@link olvid.NotifDeleteDraftAttachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifDeleteDraftAttachment
         * @static
         * @param {olvid.INotifDeleteDraftAttachment} message NotifDeleteDraftAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifDeleteDraftAttachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifDeleteDraftAttachment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifDeleteDraftAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifDeleteDraftAttachment} NotifDeleteDraftAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifDeleteDraftAttachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifDeleteDraftAttachment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.draftAttachment = $root.olvid.DraftAttachment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifDeleteDraftAttachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifDeleteDraftAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifDeleteDraftAttachment} NotifDeleteDraftAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifDeleteDraftAttachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifDeleteDraftAttachment message.
         * @function verify
         * @memberof olvid.NotifDeleteDraftAttachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifDeleteDraftAttachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.draftAttachment != null && message.hasOwnProperty("draftAttachment")) {
                var error = $root.olvid.DraftAttachment.verify(message.draftAttachment);
                if (error)
                    return "draftAttachment." + error;
            }
            return null;
        };

        /**
         * Creates a NotifDeleteDraftAttachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifDeleteDraftAttachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifDeleteDraftAttachment} NotifDeleteDraftAttachment
         */
        NotifDeleteDraftAttachment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifDeleteDraftAttachment)
                return object;
            var message = new $root.olvid.NotifDeleteDraftAttachment();
            if (object.draftAttachment != null) {
                if (typeof object.draftAttachment !== "object")
                    throw TypeError(".olvid.NotifDeleteDraftAttachment.draftAttachment: object expected");
                message.draftAttachment = $root.olvid.DraftAttachment.fromObject(object.draftAttachment);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifDeleteDraftAttachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifDeleteDraftAttachment
         * @static
         * @param {olvid.NotifDeleteDraftAttachment} message NotifDeleteDraftAttachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifDeleteDraftAttachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.draftAttachment = null;
            if (message.draftAttachment != null && message.hasOwnProperty("draftAttachment"))
                object.draftAttachment = $root.olvid.DraftAttachment.toObject(message.draftAttachment, options);
            return object;
        };

        /**
         * Converts this NotifDeleteDraftAttachment to JSON.
         * @function toJSON
         * @memberof olvid.NotifDeleteDraftAttachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifDeleteDraftAttachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifDeleteDraftAttachment
         * @function getTypeUrl
         * @memberof olvid.NotifDeleteDraftAttachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifDeleteDraftAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifDeleteDraftAttachment";
        };

        return NotifDeleteDraftAttachment;
    })();

    olvid.NotifNoDraftForDiscussion = (function() {

        /**
         * Properties of a NotifNoDraftForDiscussion.
         * @memberof olvid
         * @interface INotifNoDraftForDiscussion
         * @property {number|Long|null} [discussionId] NotifNoDraftForDiscussion discussionId
         */

        /**
         * Constructs a new NotifNoDraftForDiscussion.
         * @memberof olvid
         * @classdesc Represents a NotifNoDraftForDiscussion.
         * @implements INotifNoDraftForDiscussion
         * @constructor
         * @param {olvid.INotifNoDraftForDiscussion=} [properties] Properties to set
         */
        function NotifNoDraftForDiscussion(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifNoDraftForDiscussion discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.NotifNoDraftForDiscussion
         * @instance
         */
        NotifNoDraftForDiscussion.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new NotifNoDraftForDiscussion instance using the specified properties.
         * @function create
         * @memberof olvid.NotifNoDraftForDiscussion
         * @static
         * @param {olvid.INotifNoDraftForDiscussion=} [properties] Properties to set
         * @returns {olvid.NotifNoDraftForDiscussion} NotifNoDraftForDiscussion instance
         */
        NotifNoDraftForDiscussion.create = function create(properties) {
            return new NotifNoDraftForDiscussion(properties);
        };

        /**
         * Encodes the specified NotifNoDraftForDiscussion message. Does not implicitly {@link olvid.NotifNoDraftForDiscussion.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifNoDraftForDiscussion
         * @static
         * @param {olvid.INotifNoDraftForDiscussion} message NotifNoDraftForDiscussion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifNoDraftForDiscussion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.discussionId);
            return writer;
        };

        /**
         * Encodes the specified NotifNoDraftForDiscussion message, length delimited. Does not implicitly {@link olvid.NotifNoDraftForDiscussion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifNoDraftForDiscussion
         * @static
         * @param {olvid.INotifNoDraftForDiscussion} message NotifNoDraftForDiscussion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifNoDraftForDiscussion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifNoDraftForDiscussion message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifNoDraftForDiscussion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifNoDraftForDiscussion} NotifNoDraftForDiscussion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifNoDraftForDiscussion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifNoDraftForDiscussion();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.discussionId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifNoDraftForDiscussion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifNoDraftForDiscussion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifNoDraftForDiscussion} NotifNoDraftForDiscussion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifNoDraftForDiscussion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifNoDraftForDiscussion message.
         * @function verify
         * @memberof olvid.NotifNoDraftForDiscussion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifNoDraftForDiscussion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            return null;
        };

        /**
         * Creates a NotifNoDraftForDiscussion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifNoDraftForDiscussion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifNoDraftForDiscussion} NotifNoDraftForDiscussion
         */
        NotifNoDraftForDiscussion.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifNoDraftForDiscussion)
                return object;
            var message = new $root.olvid.NotifNoDraftForDiscussion();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a NotifNoDraftForDiscussion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifNoDraftForDiscussion
         * @static
         * @param {olvid.NotifNoDraftForDiscussion} message NotifNoDraftForDiscussion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifNoDraftForDiscussion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            return object;
        };

        /**
         * Converts this NotifNoDraftForDiscussion to JSON.
         * @function toJSON
         * @memberof olvid.NotifNoDraftForDiscussion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifNoDraftForDiscussion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifNoDraftForDiscussion
         * @function getTypeUrl
         * @memberof olvid.NotifNoDraftForDiscussion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifNoDraftForDiscussion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifNoDraftForDiscussion";
        };

        return NotifNoDraftForDiscussion;
    })();

    olvid.NotifFileAlreadyExists = (function() {

        /**
         * Properties of a NotifFileAlreadyExists.
         * @memberof olvid
         * @interface INotifFileAlreadyExists
         * @property {number|Long|null} [localId] NotifFileAlreadyExists localId
         * @property {number|Long|null} [discussionId] NotifFileAlreadyExists discussionId
         */

        /**
         * Constructs a new NotifFileAlreadyExists.
         * @memberof olvid
         * @classdesc Represents a NotifFileAlreadyExists.
         * @implements INotifFileAlreadyExists
         * @constructor
         * @param {olvid.INotifFileAlreadyExists=} [properties] Properties to set
         */
        function NotifFileAlreadyExists(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifFileAlreadyExists localId.
         * @member {number|Long} localId
         * @memberof olvid.NotifFileAlreadyExists
         * @instance
         */
        NotifFileAlreadyExists.prototype.localId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NotifFileAlreadyExists discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.NotifFileAlreadyExists
         * @instance
         */
        NotifFileAlreadyExists.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new NotifFileAlreadyExists instance using the specified properties.
         * @function create
         * @memberof olvid.NotifFileAlreadyExists
         * @static
         * @param {olvid.INotifFileAlreadyExists=} [properties] Properties to set
         * @returns {olvid.NotifFileAlreadyExists} NotifFileAlreadyExists instance
         */
        NotifFileAlreadyExists.create = function create(properties) {
            return new NotifFileAlreadyExists(properties);
        };

        /**
         * Encodes the specified NotifFileAlreadyExists message. Does not implicitly {@link olvid.NotifFileAlreadyExists.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifFileAlreadyExists
         * @static
         * @param {olvid.INotifFileAlreadyExists} message NotifFileAlreadyExists message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifFileAlreadyExists.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localId != null && Object.hasOwnProperty.call(message, "localId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.localId);
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.discussionId);
            return writer;
        };

        /**
         * Encodes the specified NotifFileAlreadyExists message, length delimited. Does not implicitly {@link olvid.NotifFileAlreadyExists.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifFileAlreadyExists
         * @static
         * @param {olvid.INotifFileAlreadyExists} message NotifFileAlreadyExists message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifFileAlreadyExists.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifFileAlreadyExists message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifFileAlreadyExists
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifFileAlreadyExists} NotifFileAlreadyExists
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifFileAlreadyExists.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifFileAlreadyExists();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localId = reader.int64();
                        break;
                    }
                case 2: {
                        message.discussionId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifFileAlreadyExists message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifFileAlreadyExists
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifFileAlreadyExists} NotifFileAlreadyExists
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifFileAlreadyExists.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifFileAlreadyExists message.
         * @function verify
         * @memberof olvid.NotifFileAlreadyExists
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifFileAlreadyExists.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (!$util.isInteger(message.localId) && !(message.localId && $util.isInteger(message.localId.low) && $util.isInteger(message.localId.high)))
                    return "localId: integer|Long expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            return null;
        };

        /**
         * Creates a NotifFileAlreadyExists message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifFileAlreadyExists
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifFileAlreadyExists} NotifFileAlreadyExists
         */
        NotifFileAlreadyExists.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifFileAlreadyExists)
                return object;
            var message = new $root.olvid.NotifFileAlreadyExists();
            if (object.localId != null)
                if ($util.Long)
                    (message.localId = $util.Long.fromValue(object.localId)).unsigned = false;
                else if (typeof object.localId === "string")
                    message.localId = parseInt(object.localId, 10);
                else if (typeof object.localId === "number")
                    message.localId = object.localId;
                else if (typeof object.localId === "object")
                    message.localId = new $util.LongBits(object.localId.low >>> 0, object.localId.high >>> 0).toNumber();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a NotifFileAlreadyExists message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifFileAlreadyExists
         * @static
         * @param {olvid.NotifFileAlreadyExists} message NotifFileAlreadyExists
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifFileAlreadyExists.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.localId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
            }
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (typeof message.localId === "number")
                    object.localId = options.longs === String ? String(message.localId) : message.localId;
                else
                    object.localId = options.longs === String ? $util.Long.prototype.toString.call(message.localId) : options.longs === Number ? new $util.LongBits(message.localId.low >>> 0, message.localId.high >>> 0).toNumber() : message.localId;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            return object;
        };

        /**
         * Converts this NotifFileAlreadyExists to JSON.
         * @function toJSON
         * @memberof olvid.NotifFileAlreadyExists
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifFileAlreadyExists.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifFileAlreadyExists
         * @function getTypeUrl
         * @memberof olvid.NotifFileAlreadyExists
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifFileAlreadyExists.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifFileAlreadyExists";
        };

        return NotifFileAlreadyExists;
    })();

    olvid.NotifFileAlreadyAttached = (function() {

        /**
         * Properties of a NotifFileAlreadyAttached.
         * @memberof olvid
         * @interface INotifFileAlreadyAttached
         * @property {number|Long|null} [localId] NotifFileAlreadyAttached localId
         * @property {number|Long|null} [discussionId] NotifFileAlreadyAttached discussionId
         */

        /**
         * Constructs a new NotifFileAlreadyAttached.
         * @memberof olvid
         * @classdesc Represents a NotifFileAlreadyAttached.
         * @implements INotifFileAlreadyAttached
         * @constructor
         * @param {olvid.INotifFileAlreadyAttached=} [properties] Properties to set
         */
        function NotifFileAlreadyAttached(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifFileAlreadyAttached localId.
         * @member {number|Long} localId
         * @memberof olvid.NotifFileAlreadyAttached
         * @instance
         */
        NotifFileAlreadyAttached.prototype.localId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NotifFileAlreadyAttached discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.NotifFileAlreadyAttached
         * @instance
         */
        NotifFileAlreadyAttached.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new NotifFileAlreadyAttached instance using the specified properties.
         * @function create
         * @memberof olvid.NotifFileAlreadyAttached
         * @static
         * @param {olvid.INotifFileAlreadyAttached=} [properties] Properties to set
         * @returns {olvid.NotifFileAlreadyAttached} NotifFileAlreadyAttached instance
         */
        NotifFileAlreadyAttached.create = function create(properties) {
            return new NotifFileAlreadyAttached(properties);
        };

        /**
         * Encodes the specified NotifFileAlreadyAttached message. Does not implicitly {@link olvid.NotifFileAlreadyAttached.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifFileAlreadyAttached
         * @static
         * @param {olvid.INotifFileAlreadyAttached} message NotifFileAlreadyAttached message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifFileAlreadyAttached.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localId != null && Object.hasOwnProperty.call(message, "localId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.localId);
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.discussionId);
            return writer;
        };

        /**
         * Encodes the specified NotifFileAlreadyAttached message, length delimited. Does not implicitly {@link olvid.NotifFileAlreadyAttached.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifFileAlreadyAttached
         * @static
         * @param {olvid.INotifFileAlreadyAttached} message NotifFileAlreadyAttached message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifFileAlreadyAttached.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifFileAlreadyAttached message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifFileAlreadyAttached
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifFileAlreadyAttached} NotifFileAlreadyAttached
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifFileAlreadyAttached.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifFileAlreadyAttached();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localId = reader.int64();
                        break;
                    }
                case 2: {
                        message.discussionId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifFileAlreadyAttached message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifFileAlreadyAttached
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifFileAlreadyAttached} NotifFileAlreadyAttached
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifFileAlreadyAttached.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifFileAlreadyAttached message.
         * @function verify
         * @memberof olvid.NotifFileAlreadyAttached
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifFileAlreadyAttached.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (!$util.isInteger(message.localId) && !(message.localId && $util.isInteger(message.localId.low) && $util.isInteger(message.localId.high)))
                    return "localId: integer|Long expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            return null;
        };

        /**
         * Creates a NotifFileAlreadyAttached message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifFileAlreadyAttached
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifFileAlreadyAttached} NotifFileAlreadyAttached
         */
        NotifFileAlreadyAttached.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifFileAlreadyAttached)
                return object;
            var message = new $root.olvid.NotifFileAlreadyAttached();
            if (object.localId != null)
                if ($util.Long)
                    (message.localId = $util.Long.fromValue(object.localId)).unsigned = false;
                else if (typeof object.localId === "string")
                    message.localId = parseInt(object.localId, 10);
                else if (typeof object.localId === "number")
                    message.localId = object.localId;
                else if (typeof object.localId === "object")
                    message.localId = new $util.LongBits(object.localId.low >>> 0, object.localId.high >>> 0).toNumber();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a NotifFileAlreadyAttached message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifFileAlreadyAttached
         * @static
         * @param {olvid.NotifFileAlreadyAttached} message NotifFileAlreadyAttached
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifFileAlreadyAttached.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.localId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
            }
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (typeof message.localId === "number")
                    object.localId = options.longs === String ? String(message.localId) : message.localId;
                else
                    object.localId = options.longs === String ? $util.Long.prototype.toString.call(message.localId) : options.longs === Number ? new $util.LongBits(message.localId.low >>> 0, message.localId.high >>> 0).toNumber() : message.localId;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            return object;
        };

        /**
         * Converts this NotifFileAlreadyAttached to JSON.
         * @function toJSON
         * @memberof olvid.NotifFileAlreadyAttached
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifFileAlreadyAttached.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifFileAlreadyAttached
         * @function getTypeUrl
         * @memberof olvid.NotifFileAlreadyAttached
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifFileAlreadyAttached.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifFileAlreadyAttached";
        };

        return NotifFileAlreadyAttached;
    })();

    olvid.NotifUploadFile = (function() {

        /**
         * Properties of a NotifUploadFile.
         * @memberof olvid
         * @interface INotifUploadFile
         * @property {number|Long|null} [localId] NotifUploadFile localId
         */

        /**
         * Constructs a new NotifUploadFile.
         * @memberof olvid
         * @classdesc Represents a NotifUploadFile.
         * @implements INotifUploadFile
         * @constructor
         * @param {olvid.INotifUploadFile=} [properties] Properties to set
         */
        function NotifUploadFile(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifUploadFile localId.
         * @member {number|Long} localId
         * @memberof olvid.NotifUploadFile
         * @instance
         */
        NotifUploadFile.prototype.localId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new NotifUploadFile instance using the specified properties.
         * @function create
         * @memberof olvid.NotifUploadFile
         * @static
         * @param {olvid.INotifUploadFile=} [properties] Properties to set
         * @returns {olvid.NotifUploadFile} NotifUploadFile instance
         */
        NotifUploadFile.create = function create(properties) {
            return new NotifUploadFile(properties);
        };

        /**
         * Encodes the specified NotifUploadFile message. Does not implicitly {@link olvid.NotifUploadFile.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifUploadFile
         * @static
         * @param {olvid.INotifUploadFile} message NotifUploadFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifUploadFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localId != null && Object.hasOwnProperty.call(message, "localId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.localId);
            return writer;
        };

        /**
         * Encodes the specified NotifUploadFile message, length delimited. Does not implicitly {@link olvid.NotifUploadFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifUploadFile
         * @static
         * @param {olvid.INotifUploadFile} message NotifUploadFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifUploadFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifUploadFile message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifUploadFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifUploadFile} NotifUploadFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifUploadFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifUploadFile();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifUploadFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifUploadFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifUploadFile} NotifUploadFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifUploadFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifUploadFile message.
         * @function verify
         * @memberof olvid.NotifUploadFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifUploadFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (!$util.isInteger(message.localId) && !(message.localId && $util.isInteger(message.localId.low) && $util.isInteger(message.localId.high)))
                    return "localId: integer|Long expected";
            return null;
        };

        /**
         * Creates a NotifUploadFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifUploadFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifUploadFile} NotifUploadFile
         */
        NotifUploadFile.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifUploadFile)
                return object;
            var message = new $root.olvid.NotifUploadFile();
            if (object.localId != null)
                if ($util.Long)
                    (message.localId = $util.Long.fromValue(object.localId)).unsigned = false;
                else if (typeof object.localId === "string")
                    message.localId = parseInt(object.localId, 10);
                else if (typeof object.localId === "number")
                    message.localId = object.localId;
                else if (typeof object.localId === "object")
                    message.localId = new $util.LongBits(object.localId.low >>> 0, object.localId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a NotifUploadFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifUploadFile
         * @static
         * @param {olvid.NotifUploadFile} message NotifUploadFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifUploadFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.localId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localId = options.longs === String ? "0" : 0;
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (typeof message.localId === "number")
                    object.localId = options.longs === String ? String(message.localId) : message.localId;
                else
                    object.localId = options.longs === String ? $util.Long.prototype.toString.call(message.localId) : options.longs === Number ? new $util.LongBits(message.localId.low >>> 0, message.localId.high >>> 0).toNumber() : message.localId;
            return object;
        };

        /**
         * Converts this NotifUploadFile to JSON.
         * @function toJSON
         * @memberof olvid.NotifUploadFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifUploadFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifUploadFile
         * @function getTypeUrl
         * @memberof olvid.NotifUploadFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifUploadFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifUploadFile";
        };

        return NotifUploadFile;
    })();

    olvid.NotifUploadResult = (function() {

        /**
         * Properties of a NotifUploadResult.
         * @memberof olvid
         * @interface INotifUploadResult
         * @property {number|Long|null} [localId] NotifUploadResult localId
         * @property {number|Long|null} [discussionId] NotifUploadResult discussionId
         * @property {number|Long|null} [resultCode] NotifUploadResult resultCode
         */

        /**
         * Constructs a new NotifUploadResult.
         * @memberof olvid
         * @classdesc Represents a NotifUploadResult.
         * @implements INotifUploadResult
         * @constructor
         * @param {olvid.INotifUploadResult=} [properties] Properties to set
         */
        function NotifUploadResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifUploadResult localId.
         * @member {number|Long} localId
         * @memberof olvid.NotifUploadResult
         * @instance
         */
        NotifUploadResult.prototype.localId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NotifUploadResult discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.NotifUploadResult
         * @instance
         */
        NotifUploadResult.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NotifUploadResult resultCode.
         * @member {number|Long} resultCode
         * @memberof olvid.NotifUploadResult
         * @instance
         */
        NotifUploadResult.prototype.resultCode = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new NotifUploadResult instance using the specified properties.
         * @function create
         * @memberof olvid.NotifUploadResult
         * @static
         * @param {olvid.INotifUploadResult=} [properties] Properties to set
         * @returns {olvid.NotifUploadResult} NotifUploadResult instance
         */
        NotifUploadResult.create = function create(properties) {
            return new NotifUploadResult(properties);
        };

        /**
         * Encodes the specified NotifUploadResult message. Does not implicitly {@link olvid.NotifUploadResult.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifUploadResult
         * @static
         * @param {olvid.INotifUploadResult} message NotifUploadResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifUploadResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localId != null && Object.hasOwnProperty.call(message, "localId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.localId);
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.discussionId);
            if (message.resultCode != null && Object.hasOwnProperty.call(message, "resultCode"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.resultCode);
            return writer;
        };

        /**
         * Encodes the specified NotifUploadResult message, length delimited. Does not implicitly {@link olvid.NotifUploadResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifUploadResult
         * @static
         * @param {olvid.INotifUploadResult} message NotifUploadResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifUploadResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifUploadResult message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifUploadResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifUploadResult} NotifUploadResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifUploadResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifUploadResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localId = reader.int64();
                        break;
                    }
                case 2: {
                        message.discussionId = reader.int64();
                        break;
                    }
                case 3: {
                        message.resultCode = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifUploadResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifUploadResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifUploadResult} NotifUploadResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifUploadResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifUploadResult message.
         * @function verify
         * @memberof olvid.NotifUploadResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifUploadResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (!$util.isInteger(message.localId) && !(message.localId && $util.isInteger(message.localId.low) && $util.isInteger(message.localId.high)))
                    return "localId: integer|Long expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            if (message.resultCode != null && message.hasOwnProperty("resultCode"))
                if (!$util.isInteger(message.resultCode) && !(message.resultCode && $util.isInteger(message.resultCode.low) && $util.isInteger(message.resultCode.high)))
                    return "resultCode: integer|Long expected";
            return null;
        };

        /**
         * Creates a NotifUploadResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifUploadResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifUploadResult} NotifUploadResult
         */
        NotifUploadResult.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifUploadResult)
                return object;
            var message = new $root.olvid.NotifUploadResult();
            if (object.localId != null)
                if ($util.Long)
                    (message.localId = $util.Long.fromValue(object.localId)).unsigned = false;
                else if (typeof object.localId === "string")
                    message.localId = parseInt(object.localId, 10);
                else if (typeof object.localId === "number")
                    message.localId = object.localId;
                else if (typeof object.localId === "object")
                    message.localId = new $util.LongBits(object.localId.low >>> 0, object.localId.high >>> 0).toNumber();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            if (object.resultCode != null)
                if ($util.Long)
                    (message.resultCode = $util.Long.fromValue(object.resultCode)).unsigned = false;
                else if (typeof object.resultCode === "string")
                    message.resultCode = parseInt(object.resultCode, 10);
                else if (typeof object.resultCode === "number")
                    message.resultCode = object.resultCode;
                else if (typeof object.resultCode === "object")
                    message.resultCode = new $util.LongBits(object.resultCode.low >>> 0, object.resultCode.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a NotifUploadResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifUploadResult
         * @static
         * @param {olvid.NotifUploadResult} message NotifUploadResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifUploadResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.localId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.resultCode = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.resultCode = options.longs === String ? "0" : 0;
            }
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (typeof message.localId === "number")
                    object.localId = options.longs === String ? String(message.localId) : message.localId;
                else
                    object.localId = options.longs === String ? $util.Long.prototype.toString.call(message.localId) : options.longs === Number ? new $util.LongBits(message.localId.low >>> 0, message.localId.high >>> 0).toNumber() : message.localId;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            if (message.resultCode != null && message.hasOwnProperty("resultCode"))
                if (typeof message.resultCode === "number")
                    object.resultCode = options.longs === String ? String(message.resultCode) : message.resultCode;
                else
                    object.resultCode = options.longs === String ? $util.Long.prototype.toString.call(message.resultCode) : options.longs === Number ? new $util.LongBits(message.resultCode.low >>> 0, message.resultCode.high >>> 0).toNumber() : message.resultCode;
            return object;
        };

        /**
         * Converts this NotifUploadResult to JSON.
         * @function toJSON
         * @memberof olvid.NotifUploadResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifUploadResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifUploadResult
         * @function getTypeUrl
         * @memberof olvid.NotifUploadResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifUploadResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifUploadResult";
        };

        return NotifUploadResult;
    })();

    olvid.NotifDeleteAttachment = (function() {

        /**
         * Properties of a NotifDeleteAttachment.
         * @memberof olvid
         * @interface INotifDeleteAttachment
         * @property {olvid.IAttachment|null} [attachment] NotifDeleteAttachment attachment
         */

        /**
         * Constructs a new NotifDeleteAttachment.
         * @memberof olvid
         * @classdesc Represents a NotifDeleteAttachment.
         * @implements INotifDeleteAttachment
         * @constructor
         * @param {olvid.INotifDeleteAttachment=} [properties] Properties to set
         */
        function NotifDeleteAttachment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifDeleteAttachment attachment.
         * @member {olvid.IAttachment|null|undefined} attachment
         * @memberof olvid.NotifDeleteAttachment
         * @instance
         */
        NotifDeleteAttachment.prototype.attachment = null;

        /**
         * Creates a new NotifDeleteAttachment instance using the specified properties.
         * @function create
         * @memberof olvid.NotifDeleteAttachment
         * @static
         * @param {olvid.INotifDeleteAttachment=} [properties] Properties to set
         * @returns {olvid.NotifDeleteAttachment} NotifDeleteAttachment instance
         */
        NotifDeleteAttachment.create = function create(properties) {
            return new NotifDeleteAttachment(properties);
        };

        /**
         * Encodes the specified NotifDeleteAttachment message. Does not implicitly {@link olvid.NotifDeleteAttachment.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifDeleteAttachment
         * @static
         * @param {olvid.INotifDeleteAttachment} message NotifDeleteAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifDeleteAttachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.attachment != null && Object.hasOwnProperty.call(message, "attachment"))
                $root.olvid.Attachment.encode(message.attachment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifDeleteAttachment message, length delimited. Does not implicitly {@link olvid.NotifDeleteAttachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifDeleteAttachment
         * @static
         * @param {olvid.INotifDeleteAttachment} message NotifDeleteAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifDeleteAttachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifDeleteAttachment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifDeleteAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifDeleteAttachment} NotifDeleteAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifDeleteAttachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifDeleteAttachment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.attachment = $root.olvid.Attachment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifDeleteAttachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifDeleteAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifDeleteAttachment} NotifDeleteAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifDeleteAttachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifDeleteAttachment message.
         * @function verify
         * @memberof olvid.NotifDeleteAttachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifDeleteAttachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.attachment != null && message.hasOwnProperty("attachment")) {
                var error = $root.olvid.Attachment.verify(message.attachment);
                if (error)
                    return "attachment." + error;
            }
            return null;
        };

        /**
         * Creates a NotifDeleteAttachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifDeleteAttachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifDeleteAttachment} NotifDeleteAttachment
         */
        NotifDeleteAttachment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifDeleteAttachment)
                return object;
            var message = new $root.olvid.NotifDeleteAttachment();
            if (object.attachment != null) {
                if (typeof object.attachment !== "object")
                    throw TypeError(".olvid.NotifDeleteAttachment.attachment: object expected");
                message.attachment = $root.olvid.Attachment.fromObject(object.attachment);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifDeleteAttachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifDeleteAttachment
         * @static
         * @param {olvid.NotifDeleteAttachment} message NotifDeleteAttachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifDeleteAttachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.attachment = null;
            if (message.attachment != null && message.hasOwnProperty("attachment"))
                object.attachment = $root.olvid.Attachment.toObject(message.attachment, options);
            return object;
        };

        /**
         * Converts this NotifDeleteAttachment to JSON.
         * @function toJSON
         * @memberof olvid.NotifDeleteAttachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifDeleteAttachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifDeleteAttachment
         * @function getTypeUrl
         * @memberof olvid.NotifDeleteAttachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifDeleteAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifDeleteAttachment";
        };

        return NotifDeleteAttachment;
    })();

    olvid.NotifDeleteDiscussion = (function() {

        /**
         * Properties of a NotifDeleteDiscussion.
         * @memberof olvid
         * @interface INotifDeleteDiscussion
         * @property {olvid.IDiscussion|null} [discussion] NotifDeleteDiscussion discussion
         */

        /**
         * Constructs a new NotifDeleteDiscussion.
         * @memberof olvid
         * @classdesc Represents a NotifDeleteDiscussion.
         * @implements INotifDeleteDiscussion
         * @constructor
         * @param {olvid.INotifDeleteDiscussion=} [properties] Properties to set
         */
        function NotifDeleteDiscussion(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifDeleteDiscussion discussion.
         * @member {olvid.IDiscussion|null|undefined} discussion
         * @memberof olvid.NotifDeleteDiscussion
         * @instance
         */
        NotifDeleteDiscussion.prototype.discussion = null;

        /**
         * Creates a new NotifDeleteDiscussion instance using the specified properties.
         * @function create
         * @memberof olvid.NotifDeleteDiscussion
         * @static
         * @param {olvid.INotifDeleteDiscussion=} [properties] Properties to set
         * @returns {olvid.NotifDeleteDiscussion} NotifDeleteDiscussion instance
         */
        NotifDeleteDiscussion.create = function create(properties) {
            return new NotifDeleteDiscussion(properties);
        };

        /**
         * Encodes the specified NotifDeleteDiscussion message. Does not implicitly {@link olvid.NotifDeleteDiscussion.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifDeleteDiscussion
         * @static
         * @param {olvid.INotifDeleteDiscussion} message NotifDeleteDiscussion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifDeleteDiscussion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussion != null && Object.hasOwnProperty.call(message, "discussion"))
                $root.olvid.Discussion.encode(message.discussion, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifDeleteDiscussion message, length delimited. Does not implicitly {@link olvid.NotifDeleteDiscussion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifDeleteDiscussion
         * @static
         * @param {olvid.INotifDeleteDiscussion} message NotifDeleteDiscussion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifDeleteDiscussion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifDeleteDiscussion message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifDeleteDiscussion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifDeleteDiscussion} NotifDeleteDiscussion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifDeleteDiscussion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifDeleteDiscussion();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.discussion = $root.olvid.Discussion.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifDeleteDiscussion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifDeleteDiscussion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifDeleteDiscussion} NotifDeleteDiscussion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifDeleteDiscussion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifDeleteDiscussion message.
         * @function verify
         * @memberof olvid.NotifDeleteDiscussion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifDeleteDiscussion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussion != null && message.hasOwnProperty("discussion")) {
                var error = $root.olvid.Discussion.verify(message.discussion);
                if (error)
                    return "discussion." + error;
            }
            return null;
        };

        /**
         * Creates a NotifDeleteDiscussion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifDeleteDiscussion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifDeleteDiscussion} NotifDeleteDiscussion
         */
        NotifDeleteDiscussion.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifDeleteDiscussion)
                return object;
            var message = new $root.olvid.NotifDeleteDiscussion();
            if (object.discussion != null) {
                if (typeof object.discussion !== "object")
                    throw TypeError(".olvid.NotifDeleteDiscussion.discussion: object expected");
                message.discussion = $root.olvid.Discussion.fromObject(object.discussion);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifDeleteDiscussion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifDeleteDiscussion
         * @static
         * @param {olvid.NotifDeleteDiscussion} message NotifDeleteDiscussion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifDeleteDiscussion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.discussion = null;
            if (message.discussion != null && message.hasOwnProperty("discussion"))
                object.discussion = $root.olvid.Discussion.toObject(message.discussion, options);
            return object;
        };

        /**
         * Converts this NotifDeleteDiscussion to JSON.
         * @function toJSON
         * @memberof olvid.NotifDeleteDiscussion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifDeleteDiscussion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifDeleteDiscussion
         * @function getTypeUrl
         * @memberof olvid.NotifDeleteDiscussion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifDeleteDiscussion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifDeleteDiscussion";
        };

        return NotifDeleteDiscussion;
    })();

    olvid.NotifUpdateDraftAttachment = (function() {

        /**
         * Properties of a NotifUpdateDraftAttachment.
         * @memberof olvid
         * @interface INotifUpdateDraftAttachment
         * @property {olvid.IDraftAttachment|null} [draftAttachment] NotifUpdateDraftAttachment draftAttachment
         */

        /**
         * Constructs a new NotifUpdateDraftAttachment.
         * @memberof olvid
         * @classdesc Represents a NotifUpdateDraftAttachment.
         * @implements INotifUpdateDraftAttachment
         * @constructor
         * @param {olvid.INotifUpdateDraftAttachment=} [properties] Properties to set
         */
        function NotifUpdateDraftAttachment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifUpdateDraftAttachment draftAttachment.
         * @member {olvid.IDraftAttachment|null|undefined} draftAttachment
         * @memberof olvid.NotifUpdateDraftAttachment
         * @instance
         */
        NotifUpdateDraftAttachment.prototype.draftAttachment = null;

        /**
         * Creates a new NotifUpdateDraftAttachment instance using the specified properties.
         * @function create
         * @memberof olvid.NotifUpdateDraftAttachment
         * @static
         * @param {olvid.INotifUpdateDraftAttachment=} [properties] Properties to set
         * @returns {olvid.NotifUpdateDraftAttachment} NotifUpdateDraftAttachment instance
         */
        NotifUpdateDraftAttachment.create = function create(properties) {
            return new NotifUpdateDraftAttachment(properties);
        };

        /**
         * Encodes the specified NotifUpdateDraftAttachment message. Does not implicitly {@link olvid.NotifUpdateDraftAttachment.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifUpdateDraftAttachment
         * @static
         * @param {olvid.INotifUpdateDraftAttachment} message NotifUpdateDraftAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifUpdateDraftAttachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.draftAttachment != null && Object.hasOwnProperty.call(message, "draftAttachment"))
                $root.olvid.DraftAttachment.encode(message.draftAttachment, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NotifUpdateDraftAttachment message, length delimited. Does not implicitly {@link olvid.NotifUpdateDraftAttachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifUpdateDraftAttachment
         * @static
         * @param {olvid.INotifUpdateDraftAttachment} message NotifUpdateDraftAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifUpdateDraftAttachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifUpdateDraftAttachment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifUpdateDraftAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifUpdateDraftAttachment} NotifUpdateDraftAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifUpdateDraftAttachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifUpdateDraftAttachment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.draftAttachment = $root.olvid.DraftAttachment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifUpdateDraftAttachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifUpdateDraftAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifUpdateDraftAttachment} NotifUpdateDraftAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifUpdateDraftAttachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifUpdateDraftAttachment message.
         * @function verify
         * @memberof olvid.NotifUpdateDraftAttachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifUpdateDraftAttachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.draftAttachment != null && message.hasOwnProperty("draftAttachment")) {
                var error = $root.olvid.DraftAttachment.verify(message.draftAttachment);
                if (error)
                    return "draftAttachment." + error;
            }
            return null;
        };

        /**
         * Creates a NotifUpdateDraftAttachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifUpdateDraftAttachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifUpdateDraftAttachment} NotifUpdateDraftAttachment
         */
        NotifUpdateDraftAttachment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifUpdateDraftAttachment)
                return object;
            var message = new $root.olvid.NotifUpdateDraftAttachment();
            if (object.draftAttachment != null) {
                if (typeof object.draftAttachment !== "object")
                    throw TypeError(".olvid.NotifUpdateDraftAttachment.draftAttachment: object expected");
                message.draftAttachment = $root.olvid.DraftAttachment.fromObject(object.draftAttachment);
            }
            return message;
        };

        /**
         * Creates a plain object from a NotifUpdateDraftAttachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifUpdateDraftAttachment
         * @static
         * @param {olvid.NotifUpdateDraftAttachment} message NotifUpdateDraftAttachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifUpdateDraftAttachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.draftAttachment = null;
            if (message.draftAttachment != null && message.hasOwnProperty("draftAttachment"))
                object.draftAttachment = $root.olvid.DraftAttachment.toObject(message.draftAttachment, options);
            return object;
        };

        /**
         * Converts this NotifUpdateDraftAttachment to JSON.
         * @function toJSON
         * @memberof olvid.NotifUpdateDraftAttachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifUpdateDraftAttachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifUpdateDraftAttachment
         * @function getTypeUrl
         * @memberof olvid.NotifUpdateDraftAttachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifUpdateDraftAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifUpdateDraftAttachment";
        };

        return NotifUpdateDraftAttachment;
    })();

    olvid.CreateMessage = (function() {

        /**
         * Properties of a CreateMessage.
         * @memberof olvid
         * @interface ICreateMessage
         * @property {number|Long|null} [discussionId] CreateMessage discussionId
         * @property {string|null} [content] CreateMessage content
         * @property {number|Long|null} [localId] CreateMessage localId
         * @property {number|Long|null} [replyMessageId] CreateMessage replyMessageId
         */

        /**
         * Constructs a new CreateMessage.
         * @memberof olvid
         * @classdesc Represents a CreateMessage.
         * @implements ICreateMessage
         * @constructor
         * @param {olvid.ICreateMessage=} [properties] Properties to set
         */
        function CreateMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateMessage discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.CreateMessage
         * @instance
         */
        CreateMessage.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CreateMessage content.
         * @member {string} content
         * @memberof olvid.CreateMessage
         * @instance
         */
        CreateMessage.prototype.content = "";

        /**
         * CreateMessage localId.
         * @member {number|Long} localId
         * @memberof olvid.CreateMessage
         * @instance
         */
        CreateMessage.prototype.localId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CreateMessage replyMessageId.
         * @member {number|Long} replyMessageId
         * @memberof olvid.CreateMessage
         * @instance
         */
        CreateMessage.prototype.replyMessageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new CreateMessage instance using the specified properties.
         * @function create
         * @memberof olvid.CreateMessage
         * @static
         * @param {olvid.ICreateMessage=} [properties] Properties to set
         * @returns {olvid.CreateMessage} CreateMessage instance
         */
        CreateMessage.create = function create(properties) {
            return new CreateMessage(properties);
        };

        /**
         * Encodes the specified CreateMessage message. Does not implicitly {@link olvid.CreateMessage.verify|verify} messages.
         * @function encode
         * @memberof olvid.CreateMessage
         * @static
         * @param {olvid.ICreateMessage} message CreateMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.discussionId);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
            if (message.localId != null && Object.hasOwnProperty.call(message, "localId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.localId);
            if (message.replyMessageId != null && Object.hasOwnProperty.call(message, "replyMessageId"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.replyMessageId);
            return writer;
        };

        /**
         * Encodes the specified CreateMessage message, length delimited. Does not implicitly {@link olvid.CreateMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.CreateMessage
         * @static
         * @param {olvid.ICreateMessage} message CreateMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateMessage message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.CreateMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.CreateMessage} CreateMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.CreateMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.discussionId = reader.int64();
                        break;
                    }
                case 2: {
                        message.content = reader.string();
                        break;
                    }
                case 3: {
                        message.localId = reader.int64();
                        break;
                    }
                case 4: {
                        message.replyMessageId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.CreateMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.CreateMessage} CreateMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateMessage message.
         * @function verify
         * @memberof olvid.CreateMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (!$util.isInteger(message.localId) && !(message.localId && $util.isInteger(message.localId.low) && $util.isInteger(message.localId.high)))
                    return "localId: integer|Long expected";
            if (message.replyMessageId != null && message.hasOwnProperty("replyMessageId"))
                if (!$util.isInteger(message.replyMessageId) && !(message.replyMessageId && $util.isInteger(message.replyMessageId.low) && $util.isInteger(message.replyMessageId.high)))
                    return "replyMessageId: integer|Long expected";
            return null;
        };

        /**
         * Creates a CreateMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.CreateMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.CreateMessage} CreateMessage
         */
        CreateMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.CreateMessage)
                return object;
            var message = new $root.olvid.CreateMessage();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            if (object.content != null)
                message.content = String(object.content);
            if (object.localId != null)
                if ($util.Long)
                    (message.localId = $util.Long.fromValue(object.localId)).unsigned = false;
                else if (typeof object.localId === "string")
                    message.localId = parseInt(object.localId, 10);
                else if (typeof object.localId === "number")
                    message.localId = object.localId;
                else if (typeof object.localId === "object")
                    message.localId = new $util.LongBits(object.localId.low >>> 0, object.localId.high >>> 0).toNumber();
            if (object.replyMessageId != null)
                if ($util.Long)
                    (message.replyMessageId = $util.Long.fromValue(object.replyMessageId)).unsigned = false;
                else if (typeof object.replyMessageId === "string")
                    message.replyMessageId = parseInt(object.replyMessageId, 10);
                else if (typeof object.replyMessageId === "number")
                    message.replyMessageId = object.replyMessageId;
                else if (typeof object.replyMessageId === "object")
                    message.replyMessageId = new $util.LongBits(object.replyMessageId.low >>> 0, object.replyMessageId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a CreateMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.CreateMessage
         * @static
         * @param {olvid.CreateMessage} message CreateMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
                object.content = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.localId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.replyMessageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.replyMessageId = options.longs === String ? "0" : 0;
            }
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (typeof message.localId === "number")
                    object.localId = options.longs === String ? String(message.localId) : message.localId;
                else
                    object.localId = options.longs === String ? $util.Long.prototype.toString.call(message.localId) : options.longs === Number ? new $util.LongBits(message.localId.low >>> 0, message.localId.high >>> 0).toNumber() : message.localId;
            if (message.replyMessageId != null && message.hasOwnProperty("replyMessageId"))
                if (typeof message.replyMessageId === "number")
                    object.replyMessageId = options.longs === String ? String(message.replyMessageId) : message.replyMessageId;
                else
                    object.replyMessageId = options.longs === String ? $util.Long.prototype.toString.call(message.replyMessageId) : options.longs === Number ? new $util.LongBits(message.replyMessageId.low >>> 0, message.replyMessageId.high >>> 0).toNumber() : message.replyMessageId;
            return object;
        };

        /**
         * Converts this CreateMessage to JSON.
         * @function toJSON
         * @memberof olvid.CreateMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CreateMessage
         * @function getTypeUrl
         * @memberof olvid.CreateMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.CreateMessage";
        };

        return CreateMessage;
    })();

    olvid.NotifMessageSent = (function() {

        /**
         * Properties of a NotifMessageSent.
         * @memberof olvid
         * @interface INotifMessageSent
         * @property {number|Long|null} [localId] NotifMessageSent localId
         */

        /**
         * Constructs a new NotifMessageSent.
         * @memberof olvid
         * @classdesc Represents a NotifMessageSent.
         * @implements INotifMessageSent
         * @constructor
         * @param {olvid.INotifMessageSent=} [properties] Properties to set
         */
        function NotifMessageSent(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NotifMessageSent localId.
         * @member {number|Long} localId
         * @memberof olvid.NotifMessageSent
         * @instance
         */
        NotifMessageSent.prototype.localId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new NotifMessageSent instance using the specified properties.
         * @function create
         * @memberof olvid.NotifMessageSent
         * @static
         * @param {olvid.INotifMessageSent=} [properties] Properties to set
         * @returns {olvid.NotifMessageSent} NotifMessageSent instance
         */
        NotifMessageSent.create = function create(properties) {
            return new NotifMessageSent(properties);
        };

        /**
         * Encodes the specified NotifMessageSent message. Does not implicitly {@link olvid.NotifMessageSent.verify|verify} messages.
         * @function encode
         * @memberof olvid.NotifMessageSent
         * @static
         * @param {olvid.INotifMessageSent} message NotifMessageSent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifMessageSent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localId != null && Object.hasOwnProperty.call(message, "localId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.localId);
            return writer;
        };

        /**
         * Encodes the specified NotifMessageSent message, length delimited. Does not implicitly {@link olvid.NotifMessageSent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.NotifMessageSent
         * @static
         * @param {olvid.INotifMessageSent} message NotifMessageSent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NotifMessageSent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NotifMessageSent message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.NotifMessageSent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.NotifMessageSent} NotifMessageSent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifMessageSent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.NotifMessageSent();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NotifMessageSent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.NotifMessageSent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.NotifMessageSent} NotifMessageSent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NotifMessageSent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NotifMessageSent message.
         * @function verify
         * @memberof olvid.NotifMessageSent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NotifMessageSent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (!$util.isInteger(message.localId) && !(message.localId && $util.isInteger(message.localId.low) && $util.isInteger(message.localId.high)))
                    return "localId: integer|Long expected";
            return null;
        };

        /**
         * Creates a NotifMessageSent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.NotifMessageSent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.NotifMessageSent} NotifMessageSent
         */
        NotifMessageSent.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.NotifMessageSent)
                return object;
            var message = new $root.olvid.NotifMessageSent();
            if (object.localId != null)
                if ($util.Long)
                    (message.localId = $util.Long.fromValue(object.localId)).unsigned = false;
                else if (typeof object.localId === "string")
                    message.localId = parseInt(object.localId, 10);
                else if (typeof object.localId === "number")
                    message.localId = object.localId;
                else if (typeof object.localId === "object")
                    message.localId = new $util.LongBits(object.localId.low >>> 0, object.localId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a NotifMessageSent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.NotifMessageSent
         * @static
         * @param {olvid.NotifMessageSent} message NotifMessageSent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NotifMessageSent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.localId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localId = options.longs === String ? "0" : 0;
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (typeof message.localId === "number")
                    object.localId = options.longs === String ? String(message.localId) : message.localId;
                else
                    object.localId = options.longs === String ? $util.Long.prototype.toString.call(message.localId) : options.longs === Number ? new $util.LongBits(message.localId.low >>> 0, message.localId.high >>> 0).toNumber() : message.localId;
            return object;
        };

        /**
         * Converts this NotifMessageSent to JSON.
         * @function toJSON
         * @memberof olvid.NotifMessageSent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NotifMessageSent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NotifMessageSent
         * @function getTypeUrl
         * @memberof olvid.NotifMessageSent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NotifMessageSent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.NotifMessageSent";
        };

        return NotifMessageSent;
    })();

    olvid.SendAttachmentNotice = (function() {

        /**
         * Properties of a SendAttachmentNotice.
         * @memberof olvid
         * @interface ISendAttachmentNotice
         * @property {number|Long|null} [localId] SendAttachmentNotice localId
         * @property {Uint8Array|null} [sha256] SendAttachmentNotice sha256
         * @property {number|Long|null} [size] SendAttachmentNotice size
         * @property {number|Long|null} [numberChunks] SendAttachmentNotice numberChunks
         * @property {string|null} [type] SendAttachmentNotice type
         * @property {string|null} [fileName] SendAttachmentNotice fileName
         * @property {number|Long|null} [discussionId] SendAttachmentNotice discussionId
         */

        /**
         * Constructs a new SendAttachmentNotice.
         * @memberof olvid
         * @classdesc Represents a SendAttachmentNotice.
         * @implements ISendAttachmentNotice
         * @constructor
         * @param {olvid.ISendAttachmentNotice=} [properties] Properties to set
         */
        function SendAttachmentNotice(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendAttachmentNotice localId.
         * @member {number|Long} localId
         * @memberof olvid.SendAttachmentNotice
         * @instance
         */
        SendAttachmentNotice.prototype.localId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendAttachmentNotice sha256.
         * @member {Uint8Array} sha256
         * @memberof olvid.SendAttachmentNotice
         * @instance
         */
        SendAttachmentNotice.prototype.sha256 = $util.newBuffer([]);

        /**
         * SendAttachmentNotice size.
         * @member {number|Long} size
         * @memberof olvid.SendAttachmentNotice
         * @instance
         */
        SendAttachmentNotice.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendAttachmentNotice numberChunks.
         * @member {number|Long} numberChunks
         * @memberof olvid.SendAttachmentNotice
         * @instance
         */
        SendAttachmentNotice.prototype.numberChunks = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendAttachmentNotice type.
         * @member {string} type
         * @memberof olvid.SendAttachmentNotice
         * @instance
         */
        SendAttachmentNotice.prototype.type = "";

        /**
         * SendAttachmentNotice fileName.
         * @member {string} fileName
         * @memberof olvid.SendAttachmentNotice
         * @instance
         */
        SendAttachmentNotice.prototype.fileName = "";

        /**
         * SendAttachmentNotice discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.SendAttachmentNotice
         * @instance
         */
        SendAttachmentNotice.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SendAttachmentNotice instance using the specified properties.
         * @function create
         * @memberof olvid.SendAttachmentNotice
         * @static
         * @param {olvid.ISendAttachmentNotice=} [properties] Properties to set
         * @returns {olvid.SendAttachmentNotice} SendAttachmentNotice instance
         */
        SendAttachmentNotice.create = function create(properties) {
            return new SendAttachmentNotice(properties);
        };

        /**
         * Encodes the specified SendAttachmentNotice message. Does not implicitly {@link olvid.SendAttachmentNotice.verify|verify} messages.
         * @function encode
         * @memberof olvid.SendAttachmentNotice
         * @static
         * @param {olvid.ISendAttachmentNotice} message SendAttachmentNotice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendAttachmentNotice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localId != null && Object.hasOwnProperty.call(message, "localId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.localId);
            if (message.sha256 != null && Object.hasOwnProperty.call(message, "sha256"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.sha256);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.size);
            if (message.numberChunks != null && Object.hasOwnProperty.call(message, "numberChunks"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.numberChunks);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.type);
            if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.fileName);
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.discussionId);
            return writer;
        };

        /**
         * Encodes the specified SendAttachmentNotice message, length delimited. Does not implicitly {@link olvid.SendAttachmentNotice.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.SendAttachmentNotice
         * @static
         * @param {olvid.ISendAttachmentNotice} message SendAttachmentNotice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendAttachmentNotice.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendAttachmentNotice message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.SendAttachmentNotice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.SendAttachmentNotice} SendAttachmentNotice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendAttachmentNotice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.SendAttachmentNotice();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localId = reader.int64();
                        break;
                    }
                case 2: {
                        message.sha256 = reader.bytes();
                        break;
                    }
                case 3: {
                        message.size = reader.int64();
                        break;
                    }
                case 4: {
                        message.numberChunks = reader.int64();
                        break;
                    }
                case 5: {
                        message.type = reader.string();
                        break;
                    }
                case 6: {
                        message.fileName = reader.string();
                        break;
                    }
                case 7: {
                        message.discussionId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendAttachmentNotice message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.SendAttachmentNotice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.SendAttachmentNotice} SendAttachmentNotice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendAttachmentNotice.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendAttachmentNotice message.
         * @function verify
         * @memberof olvid.SendAttachmentNotice
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendAttachmentNotice.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (!$util.isInteger(message.localId) && !(message.localId && $util.isInteger(message.localId.low) && $util.isInteger(message.localId.high)))
                    return "localId: integer|Long expected";
            if (message.sha256 != null && message.hasOwnProperty("sha256"))
                if (!(message.sha256 && typeof message.sha256.length === "number" || $util.isString(message.sha256)))
                    return "sha256: buffer expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            if (message.numberChunks != null && message.hasOwnProperty("numberChunks"))
                if (!$util.isInteger(message.numberChunks) && !(message.numberChunks && $util.isInteger(message.numberChunks.low) && $util.isInteger(message.numberChunks.high)))
                    return "numberChunks: integer|Long expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isString(message.type))
                    return "type: string expected";
            if (message.fileName != null && message.hasOwnProperty("fileName"))
                if (!$util.isString(message.fileName))
                    return "fileName: string expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            return null;
        };

        /**
         * Creates a SendAttachmentNotice message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.SendAttachmentNotice
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.SendAttachmentNotice} SendAttachmentNotice
         */
        SendAttachmentNotice.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.SendAttachmentNotice)
                return object;
            var message = new $root.olvid.SendAttachmentNotice();
            if (object.localId != null)
                if ($util.Long)
                    (message.localId = $util.Long.fromValue(object.localId)).unsigned = false;
                else if (typeof object.localId === "string")
                    message.localId = parseInt(object.localId, 10);
                else if (typeof object.localId === "number")
                    message.localId = object.localId;
                else if (typeof object.localId === "object")
                    message.localId = new $util.LongBits(object.localId.low >>> 0, object.localId.high >>> 0).toNumber();
            if (object.sha256 != null)
                if (typeof object.sha256 === "string")
                    $util.base64.decode(object.sha256, message.sha256 = $util.newBuffer($util.base64.length(object.sha256)), 0);
                else if (object.sha256.length >= 0)
                    message.sha256 = object.sha256;
            if (object.size != null)
                if ($util.Long)
                    (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                else if (typeof object.size === "string")
                    message.size = parseInt(object.size, 10);
                else if (typeof object.size === "number")
                    message.size = object.size;
                else if (typeof object.size === "object")
                    message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
            if (object.numberChunks != null)
                if ($util.Long)
                    (message.numberChunks = $util.Long.fromValue(object.numberChunks)).unsigned = false;
                else if (typeof object.numberChunks === "string")
                    message.numberChunks = parseInt(object.numberChunks, 10);
                else if (typeof object.numberChunks === "number")
                    message.numberChunks = object.numberChunks;
                else if (typeof object.numberChunks === "object")
                    message.numberChunks = new $util.LongBits(object.numberChunks.low >>> 0, object.numberChunks.high >>> 0).toNumber();
            if (object.type != null)
                message.type = String(object.type);
            if (object.fileName != null)
                message.fileName = String(object.fileName);
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a SendAttachmentNotice message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.SendAttachmentNotice
         * @static
         * @param {olvid.SendAttachmentNotice} message SendAttachmentNotice
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendAttachmentNotice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.localId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localId = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.sha256 = "";
                else {
                    object.sha256 = [];
                    if (options.bytes !== Array)
                        object.sha256 = $util.newBuffer(object.sha256);
                }
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.size = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.numberChunks = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.numberChunks = options.longs === String ? "0" : 0;
                object.type = "";
                object.fileName = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
            }
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (typeof message.localId === "number")
                    object.localId = options.longs === String ? String(message.localId) : message.localId;
                else
                    object.localId = options.longs === String ? $util.Long.prototype.toString.call(message.localId) : options.longs === Number ? new $util.LongBits(message.localId.low >>> 0, message.localId.high >>> 0).toNumber() : message.localId;
            if (message.sha256 != null && message.hasOwnProperty("sha256"))
                object.sha256 = options.bytes === String ? $util.base64.encode(message.sha256, 0, message.sha256.length) : options.bytes === Array ? Array.prototype.slice.call(message.sha256) : message.sha256;
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size === "number")
                    object.size = options.longs === String ? String(message.size) : message.size;
                else
                    object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
            if (message.numberChunks != null && message.hasOwnProperty("numberChunks"))
                if (typeof message.numberChunks === "number")
                    object.numberChunks = options.longs === String ? String(message.numberChunks) : message.numberChunks;
                else
                    object.numberChunks = options.longs === String ? $util.Long.prototype.toString.call(message.numberChunks) : options.longs === Number ? new $util.LongBits(message.numberChunks.low >>> 0, message.numberChunks.high >>> 0).toNumber() : message.numberChunks;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.fileName != null && message.hasOwnProperty("fileName"))
                object.fileName = message.fileName;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            return object;
        };

        /**
         * Converts this SendAttachmentNotice to JSON.
         * @function toJSON
         * @memberof olvid.SendAttachmentNotice
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendAttachmentNotice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SendAttachmentNotice
         * @function getTypeUrl
         * @memberof olvid.SendAttachmentNotice
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SendAttachmentNotice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.SendAttachmentNotice";
        };

        return SendAttachmentNotice;
    })();

    olvid.SendAttachmentChunk = (function() {

        /**
         * Properties of a SendAttachmentChunk.
         * @memberof olvid
         * @interface ISendAttachmentChunk
         * @property {number|Long|null} [localId] SendAttachmentChunk localId
         * @property {number|Long|null} [offset] SendAttachmentChunk offset
         * @property {number|Long|null} [chunkNumber] SendAttachmentChunk chunkNumber
         * @property {Uint8Array|null} [chunk] SendAttachmentChunk chunk
         */

        /**
         * Constructs a new SendAttachmentChunk.
         * @memberof olvid
         * @classdesc Represents a SendAttachmentChunk.
         * @implements ISendAttachmentChunk
         * @constructor
         * @param {olvid.ISendAttachmentChunk=} [properties] Properties to set
         */
        function SendAttachmentChunk(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendAttachmentChunk localId.
         * @member {number|Long} localId
         * @memberof olvid.SendAttachmentChunk
         * @instance
         */
        SendAttachmentChunk.prototype.localId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendAttachmentChunk offset.
         * @member {number|Long} offset
         * @memberof olvid.SendAttachmentChunk
         * @instance
         */
        SendAttachmentChunk.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendAttachmentChunk chunkNumber.
         * @member {number|Long} chunkNumber
         * @memberof olvid.SendAttachmentChunk
         * @instance
         */
        SendAttachmentChunk.prototype.chunkNumber = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendAttachmentChunk chunk.
         * @member {Uint8Array} chunk
         * @memberof olvid.SendAttachmentChunk
         * @instance
         */
        SendAttachmentChunk.prototype.chunk = $util.newBuffer([]);

        /**
         * Creates a new SendAttachmentChunk instance using the specified properties.
         * @function create
         * @memberof olvid.SendAttachmentChunk
         * @static
         * @param {olvid.ISendAttachmentChunk=} [properties] Properties to set
         * @returns {olvid.SendAttachmentChunk} SendAttachmentChunk instance
         */
        SendAttachmentChunk.create = function create(properties) {
            return new SendAttachmentChunk(properties);
        };

        /**
         * Encodes the specified SendAttachmentChunk message. Does not implicitly {@link olvid.SendAttachmentChunk.verify|verify} messages.
         * @function encode
         * @memberof olvid.SendAttachmentChunk
         * @static
         * @param {olvid.ISendAttachmentChunk} message SendAttachmentChunk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendAttachmentChunk.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localId != null && Object.hasOwnProperty.call(message, "localId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.localId);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.offset);
            if (message.chunkNumber != null && Object.hasOwnProperty.call(message, "chunkNumber"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.chunkNumber);
            if (message.chunk != null && Object.hasOwnProperty.call(message, "chunk"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.chunk);
            return writer;
        };

        /**
         * Encodes the specified SendAttachmentChunk message, length delimited. Does not implicitly {@link olvid.SendAttachmentChunk.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.SendAttachmentChunk
         * @static
         * @param {olvid.ISendAttachmentChunk} message SendAttachmentChunk message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendAttachmentChunk.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendAttachmentChunk message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.SendAttachmentChunk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.SendAttachmentChunk} SendAttachmentChunk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendAttachmentChunk.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.SendAttachmentChunk();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localId = reader.int64();
                        break;
                    }
                case 2: {
                        message.offset = reader.int64();
                        break;
                    }
                case 3: {
                        message.chunkNumber = reader.int64();
                        break;
                    }
                case 4: {
                        message.chunk = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendAttachmentChunk message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.SendAttachmentChunk
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.SendAttachmentChunk} SendAttachmentChunk
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendAttachmentChunk.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendAttachmentChunk message.
         * @function verify
         * @memberof olvid.SendAttachmentChunk
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendAttachmentChunk.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (!$util.isInteger(message.localId) && !(message.localId && $util.isInteger(message.localId.low) && $util.isInteger(message.localId.high)))
                    return "localId: integer|Long expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                    return "offset: integer|Long expected";
            if (message.chunkNumber != null && message.hasOwnProperty("chunkNumber"))
                if (!$util.isInteger(message.chunkNumber) && !(message.chunkNumber && $util.isInteger(message.chunkNumber.low) && $util.isInteger(message.chunkNumber.high)))
                    return "chunkNumber: integer|Long expected";
            if (message.chunk != null && message.hasOwnProperty("chunk"))
                if (!(message.chunk && typeof message.chunk.length === "number" || $util.isString(message.chunk)))
                    return "chunk: buffer expected";
            return null;
        };

        /**
         * Creates a SendAttachmentChunk message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.SendAttachmentChunk
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.SendAttachmentChunk} SendAttachmentChunk
         */
        SendAttachmentChunk.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.SendAttachmentChunk)
                return object;
            var message = new $root.olvid.SendAttachmentChunk();
            if (object.localId != null)
                if ($util.Long)
                    (message.localId = $util.Long.fromValue(object.localId)).unsigned = false;
                else if (typeof object.localId === "string")
                    message.localId = parseInt(object.localId, 10);
                else if (typeof object.localId === "number")
                    message.localId = object.localId;
                else if (typeof object.localId === "object")
                    message.localId = new $util.LongBits(object.localId.low >>> 0, object.localId.high >>> 0).toNumber();
            if (object.offset != null)
                if ($util.Long)
                    (message.offset = $util.Long.fromValue(object.offset)).unsigned = false;
                else if (typeof object.offset === "string")
                    message.offset = parseInt(object.offset, 10);
                else if (typeof object.offset === "number")
                    message.offset = object.offset;
                else if (typeof object.offset === "object")
                    message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber();
            if (object.chunkNumber != null)
                if ($util.Long)
                    (message.chunkNumber = $util.Long.fromValue(object.chunkNumber)).unsigned = false;
                else if (typeof object.chunkNumber === "string")
                    message.chunkNumber = parseInt(object.chunkNumber, 10);
                else if (typeof object.chunkNumber === "number")
                    message.chunkNumber = object.chunkNumber;
                else if (typeof object.chunkNumber === "object")
                    message.chunkNumber = new $util.LongBits(object.chunkNumber.low >>> 0, object.chunkNumber.high >>> 0).toNumber();
            if (object.chunk != null)
                if (typeof object.chunk === "string")
                    $util.base64.decode(object.chunk, message.chunk = $util.newBuffer($util.base64.length(object.chunk)), 0);
                else if (object.chunk.length >= 0)
                    message.chunk = object.chunk;
            return message;
        };

        /**
         * Creates a plain object from a SendAttachmentChunk message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.SendAttachmentChunk
         * @static
         * @param {olvid.SendAttachmentChunk} message SendAttachmentChunk
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendAttachmentChunk.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.localId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.offset = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.chunkNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chunkNumber = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.chunk = "";
                else {
                    object.chunk = [];
                    if (options.bytes !== Array)
                        object.chunk = $util.newBuffer(object.chunk);
                }
            }
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (typeof message.localId === "number")
                    object.localId = options.longs === String ? String(message.localId) : message.localId;
                else
                    object.localId = options.longs === String ? $util.Long.prototype.toString.call(message.localId) : options.longs === Number ? new $util.LongBits(message.localId.low >>> 0, message.localId.high >>> 0).toNumber() : message.localId;
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (typeof message.offset === "number")
                    object.offset = options.longs === String ? String(message.offset) : message.offset;
                else
                    object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber() : message.offset;
            if (message.chunkNumber != null && message.hasOwnProperty("chunkNumber"))
                if (typeof message.chunkNumber === "number")
                    object.chunkNumber = options.longs === String ? String(message.chunkNumber) : message.chunkNumber;
                else
                    object.chunkNumber = options.longs === String ? $util.Long.prototype.toString.call(message.chunkNumber) : options.longs === Number ? new $util.LongBits(message.chunkNumber.low >>> 0, message.chunkNumber.high >>> 0).toNumber() : message.chunkNumber;
            if (message.chunk != null && message.hasOwnProperty("chunk"))
                object.chunk = options.bytes === String ? $util.base64.encode(message.chunk, 0, message.chunk.length) : options.bytes === Array ? Array.prototype.slice.call(message.chunk) : message.chunk;
            return object;
        };

        /**
         * Converts this SendAttachmentChunk to JSON.
         * @function toJSON
         * @memberof olvid.SendAttachmentChunk
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendAttachmentChunk.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SendAttachmentChunk
         * @function getTypeUrl
         * @memberof olvid.SendAttachmentChunk
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SendAttachmentChunk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.SendAttachmentChunk";
        };

        return SendAttachmentChunk;
    })();

    olvid.SendAttachmentDone = (function() {

        /**
         * Properties of a SendAttachmentDone.
         * @memberof olvid
         * @interface ISendAttachmentDone
         * @property {number|Long|null} [localId] SendAttachmentDone localId
         */

        /**
         * Constructs a new SendAttachmentDone.
         * @memberof olvid
         * @classdesc Represents a SendAttachmentDone.
         * @implements ISendAttachmentDone
         * @constructor
         * @param {olvid.ISendAttachmentDone=} [properties] Properties to set
         */
        function SendAttachmentDone(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendAttachmentDone localId.
         * @member {number|Long} localId
         * @memberof olvid.SendAttachmentDone
         * @instance
         */
        SendAttachmentDone.prototype.localId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new SendAttachmentDone instance using the specified properties.
         * @function create
         * @memberof olvid.SendAttachmentDone
         * @static
         * @param {olvid.ISendAttachmentDone=} [properties] Properties to set
         * @returns {olvid.SendAttachmentDone} SendAttachmentDone instance
         */
        SendAttachmentDone.create = function create(properties) {
            return new SendAttachmentDone(properties);
        };

        /**
         * Encodes the specified SendAttachmentDone message. Does not implicitly {@link olvid.SendAttachmentDone.verify|verify} messages.
         * @function encode
         * @memberof olvid.SendAttachmentDone
         * @static
         * @param {olvid.ISendAttachmentDone} message SendAttachmentDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendAttachmentDone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localId != null && Object.hasOwnProperty.call(message, "localId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.localId);
            return writer;
        };

        /**
         * Encodes the specified SendAttachmentDone message, length delimited. Does not implicitly {@link olvid.SendAttachmentDone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.SendAttachmentDone
         * @static
         * @param {olvid.ISendAttachmentDone} message SendAttachmentDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendAttachmentDone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendAttachmentDone message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.SendAttachmentDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.SendAttachmentDone} SendAttachmentDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendAttachmentDone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.SendAttachmentDone();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendAttachmentDone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.SendAttachmentDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.SendAttachmentDone} SendAttachmentDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendAttachmentDone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendAttachmentDone message.
         * @function verify
         * @memberof olvid.SendAttachmentDone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendAttachmentDone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (!$util.isInteger(message.localId) && !(message.localId && $util.isInteger(message.localId.low) && $util.isInteger(message.localId.high)))
                    return "localId: integer|Long expected";
            return null;
        };

        /**
         * Creates a SendAttachmentDone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.SendAttachmentDone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.SendAttachmentDone} SendAttachmentDone
         */
        SendAttachmentDone.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.SendAttachmentDone)
                return object;
            var message = new $root.olvid.SendAttachmentDone();
            if (object.localId != null)
                if ($util.Long)
                    (message.localId = $util.Long.fromValue(object.localId)).unsigned = false;
                else if (typeof object.localId === "string")
                    message.localId = parseInt(object.localId, 10);
                else if (typeof object.localId === "number")
                    message.localId = object.localId;
                else if (typeof object.localId === "object")
                    message.localId = new $util.LongBits(object.localId.low >>> 0, object.localId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a SendAttachmentDone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.SendAttachmentDone
         * @static
         * @param {olvid.SendAttachmentDone} message SendAttachmentDone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendAttachmentDone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.localId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localId = options.longs === String ? "0" : 0;
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (typeof message.localId === "number")
                    object.localId = options.longs === String ? String(message.localId) : message.localId;
                else
                    object.localId = options.longs === String ? $util.Long.prototype.toString.call(message.localId) : options.longs === Number ? new $util.LongBits(message.localId.low >>> 0, message.localId.high >>> 0).toNumber() : message.localId;
            return object;
        };

        /**
         * Converts this SendAttachmentDone to JSON.
         * @function toJSON
         * @memberof olvid.SendAttachmentDone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendAttachmentDone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SendAttachmentDone
         * @function getTypeUrl
         * @memberof olvid.SendAttachmentDone
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SendAttachmentDone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.SendAttachmentDone";
        };

        return SendAttachmentDone;
    })();

    olvid.RequestSaveDraftMessage = (function() {

        /**
         * Properties of a RequestSaveDraftMessage.
         * @memberof olvid
         * @interface IRequestSaveDraftMessage
         * @property {number|Long|null} [discussionId] RequestSaveDraftMessage discussionId
         * @property {string|null} [message] RequestSaveDraftMessage message
         * @property {number|Long|null} [replyMessageId] RequestSaveDraftMessage replyMessageId
         */

        /**
         * Constructs a new RequestSaveDraftMessage.
         * @memberof olvid
         * @classdesc Represents a RequestSaveDraftMessage.
         * @implements IRequestSaveDraftMessage
         * @constructor
         * @param {olvid.IRequestSaveDraftMessage=} [properties] Properties to set
         */
        function RequestSaveDraftMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestSaveDraftMessage discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.RequestSaveDraftMessage
         * @instance
         */
        RequestSaveDraftMessage.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RequestSaveDraftMessage message.
         * @member {string} message
         * @memberof olvid.RequestSaveDraftMessage
         * @instance
         */
        RequestSaveDraftMessage.prototype.message = "";

        /**
         * RequestSaveDraftMessage replyMessageId.
         * @member {number|Long} replyMessageId
         * @memberof olvid.RequestSaveDraftMessage
         * @instance
         */
        RequestSaveDraftMessage.prototype.replyMessageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new RequestSaveDraftMessage instance using the specified properties.
         * @function create
         * @memberof olvid.RequestSaveDraftMessage
         * @static
         * @param {olvid.IRequestSaveDraftMessage=} [properties] Properties to set
         * @returns {olvid.RequestSaveDraftMessage} RequestSaveDraftMessage instance
         */
        RequestSaveDraftMessage.create = function create(properties) {
            return new RequestSaveDraftMessage(properties);
        };

        /**
         * Encodes the specified RequestSaveDraftMessage message. Does not implicitly {@link olvid.RequestSaveDraftMessage.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestSaveDraftMessage
         * @static
         * @param {olvid.IRequestSaveDraftMessage} message RequestSaveDraftMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestSaveDraftMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.discussionId);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            if (message.replyMessageId != null && Object.hasOwnProperty.call(message, "replyMessageId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.replyMessageId);
            return writer;
        };

        /**
         * Encodes the specified RequestSaveDraftMessage message, length delimited. Does not implicitly {@link olvid.RequestSaveDraftMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestSaveDraftMessage
         * @static
         * @param {olvid.IRequestSaveDraftMessage} message RequestSaveDraftMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestSaveDraftMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestSaveDraftMessage message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestSaveDraftMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestSaveDraftMessage} RequestSaveDraftMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestSaveDraftMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestSaveDraftMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.discussionId = reader.int64();
                        break;
                    }
                case 2: {
                        message.message = reader.string();
                        break;
                    }
                case 3: {
                        message.replyMessageId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestSaveDraftMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestSaveDraftMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestSaveDraftMessage} RequestSaveDraftMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestSaveDraftMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestSaveDraftMessage message.
         * @function verify
         * @memberof olvid.RequestSaveDraftMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestSaveDraftMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.replyMessageId != null && message.hasOwnProperty("replyMessageId"))
                if (!$util.isInteger(message.replyMessageId) && !(message.replyMessageId && $util.isInteger(message.replyMessageId.low) && $util.isInteger(message.replyMessageId.high)))
                    return "replyMessageId: integer|Long expected";
            return null;
        };

        /**
         * Creates a RequestSaveDraftMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestSaveDraftMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestSaveDraftMessage} RequestSaveDraftMessage
         */
        RequestSaveDraftMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestSaveDraftMessage)
                return object;
            var message = new $root.olvid.RequestSaveDraftMessage();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            if (object.message != null)
                message.message = String(object.message);
            if (object.replyMessageId != null)
                if ($util.Long)
                    (message.replyMessageId = $util.Long.fromValue(object.replyMessageId)).unsigned = false;
                else if (typeof object.replyMessageId === "string")
                    message.replyMessageId = parseInt(object.replyMessageId, 10);
                else if (typeof object.replyMessageId === "number")
                    message.replyMessageId = object.replyMessageId;
                else if (typeof object.replyMessageId === "object")
                    message.replyMessageId = new $util.LongBits(object.replyMessageId.low >>> 0, object.replyMessageId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RequestSaveDraftMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestSaveDraftMessage
         * @static
         * @param {olvid.RequestSaveDraftMessage} message RequestSaveDraftMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestSaveDraftMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
                object.message = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.replyMessageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.replyMessageId = options.longs === String ? "0" : 0;
            }
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.replyMessageId != null && message.hasOwnProperty("replyMessageId"))
                if (typeof message.replyMessageId === "number")
                    object.replyMessageId = options.longs === String ? String(message.replyMessageId) : message.replyMessageId;
                else
                    object.replyMessageId = options.longs === String ? $util.Long.prototype.toString.call(message.replyMessageId) : options.longs === Number ? new $util.LongBits(message.replyMessageId.low >>> 0, message.replyMessageId.high >>> 0).toNumber() : message.replyMessageId;
            return object;
        };

        /**
         * Converts this RequestSaveDraftMessage to JSON.
         * @function toJSON
         * @memberof olvid.RequestSaveDraftMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestSaveDraftMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestSaveDraftMessage
         * @function getTypeUrl
         * @memberof olvid.RequestSaveDraftMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestSaveDraftMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestSaveDraftMessage";
        };

        return RequestSaveDraftMessage;
    })();

    olvid.RequestDeleteDraftAttachment = (function() {

        /**
         * Properties of a RequestDeleteDraftAttachment.
         * @memberof olvid
         * @interface IRequestDeleteDraftAttachment
         * @property {number|Long|null} [fyleId] RequestDeleteDraftAttachment fyleId
         * @property {number|Long|null} [messageId] RequestDeleteDraftAttachment messageId
         */

        /**
         * Constructs a new RequestDeleteDraftAttachment.
         * @memberof olvid
         * @classdesc Represents a RequestDeleteDraftAttachment.
         * @implements IRequestDeleteDraftAttachment
         * @constructor
         * @param {olvid.IRequestDeleteDraftAttachment=} [properties] Properties to set
         */
        function RequestDeleteDraftAttachment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestDeleteDraftAttachment fyleId.
         * @member {number|Long} fyleId
         * @memberof olvid.RequestDeleteDraftAttachment
         * @instance
         */
        RequestDeleteDraftAttachment.prototype.fyleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RequestDeleteDraftAttachment messageId.
         * @member {number|Long} messageId
         * @memberof olvid.RequestDeleteDraftAttachment
         * @instance
         */
        RequestDeleteDraftAttachment.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new RequestDeleteDraftAttachment instance using the specified properties.
         * @function create
         * @memberof olvid.RequestDeleteDraftAttachment
         * @static
         * @param {olvid.IRequestDeleteDraftAttachment=} [properties] Properties to set
         * @returns {olvid.RequestDeleteDraftAttachment} RequestDeleteDraftAttachment instance
         */
        RequestDeleteDraftAttachment.create = function create(properties) {
            return new RequestDeleteDraftAttachment(properties);
        };

        /**
         * Encodes the specified RequestDeleteDraftAttachment message. Does not implicitly {@link olvid.RequestDeleteDraftAttachment.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestDeleteDraftAttachment
         * @static
         * @param {olvid.IRequestDeleteDraftAttachment} message RequestDeleteDraftAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestDeleteDraftAttachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fyleId != null && Object.hasOwnProperty.call(message, "fyleId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fyleId);
            if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.messageId);
            return writer;
        };

        /**
         * Encodes the specified RequestDeleteDraftAttachment message, length delimited. Does not implicitly {@link olvid.RequestDeleteDraftAttachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestDeleteDraftAttachment
         * @static
         * @param {olvid.IRequestDeleteDraftAttachment} message RequestDeleteDraftAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestDeleteDraftAttachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestDeleteDraftAttachment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestDeleteDraftAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestDeleteDraftAttachment} RequestDeleteDraftAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestDeleteDraftAttachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestDeleteDraftAttachment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fyleId = reader.int64();
                        break;
                    }
                case 2: {
                        message.messageId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestDeleteDraftAttachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestDeleteDraftAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestDeleteDraftAttachment} RequestDeleteDraftAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestDeleteDraftAttachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestDeleteDraftAttachment message.
         * @function verify
         * @memberof olvid.RequestDeleteDraftAttachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestDeleteDraftAttachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (!$util.isInteger(message.fyleId) && !(message.fyleId && $util.isInteger(message.fyleId.low) && $util.isInteger(message.fyleId.high)))
                    return "fyleId: integer|Long expected";
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                    return "messageId: integer|Long expected";
            return null;
        };

        /**
         * Creates a RequestDeleteDraftAttachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestDeleteDraftAttachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestDeleteDraftAttachment} RequestDeleteDraftAttachment
         */
        RequestDeleteDraftAttachment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestDeleteDraftAttachment)
                return object;
            var message = new $root.olvid.RequestDeleteDraftAttachment();
            if (object.fyleId != null)
                if ($util.Long)
                    (message.fyleId = $util.Long.fromValue(object.fyleId)).unsigned = false;
                else if (typeof object.fyleId === "string")
                    message.fyleId = parseInt(object.fyleId, 10);
                else if (typeof object.fyleId === "number")
                    message.fyleId = object.fyleId;
                else if (typeof object.fyleId === "object")
                    message.fyleId = new $util.LongBits(object.fyleId.low >>> 0, object.fyleId.high >>> 0).toNumber();
            if (object.messageId != null)
                if ($util.Long)
                    (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = false;
                else if (typeof object.messageId === "string")
                    message.messageId = parseInt(object.messageId, 10);
                else if (typeof object.messageId === "number")
                    message.messageId = object.messageId;
                else if (typeof object.messageId === "object")
                    message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RequestDeleteDraftAttachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestDeleteDraftAttachment
         * @static
         * @param {olvid.RequestDeleteDraftAttachment} message RequestDeleteDraftAttachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestDeleteDraftAttachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.fyleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fyleId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.messageId = options.longs === String ? "0" : 0;
            }
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (typeof message.fyleId === "number")
                    object.fyleId = options.longs === String ? String(message.fyleId) : message.fyleId;
                else
                    object.fyleId = options.longs === String ? $util.Long.prototype.toString.call(message.fyleId) : options.longs === Number ? new $util.LongBits(message.fyleId.low >>> 0, message.fyleId.high >>> 0).toNumber() : message.fyleId;
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (typeof message.messageId === "number")
                    object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
                else
                    object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber() : message.messageId;
            return object;
        };

        /**
         * Converts this RequestDeleteDraftAttachment to JSON.
         * @function toJSON
         * @memberof olvid.RequestDeleteDraftAttachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestDeleteDraftAttachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestDeleteDraftAttachment
         * @function getTypeUrl
         * @memberof olvid.RequestDeleteDraftAttachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestDeleteDraftAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestDeleteDraftAttachment";
        };

        return RequestDeleteDraftAttachment;
    })();

    olvid.RequestDeleteDraftMessage = (function() {

        /**
         * Properties of a RequestDeleteDraftMessage.
         * @memberof olvid
         * @interface IRequestDeleteDraftMessage
         * @property {number|Long|null} [discussionId] RequestDeleteDraftMessage discussionId
         */

        /**
         * Constructs a new RequestDeleteDraftMessage.
         * @memberof olvid
         * @classdesc Represents a RequestDeleteDraftMessage.
         * @implements IRequestDeleteDraftMessage
         * @constructor
         * @param {olvid.IRequestDeleteDraftMessage=} [properties] Properties to set
         */
        function RequestDeleteDraftMessage(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestDeleteDraftMessage discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.RequestDeleteDraftMessage
         * @instance
         */
        RequestDeleteDraftMessage.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new RequestDeleteDraftMessage instance using the specified properties.
         * @function create
         * @memberof olvid.RequestDeleteDraftMessage
         * @static
         * @param {olvid.IRequestDeleteDraftMessage=} [properties] Properties to set
         * @returns {olvid.RequestDeleteDraftMessage} RequestDeleteDraftMessage instance
         */
        RequestDeleteDraftMessage.create = function create(properties) {
            return new RequestDeleteDraftMessage(properties);
        };

        /**
         * Encodes the specified RequestDeleteDraftMessage message. Does not implicitly {@link olvid.RequestDeleteDraftMessage.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestDeleteDraftMessage
         * @static
         * @param {olvid.IRequestDeleteDraftMessage} message RequestDeleteDraftMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestDeleteDraftMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.discussionId);
            return writer;
        };

        /**
         * Encodes the specified RequestDeleteDraftMessage message, length delimited. Does not implicitly {@link olvid.RequestDeleteDraftMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestDeleteDraftMessage
         * @static
         * @param {olvid.IRequestDeleteDraftMessage} message RequestDeleteDraftMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestDeleteDraftMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestDeleteDraftMessage message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestDeleteDraftMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestDeleteDraftMessage} RequestDeleteDraftMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestDeleteDraftMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestDeleteDraftMessage();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.discussionId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestDeleteDraftMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestDeleteDraftMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestDeleteDraftMessage} RequestDeleteDraftMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestDeleteDraftMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestDeleteDraftMessage message.
         * @function verify
         * @memberof olvid.RequestDeleteDraftMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestDeleteDraftMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            return null;
        };

        /**
         * Creates a RequestDeleteDraftMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestDeleteDraftMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestDeleteDraftMessage} RequestDeleteDraftMessage
         */
        RequestDeleteDraftMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestDeleteDraftMessage)
                return object;
            var message = new $root.olvid.RequestDeleteDraftMessage();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RequestDeleteDraftMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestDeleteDraftMessage
         * @static
         * @param {olvid.RequestDeleteDraftMessage} message RequestDeleteDraftMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestDeleteDraftMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            return object;
        };

        /**
         * Converts this RequestDeleteDraftMessage to JSON.
         * @function toJSON
         * @memberof olvid.RequestDeleteDraftMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestDeleteDraftMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestDeleteDraftMessage
         * @function getTypeUrl
         * @memberof olvid.RequestDeleteDraftMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestDeleteDraftMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestDeleteDraftMessage";
        };

        return RequestDeleteDraftMessage;
    })();

    olvid.RequestStopDraftAttachmentUpload = (function() {

        /**
         * Properties of a RequestStopDraftAttachmentUpload.
         * @memberof olvid
         * @interface IRequestStopDraftAttachmentUpload
         * @property {number|Long|null} [localId] RequestStopDraftAttachmentUpload localId
         */

        /**
         * Constructs a new RequestStopDraftAttachmentUpload.
         * @memberof olvid
         * @classdesc Represents a RequestStopDraftAttachmentUpload.
         * @implements IRequestStopDraftAttachmentUpload
         * @constructor
         * @param {olvid.IRequestStopDraftAttachmentUpload=} [properties] Properties to set
         */
        function RequestStopDraftAttachmentUpload(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestStopDraftAttachmentUpload localId.
         * @member {number|Long} localId
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @instance
         */
        RequestStopDraftAttachmentUpload.prototype.localId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new RequestStopDraftAttachmentUpload instance using the specified properties.
         * @function create
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @static
         * @param {olvid.IRequestStopDraftAttachmentUpload=} [properties] Properties to set
         * @returns {olvid.RequestStopDraftAttachmentUpload} RequestStopDraftAttachmentUpload instance
         */
        RequestStopDraftAttachmentUpload.create = function create(properties) {
            return new RequestStopDraftAttachmentUpload(properties);
        };

        /**
         * Encodes the specified RequestStopDraftAttachmentUpload message. Does not implicitly {@link olvid.RequestStopDraftAttachmentUpload.verify|verify} messages.
         * @function encode
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @static
         * @param {olvid.IRequestStopDraftAttachmentUpload} message RequestStopDraftAttachmentUpload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestStopDraftAttachmentUpload.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localId != null && Object.hasOwnProperty.call(message, "localId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.localId);
            return writer;
        };

        /**
         * Encodes the specified RequestStopDraftAttachmentUpload message, length delimited. Does not implicitly {@link olvid.RequestStopDraftAttachmentUpload.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @static
         * @param {olvid.IRequestStopDraftAttachmentUpload} message RequestStopDraftAttachmentUpload message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestStopDraftAttachmentUpload.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestStopDraftAttachmentUpload message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.RequestStopDraftAttachmentUpload} RequestStopDraftAttachmentUpload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestStopDraftAttachmentUpload.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.RequestStopDraftAttachmentUpload();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestStopDraftAttachmentUpload message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.RequestStopDraftAttachmentUpload} RequestStopDraftAttachmentUpload
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestStopDraftAttachmentUpload.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestStopDraftAttachmentUpload message.
         * @function verify
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestStopDraftAttachmentUpload.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (!$util.isInteger(message.localId) && !(message.localId && $util.isInteger(message.localId.low) && $util.isInteger(message.localId.high)))
                    return "localId: integer|Long expected";
            return null;
        };

        /**
         * Creates a RequestStopDraftAttachmentUpload message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.RequestStopDraftAttachmentUpload} RequestStopDraftAttachmentUpload
         */
        RequestStopDraftAttachmentUpload.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.RequestStopDraftAttachmentUpload)
                return object;
            var message = new $root.olvid.RequestStopDraftAttachmentUpload();
            if (object.localId != null)
                if ($util.Long)
                    (message.localId = $util.Long.fromValue(object.localId)).unsigned = false;
                else if (typeof object.localId === "string")
                    message.localId = parseInt(object.localId, 10);
                else if (typeof object.localId === "number")
                    message.localId = object.localId;
                else if (typeof object.localId === "object")
                    message.localId = new $util.LongBits(object.localId.low >>> 0, object.localId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RequestStopDraftAttachmentUpload message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @static
         * @param {olvid.RequestStopDraftAttachmentUpload} message RequestStopDraftAttachmentUpload
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestStopDraftAttachmentUpload.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.localId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localId = options.longs === String ? "0" : 0;
            if (message.localId != null && message.hasOwnProperty("localId"))
                if (typeof message.localId === "number")
                    object.localId = options.longs === String ? String(message.localId) : message.localId;
                else
                    object.localId = options.longs === String ? $util.Long.prototype.toString.call(message.localId) : options.longs === Number ? new $util.LongBits(message.localId.low >>> 0, message.localId.high >>> 0).toNumber() : message.localId;
            return object;
        };

        /**
         * Converts this RequestStopDraftAttachmentUpload to JSON.
         * @function toJSON
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestStopDraftAttachmentUpload.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RequestStopDraftAttachmentUpload
         * @function getTypeUrl
         * @memberof olvid.RequestStopDraftAttachmentUpload
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestStopDraftAttachmentUpload.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.RequestStopDraftAttachmentUpload";
        };

        return RequestStopDraftAttachmentUpload;
    })();

    olvid.Settings = (function() {

        /**
         * Properties of a Settings.
         * @memberof olvid
         * @interface ISettings
         * @property {string|null} [settings] Settings settings
         */

        /**
         * Constructs a new Settings.
         * @memberof olvid
         * @classdesc Represents a Settings.
         * @implements ISettings
         * @constructor
         * @param {olvid.ISettings=} [properties] Properties to set
         */
        function Settings(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Settings settings.
         * @member {string} settings
         * @memberof olvid.Settings
         * @instance
         */
        Settings.prototype.settings = "";

        /**
         * Creates a new Settings instance using the specified properties.
         * @function create
         * @memberof olvid.Settings
         * @static
         * @param {olvid.ISettings=} [properties] Properties to set
         * @returns {olvid.Settings} Settings instance
         */
        Settings.create = function create(properties) {
            return new Settings(properties);
        };

        /**
         * Encodes the specified Settings message. Does not implicitly {@link olvid.Settings.verify|verify} messages.
         * @function encode
         * @memberof olvid.Settings
         * @static
         * @param {olvid.ISettings} message Settings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Settings.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.settings);
            return writer;
        };

        /**
         * Encodes the specified Settings message, length delimited. Does not implicitly {@link olvid.Settings.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.Settings
         * @static
         * @param {olvid.ISettings} message Settings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Settings.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Settings message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.Settings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.Settings} Settings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Settings.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.Settings();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.settings = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Settings message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.Settings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.Settings} Settings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Settings.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Settings message.
         * @function verify
         * @memberof olvid.Settings
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Settings.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.settings != null && message.hasOwnProperty("settings"))
                if (!$util.isString(message.settings))
                    return "settings: string expected";
            return null;
        };

        /**
         * Creates a Settings message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.Settings
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.Settings} Settings
         */
        Settings.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.Settings)
                return object;
            var message = new $root.olvid.Settings();
            if (object.settings != null)
                message.settings = String(object.settings);
            return message;
        };

        /**
         * Creates a plain object from a Settings message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.Settings
         * @static
         * @param {olvid.Settings} message Settings
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Settings.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.settings = "";
            if (message.settings != null && message.hasOwnProperty("settings"))
                object.settings = message.settings;
            return object;
        };

        /**
         * Converts this Settings to JSON.
         * @function toJSON
         * @memberof olvid.Settings
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Settings.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Settings
         * @function getTypeUrl
         * @memberof olvid.Settings
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Settings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.Settings";
        };

        return Settings;
    })();

    /**
     * ConnectionColissimoType enum.
     * @name olvid.ConnectionColissimoType
     * @enum {number}
     * @property {number} CONNECTION_COLISSIMO_TYPE_DEFAULT=0 CONNECTION_COLISSIMO_TYPE_DEFAULT value
     * @property {number} CONNECTION_APP_IDENTIFIER_PK_KEM_COMMIT_SEED=1 CONNECTION_APP_IDENTIFIER_PK_KEM_COMMIT_SEED value
     * @property {number} CONNECTION_BROWSER_KEM_SEED=2 CONNECTION_BROWSER_KEM_SEED value
     * @property {number} CONNECTION_APP_DECOMMITMENT=3 CONNECTION_APP_DECOMMITMENT value
     */
    olvid.ConnectionColissimoType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CONNECTION_COLISSIMO_TYPE_DEFAULT"] = 0;
        values[valuesById[1] = "CONNECTION_APP_IDENTIFIER_PK_KEM_COMMIT_SEED"] = 1;
        values[valuesById[2] = "CONNECTION_BROWSER_KEM_SEED"] = 2;
        values[valuesById[3] = "CONNECTION_APP_DECOMMITMENT"] = 3;
        return values;
    })();

    olvid.ConnectionColissimo = (function() {

        /**
         * Properties of a ConnectionColissimo.
         * @memberof olvid
         * @interface IConnectionColissimo
         * @property {olvid.ConnectionColissimoType|null} [type] ConnectionColissimo type
         * @property {olvid.IConnectionAppIdentifierPkKemCommitSeed|null} [connectionAppIdentifierPkKemCommitSeed] ConnectionColissimo connectionAppIdentifierPkKemCommitSeed
         * @property {olvid.IConnectionBrowserKemSeed|null} [ConnectionBrowserKemSeed] ConnectionColissimo ConnectionBrowserKemSeed
         * @property {olvid.IConnectionAppDecommitment|null} [ConnectionAppDecommitment] ConnectionColissimo ConnectionAppDecommitment
         */

        /**
         * Constructs a new ConnectionColissimo.
         * @memberof olvid
         * @classdesc Represents a ConnectionColissimo.
         * @implements IConnectionColissimo
         * @constructor
         * @param {olvid.IConnectionColissimo=} [properties] Properties to set
         */
        function ConnectionColissimo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConnectionColissimo type.
         * @member {olvid.ConnectionColissimoType} type
         * @memberof olvid.ConnectionColissimo
         * @instance
         */
        ConnectionColissimo.prototype.type = 0;

        /**
         * ConnectionColissimo connectionAppIdentifierPkKemCommitSeed.
         * @member {olvid.IConnectionAppIdentifierPkKemCommitSeed|null|undefined} connectionAppIdentifierPkKemCommitSeed
         * @memberof olvid.ConnectionColissimo
         * @instance
         */
        ConnectionColissimo.prototype.connectionAppIdentifierPkKemCommitSeed = null;

        /**
         * ConnectionColissimo ConnectionBrowserKemSeed.
         * @member {olvid.IConnectionBrowserKemSeed|null|undefined} ConnectionBrowserKemSeed
         * @memberof olvid.ConnectionColissimo
         * @instance
         */
        ConnectionColissimo.prototype.ConnectionBrowserKemSeed = null;

        /**
         * ConnectionColissimo ConnectionAppDecommitment.
         * @member {olvid.IConnectionAppDecommitment|null|undefined} ConnectionAppDecommitment
         * @memberof olvid.ConnectionColissimo
         * @instance
         */
        ConnectionColissimo.prototype.ConnectionAppDecommitment = null;

        /**
         * Creates a new ConnectionColissimo instance using the specified properties.
         * @function create
         * @memberof olvid.ConnectionColissimo
         * @static
         * @param {olvid.IConnectionColissimo=} [properties] Properties to set
         * @returns {olvid.ConnectionColissimo} ConnectionColissimo instance
         */
        ConnectionColissimo.create = function create(properties) {
            return new ConnectionColissimo(properties);
        };

        /**
         * Encodes the specified ConnectionColissimo message. Does not implicitly {@link olvid.ConnectionColissimo.verify|verify} messages.
         * @function encode
         * @memberof olvid.ConnectionColissimo
         * @static
         * @param {olvid.IConnectionColissimo} message ConnectionColissimo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectionColissimo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.connectionAppIdentifierPkKemCommitSeed != null && Object.hasOwnProperty.call(message, "connectionAppIdentifierPkKemCommitSeed"))
                $root.olvid.ConnectionAppIdentifierPkKemCommitSeed.encode(message.connectionAppIdentifierPkKemCommitSeed, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.ConnectionBrowserKemSeed != null && Object.hasOwnProperty.call(message, "ConnectionBrowserKemSeed"))
                $root.olvid.ConnectionBrowserKemSeed.encode(message.ConnectionBrowserKemSeed, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.ConnectionAppDecommitment != null && Object.hasOwnProperty.call(message, "ConnectionAppDecommitment"))
                $root.olvid.ConnectionAppDecommitment.encode(message.ConnectionAppDecommitment, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ConnectionColissimo message, length delimited. Does not implicitly {@link olvid.ConnectionColissimo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.ConnectionColissimo
         * @static
         * @param {olvid.IConnectionColissimo} message ConnectionColissimo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectionColissimo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConnectionColissimo message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.ConnectionColissimo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.ConnectionColissimo} ConnectionColissimo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectionColissimo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.ConnectionColissimo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.connectionAppIdentifierPkKemCommitSeed = $root.olvid.ConnectionAppIdentifierPkKemCommitSeed.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.ConnectionBrowserKemSeed = $root.olvid.ConnectionBrowserKemSeed.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.ConnectionAppDecommitment = $root.olvid.ConnectionAppDecommitment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConnectionColissimo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.ConnectionColissimo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.ConnectionColissimo} ConnectionColissimo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectionColissimo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConnectionColissimo message.
         * @function verify
         * @memberof olvid.ConnectionColissimo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConnectionColissimo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.connectionAppIdentifierPkKemCommitSeed != null && message.hasOwnProperty("connectionAppIdentifierPkKemCommitSeed")) {
                var error = $root.olvid.ConnectionAppIdentifierPkKemCommitSeed.verify(message.connectionAppIdentifierPkKemCommitSeed);
                if (error)
                    return "connectionAppIdentifierPkKemCommitSeed." + error;
            }
            if (message.ConnectionBrowserKemSeed != null && message.hasOwnProperty("ConnectionBrowserKemSeed")) {
                var error = $root.olvid.ConnectionBrowserKemSeed.verify(message.ConnectionBrowserKemSeed);
                if (error)
                    return "ConnectionBrowserKemSeed." + error;
            }
            if (message.ConnectionAppDecommitment != null && message.hasOwnProperty("ConnectionAppDecommitment")) {
                var error = $root.olvid.ConnectionAppDecommitment.verify(message.ConnectionAppDecommitment);
                if (error)
                    return "ConnectionAppDecommitment." + error;
            }
            return null;
        };

        /**
         * Creates a ConnectionColissimo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.ConnectionColissimo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.ConnectionColissimo} ConnectionColissimo
         */
        ConnectionColissimo.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.ConnectionColissimo)
                return object;
            var message = new $root.olvid.ConnectionColissimo();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "CONNECTION_COLISSIMO_TYPE_DEFAULT":
            case 0:
                message.type = 0;
                break;
            case "CONNECTION_APP_IDENTIFIER_PK_KEM_COMMIT_SEED":
            case 1:
                message.type = 1;
                break;
            case "CONNECTION_BROWSER_KEM_SEED":
            case 2:
                message.type = 2;
                break;
            case "CONNECTION_APP_DECOMMITMENT":
            case 3:
                message.type = 3;
                break;
            }
            if (object.connectionAppIdentifierPkKemCommitSeed != null) {
                if (typeof object.connectionAppIdentifierPkKemCommitSeed !== "object")
                    throw TypeError(".olvid.ConnectionColissimo.connectionAppIdentifierPkKemCommitSeed: object expected");
                message.connectionAppIdentifierPkKemCommitSeed = $root.olvid.ConnectionAppIdentifierPkKemCommitSeed.fromObject(object.connectionAppIdentifierPkKemCommitSeed);
            }
            if (object.ConnectionBrowserKemSeed != null) {
                if (typeof object.ConnectionBrowserKemSeed !== "object")
                    throw TypeError(".olvid.ConnectionColissimo.ConnectionBrowserKemSeed: object expected");
                message.ConnectionBrowserKemSeed = $root.olvid.ConnectionBrowserKemSeed.fromObject(object.ConnectionBrowserKemSeed);
            }
            if (object.ConnectionAppDecommitment != null) {
                if (typeof object.ConnectionAppDecommitment !== "object")
                    throw TypeError(".olvid.ConnectionColissimo.ConnectionAppDecommitment: object expected");
                message.ConnectionAppDecommitment = $root.olvid.ConnectionAppDecommitment.fromObject(object.ConnectionAppDecommitment);
            }
            return message;
        };

        /**
         * Creates a plain object from a ConnectionColissimo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.ConnectionColissimo
         * @static
         * @param {olvid.ConnectionColissimo} message ConnectionColissimo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConnectionColissimo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "CONNECTION_COLISSIMO_TYPE_DEFAULT" : 0;
                object.connectionAppIdentifierPkKemCommitSeed = null;
                object.ConnectionBrowserKemSeed = null;
                object.ConnectionAppDecommitment = null;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.olvid.ConnectionColissimoType[message.type] === undefined ? message.type : $root.olvid.ConnectionColissimoType[message.type] : message.type;
            if (message.connectionAppIdentifierPkKemCommitSeed != null && message.hasOwnProperty("connectionAppIdentifierPkKemCommitSeed"))
                object.connectionAppIdentifierPkKemCommitSeed = $root.olvid.ConnectionAppIdentifierPkKemCommitSeed.toObject(message.connectionAppIdentifierPkKemCommitSeed, options);
            if (message.ConnectionBrowserKemSeed != null && message.hasOwnProperty("ConnectionBrowserKemSeed"))
                object.ConnectionBrowserKemSeed = $root.olvid.ConnectionBrowserKemSeed.toObject(message.ConnectionBrowserKemSeed, options);
            if (message.ConnectionAppDecommitment != null && message.hasOwnProperty("ConnectionAppDecommitment"))
                object.ConnectionAppDecommitment = $root.olvid.ConnectionAppDecommitment.toObject(message.ConnectionAppDecommitment, options);
            return object;
        };

        /**
         * Converts this ConnectionColissimo to JSON.
         * @function toJSON
         * @memberof olvid.ConnectionColissimo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConnectionColissimo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConnectionColissimo
         * @function getTypeUrl
         * @memberof olvid.ConnectionColissimo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConnectionColissimo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.ConnectionColissimo";
        };

        return ConnectionColissimo;
    })();

    olvid.ConnectionAppDecommitment = (function() {

        /**
         * Properties of a ConnectionAppDecommitment.
         * @memberof olvid
         * @interface IConnectionAppDecommitment
         * @property {Uint8Array|null} [decommitment] ConnectionAppDecommitment decommitment
         */

        /**
         * Constructs a new ConnectionAppDecommitment.
         * @memberof olvid
         * @classdesc Represents a ConnectionAppDecommitment.
         * @implements IConnectionAppDecommitment
         * @constructor
         * @param {olvid.IConnectionAppDecommitment=} [properties] Properties to set
         */
        function ConnectionAppDecommitment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConnectionAppDecommitment decommitment.
         * @member {Uint8Array} decommitment
         * @memberof olvid.ConnectionAppDecommitment
         * @instance
         */
        ConnectionAppDecommitment.prototype.decommitment = $util.newBuffer([]);

        /**
         * Creates a new ConnectionAppDecommitment instance using the specified properties.
         * @function create
         * @memberof olvid.ConnectionAppDecommitment
         * @static
         * @param {olvid.IConnectionAppDecommitment=} [properties] Properties to set
         * @returns {olvid.ConnectionAppDecommitment} ConnectionAppDecommitment instance
         */
        ConnectionAppDecommitment.create = function create(properties) {
            return new ConnectionAppDecommitment(properties);
        };

        /**
         * Encodes the specified ConnectionAppDecommitment message. Does not implicitly {@link olvid.ConnectionAppDecommitment.verify|verify} messages.
         * @function encode
         * @memberof olvid.ConnectionAppDecommitment
         * @static
         * @param {olvid.IConnectionAppDecommitment} message ConnectionAppDecommitment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectionAppDecommitment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.decommitment != null && Object.hasOwnProperty.call(message, "decommitment"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.decommitment);
            return writer;
        };

        /**
         * Encodes the specified ConnectionAppDecommitment message, length delimited. Does not implicitly {@link olvid.ConnectionAppDecommitment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.ConnectionAppDecommitment
         * @static
         * @param {olvid.IConnectionAppDecommitment} message ConnectionAppDecommitment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectionAppDecommitment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConnectionAppDecommitment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.ConnectionAppDecommitment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.ConnectionAppDecommitment} ConnectionAppDecommitment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectionAppDecommitment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.ConnectionAppDecommitment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.decommitment = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConnectionAppDecommitment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.ConnectionAppDecommitment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.ConnectionAppDecommitment} ConnectionAppDecommitment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectionAppDecommitment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConnectionAppDecommitment message.
         * @function verify
         * @memberof olvid.ConnectionAppDecommitment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConnectionAppDecommitment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.decommitment != null && message.hasOwnProperty("decommitment"))
                if (!(message.decommitment && typeof message.decommitment.length === "number" || $util.isString(message.decommitment)))
                    return "decommitment: buffer expected";
            return null;
        };

        /**
         * Creates a ConnectionAppDecommitment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.ConnectionAppDecommitment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.ConnectionAppDecommitment} ConnectionAppDecommitment
         */
        ConnectionAppDecommitment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.ConnectionAppDecommitment)
                return object;
            var message = new $root.olvid.ConnectionAppDecommitment();
            if (object.decommitment != null)
                if (typeof object.decommitment === "string")
                    $util.base64.decode(object.decommitment, message.decommitment = $util.newBuffer($util.base64.length(object.decommitment)), 0);
                else if (object.decommitment.length >= 0)
                    message.decommitment = object.decommitment;
            return message;
        };

        /**
         * Creates a plain object from a ConnectionAppDecommitment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.ConnectionAppDecommitment
         * @static
         * @param {olvid.ConnectionAppDecommitment} message ConnectionAppDecommitment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConnectionAppDecommitment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.decommitment = "";
                else {
                    object.decommitment = [];
                    if (options.bytes !== Array)
                        object.decommitment = $util.newBuffer(object.decommitment);
                }
            if (message.decommitment != null && message.hasOwnProperty("decommitment"))
                object.decommitment = options.bytes === String ? $util.base64.encode(message.decommitment, 0, message.decommitment.length) : options.bytes === Array ? Array.prototype.slice.call(message.decommitment) : message.decommitment;
            return object;
        };

        /**
         * Converts this ConnectionAppDecommitment to JSON.
         * @function toJSON
         * @memberof olvid.ConnectionAppDecommitment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConnectionAppDecommitment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConnectionAppDecommitment
         * @function getTypeUrl
         * @memberof olvid.ConnectionAppDecommitment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConnectionAppDecommitment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.ConnectionAppDecommitment";
        };

        return ConnectionAppDecommitment;
    })();

    olvid.ConnectionAppIdentifierPkKemCommitSeed = (function() {

        /**
         * Properties of a ConnectionAppIdentifierPkKemCommitSeed.
         * @memberof olvid
         * @interface IConnectionAppIdentifierPkKemCommitSeed
         * @property {string|null} [identifier] ConnectionAppIdentifierPkKemCommitSeed identifier
         * @property {Uint8Array|null} [publicKey] ConnectionAppIdentifierPkKemCommitSeed publicKey
         * @property {Uint8Array|null} [kemCipher] ConnectionAppIdentifierPkKemCommitSeed kemCipher
         * @property {Uint8Array|null} [commit] ConnectionAppIdentifierPkKemCommitSeed commit
         */

        /**
         * Constructs a new ConnectionAppIdentifierPkKemCommitSeed.
         * @memberof olvid
         * @classdesc Represents a ConnectionAppIdentifierPkKemCommitSeed.
         * @implements IConnectionAppIdentifierPkKemCommitSeed
         * @constructor
         * @param {olvid.IConnectionAppIdentifierPkKemCommitSeed=} [properties] Properties to set
         */
        function ConnectionAppIdentifierPkKemCommitSeed(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConnectionAppIdentifierPkKemCommitSeed identifier.
         * @member {string} identifier
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @instance
         */
        ConnectionAppIdentifierPkKemCommitSeed.prototype.identifier = "";

        /**
         * ConnectionAppIdentifierPkKemCommitSeed publicKey.
         * @member {Uint8Array} publicKey
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @instance
         */
        ConnectionAppIdentifierPkKemCommitSeed.prototype.publicKey = $util.newBuffer([]);

        /**
         * ConnectionAppIdentifierPkKemCommitSeed kemCipher.
         * @member {Uint8Array} kemCipher
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @instance
         */
        ConnectionAppIdentifierPkKemCommitSeed.prototype.kemCipher = $util.newBuffer([]);

        /**
         * ConnectionAppIdentifierPkKemCommitSeed commit.
         * @member {Uint8Array} commit
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @instance
         */
        ConnectionAppIdentifierPkKemCommitSeed.prototype.commit = $util.newBuffer([]);

        /**
         * Creates a new ConnectionAppIdentifierPkKemCommitSeed instance using the specified properties.
         * @function create
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @static
         * @param {olvid.IConnectionAppIdentifierPkKemCommitSeed=} [properties] Properties to set
         * @returns {olvid.ConnectionAppIdentifierPkKemCommitSeed} ConnectionAppIdentifierPkKemCommitSeed instance
         */
        ConnectionAppIdentifierPkKemCommitSeed.create = function create(properties) {
            return new ConnectionAppIdentifierPkKemCommitSeed(properties);
        };

        /**
         * Encodes the specified ConnectionAppIdentifierPkKemCommitSeed message. Does not implicitly {@link olvid.ConnectionAppIdentifierPkKemCommitSeed.verify|verify} messages.
         * @function encode
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @static
         * @param {olvid.IConnectionAppIdentifierPkKemCommitSeed} message ConnectionAppIdentifierPkKemCommitSeed message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectionAppIdentifierPkKemCommitSeed.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.identifier != null && Object.hasOwnProperty.call(message, "identifier"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.identifier);
            if (message.publicKey != null && Object.hasOwnProperty.call(message, "publicKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.publicKey);
            if (message.kemCipher != null && Object.hasOwnProperty.call(message, "kemCipher"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.kemCipher);
            if (message.commit != null && Object.hasOwnProperty.call(message, "commit"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.commit);
            return writer;
        };

        /**
         * Encodes the specified ConnectionAppIdentifierPkKemCommitSeed message, length delimited. Does not implicitly {@link olvid.ConnectionAppIdentifierPkKemCommitSeed.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @static
         * @param {olvid.IConnectionAppIdentifierPkKemCommitSeed} message ConnectionAppIdentifierPkKemCommitSeed message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectionAppIdentifierPkKemCommitSeed.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConnectionAppIdentifierPkKemCommitSeed message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.ConnectionAppIdentifierPkKemCommitSeed} ConnectionAppIdentifierPkKemCommitSeed
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectionAppIdentifierPkKemCommitSeed.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.ConnectionAppIdentifierPkKemCommitSeed();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.identifier = reader.string();
                        break;
                    }
                case 2: {
                        message.publicKey = reader.bytes();
                        break;
                    }
                case 3: {
                        message.kemCipher = reader.bytes();
                        break;
                    }
                case 4: {
                        message.commit = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConnectionAppIdentifierPkKemCommitSeed message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.ConnectionAppIdentifierPkKemCommitSeed} ConnectionAppIdentifierPkKemCommitSeed
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectionAppIdentifierPkKemCommitSeed.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConnectionAppIdentifierPkKemCommitSeed message.
         * @function verify
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConnectionAppIdentifierPkKemCommitSeed.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.identifier != null && message.hasOwnProperty("identifier"))
                if (!$util.isString(message.identifier))
                    return "identifier: string expected";
            if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                if (!(message.publicKey && typeof message.publicKey.length === "number" || $util.isString(message.publicKey)))
                    return "publicKey: buffer expected";
            if (message.kemCipher != null && message.hasOwnProperty("kemCipher"))
                if (!(message.kemCipher && typeof message.kemCipher.length === "number" || $util.isString(message.kemCipher)))
                    return "kemCipher: buffer expected";
            if (message.commit != null && message.hasOwnProperty("commit"))
                if (!(message.commit && typeof message.commit.length === "number" || $util.isString(message.commit)))
                    return "commit: buffer expected";
            return null;
        };

        /**
         * Creates a ConnectionAppIdentifierPkKemCommitSeed message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.ConnectionAppIdentifierPkKemCommitSeed} ConnectionAppIdentifierPkKemCommitSeed
         */
        ConnectionAppIdentifierPkKemCommitSeed.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.ConnectionAppIdentifierPkKemCommitSeed)
                return object;
            var message = new $root.olvid.ConnectionAppIdentifierPkKemCommitSeed();
            if (object.identifier != null)
                message.identifier = String(object.identifier);
            if (object.publicKey != null)
                if (typeof object.publicKey === "string")
                    $util.base64.decode(object.publicKey, message.publicKey = $util.newBuffer($util.base64.length(object.publicKey)), 0);
                else if (object.publicKey.length >= 0)
                    message.publicKey = object.publicKey;
            if (object.kemCipher != null)
                if (typeof object.kemCipher === "string")
                    $util.base64.decode(object.kemCipher, message.kemCipher = $util.newBuffer($util.base64.length(object.kemCipher)), 0);
                else if (object.kemCipher.length >= 0)
                    message.kemCipher = object.kemCipher;
            if (object.commit != null)
                if (typeof object.commit === "string")
                    $util.base64.decode(object.commit, message.commit = $util.newBuffer($util.base64.length(object.commit)), 0);
                else if (object.commit.length >= 0)
                    message.commit = object.commit;
            return message;
        };

        /**
         * Creates a plain object from a ConnectionAppIdentifierPkKemCommitSeed message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @static
         * @param {olvid.ConnectionAppIdentifierPkKemCommitSeed} message ConnectionAppIdentifierPkKemCommitSeed
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConnectionAppIdentifierPkKemCommitSeed.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.identifier = "";
                if (options.bytes === String)
                    object.publicKey = "";
                else {
                    object.publicKey = [];
                    if (options.bytes !== Array)
                        object.publicKey = $util.newBuffer(object.publicKey);
                }
                if (options.bytes === String)
                    object.kemCipher = "";
                else {
                    object.kemCipher = [];
                    if (options.bytes !== Array)
                        object.kemCipher = $util.newBuffer(object.kemCipher);
                }
                if (options.bytes === String)
                    object.commit = "";
                else {
                    object.commit = [];
                    if (options.bytes !== Array)
                        object.commit = $util.newBuffer(object.commit);
                }
            }
            if (message.identifier != null && message.hasOwnProperty("identifier"))
                object.identifier = message.identifier;
            if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                object.publicKey = options.bytes === String ? $util.base64.encode(message.publicKey, 0, message.publicKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.publicKey) : message.publicKey;
            if (message.kemCipher != null && message.hasOwnProperty("kemCipher"))
                object.kemCipher = options.bytes === String ? $util.base64.encode(message.kemCipher, 0, message.kemCipher.length) : options.bytes === Array ? Array.prototype.slice.call(message.kemCipher) : message.kemCipher;
            if (message.commit != null && message.hasOwnProperty("commit"))
                object.commit = options.bytes === String ? $util.base64.encode(message.commit, 0, message.commit.length) : options.bytes === Array ? Array.prototype.slice.call(message.commit) : message.commit;
            return object;
        };

        /**
         * Converts this ConnectionAppIdentifierPkKemCommitSeed to JSON.
         * @function toJSON
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConnectionAppIdentifierPkKemCommitSeed.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConnectionAppIdentifierPkKemCommitSeed
         * @function getTypeUrl
         * @memberof olvid.ConnectionAppIdentifierPkKemCommitSeed
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConnectionAppIdentifierPkKemCommitSeed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.ConnectionAppIdentifierPkKemCommitSeed";
        };

        return ConnectionAppIdentifierPkKemCommitSeed;
    })();

    olvid.ConnectionBrowserKemSeed = (function() {

        /**
         * Properties of a ConnectionBrowserKemSeed.
         * @memberof olvid
         * @interface IConnectionBrowserKemSeed
         * @property {Uint8Array|null} [kemCipher] ConnectionBrowserKemSeed kemCipher
         * @property {Uint8Array|null} [seed] ConnectionBrowserKemSeed seed
         */

        /**
         * Constructs a new ConnectionBrowserKemSeed.
         * @memberof olvid
         * @classdesc Represents a ConnectionBrowserKemSeed.
         * @implements IConnectionBrowserKemSeed
         * @constructor
         * @param {olvid.IConnectionBrowserKemSeed=} [properties] Properties to set
         */
        function ConnectionBrowserKemSeed(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConnectionBrowserKemSeed kemCipher.
         * @member {Uint8Array} kemCipher
         * @memberof olvid.ConnectionBrowserKemSeed
         * @instance
         */
        ConnectionBrowserKemSeed.prototype.kemCipher = $util.newBuffer([]);

        /**
         * ConnectionBrowserKemSeed seed.
         * @member {Uint8Array} seed
         * @memberof olvid.ConnectionBrowserKemSeed
         * @instance
         */
        ConnectionBrowserKemSeed.prototype.seed = $util.newBuffer([]);

        /**
         * Creates a new ConnectionBrowserKemSeed instance using the specified properties.
         * @function create
         * @memberof olvid.ConnectionBrowserKemSeed
         * @static
         * @param {olvid.IConnectionBrowserKemSeed=} [properties] Properties to set
         * @returns {olvid.ConnectionBrowserKemSeed} ConnectionBrowserKemSeed instance
         */
        ConnectionBrowserKemSeed.create = function create(properties) {
            return new ConnectionBrowserKemSeed(properties);
        };

        /**
         * Encodes the specified ConnectionBrowserKemSeed message. Does not implicitly {@link olvid.ConnectionBrowserKemSeed.verify|verify} messages.
         * @function encode
         * @memberof olvid.ConnectionBrowserKemSeed
         * @static
         * @param {olvid.IConnectionBrowserKemSeed} message ConnectionBrowserKemSeed message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectionBrowserKemSeed.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.kemCipher != null && Object.hasOwnProperty.call(message, "kemCipher"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.kemCipher);
            if (message.seed != null && Object.hasOwnProperty.call(message, "seed"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.seed);
            return writer;
        };

        /**
         * Encodes the specified ConnectionBrowserKemSeed message, length delimited. Does not implicitly {@link olvid.ConnectionBrowserKemSeed.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.ConnectionBrowserKemSeed
         * @static
         * @param {olvid.IConnectionBrowserKemSeed} message ConnectionBrowserKemSeed message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectionBrowserKemSeed.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConnectionBrowserKemSeed message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.ConnectionBrowserKemSeed
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.ConnectionBrowserKemSeed} ConnectionBrowserKemSeed
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectionBrowserKemSeed.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.ConnectionBrowserKemSeed();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.kemCipher = reader.bytes();
                        break;
                    }
                case 2: {
                        message.seed = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConnectionBrowserKemSeed message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.ConnectionBrowserKemSeed
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.ConnectionBrowserKemSeed} ConnectionBrowserKemSeed
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectionBrowserKemSeed.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConnectionBrowserKemSeed message.
         * @function verify
         * @memberof olvid.ConnectionBrowserKemSeed
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConnectionBrowserKemSeed.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.kemCipher != null && message.hasOwnProperty("kemCipher"))
                if (!(message.kemCipher && typeof message.kemCipher.length === "number" || $util.isString(message.kemCipher)))
                    return "kemCipher: buffer expected";
            if (message.seed != null && message.hasOwnProperty("seed"))
                if (!(message.seed && typeof message.seed.length === "number" || $util.isString(message.seed)))
                    return "seed: buffer expected";
            return null;
        };

        /**
         * Creates a ConnectionBrowserKemSeed message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.ConnectionBrowserKemSeed
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.ConnectionBrowserKemSeed} ConnectionBrowserKemSeed
         */
        ConnectionBrowserKemSeed.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.ConnectionBrowserKemSeed)
                return object;
            var message = new $root.olvid.ConnectionBrowserKemSeed();
            if (object.kemCipher != null)
                if (typeof object.kemCipher === "string")
                    $util.base64.decode(object.kemCipher, message.kemCipher = $util.newBuffer($util.base64.length(object.kemCipher)), 0);
                else if (object.kemCipher.length >= 0)
                    message.kemCipher = object.kemCipher;
            if (object.seed != null)
                if (typeof object.seed === "string")
                    $util.base64.decode(object.seed, message.seed = $util.newBuffer($util.base64.length(object.seed)), 0);
                else if (object.seed.length >= 0)
                    message.seed = object.seed;
            return message;
        };

        /**
         * Creates a plain object from a ConnectionBrowserKemSeed message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.ConnectionBrowserKemSeed
         * @static
         * @param {olvid.ConnectionBrowserKemSeed} message ConnectionBrowserKemSeed
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConnectionBrowserKemSeed.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.kemCipher = "";
                else {
                    object.kemCipher = [];
                    if (options.bytes !== Array)
                        object.kemCipher = $util.newBuffer(object.kemCipher);
                }
                if (options.bytes === String)
                    object.seed = "";
                else {
                    object.seed = [];
                    if (options.bytes !== Array)
                        object.seed = $util.newBuffer(object.seed);
                }
            }
            if (message.kemCipher != null && message.hasOwnProperty("kemCipher"))
                object.kemCipher = options.bytes === String ? $util.base64.encode(message.kemCipher, 0, message.kemCipher.length) : options.bytes === Array ? Array.prototype.slice.call(message.kemCipher) : message.kemCipher;
            if (message.seed != null && message.hasOwnProperty("seed"))
                object.seed = options.bytes === String ? $util.base64.encode(message.seed, 0, message.seed.length) : options.bytes === Array ? Array.prototype.slice.call(message.seed) : message.seed;
            return object;
        };

        /**
         * Converts this ConnectionBrowserKemSeed to JSON.
         * @function toJSON
         * @memberof olvid.ConnectionBrowserKemSeed
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConnectionBrowserKemSeed.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ConnectionBrowserKemSeed
         * @function getTypeUrl
         * @memberof olvid.ConnectionBrowserKemSeed
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConnectionBrowserKemSeed.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.ConnectionBrowserKemSeed";
        };

        return ConnectionBrowserKemSeed;
    })();

    olvid.QrCodeInfo = (function() {

        /**
         * Properties of a QrCodeInfo.
         * @memberof olvid
         * @interface IQrCodeInfo
         * @property {string|null} [identifier] QrCodeInfo identifier
         * @property {string|null} [serverUrl] QrCodeInfo serverUrl
         * @property {Uint8Array|null} [publicKey] QrCodeInfo publicKey
         * @property {string|null} [awsSessionCookieName] QrCodeInfo awsSessionCookieName
         * @property {Uint8Array|null} [awsSessionCookie] QrCodeInfo awsSessionCookie
         */

        /**
         * Constructs a new QrCodeInfo.
         * @memberof olvid
         * @classdesc Represents a QrCodeInfo.
         * @implements IQrCodeInfo
         * @constructor
         * @param {olvid.IQrCodeInfo=} [properties] Properties to set
         */
        function QrCodeInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QrCodeInfo identifier.
         * @member {string} identifier
         * @memberof olvid.QrCodeInfo
         * @instance
         */
        QrCodeInfo.prototype.identifier = "";

        /**
         * QrCodeInfo serverUrl.
         * @member {string} serverUrl
         * @memberof olvid.QrCodeInfo
         * @instance
         */
        QrCodeInfo.prototype.serverUrl = "";

        /**
         * QrCodeInfo publicKey.
         * @member {Uint8Array} publicKey
         * @memberof olvid.QrCodeInfo
         * @instance
         */
        QrCodeInfo.prototype.publicKey = $util.newBuffer([]);

        /**
         * QrCodeInfo awsSessionCookieName.
         * @member {string} awsSessionCookieName
         * @memberof olvid.QrCodeInfo
         * @instance
         */
        QrCodeInfo.prototype.awsSessionCookieName = "";

        /**
         * QrCodeInfo awsSessionCookie.
         * @member {Uint8Array} awsSessionCookie
         * @memberof olvid.QrCodeInfo
         * @instance
         */
        QrCodeInfo.prototype.awsSessionCookie = $util.newBuffer([]);

        /**
         * Creates a new QrCodeInfo instance using the specified properties.
         * @function create
         * @memberof olvid.QrCodeInfo
         * @static
         * @param {olvid.IQrCodeInfo=} [properties] Properties to set
         * @returns {olvid.QrCodeInfo} QrCodeInfo instance
         */
        QrCodeInfo.create = function create(properties) {
            return new QrCodeInfo(properties);
        };

        /**
         * Encodes the specified QrCodeInfo message. Does not implicitly {@link olvid.QrCodeInfo.verify|verify} messages.
         * @function encode
         * @memberof olvid.QrCodeInfo
         * @static
         * @param {olvid.IQrCodeInfo} message QrCodeInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QrCodeInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.identifier != null && Object.hasOwnProperty.call(message, "identifier"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.identifier);
            if (message.serverUrl != null && Object.hasOwnProperty.call(message, "serverUrl"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.serverUrl);
            if (message.publicKey != null && Object.hasOwnProperty.call(message, "publicKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.publicKey);
            if (message.awsSessionCookieName != null && Object.hasOwnProperty.call(message, "awsSessionCookieName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.awsSessionCookieName);
            if (message.awsSessionCookie != null && Object.hasOwnProperty.call(message, "awsSessionCookie"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.awsSessionCookie);
            return writer;
        };

        /**
         * Encodes the specified QrCodeInfo message, length delimited. Does not implicitly {@link olvid.QrCodeInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.QrCodeInfo
         * @static
         * @param {olvid.IQrCodeInfo} message QrCodeInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QrCodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QrCodeInfo message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.QrCodeInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.QrCodeInfo} QrCodeInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QrCodeInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.QrCodeInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.identifier = reader.string();
                        break;
                    }
                case 2: {
                        message.serverUrl = reader.string();
                        break;
                    }
                case 3: {
                        message.publicKey = reader.bytes();
                        break;
                    }
                case 4: {
                        message.awsSessionCookieName = reader.string();
                        break;
                    }
                case 5: {
                        message.awsSessionCookie = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QrCodeInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.QrCodeInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.QrCodeInfo} QrCodeInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QrCodeInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QrCodeInfo message.
         * @function verify
         * @memberof olvid.QrCodeInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QrCodeInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.identifier != null && message.hasOwnProperty("identifier"))
                if (!$util.isString(message.identifier))
                    return "identifier: string expected";
            if (message.serverUrl != null && message.hasOwnProperty("serverUrl"))
                if (!$util.isString(message.serverUrl))
                    return "serverUrl: string expected";
            if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                if (!(message.publicKey && typeof message.publicKey.length === "number" || $util.isString(message.publicKey)))
                    return "publicKey: buffer expected";
            if (message.awsSessionCookieName != null && message.hasOwnProperty("awsSessionCookieName"))
                if (!$util.isString(message.awsSessionCookieName))
                    return "awsSessionCookieName: string expected";
            if (message.awsSessionCookie != null && message.hasOwnProperty("awsSessionCookie"))
                if (!(message.awsSessionCookie && typeof message.awsSessionCookie.length === "number" || $util.isString(message.awsSessionCookie)))
                    return "awsSessionCookie: buffer expected";
            return null;
        };

        /**
         * Creates a QrCodeInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.QrCodeInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.QrCodeInfo} QrCodeInfo
         */
        QrCodeInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.QrCodeInfo)
                return object;
            var message = new $root.olvid.QrCodeInfo();
            if (object.identifier != null)
                message.identifier = String(object.identifier);
            if (object.serverUrl != null)
                message.serverUrl = String(object.serverUrl);
            if (object.publicKey != null)
                if (typeof object.publicKey === "string")
                    $util.base64.decode(object.publicKey, message.publicKey = $util.newBuffer($util.base64.length(object.publicKey)), 0);
                else if (object.publicKey.length >= 0)
                    message.publicKey = object.publicKey;
            if (object.awsSessionCookieName != null)
                message.awsSessionCookieName = String(object.awsSessionCookieName);
            if (object.awsSessionCookie != null)
                if (typeof object.awsSessionCookie === "string")
                    $util.base64.decode(object.awsSessionCookie, message.awsSessionCookie = $util.newBuffer($util.base64.length(object.awsSessionCookie)), 0);
                else if (object.awsSessionCookie.length >= 0)
                    message.awsSessionCookie = object.awsSessionCookie;
            return message;
        };

        /**
         * Creates a plain object from a QrCodeInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.QrCodeInfo
         * @static
         * @param {olvid.QrCodeInfo} message QrCodeInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QrCodeInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.identifier = "";
                object.serverUrl = "";
                if (options.bytes === String)
                    object.publicKey = "";
                else {
                    object.publicKey = [];
                    if (options.bytes !== Array)
                        object.publicKey = $util.newBuffer(object.publicKey);
                }
                object.awsSessionCookieName = "";
                if (options.bytes === String)
                    object.awsSessionCookie = "";
                else {
                    object.awsSessionCookie = [];
                    if (options.bytes !== Array)
                        object.awsSessionCookie = $util.newBuffer(object.awsSessionCookie);
                }
            }
            if (message.identifier != null && message.hasOwnProperty("identifier"))
                object.identifier = message.identifier;
            if (message.serverUrl != null && message.hasOwnProperty("serverUrl"))
                object.serverUrl = message.serverUrl;
            if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                object.publicKey = options.bytes === String ? $util.base64.encode(message.publicKey, 0, message.publicKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.publicKey) : message.publicKey;
            if (message.awsSessionCookieName != null && message.hasOwnProperty("awsSessionCookieName"))
                object.awsSessionCookieName = message.awsSessionCookieName;
            if (message.awsSessionCookie != null && message.hasOwnProperty("awsSessionCookie"))
                object.awsSessionCookie = options.bytes === String ? $util.base64.encode(message.awsSessionCookie, 0, message.awsSessionCookie.length) : options.bytes === Array ? Array.prototype.slice.call(message.awsSessionCookie) : message.awsSessionCookie;
            return object;
        };

        /**
         * Converts this QrCodeInfo to JSON.
         * @function toJSON
         * @memberof olvid.QrCodeInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QrCodeInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for QrCodeInfo
         * @function getTypeUrl
         * @memberof olvid.QrCodeInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QrCodeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.QrCodeInfo";
        };

        return QrCodeInfo;
    })();

    /**
     * AttachmentStatus enum.
     * @name olvid.AttachmentStatus
     * @enum {number}
     * @property {number} READY_FOR_DOWNLOAD=0 READY_FOR_DOWNLOAD value
     * @property {number} DOWNLOADING=1 DOWNLOADING value
     * @property {number} DOWNLOAD_FAILED=2 DOWNLOAD_FAILED value
     * @property {number} DOWNLOAD_FINISHED=3 DOWNLOAD_FINISHED value
     * @property {number} DOWNLOAD_DELETED=4 DOWNLOAD_DELETED value
     */
    olvid.AttachmentStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "READY_FOR_DOWNLOAD"] = 0;
        values[valuesById[1] = "DOWNLOADING"] = 1;
        values[valuesById[2] = "DOWNLOAD_FAILED"] = 2;
        values[valuesById[3] = "DOWNLOAD_FINISHED"] = 3;
        values[valuesById[4] = "DOWNLOAD_DELETED"] = 4;
        return values;
    })();

    olvid.Attachment = (function() {

        /**
         * Properties of an Attachment.
         * @memberof olvid
         * @interface IAttachment
         * @property {number|Long|null} [fyleId] Attachment fyleId
         * @property {number|Long|null} [messageId] Attachment messageId
         * @property {string|null} [name] Attachment name
         * @property {string|null} [mime] Attachment mime
         * @property {string|null} [path] Attachment path
         * @property {olvid.AttachmentStatus|null} [status] Attachment status
         * @property {number|Long|null} [size] Attachment size
         * @property {number|Long|null} [messageTimestamp] Attachment messageTimestamp
         * @property {number|Long|null} [discussionId] Attachment discussionId
         */

        /**
         * Constructs a new Attachment.
         * @memberof olvid
         * @classdesc Represents an Attachment.
         * @implements IAttachment
         * @constructor
         * @param {olvid.IAttachment=} [properties] Properties to set
         */
        function Attachment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Attachment fyleId.
         * @member {number|Long} fyleId
         * @memberof olvid.Attachment
         * @instance
         */
        Attachment.prototype.fyleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Attachment messageId.
         * @member {number|Long} messageId
         * @memberof olvid.Attachment
         * @instance
         */
        Attachment.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Attachment name.
         * @member {string} name
         * @memberof olvid.Attachment
         * @instance
         */
        Attachment.prototype.name = "";

        /**
         * Attachment mime.
         * @member {string} mime
         * @memberof olvid.Attachment
         * @instance
         */
        Attachment.prototype.mime = "";

        /**
         * Attachment path.
         * @member {string} path
         * @memberof olvid.Attachment
         * @instance
         */
        Attachment.prototype.path = "";

        /**
         * Attachment status.
         * @member {olvid.AttachmentStatus} status
         * @memberof olvid.Attachment
         * @instance
         */
        Attachment.prototype.status = 0;

        /**
         * Attachment size.
         * @member {number|Long} size
         * @memberof olvid.Attachment
         * @instance
         */
        Attachment.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Attachment messageTimestamp.
         * @member {number|Long} messageTimestamp
         * @memberof olvid.Attachment
         * @instance
         */
        Attachment.prototype.messageTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Attachment discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.Attachment
         * @instance
         */
        Attachment.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Attachment instance using the specified properties.
         * @function create
         * @memberof olvid.Attachment
         * @static
         * @param {olvid.IAttachment=} [properties] Properties to set
         * @returns {olvid.Attachment} Attachment instance
         */
        Attachment.create = function create(properties) {
            return new Attachment(properties);
        };

        /**
         * Encodes the specified Attachment message. Does not implicitly {@link olvid.Attachment.verify|verify} messages.
         * @function encode
         * @memberof olvid.Attachment
         * @static
         * @param {olvid.IAttachment} message Attachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Attachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fyleId != null && Object.hasOwnProperty.call(message, "fyleId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fyleId);
            if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.messageId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.path);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.status);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.size);
            if (message.messageTimestamp != null && Object.hasOwnProperty.call(message, "messageTimestamp"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.messageTimestamp);
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.discussionId);
            return writer;
        };

        /**
         * Encodes the specified Attachment message, length delimited. Does not implicitly {@link olvid.Attachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.Attachment
         * @static
         * @param {olvid.IAttachment} message Attachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Attachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Attachment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.Attachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.Attachment} Attachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Attachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.Attachment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fyleId = reader.int64();
                        break;
                    }
                case 2: {
                        message.messageId = reader.int64();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.mime = reader.string();
                        break;
                    }
                case 5: {
                        message.path = reader.string();
                        break;
                    }
                case 6: {
                        message.status = reader.int32();
                        break;
                    }
                case 7: {
                        message.size = reader.int64();
                        break;
                    }
                case 8: {
                        message.messageTimestamp = reader.int64();
                        break;
                    }
                case 9: {
                        message.discussionId = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Attachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.Attachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.Attachment} Attachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Attachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Attachment message.
         * @function verify
         * @memberof olvid.Attachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Attachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (!$util.isInteger(message.fyleId) && !(message.fyleId && $util.isInteger(message.fyleId.low) && $util.isInteger(message.fyleId.high)))
                    return "fyleId: integer|Long expected";
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                    return "messageId: integer|Long expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            if (message.messageTimestamp != null && message.hasOwnProperty("messageTimestamp"))
                if (!$util.isInteger(message.messageTimestamp) && !(message.messageTimestamp && $util.isInteger(message.messageTimestamp.low) && $util.isInteger(message.messageTimestamp.high)))
                    return "messageTimestamp: integer|Long expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            return null;
        };

        /**
         * Creates an Attachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.Attachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.Attachment} Attachment
         */
        Attachment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.Attachment)
                return object;
            var message = new $root.olvid.Attachment();
            if (object.fyleId != null)
                if ($util.Long)
                    (message.fyleId = $util.Long.fromValue(object.fyleId)).unsigned = false;
                else if (typeof object.fyleId === "string")
                    message.fyleId = parseInt(object.fyleId, 10);
                else if (typeof object.fyleId === "number")
                    message.fyleId = object.fyleId;
                else if (typeof object.fyleId === "object")
                    message.fyleId = new $util.LongBits(object.fyleId.low >>> 0, object.fyleId.high >>> 0).toNumber();
            if (object.messageId != null)
                if ($util.Long)
                    (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = false;
                else if (typeof object.messageId === "string")
                    message.messageId = parseInt(object.messageId, 10);
                else if (typeof object.messageId === "number")
                    message.messageId = object.messageId;
                else if (typeof object.messageId === "object")
                    message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber();
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            if (object.path != null)
                message.path = String(object.path);
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "READY_FOR_DOWNLOAD":
            case 0:
                message.status = 0;
                break;
            case "DOWNLOADING":
            case 1:
                message.status = 1;
                break;
            case "DOWNLOAD_FAILED":
            case 2:
                message.status = 2;
                break;
            case "DOWNLOAD_FINISHED":
            case 3:
                message.status = 3;
                break;
            case "DOWNLOAD_DELETED":
            case 4:
                message.status = 4;
                break;
            }
            if (object.size != null)
                if ($util.Long)
                    (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                else if (typeof object.size === "string")
                    message.size = parseInt(object.size, 10);
                else if (typeof object.size === "number")
                    message.size = object.size;
                else if (typeof object.size === "object")
                    message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
            if (object.messageTimestamp != null)
                if ($util.Long)
                    (message.messageTimestamp = $util.Long.fromValue(object.messageTimestamp)).unsigned = false;
                else if (typeof object.messageTimestamp === "string")
                    message.messageTimestamp = parseInt(object.messageTimestamp, 10);
                else if (typeof object.messageTimestamp === "number")
                    message.messageTimestamp = object.messageTimestamp;
                else if (typeof object.messageTimestamp === "object")
                    message.messageTimestamp = new $util.LongBits(object.messageTimestamp.low >>> 0, object.messageTimestamp.high >>> 0).toNumber();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an Attachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.Attachment
         * @static
         * @param {olvid.Attachment} message Attachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Attachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.fyleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fyleId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.messageId = options.longs === String ? "0" : 0;
                object.name = "";
                object.mime = "";
                object.path = "";
                object.status = options.enums === String ? "READY_FOR_DOWNLOAD" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.size = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.messageTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.messageTimestamp = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
            }
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (typeof message.fyleId === "number")
                    object.fyleId = options.longs === String ? String(message.fyleId) : message.fyleId;
                else
                    object.fyleId = options.longs === String ? $util.Long.prototype.toString.call(message.fyleId) : options.longs === Number ? new $util.LongBits(message.fyleId.low >>> 0, message.fyleId.high >>> 0).toNumber() : message.fyleId;
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (typeof message.messageId === "number")
                    object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
                else
                    object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber() : message.messageId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.olvid.AttachmentStatus[message.status] === undefined ? message.status : $root.olvid.AttachmentStatus[message.status] : message.status;
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size === "number")
                    object.size = options.longs === String ? String(message.size) : message.size;
                else
                    object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
            if (message.messageTimestamp != null && message.hasOwnProperty("messageTimestamp"))
                if (typeof message.messageTimestamp === "number")
                    object.messageTimestamp = options.longs === String ? String(message.messageTimestamp) : message.messageTimestamp;
                else
                    object.messageTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.messageTimestamp) : options.longs === Number ? new $util.LongBits(message.messageTimestamp.low >>> 0, message.messageTimestamp.high >>> 0).toNumber() : message.messageTimestamp;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            return object;
        };

        /**
         * Converts this Attachment to JSON.
         * @function toJSON
         * @memberof olvid.Attachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Attachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Attachment
         * @function getTypeUrl
         * @memberof olvid.Attachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Attachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.Attachment";
        };

        return Attachment;
    })();

    olvid.Discussion = (function() {

        /**
         * Properties of a Discussion.
         * @memberof olvid
         * @interface IDiscussion
         * @property {number|Long|null} [id] Discussion id
         * @property {string|null} [title] Discussion title
         * @property {Uint8Array|null} [contactIdentity] Discussion contactIdentity
         * @property {Uint8Array|null} [groupOwnerAndUid] Discussion groupOwnerAndUid
         * @property {number|null} [unreadMessagesCount] Discussion unreadMessagesCount
         * @property {olvid.IMessage|null} [lastMessage] Discussion lastMessage
         * @property {string|null} [photoURL] Discussion photoURL
         * @property {number|Long|null} [discussionTimestamp] Discussion discussionTimestamp
         */

        /**
         * Constructs a new Discussion.
         * @memberof olvid
         * @classdesc Represents a Discussion.
         * @implements IDiscussion
         * @constructor
         * @param {olvid.IDiscussion=} [properties] Properties to set
         */
        function Discussion(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Discussion id.
         * @member {number|Long} id
         * @memberof olvid.Discussion
         * @instance
         */
        Discussion.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Discussion title.
         * @member {string} title
         * @memberof olvid.Discussion
         * @instance
         */
        Discussion.prototype.title = "";

        /**
         * Discussion contactIdentity.
         * @member {Uint8Array} contactIdentity
         * @memberof olvid.Discussion
         * @instance
         */
        Discussion.prototype.contactIdentity = $util.newBuffer([]);

        /**
         * Discussion groupOwnerAndUid.
         * @member {Uint8Array} groupOwnerAndUid
         * @memberof olvid.Discussion
         * @instance
         */
        Discussion.prototype.groupOwnerAndUid = $util.newBuffer([]);

        /**
         * Discussion unreadMessagesCount.
         * @member {number} unreadMessagesCount
         * @memberof olvid.Discussion
         * @instance
         */
        Discussion.prototype.unreadMessagesCount = 0;

        /**
         * Discussion lastMessage.
         * @member {olvid.IMessage|null|undefined} lastMessage
         * @memberof olvid.Discussion
         * @instance
         */
        Discussion.prototype.lastMessage = null;

        /**
         * Discussion photoURL.
         * @member {string} photoURL
         * @memberof olvid.Discussion
         * @instance
         */
        Discussion.prototype.photoURL = "";

        /**
         * Discussion discussionTimestamp.
         * @member {number|Long} discussionTimestamp
         * @memberof olvid.Discussion
         * @instance
         */
        Discussion.prototype.discussionTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Discussion instance using the specified properties.
         * @function create
         * @memberof olvid.Discussion
         * @static
         * @param {olvid.IDiscussion=} [properties] Properties to set
         * @returns {olvid.Discussion} Discussion instance
         */
        Discussion.create = function create(properties) {
            return new Discussion(properties);
        };

        /**
         * Encodes the specified Discussion message. Does not implicitly {@link olvid.Discussion.verify|verify} messages.
         * @function encode
         * @memberof olvid.Discussion
         * @static
         * @param {olvid.IDiscussion} message Discussion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Discussion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.contactIdentity != null && Object.hasOwnProperty.call(message, "contactIdentity"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.contactIdentity);
            if (message.groupOwnerAndUid != null && Object.hasOwnProperty.call(message, "groupOwnerAndUid"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.groupOwnerAndUid);
            if (message.unreadMessagesCount != null && Object.hasOwnProperty.call(message, "unreadMessagesCount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.unreadMessagesCount);
            if (message.lastMessage != null && Object.hasOwnProperty.call(message, "lastMessage"))
                $root.olvid.Message.encode(message.lastMessage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.photoURL != null && Object.hasOwnProperty.call(message, "photoURL"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.photoURL);
            if (message.discussionTimestamp != null && Object.hasOwnProperty.call(message, "discussionTimestamp"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.discussionTimestamp);
            return writer;
        };

        /**
         * Encodes the specified Discussion message, length delimited. Does not implicitly {@link olvid.Discussion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.Discussion
         * @static
         * @param {olvid.IDiscussion} message Discussion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Discussion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Discussion message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.Discussion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.Discussion} Discussion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Discussion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.Discussion();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int64();
                        break;
                    }
                case 2: {
                        message.title = reader.string();
                        break;
                    }
                case 3: {
                        message.contactIdentity = reader.bytes();
                        break;
                    }
                case 4: {
                        message.groupOwnerAndUid = reader.bytes();
                        break;
                    }
                case 5: {
                        message.unreadMessagesCount = reader.int32();
                        break;
                    }
                case 6: {
                        message.lastMessage = $root.olvid.Message.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.photoURL = reader.string();
                        break;
                    }
                case 8: {
                        message.discussionTimestamp = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Discussion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.Discussion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.Discussion} Discussion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Discussion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Discussion message.
         * @function verify
         * @memberof olvid.Discussion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Discussion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.contactIdentity != null && message.hasOwnProperty("contactIdentity"))
                if (!(message.contactIdentity && typeof message.contactIdentity.length === "number" || $util.isString(message.contactIdentity)))
                    return "contactIdentity: buffer expected";
            if (message.groupOwnerAndUid != null && message.hasOwnProperty("groupOwnerAndUid"))
                if (!(message.groupOwnerAndUid && typeof message.groupOwnerAndUid.length === "number" || $util.isString(message.groupOwnerAndUid)))
                    return "groupOwnerAndUid: buffer expected";
            if (message.unreadMessagesCount != null && message.hasOwnProperty("unreadMessagesCount"))
                if (!$util.isInteger(message.unreadMessagesCount))
                    return "unreadMessagesCount: integer expected";
            if (message.lastMessage != null && message.hasOwnProperty("lastMessage")) {
                var error = $root.olvid.Message.verify(message.lastMessage);
                if (error)
                    return "lastMessage." + error;
            }
            if (message.photoURL != null && message.hasOwnProperty("photoURL"))
                if (!$util.isString(message.photoURL))
                    return "photoURL: string expected";
            if (message.discussionTimestamp != null && message.hasOwnProperty("discussionTimestamp"))
                if (!$util.isInteger(message.discussionTimestamp) && !(message.discussionTimestamp && $util.isInteger(message.discussionTimestamp.low) && $util.isInteger(message.discussionTimestamp.high)))
                    return "discussionTimestamp: integer|Long expected";
            return null;
        };

        /**
         * Creates a Discussion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.Discussion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.Discussion} Discussion
         */
        Discussion.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.Discussion)
                return object;
            var message = new $root.olvid.Discussion();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
            if (object.title != null)
                message.title = String(object.title);
            if (object.contactIdentity != null)
                if (typeof object.contactIdentity === "string")
                    $util.base64.decode(object.contactIdentity, message.contactIdentity = $util.newBuffer($util.base64.length(object.contactIdentity)), 0);
                else if (object.contactIdentity.length >= 0)
                    message.contactIdentity = object.contactIdentity;
            if (object.groupOwnerAndUid != null)
                if (typeof object.groupOwnerAndUid === "string")
                    $util.base64.decode(object.groupOwnerAndUid, message.groupOwnerAndUid = $util.newBuffer($util.base64.length(object.groupOwnerAndUid)), 0);
                else if (object.groupOwnerAndUid.length >= 0)
                    message.groupOwnerAndUid = object.groupOwnerAndUid;
            if (object.unreadMessagesCount != null)
                message.unreadMessagesCount = object.unreadMessagesCount | 0;
            if (object.lastMessage != null) {
                if (typeof object.lastMessage !== "object")
                    throw TypeError(".olvid.Discussion.lastMessage: object expected");
                message.lastMessage = $root.olvid.Message.fromObject(object.lastMessage);
            }
            if (object.photoURL != null)
                message.photoURL = String(object.photoURL);
            if (object.discussionTimestamp != null)
                if ($util.Long)
                    (message.discussionTimestamp = $util.Long.fromValue(object.discussionTimestamp)).unsigned = false;
                else if (typeof object.discussionTimestamp === "string")
                    message.discussionTimestamp = parseInt(object.discussionTimestamp, 10);
                else if (typeof object.discussionTimestamp === "number")
                    message.discussionTimestamp = object.discussionTimestamp;
                else if (typeof object.discussionTimestamp === "object")
                    message.discussionTimestamp = new $util.LongBits(object.discussionTimestamp.low >>> 0, object.discussionTimestamp.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Discussion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.Discussion
         * @static
         * @param {olvid.Discussion} message Discussion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Discussion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.title = "";
                if (options.bytes === String)
                    object.contactIdentity = "";
                else {
                    object.contactIdentity = [];
                    if (options.bytes !== Array)
                        object.contactIdentity = $util.newBuffer(object.contactIdentity);
                }
                if (options.bytes === String)
                    object.groupOwnerAndUid = "";
                else {
                    object.groupOwnerAndUid = [];
                    if (options.bytes !== Array)
                        object.groupOwnerAndUid = $util.newBuffer(object.groupOwnerAndUid);
                }
                object.unreadMessagesCount = 0;
                object.lastMessage = null;
                object.photoURL = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionTimestamp = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.contactIdentity != null && message.hasOwnProperty("contactIdentity"))
                object.contactIdentity = options.bytes === String ? $util.base64.encode(message.contactIdentity, 0, message.contactIdentity.length) : options.bytes === Array ? Array.prototype.slice.call(message.contactIdentity) : message.contactIdentity;
            if (message.groupOwnerAndUid != null && message.hasOwnProperty("groupOwnerAndUid"))
                object.groupOwnerAndUid = options.bytes === String ? $util.base64.encode(message.groupOwnerAndUid, 0, message.groupOwnerAndUid.length) : options.bytes === Array ? Array.prototype.slice.call(message.groupOwnerAndUid) : message.groupOwnerAndUid;
            if (message.unreadMessagesCount != null && message.hasOwnProperty("unreadMessagesCount"))
                object.unreadMessagesCount = message.unreadMessagesCount;
            if (message.lastMessage != null && message.hasOwnProperty("lastMessage"))
                object.lastMessage = $root.olvid.Message.toObject(message.lastMessage, options);
            if (message.photoURL != null && message.hasOwnProperty("photoURL"))
                object.photoURL = message.photoURL;
            if (message.discussionTimestamp != null && message.hasOwnProperty("discussionTimestamp"))
                if (typeof message.discussionTimestamp === "number")
                    object.discussionTimestamp = options.longs === String ? String(message.discussionTimestamp) : message.discussionTimestamp;
                else
                    object.discussionTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.discussionTimestamp) : options.longs === Number ? new $util.LongBits(message.discussionTimestamp.low >>> 0, message.discussionTimestamp.high >>> 0).toNumber() : message.discussionTimestamp;
            return object;
        };

        /**
         * Converts this Discussion to JSON.
         * @function toJSON
         * @memberof olvid.Discussion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Discussion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Discussion
         * @function getTypeUrl
         * @memberof olvid.Discussion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Discussion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.Discussion";
        };

        return Discussion;
    })();

    /**
     * DraftAttachmentStatus enum.
     * @name olvid.DraftAttachmentStatus
     * @enum {number}
     * @property {number} LOADING=0 LOADING value
     * @property {number} READY=1 READY value
     * @property {number} FAILED=2 FAILED value
     * @property {number} DELETED=3 DELETED value
     */
    olvid.DraftAttachmentStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LOADING"] = 0;
        values[valuesById[1] = "READY"] = 1;
        values[valuesById[2] = "FAILED"] = 2;
        values[valuesById[3] = "DELETED"] = 3;
        return values;
    })();

    olvid.DraftAttachment = (function() {

        /**
         * Properties of a DraftAttachment.
         * @memberof olvid
         * @interface IDraftAttachment
         * @property {number|Long|null} [fyleId] DraftAttachment fyleId
         * @property {number|Long|null} [messageId] DraftAttachment messageId
         * @property {number|Long|null} [discussionId] DraftAttachment discussionId
         * @property {string|null} [name] DraftAttachment name
         * @property {string|null} [mime] DraftAttachment mime
         * @property {Uint8Array|null} [sha256] DraftAttachment sha256
         * @property {string|null} [path] DraftAttachment path
         * @property {olvid.DraftAttachmentStatus|null} [status] DraftAttachment status
         */

        /**
         * Constructs a new DraftAttachment.
         * @memberof olvid
         * @classdesc Represents a DraftAttachment.
         * @implements IDraftAttachment
         * @constructor
         * @param {olvid.IDraftAttachment=} [properties] Properties to set
         */
        function DraftAttachment(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DraftAttachment fyleId.
         * @member {number|Long} fyleId
         * @memberof olvid.DraftAttachment
         * @instance
         */
        DraftAttachment.prototype.fyleId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DraftAttachment messageId.
         * @member {number|Long} messageId
         * @memberof olvid.DraftAttachment
         * @instance
         */
        DraftAttachment.prototype.messageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DraftAttachment discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.DraftAttachment
         * @instance
         */
        DraftAttachment.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * DraftAttachment name.
         * @member {string} name
         * @memberof olvid.DraftAttachment
         * @instance
         */
        DraftAttachment.prototype.name = "";

        /**
         * DraftAttachment mime.
         * @member {string} mime
         * @memberof olvid.DraftAttachment
         * @instance
         */
        DraftAttachment.prototype.mime = "";

        /**
         * DraftAttachment sha256.
         * @member {Uint8Array} sha256
         * @memberof olvid.DraftAttachment
         * @instance
         */
        DraftAttachment.prototype.sha256 = $util.newBuffer([]);

        /**
         * DraftAttachment path.
         * @member {string} path
         * @memberof olvid.DraftAttachment
         * @instance
         */
        DraftAttachment.prototype.path = "";

        /**
         * DraftAttachment status.
         * @member {olvid.DraftAttachmentStatus} status
         * @memberof olvid.DraftAttachment
         * @instance
         */
        DraftAttachment.prototype.status = 0;

        /**
         * Creates a new DraftAttachment instance using the specified properties.
         * @function create
         * @memberof olvid.DraftAttachment
         * @static
         * @param {olvid.IDraftAttachment=} [properties] Properties to set
         * @returns {olvid.DraftAttachment} DraftAttachment instance
         */
        DraftAttachment.create = function create(properties) {
            return new DraftAttachment(properties);
        };

        /**
         * Encodes the specified DraftAttachment message. Does not implicitly {@link olvid.DraftAttachment.verify|verify} messages.
         * @function encode
         * @memberof olvid.DraftAttachment
         * @static
         * @param {olvid.IDraftAttachment} message DraftAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DraftAttachment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fyleId != null && Object.hasOwnProperty.call(message, "fyleId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fyleId);
            if (message.messageId != null && Object.hasOwnProperty.call(message, "messageId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.messageId);
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.discussionId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.mime);
            if (message.sha256 != null && Object.hasOwnProperty.call(message, "sha256"))
                writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.sha256);
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.path);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.status);
            return writer;
        };

        /**
         * Encodes the specified DraftAttachment message, length delimited. Does not implicitly {@link olvid.DraftAttachment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.DraftAttachment
         * @static
         * @param {olvid.IDraftAttachment} message DraftAttachment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DraftAttachment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DraftAttachment message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.DraftAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.DraftAttachment} DraftAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DraftAttachment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.DraftAttachment();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fyleId = reader.int64();
                        break;
                    }
                case 2: {
                        message.messageId = reader.int64();
                        break;
                    }
                case 3: {
                        message.discussionId = reader.int64();
                        break;
                    }
                case 4: {
                        message.name = reader.string();
                        break;
                    }
                case 5: {
                        message.mime = reader.string();
                        break;
                    }
                case 6: {
                        message.sha256 = reader.bytes();
                        break;
                    }
                case 7: {
                        message.path = reader.string();
                        break;
                    }
                case 8: {
                        message.status = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DraftAttachment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.DraftAttachment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.DraftAttachment} DraftAttachment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DraftAttachment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DraftAttachment message.
         * @function verify
         * @memberof olvid.DraftAttachment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DraftAttachment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (!$util.isInteger(message.fyleId) && !(message.fyleId && $util.isInteger(message.fyleId.low) && $util.isInteger(message.fyleId.high)))
                    return "fyleId: integer|Long expected";
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (!$util.isInteger(message.messageId) && !(message.messageId && $util.isInteger(message.messageId.low) && $util.isInteger(message.messageId.high)))
                    return "messageId: integer|Long expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.sha256 != null && message.hasOwnProperty("sha256"))
                if (!(message.sha256 && typeof message.sha256.length === "number" || $util.isString(message.sha256)))
                    return "sha256: buffer expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a DraftAttachment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.DraftAttachment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.DraftAttachment} DraftAttachment
         */
        DraftAttachment.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.DraftAttachment)
                return object;
            var message = new $root.olvid.DraftAttachment();
            if (object.fyleId != null)
                if ($util.Long)
                    (message.fyleId = $util.Long.fromValue(object.fyleId)).unsigned = false;
                else if (typeof object.fyleId === "string")
                    message.fyleId = parseInt(object.fyleId, 10);
                else if (typeof object.fyleId === "number")
                    message.fyleId = object.fyleId;
                else if (typeof object.fyleId === "object")
                    message.fyleId = new $util.LongBits(object.fyleId.low >>> 0, object.fyleId.high >>> 0).toNumber();
            if (object.messageId != null)
                if ($util.Long)
                    (message.messageId = $util.Long.fromValue(object.messageId)).unsigned = false;
                else if (typeof object.messageId === "string")
                    message.messageId = parseInt(object.messageId, 10);
                else if (typeof object.messageId === "number")
                    message.messageId = object.messageId;
                else if (typeof object.messageId === "object")
                    message.messageId = new $util.LongBits(object.messageId.low >>> 0, object.messageId.high >>> 0).toNumber();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            if (object.sha256 != null)
                if (typeof object.sha256 === "string")
                    $util.base64.decode(object.sha256, message.sha256 = $util.newBuffer($util.base64.length(object.sha256)), 0);
                else if (object.sha256.length >= 0)
                    message.sha256 = object.sha256;
            if (object.path != null)
                message.path = String(object.path);
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "LOADING":
            case 0:
                message.status = 0;
                break;
            case "READY":
            case 1:
                message.status = 1;
                break;
            case "FAILED":
            case 2:
                message.status = 2;
                break;
            case "DELETED":
            case 3:
                message.status = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a DraftAttachment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.DraftAttachment
         * @static
         * @param {olvid.DraftAttachment} message DraftAttachment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DraftAttachment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.fyleId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fyleId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.messageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.messageId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
                object.name = "";
                object.mime = "";
                if (options.bytes === String)
                    object.sha256 = "";
                else {
                    object.sha256 = [];
                    if (options.bytes !== Array)
                        object.sha256 = $util.newBuffer(object.sha256);
                }
                object.path = "";
                object.status = options.enums === String ? "LOADING" : 0;
            }
            if (message.fyleId != null && message.hasOwnProperty("fyleId"))
                if (typeof message.fyleId === "number")
                    object.fyleId = options.longs === String ? String(message.fyleId) : message.fyleId;
                else
                    object.fyleId = options.longs === String ? $util.Long.prototype.toString.call(message.fyleId) : options.longs === Number ? new $util.LongBits(message.fyleId.low >>> 0, message.fyleId.high >>> 0).toNumber() : message.fyleId;
            if (message.messageId != null && message.hasOwnProperty("messageId"))
                if (typeof message.messageId === "number")
                    object.messageId = options.longs === String ? String(message.messageId) : message.messageId;
                else
                    object.messageId = options.longs === String ? $util.Long.prototype.toString.call(message.messageId) : options.longs === Number ? new $util.LongBits(message.messageId.low >>> 0, message.messageId.high >>> 0).toNumber() : message.messageId;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.sha256 != null && message.hasOwnProperty("sha256"))
                object.sha256 = options.bytes === String ? $util.base64.encode(message.sha256, 0, message.sha256.length) : options.bytes === Array ? Array.prototype.slice.call(message.sha256) : message.sha256;
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.olvid.DraftAttachmentStatus[message.status] === undefined ? message.status : $root.olvid.DraftAttachmentStatus[message.status] : message.status;
            return object;
        };

        /**
         * Converts this DraftAttachment to JSON.
         * @function toJSON
         * @memberof olvid.DraftAttachment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DraftAttachment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DraftAttachment
         * @function getTypeUrl
         * @memberof olvid.DraftAttachment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DraftAttachment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.DraftAttachment";
        };

        return DraftAttachment;
    })();

    /**
     * MessageStatus enum.
     * @name olvid.MessageStatus
     * @enum {number}
     * @property {number} STATUS_DEFAULT=0 STATUS_DEFAULT value
     * @property {number} STATUS_PROCESSING=1 STATUS_PROCESSING value
     * @property {number} STATUS_SENT=2 STATUS_SENT value
     * @property {number} STATUS_UNREAD=3 STATUS_UNREAD value
     * @property {number} STATUS_READ=4 STATUS_READ value
     * @property {number} STATUS_DRAFT=5 STATUS_DRAFT value
     * @property {number} STATUS_DELIVERED=6 STATUS_DELIVERED value
     * @property {number} STATUS_DELIVERED_AND_READ=7 STATUS_DELIVERED_AND_READ value
     */
    olvid.MessageStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STATUS_DEFAULT"] = 0;
        values[valuesById[1] = "STATUS_PROCESSING"] = 1;
        values[valuesById[2] = "STATUS_SENT"] = 2;
        values[valuesById[3] = "STATUS_UNREAD"] = 3;
        values[valuesById[4] = "STATUS_READ"] = 4;
        values[valuesById[5] = "STATUS_DRAFT"] = 5;
        values[valuesById[6] = "STATUS_DELIVERED"] = 6;
        values[valuesById[7] = "STATUS_DELIVERED_AND_READ"] = 7;
        return values;
    })();

    /**
     * MessageType enum.
     * @name olvid.MessageType
     * @enum {number}
     * @property {number} TYPE_DEFAULT=0 TYPE_DEFAULT value
     * @property {number} INBOUND_MESSAGE=1 INBOUND_MESSAGE value
     * @property {number} OUTBOUND_MESSAGE=2 OUTBOUND_MESSAGE value
     * @property {number} GROUP_MEMBER_JOINED=3 GROUP_MEMBER_JOINED value
     * @property {number} GROUP_MEMBER_LEFT=4 GROUP_MEMBER_LEFT value
     * @property {number} LEFT_GROUP=5 LEFT_GROUP value
     * @property {number} CONTACT_DELETED=6 CONTACT_DELETED value
     * @property {number} TYPE_INBOUND_EPHEMERAL_MESSAGE=7 TYPE_INBOUND_EPHEMERAL_MESSAGE value
     * @property {number} TYPE_DISCUSSION_SETTINGS_UPDATE=8 TYPE_DISCUSSION_SETTINGS_UPDATE value
     * @property {number} TYPE_DISCUSSION_REMOTELY_DELETED=9 TYPE_DISCUSSION_REMOTELY_DELETED value
     * @property {number} TYPE_PHONE_CALL=10 TYPE_PHONE_CALL value
     * @property {number} TYPE_NEW_PUBLISHED_DETAILS=11 TYPE_NEW_PUBLISHED_DETAILS value
     * @property {number} TYPE_CONTACT_INACTIVE_REASON=12 TYPE_CONTACT_INACTIVE_REASON value
     */
    olvid.MessageType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TYPE_DEFAULT"] = 0;
        values[valuesById[1] = "INBOUND_MESSAGE"] = 1;
        values[valuesById[2] = "OUTBOUND_MESSAGE"] = 2;
        values[valuesById[3] = "GROUP_MEMBER_JOINED"] = 3;
        values[valuesById[4] = "GROUP_MEMBER_LEFT"] = 4;
        values[valuesById[5] = "LEFT_GROUP"] = 5;
        values[valuesById[6] = "CONTACT_DELETED"] = 6;
        values[valuesById[7] = "TYPE_INBOUND_EPHEMERAL_MESSAGE"] = 7;
        values[valuesById[8] = "TYPE_DISCUSSION_SETTINGS_UPDATE"] = 8;
        values[valuesById[9] = "TYPE_DISCUSSION_REMOTELY_DELETED"] = 9;
        values[valuesById[10] = "TYPE_PHONE_CALL"] = 10;
        values[valuesById[11] = "TYPE_NEW_PUBLISHED_DETAILS"] = 11;
        values[valuesById[12] = "TYPE_CONTACT_INACTIVE_REASON"] = 12;
        return values;
    })();

    /**
     * EditedStatus enum.
     * @name olvid.EditedStatus
     * @enum {number}
     * @property {number} EDITED_NONE=0 EDITED_NONE value
     * @property {number} EDITED_UNSEEN=1 EDITED_UNSEEN value
     * @property {number} EDITED_SEEN=2 EDITED_SEEN value
     */
    olvid.EditedStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EDITED_NONE"] = 0;
        values[valuesById[1] = "EDITED_UNSEEN"] = 1;
        values[valuesById[2] = "EDITED_SEEN"] = 2;
        return values;
    })();

    olvid.Message = (function() {

        /**
         * Properties of a Message.
         * @memberof olvid
         * @interface IMessage
         * @property {number|Long|null} [id] Message id
         * @property {number|Long|null} [discussionId] Message discussionId
         * @property {number|null} [sortIndex] Message sortIndex
         * @property {olvid.MessageStatus|null} [status] Message status
         * @property {olvid.MessageType|null} [type] Message type
         * @property {string|null} [contentBody] Message contentBody
         * @property {number|Long|null} [timestamp] Message timestamp
         * @property {number|null} [totalAttachmentCount] Message totalAttachmentCount
         * @property {number|null} [imageCount] Message imageCount
         * @property {Uint8Array|null} [senderIdentifier] Message senderIdentifier
         * @property {string|null} [senderName] Message senderName
         * @property {number|Long|null} [replyMessageId] Message replyMessageId
         * @property {number|Long|null} [replyMessageAttachmentCount] Message replyMessageAttachmentCount
         * @property {Uint8Array|null} [replySenderIdentifier] Message replySenderIdentifier
         * @property {string|null} [replyAuthor] Message replyAuthor
         * @property {string|null} [replyBody] Message replyBody
         * @property {boolean|null} [readOnce] Message readOnce
         * @property {string|null} [visibilityDuration] Message visibilityDuration
         * @property {string|null} [existenceDuration] Message existenceDuration
         * @property {boolean|null} [wiped] Message wiped
         * @property {boolean|null} [remotelyDeleted] Message remotelyDeleted
         * @property {boolean|null} [wipeOnRead] Message wipeOnRead
         * @property {number|Long|null} [edited] Message edited
         * @property {boolean|null} [senderIsSelf] Message senderIsSelf
         * @property {string|null} [reactions] Message reactions
         */

        /**
         * Constructs a new Message.
         * @memberof olvid
         * @classdesc Represents a Message.
         * @implements IMessage
         * @constructor
         * @param {olvid.IMessage=} [properties] Properties to set
         */
        function Message(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Message id.
         * @member {number|Long} id
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Message discussionId.
         * @member {number|Long} discussionId
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.discussionId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Message sortIndex.
         * @member {number} sortIndex
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.sortIndex = 0;

        /**
         * Message status.
         * @member {olvid.MessageStatus} status
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.status = 0;

        /**
         * Message type.
         * @member {olvid.MessageType} type
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.type = 0;

        /**
         * Message contentBody.
         * @member {string} contentBody
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.contentBody = "";

        /**
         * Message timestamp.
         * @member {number|Long} timestamp
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Message totalAttachmentCount.
         * @member {number} totalAttachmentCount
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.totalAttachmentCount = 0;

        /**
         * Message imageCount.
         * @member {number} imageCount
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.imageCount = 0;

        /**
         * Message senderIdentifier.
         * @member {Uint8Array} senderIdentifier
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.senderIdentifier = $util.newBuffer([]);

        /**
         * Message senderName.
         * @member {string} senderName
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.senderName = "";

        /**
         * Message replyMessageId.
         * @member {number|Long} replyMessageId
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.replyMessageId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Message replyMessageAttachmentCount.
         * @member {number|Long} replyMessageAttachmentCount
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.replyMessageAttachmentCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Message replySenderIdentifier.
         * @member {Uint8Array} replySenderIdentifier
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.replySenderIdentifier = $util.newBuffer([]);

        /**
         * Message replyAuthor.
         * @member {string} replyAuthor
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.replyAuthor = "";

        /**
         * Message replyBody.
         * @member {string} replyBody
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.replyBody = "";

        /**
         * Message readOnce.
         * @member {boolean} readOnce
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.readOnce = false;

        /**
         * Message visibilityDuration.
         * @member {string} visibilityDuration
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.visibilityDuration = "";

        /**
         * Message existenceDuration.
         * @member {string} existenceDuration
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.existenceDuration = "";

        /**
         * Message wiped.
         * @member {boolean} wiped
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.wiped = false;

        /**
         * Message remotelyDeleted.
         * @member {boolean} remotelyDeleted
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.remotelyDeleted = false;

        /**
         * Message wipeOnRead.
         * @member {boolean} wipeOnRead
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.wipeOnRead = false;

        /**
         * Message edited.
         * @member {number|Long} edited
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.edited = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Message senderIsSelf.
         * @member {boolean} senderIsSelf
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.senderIsSelf = false;

        /**
         * Message reactions.
         * @member {string} reactions
         * @memberof olvid.Message
         * @instance
         */
        Message.prototype.reactions = "";

        /**
         * Creates a new Message instance using the specified properties.
         * @function create
         * @memberof olvid.Message
         * @static
         * @param {olvid.IMessage=} [properties] Properties to set
         * @returns {olvid.Message} Message instance
         */
        Message.create = function create(properties) {
            return new Message(properties);
        };

        /**
         * Encodes the specified Message message. Does not implicitly {@link olvid.Message.verify|verify} messages.
         * @function encode
         * @memberof olvid.Message
         * @static
         * @param {olvid.IMessage} message Message message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Message.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
            if (message.discussionId != null && Object.hasOwnProperty.call(message, "discussionId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.discussionId);
            if (message.sortIndex != null && Object.hasOwnProperty.call(message, "sortIndex"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.sortIndex);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
            if (message.contentBody != null && Object.hasOwnProperty.call(message, "contentBody"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.contentBody);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.timestamp);
            if (message.totalAttachmentCount != null && Object.hasOwnProperty.call(message, "totalAttachmentCount"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.totalAttachmentCount);
            if (message.imageCount != null && Object.hasOwnProperty.call(message, "imageCount"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.imageCount);
            if (message.senderIdentifier != null && Object.hasOwnProperty.call(message, "senderIdentifier"))
                writer.uint32(/* id 10, wireType 2 =*/82).bytes(message.senderIdentifier);
            if (message.senderName != null && Object.hasOwnProperty.call(message, "senderName"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.senderName);
            if (message.replyMessageId != null && Object.hasOwnProperty.call(message, "replyMessageId"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.replyMessageId);
            if (message.replyMessageAttachmentCount != null && Object.hasOwnProperty.call(message, "replyMessageAttachmentCount"))
                writer.uint32(/* id 13, wireType 0 =*/104).int64(message.replyMessageAttachmentCount);
            if (message.replySenderIdentifier != null && Object.hasOwnProperty.call(message, "replySenderIdentifier"))
                writer.uint32(/* id 14, wireType 2 =*/114).bytes(message.replySenderIdentifier);
            if (message.replyAuthor != null && Object.hasOwnProperty.call(message, "replyAuthor"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.replyAuthor);
            if (message.replyBody != null && Object.hasOwnProperty.call(message, "replyBody"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.replyBody);
            if (message.readOnce != null && Object.hasOwnProperty.call(message, "readOnce"))
                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.readOnce);
            if (message.visibilityDuration != null && Object.hasOwnProperty.call(message, "visibilityDuration"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.visibilityDuration);
            if (message.existenceDuration != null && Object.hasOwnProperty.call(message, "existenceDuration"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.existenceDuration);
            if (message.wiped != null && Object.hasOwnProperty.call(message, "wiped"))
                writer.uint32(/* id 20, wireType 0 =*/160).bool(message.wiped);
            if (message.remotelyDeleted != null && Object.hasOwnProperty.call(message, "remotelyDeleted"))
                writer.uint32(/* id 21, wireType 0 =*/168).bool(message.remotelyDeleted);
            if (message.wipeOnRead != null && Object.hasOwnProperty.call(message, "wipeOnRead"))
                writer.uint32(/* id 22, wireType 0 =*/176).bool(message.wipeOnRead);
            if (message.edited != null && Object.hasOwnProperty.call(message, "edited"))
                writer.uint32(/* id 23, wireType 0 =*/184).int64(message.edited);
            if (message.senderIsSelf != null && Object.hasOwnProperty.call(message, "senderIsSelf"))
                writer.uint32(/* id 24, wireType 0 =*/192).bool(message.senderIsSelf);
            if (message.reactions != null && Object.hasOwnProperty.call(message, "reactions"))
                writer.uint32(/* id 25, wireType 2 =*/202).string(message.reactions);
            return writer;
        };

        /**
         * Encodes the specified Message message, length delimited. Does not implicitly {@link olvid.Message.verify|verify} messages.
         * @function encodeDelimited
         * @memberof olvid.Message
         * @static
         * @param {olvid.IMessage} message Message message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Message.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Message message from the specified reader or buffer.
         * @function decode
         * @memberof olvid.Message
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {olvid.Message} Message
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Message.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.olvid.Message();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.int64();
                        break;
                    }
                case 2: {
                        message.discussionId = reader.int64();
                        break;
                    }
                case 3: {
                        message.sortIndex = reader.double();
                        break;
                    }
                case 4: {
                        message.status = reader.int32();
                        break;
                    }
                case 5: {
                        message.type = reader.int32();
                        break;
                    }
                case 6: {
                        message.contentBody = reader.string();
                        break;
                    }
                case 7: {
                        message.timestamp = reader.int64();
                        break;
                    }
                case 8: {
                        message.totalAttachmentCount = reader.int32();
                        break;
                    }
                case 9: {
                        message.imageCount = reader.int32();
                        break;
                    }
                case 10: {
                        message.senderIdentifier = reader.bytes();
                        break;
                    }
                case 11: {
                        message.senderName = reader.string();
                        break;
                    }
                case 12: {
                        message.replyMessageId = reader.int64();
                        break;
                    }
                case 13: {
                        message.replyMessageAttachmentCount = reader.int64();
                        break;
                    }
                case 14: {
                        message.replySenderIdentifier = reader.bytes();
                        break;
                    }
                case 15: {
                        message.replyAuthor = reader.string();
                        break;
                    }
                case 16: {
                        message.replyBody = reader.string();
                        break;
                    }
                case 17: {
                        message.readOnce = reader.bool();
                        break;
                    }
                case 18: {
                        message.visibilityDuration = reader.string();
                        break;
                    }
                case 19: {
                        message.existenceDuration = reader.string();
                        break;
                    }
                case 20: {
                        message.wiped = reader.bool();
                        break;
                    }
                case 21: {
                        message.remotelyDeleted = reader.bool();
                        break;
                    }
                case 22: {
                        message.wipeOnRead = reader.bool();
                        break;
                    }
                case 23: {
                        message.edited = reader.int64();
                        break;
                    }
                case 24: {
                        message.senderIsSelf = reader.bool();
                        break;
                    }
                case 25: {
                        message.reactions = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Message message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof olvid.Message
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {olvid.Message} Message
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Message.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Message message.
         * @function verify
         * @memberof olvid.Message
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Message.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (!$util.isInteger(message.discussionId) && !(message.discussionId && $util.isInteger(message.discussionId.low) && $util.isInteger(message.discussionId.high)))
                    return "discussionId: integer|Long expected";
            if (message.sortIndex != null && message.hasOwnProperty("sortIndex"))
                if (typeof message.sortIndex !== "number")
                    return "sortIndex: number expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                    break;
                }
            if (message.contentBody != null && message.hasOwnProperty("contentBody"))
                if (!$util.isString(message.contentBody))
                    return "contentBody: string expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message.totalAttachmentCount != null && message.hasOwnProperty("totalAttachmentCount"))
                if (!$util.isInteger(message.totalAttachmentCount))
                    return "totalAttachmentCount: integer expected";
            if (message.imageCount != null && message.hasOwnProperty("imageCount"))
                if (!$util.isInteger(message.imageCount))
                    return "imageCount: integer expected";
            if (message.senderIdentifier != null && message.hasOwnProperty("senderIdentifier"))
                if (!(message.senderIdentifier && typeof message.senderIdentifier.length === "number" || $util.isString(message.senderIdentifier)))
                    return "senderIdentifier: buffer expected";
            if (message.senderName != null && message.hasOwnProperty("senderName"))
                if (!$util.isString(message.senderName))
                    return "senderName: string expected";
            if (message.replyMessageId != null && message.hasOwnProperty("replyMessageId"))
                if (!$util.isInteger(message.replyMessageId) && !(message.replyMessageId && $util.isInteger(message.replyMessageId.low) && $util.isInteger(message.replyMessageId.high)))
                    return "replyMessageId: integer|Long expected";
            if (message.replyMessageAttachmentCount != null && message.hasOwnProperty("replyMessageAttachmentCount"))
                if (!$util.isInteger(message.replyMessageAttachmentCount) && !(message.replyMessageAttachmentCount && $util.isInteger(message.replyMessageAttachmentCount.low) && $util.isInteger(message.replyMessageAttachmentCount.high)))
                    return "replyMessageAttachmentCount: integer|Long expected";
            if (message.replySenderIdentifier != null && message.hasOwnProperty("replySenderIdentifier"))
                if (!(message.replySenderIdentifier && typeof message.replySenderIdentifier.length === "number" || $util.isString(message.replySenderIdentifier)))
                    return "replySenderIdentifier: buffer expected";
            if (message.replyAuthor != null && message.hasOwnProperty("replyAuthor"))
                if (!$util.isString(message.replyAuthor))
                    return "replyAuthor: string expected";
            if (message.replyBody != null && message.hasOwnProperty("replyBody"))
                if (!$util.isString(message.replyBody))
                    return "replyBody: string expected";
            if (message.readOnce != null && message.hasOwnProperty("readOnce"))
                if (typeof message.readOnce !== "boolean")
                    return "readOnce: boolean expected";
            if (message.visibilityDuration != null && message.hasOwnProperty("visibilityDuration"))
                if (!$util.isString(message.visibilityDuration))
                    return "visibilityDuration: string expected";
            if (message.existenceDuration != null && message.hasOwnProperty("existenceDuration"))
                if (!$util.isString(message.existenceDuration))
                    return "existenceDuration: string expected";
            if (message.wiped != null && message.hasOwnProperty("wiped"))
                if (typeof message.wiped !== "boolean")
                    return "wiped: boolean expected";
            if (message.remotelyDeleted != null && message.hasOwnProperty("remotelyDeleted"))
                if (typeof message.remotelyDeleted !== "boolean")
                    return "remotelyDeleted: boolean expected";
            if (message.wipeOnRead != null && message.hasOwnProperty("wipeOnRead"))
                if (typeof message.wipeOnRead !== "boolean")
                    return "wipeOnRead: boolean expected";
            if (message.edited != null && message.hasOwnProperty("edited"))
                if (!$util.isInteger(message.edited) && !(message.edited && $util.isInteger(message.edited.low) && $util.isInteger(message.edited.high)))
                    return "edited: integer|Long expected";
            if (message.senderIsSelf != null && message.hasOwnProperty("senderIsSelf"))
                if (typeof message.senderIsSelf !== "boolean")
                    return "senderIsSelf: boolean expected";
            if (message.reactions != null && message.hasOwnProperty("reactions"))
                if (!$util.isString(message.reactions))
                    return "reactions: string expected";
            return null;
        };

        /**
         * Creates a Message message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof olvid.Message
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {olvid.Message} Message
         */
        Message.fromObject = function fromObject(object) {
            if (object instanceof $root.olvid.Message)
                return object;
            var message = new $root.olvid.Message();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
            if (object.discussionId != null)
                if ($util.Long)
                    (message.discussionId = $util.Long.fromValue(object.discussionId)).unsigned = false;
                else if (typeof object.discussionId === "string")
                    message.discussionId = parseInt(object.discussionId, 10);
                else if (typeof object.discussionId === "number")
                    message.discussionId = object.discussionId;
                else if (typeof object.discussionId === "object")
                    message.discussionId = new $util.LongBits(object.discussionId.low >>> 0, object.discussionId.high >>> 0).toNumber();
            if (object.sortIndex != null)
                message.sortIndex = Number(object.sortIndex);
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "STATUS_DEFAULT":
            case 0:
                message.status = 0;
                break;
            case "STATUS_PROCESSING":
            case 1:
                message.status = 1;
                break;
            case "STATUS_SENT":
            case 2:
                message.status = 2;
                break;
            case "STATUS_UNREAD":
            case 3:
                message.status = 3;
                break;
            case "STATUS_READ":
            case 4:
                message.status = 4;
                break;
            case "STATUS_DRAFT":
            case 5:
                message.status = 5;
                break;
            case "STATUS_DELIVERED":
            case 6:
                message.status = 6;
                break;
            case "STATUS_DELIVERED_AND_READ":
            case 7:
                message.status = 7;
                break;
            }
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "TYPE_DEFAULT":
            case 0:
                message.type = 0;
                break;
            case "INBOUND_MESSAGE":
            case 1:
                message.type = 1;
                break;
            case "OUTBOUND_MESSAGE":
            case 2:
                message.type = 2;
                break;
            case "GROUP_MEMBER_JOINED":
            case 3:
                message.type = 3;
                break;
            case "GROUP_MEMBER_LEFT":
            case 4:
                message.type = 4;
                break;
            case "LEFT_GROUP":
            case 5:
                message.type = 5;
                break;
            case "CONTACT_DELETED":
            case 6:
                message.type = 6;
                break;
            case "TYPE_INBOUND_EPHEMERAL_MESSAGE":
            case 7:
                message.type = 7;
                break;
            case "TYPE_DISCUSSION_SETTINGS_UPDATE":
            case 8:
                message.type = 8;
                break;
            case "TYPE_DISCUSSION_REMOTELY_DELETED":
            case 9:
                message.type = 9;
                break;
            case "TYPE_PHONE_CALL":
            case 10:
                message.type = 10;
                break;
            case "TYPE_NEW_PUBLISHED_DETAILS":
            case 11:
                message.type = 11;
                break;
            case "TYPE_CONTACT_INACTIVE_REASON":
            case 12:
                message.type = 12;
                break;
            }
            if (object.contentBody != null)
                message.contentBody = String(object.contentBody);
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
            if (object.totalAttachmentCount != null)
                message.totalAttachmentCount = object.totalAttachmentCount | 0;
            if (object.imageCount != null)
                message.imageCount = object.imageCount | 0;
            if (object.senderIdentifier != null)
                if (typeof object.senderIdentifier === "string")
                    $util.base64.decode(object.senderIdentifier, message.senderIdentifier = $util.newBuffer($util.base64.length(object.senderIdentifier)), 0);
                else if (object.senderIdentifier.length >= 0)
                    message.senderIdentifier = object.senderIdentifier;
            if (object.senderName != null)
                message.senderName = String(object.senderName);
            if (object.replyMessageId != null)
                if ($util.Long)
                    (message.replyMessageId = $util.Long.fromValue(object.replyMessageId)).unsigned = false;
                else if (typeof object.replyMessageId === "string")
                    message.replyMessageId = parseInt(object.replyMessageId, 10);
                else if (typeof object.replyMessageId === "number")
                    message.replyMessageId = object.replyMessageId;
                else if (typeof object.replyMessageId === "object")
                    message.replyMessageId = new $util.LongBits(object.replyMessageId.low >>> 0, object.replyMessageId.high >>> 0).toNumber();
            if (object.replyMessageAttachmentCount != null)
                if ($util.Long)
                    (message.replyMessageAttachmentCount = $util.Long.fromValue(object.replyMessageAttachmentCount)).unsigned = false;
                else if (typeof object.replyMessageAttachmentCount === "string")
                    message.replyMessageAttachmentCount = parseInt(object.replyMessageAttachmentCount, 10);
                else if (typeof object.replyMessageAttachmentCount === "number")
                    message.replyMessageAttachmentCount = object.replyMessageAttachmentCount;
                else if (typeof object.replyMessageAttachmentCount === "object")
                    message.replyMessageAttachmentCount = new $util.LongBits(object.replyMessageAttachmentCount.low >>> 0, object.replyMessageAttachmentCount.high >>> 0).toNumber();
            if (object.replySenderIdentifier != null)
                if (typeof object.replySenderIdentifier === "string")
                    $util.base64.decode(object.replySenderIdentifier, message.replySenderIdentifier = $util.newBuffer($util.base64.length(object.replySenderIdentifier)), 0);
                else if (object.replySenderIdentifier.length >= 0)
                    message.replySenderIdentifier = object.replySenderIdentifier;
            if (object.replyAuthor != null)
                message.replyAuthor = String(object.replyAuthor);
            if (object.replyBody != null)
                message.replyBody = String(object.replyBody);
            if (object.readOnce != null)
                message.readOnce = Boolean(object.readOnce);
            if (object.visibilityDuration != null)
                message.visibilityDuration = String(object.visibilityDuration);
            if (object.existenceDuration != null)
                message.existenceDuration = String(object.existenceDuration);
            if (object.wiped != null)
                message.wiped = Boolean(object.wiped);
            if (object.remotelyDeleted != null)
                message.remotelyDeleted = Boolean(object.remotelyDeleted);
            if (object.wipeOnRead != null)
                message.wipeOnRead = Boolean(object.wipeOnRead);
            if (object.edited != null)
                if ($util.Long)
                    (message.edited = $util.Long.fromValue(object.edited)).unsigned = false;
                else if (typeof object.edited === "string")
                    message.edited = parseInt(object.edited, 10);
                else if (typeof object.edited === "number")
                    message.edited = object.edited;
                else if (typeof object.edited === "object")
                    message.edited = new $util.LongBits(object.edited.low >>> 0, object.edited.high >>> 0).toNumber();
            if (object.senderIsSelf != null)
                message.senderIsSelf = Boolean(object.senderIsSelf);
            if (object.reactions != null)
                message.reactions = String(object.reactions);
            return message;
        };

        /**
         * Creates a plain object from a Message message. Also converts values to other types if specified.
         * @function toObject
         * @memberof olvid.Message
         * @static
         * @param {olvid.Message} message Message
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Message.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.discussionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.discussionId = options.longs === String ? "0" : 0;
                object.sortIndex = 0;
                object.status = options.enums === String ? "STATUS_DEFAULT" : 0;
                object.type = options.enums === String ? "TYPE_DEFAULT" : 0;
                object.contentBody = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                object.totalAttachmentCount = 0;
                object.imageCount = 0;
                if (options.bytes === String)
                    object.senderIdentifier = "";
                else {
                    object.senderIdentifier = [];
                    if (options.bytes !== Array)
                        object.senderIdentifier = $util.newBuffer(object.senderIdentifier);
                }
                object.senderName = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.replyMessageId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.replyMessageId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.replyMessageAttachmentCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.replyMessageAttachmentCount = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.replySenderIdentifier = "";
                else {
                    object.replySenderIdentifier = [];
                    if (options.bytes !== Array)
                        object.replySenderIdentifier = $util.newBuffer(object.replySenderIdentifier);
                }
                object.replyAuthor = "";
                object.replyBody = "";
                object.readOnce = false;
                object.visibilityDuration = "";
                object.existenceDuration = "";
                object.wiped = false;
                object.remotelyDeleted = false;
                object.wipeOnRead = false;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.edited = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.edited = options.longs === String ? "0" : 0;
                object.senderIsSelf = false;
                object.reactions = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
            if (message.discussionId != null && message.hasOwnProperty("discussionId"))
                if (typeof message.discussionId === "number")
                    object.discussionId = options.longs === String ? String(message.discussionId) : message.discussionId;
                else
                    object.discussionId = options.longs === String ? $util.Long.prototype.toString.call(message.discussionId) : options.longs === Number ? new $util.LongBits(message.discussionId.low >>> 0, message.discussionId.high >>> 0).toNumber() : message.discussionId;
            if (message.sortIndex != null && message.hasOwnProperty("sortIndex"))
                object.sortIndex = options.json && !isFinite(message.sortIndex) ? String(message.sortIndex) : message.sortIndex;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.olvid.MessageStatus[message.status] === undefined ? message.status : $root.olvid.MessageStatus[message.status] : message.status;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.olvid.MessageType[message.type] === undefined ? message.type : $root.olvid.MessageType[message.type] : message.type;
            if (message.contentBody != null && message.hasOwnProperty("contentBody"))
                object.contentBody = message.contentBody;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
            if (message.totalAttachmentCount != null && message.hasOwnProperty("totalAttachmentCount"))
                object.totalAttachmentCount = message.totalAttachmentCount;
            if (message.imageCount != null && message.hasOwnProperty("imageCount"))
                object.imageCount = message.imageCount;
            if (message.senderIdentifier != null && message.hasOwnProperty("senderIdentifier"))
                object.senderIdentifier = options.bytes === String ? $util.base64.encode(message.senderIdentifier, 0, message.senderIdentifier.length) : options.bytes === Array ? Array.prototype.slice.call(message.senderIdentifier) : message.senderIdentifier;
            if (message.senderName != null && message.hasOwnProperty("senderName"))
                object.senderName = message.senderName;
            if (message.replyMessageId != null && message.hasOwnProperty("replyMessageId"))
                if (typeof message.replyMessageId === "number")
                    object.replyMessageId = options.longs === String ? String(message.replyMessageId) : message.replyMessageId;
                else
                    object.replyMessageId = options.longs === String ? $util.Long.prototype.toString.call(message.replyMessageId) : options.longs === Number ? new $util.LongBits(message.replyMessageId.low >>> 0, message.replyMessageId.high >>> 0).toNumber() : message.replyMessageId;
            if (message.replyMessageAttachmentCount != null && message.hasOwnProperty("replyMessageAttachmentCount"))
                if (typeof message.replyMessageAttachmentCount === "number")
                    object.replyMessageAttachmentCount = options.longs === String ? String(message.replyMessageAttachmentCount) : message.replyMessageAttachmentCount;
                else
                    object.replyMessageAttachmentCount = options.longs === String ? $util.Long.prototype.toString.call(message.replyMessageAttachmentCount) : options.longs === Number ? new $util.LongBits(message.replyMessageAttachmentCount.low >>> 0, message.replyMessageAttachmentCount.high >>> 0).toNumber() : message.replyMessageAttachmentCount;
            if (message.replySenderIdentifier != null && message.hasOwnProperty("replySenderIdentifier"))
                object.replySenderIdentifier = options.bytes === String ? $util.base64.encode(message.replySenderIdentifier, 0, message.replySenderIdentifier.length) : options.bytes === Array ? Array.prototype.slice.call(message.replySenderIdentifier) : message.replySenderIdentifier;
            if (message.replyAuthor != null && message.hasOwnProperty("replyAuthor"))
                object.replyAuthor = message.replyAuthor;
            if (message.replyBody != null && message.hasOwnProperty("replyBody"))
                object.replyBody = message.replyBody;
            if (message.readOnce != null && message.hasOwnProperty("readOnce"))
                object.readOnce = message.readOnce;
            if (message.visibilityDuration != null && message.hasOwnProperty("visibilityDuration"))
                object.visibilityDuration = message.visibilityDuration;
            if (message.existenceDuration != null && message.hasOwnProperty("existenceDuration"))
                object.existenceDuration = message.existenceDuration;
            if (message.wiped != null && message.hasOwnProperty("wiped"))
                object.wiped = message.wiped;
            if (message.remotelyDeleted != null && message.hasOwnProperty("remotelyDeleted"))
                object.remotelyDeleted = message.remotelyDeleted;
            if (message.wipeOnRead != null && message.hasOwnProperty("wipeOnRead"))
                object.wipeOnRead = message.wipeOnRead;
            if (message.edited != null && message.hasOwnProperty("edited"))
                if (typeof message.edited === "number")
                    object.edited = options.longs === String ? String(message.edited) : message.edited;
                else
                    object.edited = options.longs === String ? $util.Long.prototype.toString.call(message.edited) : options.longs === Number ? new $util.LongBits(message.edited.low >>> 0, message.edited.high >>> 0).toNumber() : message.edited;
            if (message.senderIsSelf != null && message.hasOwnProperty("senderIsSelf"))
                object.senderIsSelf = message.senderIsSelf;
            if (message.reactions != null && message.hasOwnProperty("reactions"))
                object.reactions = message.reactions;
            return object;
        };

        /**
         * Converts this Message to JSON.
         * @function toJSON
         * @memberof olvid.Message
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Message.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Message
         * @function getTypeUrl
         * @memberof olvid.Message
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Message.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/olvid.Message";
        };

        return Message;
    })();

    return olvid;
})();

module.exports = $root;
