/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
import * as events from "@/assets/ext/events";
import {Discussion} from "@/assets/datatypes/Discussion";
import {getThumbnail} from "@/assets/ext/images";

export function notif_discussion_updated(colissimo) {
	if(!colissimo.notifDiscussionUpdated){
		return;
	}
	if(!globals.data.discussions) {
		globals.data.discussions = new Map();
	}
    let newDiscussion = colissimo.notifDiscussionUpdated.discussion;
    let discussion = globals.data.discussions.get(newDiscussion.id);
	if (!discussion) {
		// a new discussion had been created: create every resource needed
		// create discussion item and store in global
		discussion = new Discussion(
			newDiscussion.id,
			newDiscussion.title,
			newDiscussion.contactIdentity,
			newDiscussion.groupOwnerAndUid,
			newDiscussion.unreadMessagesCount,
			newDiscussion.lastMessage,
			newDiscussion.photoURL,
			newDiscussion.discussionTimestamp,
			0,
			false,
			null,
			null //either null or saved draft
		)
		globals.data.discussions.set(newDiscussion.id, discussion);
		// get thumbnail
		getThumbnail(discussion.photoURL);
	}
	else {
		discussion.updateFromProtobuf(newDiscussion);
	}

	const event = new CustomEvent(events.NotifDiscussionUpdated.type, {
		detail: {
			discussion: discussion
		}
	});
	document.dispatchEvent(event);//handled in Body.vue
	//current discussion is locked, notify SendMessage to update vue.
	if(discussion.id === globals.currentDiscussion && discussion.isLocked()){
		document.dispatchEvent(events.NotifDiscussionLocked);
    }
    //update favicon with notification number
    updateFavicon();
}

/**
 * Update number of notifications displayed in favicon. Will increase/decrease overall number of notifications for all discussions.
 */
export function updateFavicon(){
    let nbNotifs = 0;
    globals.data.discussions.forEach(discussion => {
        nbNotifs += discussion.unreadMessagesCount;
    });
    globals.favico.badge(nbNotifs);
}
