/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {i18n} from "@/main";

/**
 * Generates a string representing the hour the message was sent. Used for rendering in messages.
 * @param {number} timestamp
 * @returns {string}
 */
function getTimeFromTimestamp(timestamp) {
    if(!timestamp){
        return;
    }
    return i18n.d(new Date(timestamp), 'hour');
}

/**
 * Generates formatted short date. Used for rendering in discussionList.
 * @param {number} timestamp
 * @returns {string}
 */
function getShortDateFromTimestamp(timestamp) {
    if(!timestamp){
        return;
    }
    let day = new Date(timestamp).getDate();
    let month = new Date(timestamp).getMonth() + 1;
    let y = new Date(timestamp).getFullYear();
    let hour = getTimeFromTimestamp(timestamp);
    let result="";
    if(new Date().getDate() === day && new Date().getMonth()+1 === month && new Date().getFullYear() === y){
      result += hour;
    }
    else if (new Date().getDate() -1 === day && new Date().getMonth()+1 === month && new Date().getFullYear() === y){
      result +=  i18n.t('time.labelYesterday') + " · " + hour;
    } else {
        result += i18n.d(new Date(timestamp), 'short');
    }
    return result;
}

/**
 * Generates formatted long date. Used for rendering in conversation (date tag).
 * @param {number} timestamp
 * @returns {string}
 */
function getLongDateFromTimestamp(timestamp) {
    if(!timestamp){
        return;
    }
    let m = new Date(timestamp).getMonth();
    let d = new Date(timestamp).getDate();
    let y = new Date(timestamp).getFullYear();
    if(new Date().getDate() === d && new Date().getMonth()+1 === m+1 && new Date().getFullYear() === y){
        return i18n.t('time.labelToday');
    }
    else if (new Date().getDate() -1 === d && new Date().getMonth() === m+1 && new Date().getFullYear() === y){
        return i18n.t('time.labelYesterday');
    }
    else {
        return i18n.d(new Date(parseInt(timestamp,10)), 'long');
    }
}

/**
 * Compare the two given timestamps for equality of their dates (day+month+year only).
 * @param {number} date1
 * @param {number} date2
 * @returns {boolean}
 */
function datesAreDifferent(date1, date2) {
    let d1 = getDateForComparison(date1);
    let d2 = getDateForComparison(date2);
    return d1 !== d2;
}

/**
 * Generate a date from given timestamp in a specific format for comparing purposes.
 * @param {number} timestamp
 * @returns {string}
 */
function getDateForComparison(timestamp) {
    if(!timestamp){
        return;
    }
    let day = new Date(timestamp).getDate();
    let month = new Date(timestamp).getMonth() + 1;
    let y = new Date(timestamp).getFullYear();
    return day+"/"+month+"/"+y;
}

export {getLongDateFromTimestamp, getShortDateFromTimestamp, getTimeFromTimestamp, datesAreDifferent};




