/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {websocket} from "@/assets/ext/websocket";
import * as crypto from "@/assets/ext/crypto.js";
import {globals} from "@/assets/ext/globals";
import * as Base64 from "@/assets/ext/libs/Base64";
const protobuf = require("@/assets/ext/protobuf/protobuf.js").olvid;

/**
 * send a registerConnection message to server, with a self generated identifier that will be used to link
 * web to app (they both have a connectionIdentifier and a correspondingIdentifier, that is the other connection identifier)
 * @param {string}identifier: connectionIdentifier
 */
export function registerConnection (identifier) {
    console.log("MESSAGE : Registering connection: " + identifier);
    const message = JSON.stringify({action: "registerConnection", identifier: identifier});
    websocket.send(message);
}

/**
 * send a registerCorresponding message to server, with the other connectionIdentifer
 * @param {string}identifier: connectionIdentifier of linked app
 */
export function registerCorresponding (identifier) {
    console.log("MESSAGE : Registering corresponding: " + identifier);
    const message = JSON.stringify({action: "registerCorresponding", identifier: identifier, version:globals.constants.VERSION});
    websocket.send(message);
}

/**
 * send a colissimo to app, this colissimo will be encoded, encrypted, converted to base64, and then pushed in a relay jsonMessage
 * @param {Colissimo}colissimo
 * @returns {void}
 */
export async function relay (colissimo) {
    if (!colissimo) {
        console.log("Unable to send an empty colissimo, ignoring");
        return ;
    }
    const encodedColissimo = protobuf.Colissimo.encode(colissimo).finish();
    let encryptedColissimo = await crypto.encryptMessage(encodedColissimo)
    if (!encryptedColissimo) {
        console.log("Unable to encrypt colissimo, ignoring");
        return ;
    }
    const jsonMessage = {
        action: "relay",
        colissimo: Base64.bytesToBase64(encryptedColissimo),
    }
    websocket.send(JSON.stringify(jsonMessage));
}

/**
 * send a connection colissimo to app, a special type of colissimo only used during protocol. These messages are not encrypted !
 * @param {Colissimo}colissimo
 * @returns {void}
 */
export function connection (colissimo) {
    const encodedColissimo = protobuf.ConnectionColissimo.encode(colissimo).finish();
    const jsonMessage = {
        action: "connection",
        colissimo: Base64.bytesToBase64(encodedColissimo),
    }
    websocket.send(JSON.stringify(jsonMessage));
}

/**
 * Send a ping to app. App is supposed to answer with a pong message.
 */
export function pingApp () {
    const ping = protobuf.ConnectionPing.create({ping: true});
    const pingColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.CONNECTION_PING,
        connectionPing: ping
    });
    relay(pingColissimo);
    console.log("MESSAGE : Sent ping");
}

/**
 * Send a bye colissimo. Used to notify app that disconnection had been decided by user, it is not supposed to wait for reconnection.
 * @returns {Promise<void>}
 */
export async function sendBye () {
    const sendBye = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.BYE
    });
    await relay(sendBye);
    console.log("MESSAGE : Send BYE Colissimo");
}

/**
 * Send a json file in a colissimo with current web configuration
 * @param jsonSettings
 */
export function saveSettings(jsonSettings) {
    const settings = protobuf.Settings.create({
        settings : jsonSettings
    }); 
    const settingsColissimo = protobuf.Colissimo.create({
        type:protobuf.ColissimoType.SETTINGS, 
        settings : settings
    });
    relay(settingsColissimo);
}

/**
 * Send request for all current discussions with their last message info, to fill the discussion field.
 * This message is considered as a reset message, and app is resetting every database listeners (discussion, messages, atachments, ...)
 * Actual strategy for re-syncing is to keep local cache, until requestDiscussionResponse or RequestMessageResponse arrives,
 * and then delete all cache to replace by the new one, to be sure that no message was deleted during process.
 */
export function requestDiscussions () {
    // REQUEST_DISCUSSION is considered as a reset message, so app is resetting all listeners and caches (for messages and others)
    // keep cached data until they will be replaced by newer on requestMessageResponse
    const requestDiscussionsColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.REQUEST_DISCUSSIONS,
        requestDiscussions: protobuf.RequestDiscussions.create()
    });
    relay(requestDiscussionsColissimo);
}

/**
 * Request messageCount messages in a discussion. First time app will send messageCount messages in requestMessageResponse,
 * Then it will only send the difference (messageCount - previouslySubscribed)
 * @param discussionId
 * @param messageCount
 */
export function subscribeToDiscussion (discussionId, messageCount) {
    const requestMessage = protobuf.RequestMessage.create({
        discussionId: discussionId,
        count: messageCount
    });
    const colissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.REQUEST_MESSAGES,
        requestMessage: requestMessage,
    });
    relay(colissimo);
}

/**
 * Mark every messages in a discussion as read
 * @param discussionId
 */
export function markDiscussionAsRead (discussionId) {
    discussionId = parseInt(discussionId);
    if (!globals.data.discussions.get(discussionId)) {
        return ;
    }
    const requestMessage = protobuf.RequestMarkDiscussionAsRead.create({
        discussionId: discussionId
    });
    const colissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.REQUEST_MARK_DISCUSSION_AS_READ,
        requestMarkDiscussionAsRead: requestMessage
    });
    relay(colissimo);
}

export function sendOlvidMessage (discussionId, contentBody, localId, replyMessageId) {
    const createMessage = protobuf.CreateMessage.create({
        discussionId: discussionId,
        content: contentBody,
        localId:localId,
        replyMessageId : replyMessageId
    });
    const createMessageColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.CREATE_MESSAGE,
        createMessage: createMessage
    });
    relay(createMessageColissimo);
}

/**
 * request an attachment thumbnail
 * @param url
 */
export function requestThumbnail(url) {
    const requestThumbnail = protobuf.RequestThumbnail.create({
        relativeURL:url
    });
    const requestThumbnailColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.REQUEST_THUMBNAIL,
        requestThumbnail: requestThumbnail
    });
    relay(requestThumbnailColissimo);
}


/**
 * notify app for the creation of a new attachment, contains info about attachment.
 * This is sent before attachment chunks.
 * @param localid
 * @param sha256
 * @param size
 * @param nbChunks
 * @param type
 * @param fileName
 * @param discussionId
 */
export function sendAttachmentNotice (localid, sha256, size, nbChunks, type, fileName, discussionId) {
    
    const sendAttachmentNotice = protobuf.SendAttachmentNotice.create({
        localId : localid,
        sha256 : sha256,
        size : size,
        numberChunks : nbChunks,
        type : type,
        fileName:fileName,
        discussionId:discussionId
    });
    const sendAttachmentNoticeColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.SEND_ATTACHMENT_NOTICE,
        sendAttachmentNotice: sendAttachmentNotice
    });
    relay(sendAttachmentNoticeColissimo);
}

/**
 * Send an attachment chunk to app. Colissimo contains chunks bytes and meta-data to identify it.
 * @param localid
 * @param offset
 * @param chunkNumber
 * @param chunk
 */
export function sendAttachmentChunk (localid, offset, chunkNumber, chunk) {
    const sendAttachmentChunk = protobuf.SendAttachmentChunk.create({
        localId:localid,
        offset : offset,
        chunkNumber:chunkNumber,
        chunk : chunk
    });
    const sendAttachmentChunkColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.SEND_ATTACHMENT_CHUNK,
        sendAttachmentChunk: sendAttachmentChunk
    });
    relay(sendAttachmentChunkColissimo);
}

/**
 * notify app that web sent all chunks for a given attachment.
 * @param localid
 */
export function sendAttachmentDone (localid) {
    const sendAttachmentDone = protobuf.SendAttachmentDone.create({
        localId:localid
    });
    const sendAttachmentDoneColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.SEND_ATTACHMENT_DONE,
        sendAttachmentDone: sendAttachmentDone
    });
    relay(sendAttachmentDoneColissimo);
}

export function requestStopDraftAttachmentUpload (localId) {
    const sendStopUpload = protobuf.RequestStopDraftAttachmentUpload.create({
        localId:localId
    });
    const requestStopDraftAttachmentUploadColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.REQUEST_STOP_DRAFT_ATTACHMENT_UPLOAD,
        requestStopDraftAttachmentUpload: sendStopUpload
    });
    relay(requestStopDraftAttachmentUploadColissimo); 
    
}

export function requestSaveDraftMessage (discussionId, message, replyId) {
    const requestSaveDraftMessage = protobuf.RequestSaveDraftMessage.create({
        discussionId:discussionId,
        message:message,
        replyMessageId:replyId
    });
    const requestSaveDraftMessageColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.REQUEST_SAVE_DRAFT_MESSAGE,
        requestSaveDraftMessage: requestSaveDraftMessage
    });
    relay(requestSaveDraftMessageColissimo);
}

export function requestDeleteDraftMessage (discussionId) {
    // console.log("request delete draft message")
    const requestDeleteDraftMessage = protobuf.RequestDeleteDraftMessage.create({
        discussionId:discussionId
    });
    const requestDeleteDraftMessageColissimo = protobuf.Colissimo.create({
        type:protobuf.ColissimoType.REQUEST_DELETE_DRAFT_MESSAGE,
        requestDeleteDraftMessage : requestDeleteDraftMessage
    });
    relay(requestDeleteDraftMessageColissimo);
}

export function requestDeleteDraftAttachment (fyleId, messageId) {
    const requestDeleteDraftAttachment = protobuf.RequestDeleteDraftAttachment.create({
        fyleId:fyleId,
        messageId : messageId
    });
    const requestDeleteDraftAttachmentColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.REQUEST_DELETE_DRAFT_ATTACHMENT,
        requestDeleteDraftAttachment:requestDeleteDraftAttachment
    });
    relay(requestDeleteDraftAttachmentColissimo);
}

export function requestDownloadAttachment (fyleId, size) {
    const requestDownloadAttachment = protobuf.RequestDownloadAttachment.create({
        fyleId:fyleId,
        size:size
    });
    const requestDownloadAttachmentColissimo = protobuf.Colissimo.create({
        type:protobuf.ColissimoType.REQUEST_DOWNLOAD_ATTACHMENT,
        requestDownloadAttachment:requestDownloadAttachment
    });
    relay(requestDownloadAttachmentColissimo);
}


export function requestUpdateMessage (messageId, content) {
    const requestUpdate = protobuf.RequestUpdateMessage.create({
        messageId:messageId,
        newContent:content
    });
    const requestUpdateMessageColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.REQUEST_UPDATE_MESSAGE,
        requestUpdateMessage: requestUpdate
    });
    relay(requestUpdateMessageColissimo); 
}

export function requestAddReaction (messageId, emoji) {
    const requestAddEmoji = protobuf.RequestUpdateMessage.create({
        reaction:emoji,
        messageId : messageId
    });
    const requestAddEmojiColissimo = protobuf.Colissimo.create({
        type: protobuf.ColissimoType.REQUEST_ADD_REACTION_TO_MESSAGE,
        requestAddReactionToMessage: requestAddEmoji
    });
    relay(requestAddEmojiColissimo); 
}
