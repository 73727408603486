/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/

import {globals} from '@/assets/ext/globals';
import {saveSettings} from "@/assets/ext/messages/messageSender";
import {i18n} from "@/main";

/**
 * Updates current Settings according to received settings from App.
 * @param {String} language 
 * @param {String} theme 
 * @param {boolean} sendOnEnter 
 * @param {boolean} notifications 
 */
export function updateCurrentSettings(language, theme, sendOnEnter, notifications, showNotif) {
    if(language != null && language !== "" && (globals.constants.SUPPORTED_LANGUAGES.includes(language) || language === "BrowserDefault" || language === "AppDefault")){
        updateLanguage(language);
    }
    if(theme != null && theme !== ""){
        updateTheme(theme);
    }
    if(sendOnEnter != null){
        updateSendOnEnter(sendOnEnter);
    }
    if(notifications != null){
        updateNotificationsSound(notifications);
    }
    if(showNotif != null){
        updateShowNotificationsOnBrowser(showNotif);
    }
}

/**
 * Register given language as current locale or finds browser default language.
 * @param {string} language 
 */
export function updateLanguage(language){
    switch (language) {
        case 'BrowserDefault' :
            getBrowserLanguage();
            globals.parameters.isLanguageBrowserDefault = true;
            globals.parameters.isLanguageAppDefault = false;
            break;
        case 'AppDefault' :
            i18n.locale = globals.parameters.receivedSettings.appDefaultLanguage;
            globals.parameters.isLanguageBrowserDefault = false;
            globals.parameters.isLanguageAppDefault = true;
            break;
        default:{
            globals.parameters.isLanguageBrowserDefault = false;
            globals.parameters.isLanguageAppDefault = false;
            i18n.locale = language;
            break;
        }
    }
    //update picker in Settings window
    if(document.getElementById('language-picker')){
        var options = document.getElementById('language-picker').options;
        var opt;
        for (let i=0;i<options.length;i++) {
            opt = options[i];
            if (opt.value == language) {
                document.getElementById('language-picker').selectedIndex = i;
                break;
            }
        }
    }
}

/**
 * Register newly received theme setting from App.
 * @param {string} theme 
 */
export function updateTheme(theme) {
    switch (theme) {
        case 'dark':
            document.documentElement.setAttribute('data-theme', 'dark');
            globals.parameters.darkModeEnabled = true;
            globals.parameters.darkModeSysPref = false;
            globals.parameters.darkModeAppPref = false;
            break;
        case 'light':
            document.documentElement.setAttribute('data-theme', 'light');
            globals.parameters.darkModeEnabled = false;
            globals.parameters.darkModeSysPref = false;
            globals.parameters.darkModeAppPref = false;
            break;
        case 'BrowserDefault':
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                document.documentElement.setAttribute('data-theme', 'dark');
                globals.parameters.darkModeEnabled = true;
            } else {
                document.documentElement.setAttribute('data-theme', 'light');
                globals.parameters.darkModeEnabled = false; 
            }
            globals.parameters.darkModeSysPref = true; 
            globals.parameters.darkModeAppPref = false;
            break;
        case 'AppDefault' :
            document.documentElement.setAttribute('data-theme', globals.parameters.receivedSettings.appDefaultTheme);
            globals.parameters.darkModeAppPref = true;
            globals.parameters.darkModeSysPref = false;
            break;
        default: //fallback to light theme
            document.documentElement.setAttribute('data-theme', 'light');
            globals.parameters.darkModeEnabled = false;
            globals.parameters.darkModeSysPref = false; 
            globals.parameters.darkModeAppPref = false;
            break;
    }
    //update settings render if it exists
    if(document.getElementById('settings_content_theme')){
        if(globals.parameters.darkModeSysPref){
            document.getElementById('dark').checked = false;
            document.getElementById('light').checked = false;
            document.getElementById('syspref').checked = true; 
            document.getElementById('apppref').checked = false;
        } else if(globals.parameters.darkModeAppPref) {
            document.getElementById('dark').checked = false;
            document.getElementById('light').checked = false;
            document.getElementById('syspref').checked = false; 
            document.getElementById('apppref').checked = true;
        } else if(globals.parameters.darkModeEnabled) {
            document.getElementById('dark').checked = true;
            document.getElementById('light').checked = false;
            document.getElementById('syspref').checked = false; 
            document.getElementById('apppref').checked = false;
        } else {
            document.getElementById('dark').checked = false;
            document.getElementById('light').checked = true;
            document.getElementById('syspref').checked = false; 
            document.getElementById('apppref').checked = false;
        }
    }
}

/**
 * Register newly received sendOnEnter setting from App. 
 * @param {boolean} sendOnEnter 
 */
function updateSendOnEnter(sendOnEnter) {
    globals.parameters.sendOnEnter = sendOnEnter;
    if(document.getElementById("send-on-enter")){
        document.getElementById("send-on-enter").checked = sendOnEnter;
    }
}

/**
 * Register newly received notification setting from App.
 * @param {boolean} notifications 
 */
function updateNotificationsSound(notifications) {
    globals.parameters.notificationsSoundEnabled = notifications;
}

/**
 * Register newly received showNotifications setting from App. 
 * @param {boolean} showNotif 
 */
function updateShowNotificationsOnBrowser(showNotif) {
    globals.parameters.showNotificationOnScreen = showNotif;
}

/**
 * Save current Settings to App by sending a Colissimo.
 * @param {string} language 
 * @param {string} theme 
 * @param {boolean} sendOnEnter 
 * @param {boolean} notifications 
 */
export function saveSettingsToApp() {
    let language;
    if(globals.parameters.isLanguageBrowserDefault) {
        language = "BrowserDefault";
    } else if(globals.parameters.isLanguageAppDefault) {
        language = "AppDefault";
    }else {
        language = i18n.locale;
    }

    let theme ;
    if(globals.parameters.darkModeSysPref){
        theme = "BrowserDefault";
    } else if(globals.parameters.darkModeAppPref) {
        theme = "AppDefault";
    } else {
        theme = document.documentElement.getAttribute('data-theme');
    }
    
    let webDefaultLanguage = "";
    if(globals.parameters.isLanguageBrowserDefault){
        webDefaultLanguage = findBrowserPreferredLanguage();
    }
    if(webDefaultLanguage === "") {
        webDefaultLanguage="en";//fallback to english
    }

    let sendOnEnter = globals.parameters.sendOnEnter;
    let notifications = globals.parameters.notificationsSoundEnabled;
    let show = globals.parameters.showNotificationOnScreen;
    let settings = {language:language, theme:theme, sendOnEnter:sendOnEnter, notificationSound:notifications, showNotifications:show, webDefaultLanguage:webDefaultLanguage};
    saveSettings(JSON.stringify(settings));
}


/**
 * Initialize the value of the locale for internationalization. Locale name should be standardized (BCP 47 > ISO 63).
 * This function will try to retrieve prefered browser language. If it fails, will fallback to default fallback value defined in main.js
 */
export function getBrowserLanguage() {
    //retrieve navigator language preference
    let languageTag = findBrowserPreferredLanguage();
    if(languageTag !== ""){
        i18n.locale = languageTag;
        globals.parameters.isLanguageBrowserDefault = true;
    }
    //if no language matched, i18n.locale will keep its default value set in main.js
    //set language picker to BrowserDefault
    if(document.getElementById('language-picker')){
        var options = document.getElementById('language-picker').options;
        var opt;
        for (let i=0;i<options.length;i++) {
            opt = options[i];
            if (opt.value === 'BrowserDefault') {
                document.getElementById('language-picker').selectedIndex = i;
                break;
            }
        } 
    }
}

function findBrowserPreferredLanguage() {
    let listLanguages = navigator.languages;
    if (!Array.isArray(listLanguages)) {
        listLanguages = [navigator.language || navigator.userLanguage];
    }
    //loop on preferred language to find first preferred language that matches a language present in i18n
    for (let i = 0; i < listLanguages.length; i++) {
        let languageTag;
        let language = listLanguages[i];
        if (language.includes("-")) { //if there is an hyphen, keep only first part for language tag
            languageTag = language.substring(0, language.indexOf('-')); //get tag : 0 to index of the hyphen
        } else {
            languageTag = language;
        }
        if (globals.constants.SUPPORTED_LANGUAGES.includes(languageTag)) { //if this language tag exists in the list, define it as locale
            return languageTag;//return first found preferred language
        }
    }
    return ""; //no language found
}