/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
/*Locale names (e.g 'en') should follow norm ISO 639-(1|2|3) for languages tags, in order to extract them from IETF BCP 47 language tags given by browser. 
List of tags : https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
*/

export const messages = {
    'en': {
        instructions : {
            useOlvidInBrowserLabel:'Use Olvid in your browser:',
            instruction1OpenOlvidApp : 'Start Olvid on your Android device',
            instruction2TapPlusInApp : 'Tap on',
            instruction2TapScanInAppToLaunch:'then on Scan',
            instruction3ScanQR:'Scan this QR Code and enter (on your device) the 4-digit code that will appear',
            labelWaitingForServerConnection:'Connecting to server...',
            labelProtocol:'Creating secure channel...',
            labelWaitForSASConfirmation:'Enter this 4-digit code on your phone to confirm your identity:',
            labelLinkToStore:'Link to store',
            labelLogOut:'Log out',
            labelReload:'Reload page content',
        },
        protocolMessages : {
            protocolMessageMemberJoined:'{name} joined conversation',
            protocolMessageMemberLeft:'{name} left conversation',
            protocolMessageYouLeftGroup:'You left the conversation',
            protocolMessageContactDeleted:'Contact deleted',
            labelUnreadMessages : '{n} unread message | {n} unread messages',
            labelChangedDiscussionSettings : '{name} updated the discussion shared settings',
            labelChangedDiscussionSettingsYou : 'You updated the discussion shared settings', 
            labelChangedDiscussionSettingsNoName : 'Discussion shared settings updated',
        },
        systemMessages : {
            discussionRemotelyDeleted:'Discussion remotely deleted',
            contactDetailsUpdated:'Contact details updated',
            groupDetailsUpdated:'Group details updated',
            contactInactiveRevoked:"Contact revoked by your company\\'s identity provider",
            contactInactiveBlocked:"Contact blocked",
            phoneCall:"Phone call",
        },
        modals : {
            phoneDisconnectedLabel:'Phone disconnected.<br> Waiting for reconnection...', //use in v-html
            webappDisconnectedLabel:'Connection to App was lost, trying to reconnect...',
            labelLeaveWebClient:'Leave',
            labelStayWebClient:'Stay',
            labelLogOutConfirmation:'Log out?',
            labelDoYouWantToLeave:'Do you want to leave the page ? Connection will be lost, as well as the unsaved drafts.'
        },
        errors : {
            errorConnectionClosedByApp : 'Connection closed by App',
            errorDuringEstablishment : 'An error occured during establishment protocol',
            errorConnectionLost: 'Connection was lost',
            errorVersionsDontMatch : 'Webclient incompatible with App : make sure you have installed the latest updates',
            errorProtocolStoppedByApp : 'App stopped protocol',
            errorInternalError: 'Impossible happened (internal error)',
            unknownError:'Unknown error'
        },
        attachments : {
            labelLastMessageNoTextAttachments:'{n} attachment | {n} attachments',
            labelAttachmentNotSupported:'Attachment format not supported',
            labelDownloadAgain : 'Download attachment again?',
            labelDragAndDrop : 'Drag and drop files here',
            labelDownload : 'Save',
            fileAlreadyAttached : 'File is already attached to the message',
            labelDownloadFailed : 'Download failed',
            labelAddEmoji : 'Insert emoji',
            labelAddAttachment : 'Attach file'
        },
        conversation : {
            labelDiscussionLocked : 'This discussion is locked',
            labelLoadMoreMessages: 'Load more messages...',
            labelLoadingMessages : 'Loading messages...',
            labelNoMessagesInDiscussion:'No messages in discussion',
            labelDraftWriteMessage:'Write message',
            selectADiscussionLabel:'Select a discussion',
            labelSaveDraft:'Send draft to App',
            labelSendMessage:'Send message'
        },
        message : {
            labelEdited : 'EDITED',
            labelReply : 'Reply',
            labelEdit : 'Edit',
            labelUpdateMessage : 'Edit your message',
            labelDiscard : 'Discard',
            labelUpdate : 'Update',
            labelSending : 'Processing',
            labelSent : 'Sent',
            labelRead : 'Read',
            labelDelivered : 'Delivered',
            labelAddReaction : 'Add a reaction'
        },
        ephemeral : {
            labelMessageEphemere : 'Ephemeral message : go on the App to see the message',
            labelReadOnceTitle : 'Visible only once, deleted when leaving conversation',
            labelVisibilityTitle : 'Message visibility duration',
            labelExistenceTitle : 'Message existence duration',
            labelReadOnce : '<i class="fas fa-fire"></i> Visible only once', //use in v-html
            labelVisibility : '<i class="fas fa-fire"></i> Visible for {time}', //use in v-html
            labelExistence : '<i class="fas fa-fire"></i> Deleted after {time}', //use in v-html
            labelReadOnceAndVisibility : '<i class="fas fa-fire"></i> Visible for {time}, and only once', //use in v-html
            labelReadOnceAndExistence : '<i class="fas fa-fire"></i> Deleted after {time}, and visible only once', //use in v-html
            labelNotEphemeral : 'Non-ephemeral messages'
        },
        wiped : {
            wiped : '(message content erased)',
            remotelyDeleted : '(message remotely deleted)'
        },
        discussion : {
            labelNoDiscussions : 'No discussions',
            labelSearchInDiscussions : 'Search'
        },
        settings : {
            labelSettings:'Settings',
            darkTheme:'Dark',
            lightTheme:'Light',
            labelSendMessageOnEnter:'Send message on enter',
            labelChooseLanguage:'Choose your language:',
            labelSettingNotifications : 'Notifications',
            labelSettingLangTheme : 'Language/Theme',
            labelSettingInput : 'Text input',
            labelSettingSystemPreferenceTheme:'Browser\xa0Preference',
            labelSettingsAppPreference : 'App\xa0preference',
            labelChooseTheme:'Choose preferred theme:',
            labelSoundNotifications : 'Notification sound:',
            labelSoundTest:'Sound test',
            labelChangeSaveDraftFrequency : 'Draft saving frequency:',
            labelInSeconds:'(in\xa0seconds)',
            labelDefault : 'Default\xa0value',
            labelSaveSettings : 'Save preferences',
            labelBrowserDefaultLanguage : 'Browser default language',
            labelAppDefaultLanguage : 'App default language',
            labelNotificationsOnScreen : 'Show notifications on browser',
            labelShowSaveDraftButton : 'Show button to send draft to App',
            labelTitleSoundTurnOn : 'Enable notification sound',
            labelTitleSoundTurnOff : 'Disable notification sound'
        },
        time : {
            labelToday : 'Today',
            labelYesterday : 'Yesterday'
        },
        help: {
            link: 'https://olvid.io/faq/use-olvid-with-your-computer/',
            labelHelp: 'Read our FAQ'
        }
    },
    'fr': {
        instructions : {
            useOlvidInBrowserLabel:'Utilisez Olvid dans votre navigateur\xa0:',
            instruction1OpenOlvidApp : "Ouvrez Olvid sur votre appareil Android",
            instruction2TapPlusInApp : 'Appuyez sur',
            instruction2TapScanInAppToLaunch:'puis sur Scanner',
            instruction3ScanQR:'Scannez le code QR ci-contre et saisissez (sur votre appareil) le code à 4 chiffres qui apparaîtra',
            labelWaitingForServerConnection:'Connexion au serveur en cours...',
            labelProtocol:'Établissement de la connexion sécurisée en cours...',
            labelWaitForSASConfirmation:'Entrez ce code à 4 chiffres sur votre téléphone pour confirmer votre identité\xa0:',
            labelLinkToStore:'Lien vers le store',
            labelLogOut:'Déconnexion',
            labelReload:'Recharger le contenu de la page'
        },
        protocolMessages : {
            protocolMessageMemberJoined:'{name} a rejoint la conversation',
            protocolMessageMemberLeft:'{name} est parti de la conversation',
            protocolMessageYouLeftGroup:'Vous êtes partis de la conversation',
            protocolMessageContactDeleted:'Contact supprimé',
            labelUnreadMessages : '{n} message non lu | {n} messages non lus',
            labelChangedDiscussionSettings : '{name} a mis à jour les réglages de discussion partagés',
            labelChangedDiscussionSettingsYou : 'Vous avez mis à jour les réglages de discussion partagés', 
            labelChangedDiscussionSettingsNoName : 'Réglages de discussion partagés mis à jour'
        },
        systemMessages : {
            discussionRemotelyDeleted:'Discussion effacée à distance',
            contactDetailsUpdated:'Détails du contact mis à jour',
            groupDetailsUpdated:'Détails du groupe mis à jour',
            contactInactiveRevoked:"Contact bloqué",
            contactInactiveBlocked:"Contact revoqué par le fournisseur d\\'identités de votre société",
            phoneCall:"Appel téléphonique",
        },
        modals : {
            phoneDisconnectedLabel:'Le téléphone s\'est deconnecté.<br> En attente de reconnexion...',
            webappDisconnectedLabel:'La connexion avec le téléphone a été perdue, tentative de reconnexion...',
            labelLeaveWebClient:'Partir',
            labelStayWebClient:'Rester',
            labelLogOutConfirmation:'Partir en fermant la connexion\xa0?',
            labelDoYouWantToLeave:'Voulez-vous quitter la page ? La connexion sera fermée et les brouillons non sauvés seront perdus.'
        },
        errors : {
            errorConnectionClosedByApp : 'La connection a été fermée par l\'application',
            errorDuringEstablishment : 'Une erreur est survenue pendant l\'établissement du canal sécurisé',
            errorConnectionLost: 'La connection a été perdue',
            errorVersionsDontMatch : 'Client Web incompatible avec l\'App : vérifiez que vous avez les dernières mises à jour installées',
            errorProtocolStoppedByApp : 'L\'application a mis fin au protocole',
            errorInternalError: 'L\'impossible s\'est produit (erreur interne)',
            unknownError : 'Une erreur inconnue est survenue',
        },
        attachments : {
            labelLastMessageNoTextAttachments:'{n} pièce jointe | {n} pièces jointes',
            labelDownloadAgain : 'Télécharger la pièce jointe à nouveau\xa0?',
            labelAttachmentNotSupported:'Ce format de pièce jointe n\'est pas supporté',
            labelDragAndDrop : 'Glisser des fichiers ici',
            labelDownload : 'Télécharger',
            fileAlreadyAttached : 'Fichier déjà attaché au message',
            labelDownloadFailed : 'Téléchargement a échoué',
            labelAddEmoji : 'Insérer un emoji',
            labelAddAttachment : 'Ajouter une pièce jointe'
        },
        conversation : {
            labelDiscussionLocked : 'Cette discussion est verrouillée',
            labelLoadMoreMessages: 'Charger plus de messages...',
            labelLoadingMessages : 'Chargement des messages de la discussion...',
            labelNoMessagesInDiscussion:'Pas de messages dans cette discussion',
            selectADiscussionLabel:'Sélectionnez une discussion',
            labelDraftWriteMessage:'Ecrivez un message',
            labelSaveDraft:'Envoyer le brouillon à l\'application',
            labelSendMessage:'Envoyer le message'
        },
        message : {
            labelEdited : 'MODIFIÉ',
            labelReply : 'Répondre',
            labelEdit : 'Editer',
            labelUpdateMessage : 'Editez votre message',
            labelDiscard : 'Annuler',
            labelUpdate : 'Mettre à jour',
            labelSending : 'En attente',
            labelSent : 'Envoyé',
            labelRead : 'Lu',
            labelDelivered : 'Reçu',
            labelAddReaction : 'Ajouter une réaction'
        },
        ephemeral : {
            labelMessageEphemere : 'Message éphémère : allez sur l\'application pour voir son contenu',
            labelReadOnceTitle : 'Visible une seule fois, supprimé en sortant de la conversation',
            labelVisibilityTitle : 'Durée de visibilité des messages',
            labelExistenceTitle : 'Durée d&#146existence des messages',
            labelReadOnce : '<i class="fas fa-fire"></i> Visible une seule fois',
            labelVisibility : '<i class="fas fa-fire"></i> Visible pour {time}',
            labelExistence : '<i class="fas fa-fire"></i> Supprimé après {time}',
            labelReadOnceAndVisibility : '<i class="fas fa-fire"></i> Visible pour {time}, et une seule fois',
            labelReadOnceAndExistence : '<i class="fas fa-fire"></i> Supprimé après {time}, et visible une seule fois',
            labelNotEphemeral : 'Messages non-éphémères'
        },
        wiped : {
            wiped : '(contenu du message effacé)',
            remotelyDeleted : '(message supprimé à distance)'
        },
        discussion : {
            labelNoDiscussions : 'Pas de discussions',
            labelSearchInDiscussions : 'Rechercher'
        },
        settings : {
            labelSettings:'Paramètres',
            darkTheme:'Sombre',
            lightTheme:'Clair',
            labelSendMessageOnEnter:'Envoyer le message en appuyant sur Entrée',
            labelChooseLanguage:'Choisissez votre langage\xa0:',
            labelSettingNotifications : 'Notifications',
            labelSettingLangTheme : 'Langue/Thème',
            labelSettingInput : 'Saisie de texte',
            labelSettingSystemPreferenceTheme:'Préférence\xa0du\xa0navigateur',
            labelSettingsAppPreference : 'Préférence\xa0de\xa0l\'application',
            labelChooseTheme:'Choisissez le thème\xa0:',
            labelSoundNotifications : 'Son des notifications\xa0:',
            labelSoundTest:'Tester\xa0le\xa0son',
            labelChangeSaveDraftFrequency : 'Fréquence de sauvegarde du brouillon\xa0:',
            labelInSeconds:'(en\xa0secondes)',
            labelDefault : 'Valeur\xa0par\xa0défaut',
            labelSaveSettings : 'Sauvegarder les préférences',
            labelBrowserDefaultLanguage : 'Langue par défaut du navigateur',
            labelAppDefaultLanguage : 'Langue par défaut de l\'application',
            labelNotificationsOnScreen : 'Afficher les notifications sur le navigateur',
            labelShowSaveDraftButton : 'Afficher le bouton pour envoyer le brouillon à l\'application',
            labelTitleSoundTurnOn : 'Rétablir le son des notifications',
            labelTitleSoundTurnOff : 'Couper le son des notifications'
        },
        time : {
            labelToday : 'Aujourd\'hui',
            labelYesterday : 'Hier'
        },
        help: {
            link: 'https://olvid.io/faq/utiliser-Olvid-avec-votre-ordinateur/',
            labelHelp: 'Consultez notre page d\'aide'
        }
    }
}

export const dateTimeFormats = {
    'en': {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        long: { 
            year: 'numeric', 
            month: 'long',
            day: 'numeric',
            weekday: 'short',
        },
        hour : {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }
    },
    'fr': {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'short',
        },
        hour :{
            hour: 'numeric',
            minute: 'numeric',
        }
    }
}