/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
import {sanitize_text} from "@/assets/ext/messages/text_sanitizer";
import {requestThumbnail} from "@/assets/ext/messages/messageSender";

export class Discussion {
    /**
     * @param {number} id
     * @param {string} title
     * @param {Uint8Array} contactIdentity
     * @param {Uint8Array} groupOwnerAndUid
     * @param {number} unreadMessagesCount
     * @param {olvid.IMessage} lastMessage
     * @param {string} photoURL
     * @param {number} discussionTimestamp
     * @param {number} numberMessagesRequested
     * @param {boolean} receivedFirstBatch
     * @param {Array(datatypes.DraftAttachment)}draftAttachments
     * @param {datatypes.Draft}draft
     */
    constructor(id, title, contactIdentity, groupOwnerAndUid, unreadMessagesCount, lastMessage, photoURL, discussionTimestamp, numberMessagesRequested, receivedFirstBatch, draftAttachments, draft){
        this.id = id;
        this.title = sanitize_text(title);
        this.contactIdentity = contactIdentity;
        this.groupOwnerAndUid = groupOwnerAndUid;
        this.unreadMessagesCount = unreadMessagesCount;
        if (lastMessage) {
            lastMessage.contentBody = sanitize_text(lastMessage.contentBody);
        }
        this.lastMessage = lastMessage;
        this.photoURL = photoURL;
        this.discussionTimestamp = discussionTimestamp;

        this.numberMessagesRequested = numberMessagesRequested;
        this.receivedFirstBatch = receivedFirstBatch;

        this.draftAttachments = draftAttachments ? draftAttachments : [];
        this.draft = draft;
    }

    /**
     * Updates current discussion with up to date attributes from the received protobuf Object.
     * @param {Object} discussionProto 
     */
    updateFromProtobuf(discussionProto){
        if (discussionProto) {
            discussionProto.title = sanitize_text(discussionProto.title);
            if (discussionProto.lastMessage) {
                discussionProto.lastMessage.contentBody = sanitize_text(discussionProto.lastMessage.contentBody);
            }
        }
        this.id = discussionProto.id;
        this.title = discussionProto.title;
        this.contactIdentity = discussionProto.contactIdentity;
        this.groupOwnerAndUid = discussionProto.groupOwnerAndUid;
        this.unreadMessagesCount = discussionProto.unreadMessagesCount;
        this.lastMessage = discussionProto.lastMessage;
        if (this.photoURL !== discussionProto.photoURL) {
            this.photoURL = discussionProto.photoURL;
            requestThumbnail(this.photoURL);
        }
        this.discussionTimestamp = discussionProto.discussionTimestamp;
    }

    /**
     * Increase by globals.constants.STEP_NB_REQUEST_MESSAGES the number of message requested AND received. 
     */
    incrementNumberRequested(){
        this.numberMessagesRequested += globals.constants.STEP_NB_REQUEST_MESSAGES;
    }

    /**
     * Add given DraftAttachment in the array of draft attachments for this discussion. 
     * @param {datatypes.DraftAttachment} draftAttachment 
     */
    addDraftAttachment(draftAttachment) {
        if(!this.draftAttachments){
            this.draftAttachments = [];
        }
        this.draftAttachments.push(draftAttachment);
    }

    /**
     * Remove a DraftAttachment identified by its localId from the list of draft attachments.
     * @param {number} localId 
     */
    removeFromDraftAttachmentsUsingLocalId(localId){
        let index = -1;
        this.draftAttachments.forEach((file,i) => {
            if(file.localId === localId)
                index = i;
        });
        if(index > -1){
            this.draftAttachments.splice(index,1); //remove the file
        }
    }

    /**
     * Remove a DraftAttachment identified by its fyleId from the list of draft attachments. There can only be 1 draft attachment with this fyleId for a discussion<
     * @param {number} fyleId 
     */
    removeFromDraftAttachmentsUsingFyleId(fyleId){
        let index = -1;
        this.draftAttachments.forEach((file,i) => {
            if(file.fyleId === fyleId)
                index = i;
        });
        if(index > -1){
            this.draftAttachments.splice(index,1); //remove the file
        }
    }

    /**
     * Update the path of a draft attachment. Find the corresponding attachment in the list and replace the draft.
     * @param {*Object} newDraftAttachment 
     */
    updatePathDraftAttachment(newDraftAttachment){
        this.draftAttachments.forEach(attachment => {
            if(attachment.localId === newDraftAttachment.localId){
                attachment.path = newDraftAttachment.path;
            }
        })
    }

    hasDraftAttachments(){
        return this.draftAttachments != null && this.draftAttachments.length !== 0;
    }

    isLocked() {
        return (!this.contactIdentity || this.contactIdentity.length === 0) && (!this.groupOwnerAndUid || this.groupOwnerAndUid.length === 0);
    }

}