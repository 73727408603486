/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import Vue from 'vue';
import {globals} from "@/assets/ext/globals";
import {realFyle} from "@/assets/datatypes/RealFyle";
import {getThumbnail, isAnImageForGallery, isAnImageForThumbnail} from "@/assets/ext/images";
import {LocalAttachment} from "@/assets/datatypes/LocalAttachment";
import {Message} from "@/assets/datatypes/Message";

export function notif_new_attachment(colissimo) {
	if(!colissimo.notifNewAttachment){
		return;
	}
	let attachment = colissimo.notifNewAttachment.attachment;
	if(!attachment){
		return;
    }
	/**Safeguard if receiving the same attachment multiple times.*/
	if(globals.data.messagesByDiscussion
		&& globals.data.messagesByDiscussion.get(attachment.discussionId)
        && globals.data.messagesByDiscussion.get(attachment.discussionId).get(attachment.messageId)
        && globals.data.messagesByDiscussion.get(attachment.discussionId).get(attachment.messageId).hasReceivedAttachments()
		&& globals.data.messagesByDiscussion.get(attachment.discussionId).get(attachment.messageId).totalAttachmentCount === globals.data.messagesByDiscussion.get(attachment.discussionId).get(attachment.messageId).numberAttachmentsReceived())
	{
		return;
	}

	if(!(attachment.fyleId in globals.data.realFyles)) { //if we don't already have the corresponding real fyle (identified by fyleId), create it
		let realFile = new realFyle(
			attachment.fyleId,
			attachment.mime,
			attachment.path,
			attachment.status,
			attachment.size,
			attachment.name, //to use when downloading this attachment
			null, //will not be null if attachment is a file (url for downloading file and opening it in new tab)
			null, //will not be null if image and downloaded (content as base64 string for adding to src of an image)
			null,
		)
		Vue.set(globals.data.realFyles, realFile.fyleId, realFile);
		if(isAnImageForThumbnail(attachment.mime)) { //ask for thumbnail
			getThumbnail(attachment.path);
		}
	}
	//now memorize abstract Attachment object (identified by localAttachmentId or fyleId+messageId)
	//this way, we can have multiple Attachments with the same realFyle (no need to download again, no information repeated...)
	let localAttachment = new LocalAttachment(
		attachment.fyleId,
		globals.index.localAttachmentId++,
		attachment.messageId,
		attachment.discussionId,
		attachment.messageTimestamp,
        attachment.name,
        attachment.status);

	//add to global list of Attachments
	Vue.set(globals.data.attachments, localAttachment.localId, localAttachment);

	if(isAnImageForGallery(attachment.mime)) { //add this attachment in imagesForGallery if it's a displayable image
		globals.data.imagesForGallery.push(localAttachment.localId); //add to list of images for gallery
		globals.data.imagesForGallery.sort(function(a, b){ //sort the images by message timestamp
			if(globals.data.attachments[a].timestamp === globals.data.attachments[b].timestamp) {
				return globals.data.attachments[a].fyleId - globals.data.attachments[b].fyleId
			} else return  globals.data.attachments[a].timestamp - globals.data.attachments[b].timestamp
		});
    }
    if(!globals.data.messagesByDiscussion.has(attachment.discussionId)){
        globals.data.messagesByDiscussion.set(attachment.discussionId, new Map());
    }
    if(!globals.data.messagesByDiscussion.get(attachment.discussionId).has(attachment.messageId)){
        globals.data.messagesByDiscussion.get(attachment.discussionId).set(attachment.messageId, new Message()); 
    }
    let message = globals.data.messagesByDiscussion.get(attachment.discussionId).get(attachment.messageId);
    message.addAttachment(localAttachment.localId);
}
