/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {handleConnectionToServerLost, handleProtocolError} from "@/assets/ext/error";
import {registerConnection} from "@/assets/ext/messages/messageSender"; //registerCorresponding
import {globals} from "@/assets/ext/globals";
import {serverMessageHandler} from "@/assets/ext/messages/serverMessageHandler";
import {saveDraft} from '@/assets/ext/draft';

export let websocket = {
    connection: null,
    timeout : null,
    reconnect_call_in_progress : false,

    connect: function() {
        if ((this.connection != null && this.connection.readyState === this.connection.OPEN)
            || (this.connection != null && this.connection.readyState === this.connection.CONNECTING)) { 
            console.log("Trying to connect while a connection is already open !");
            return ;
        }

        console.log(globals.constants.serverUrl);

        // if server is a scaling server url will always end with /server, it implies to handle load balancer cookies
        if (globals.constants.serverUrl.endsWith("/server")) {
            // we use stickiness cookies for aws load balancer
            // first unset previous cookies not to keep connection to same instance
            //      if instance return an error because it is terminating we need to connect to another one
            document.cookie = globals.constants.OLVID_SESSION_COOKIE_NAME + "="
            document.cookie = globals.constants.AWS_SESSION_COOKIE_NAME + "="

            // set our session cookie, server will respond with two Set-cookie directive for our cookie, and aws session cookie
            // to save data in QRCode we use web page connectionIdentifier as a session cookie
            let setCookieUrl = globals.constants.serverUrl.replace("wss://", "https://").replace("/server", "") + "/setCookie";
            let setCookieRequest = fetch(setCookieUrl + "?olvidSession=" + globals.connectionIdentifier, {credentials: "include", cache: "no-store"});
            setCookieRequest.then(response => {
                // check request was successful
                if (response.status !== 200) {
                    console.log("setCookie entrypoint answer with an error", setCookieUrl, response);
                    websocket.add_reconnection_timeout(1000);
                    return ;
                }

                // expected response: this is a scaling server that need to set cookies
                if (response.status === 200) {
                    // retrieve aws session cookie in our cookies and store it in globals
                    document.cookie.split(";").map(cookie => {
                        if (cookie.indexOf("=") > 0) {
                            let name = cookie.substring(0, cookie.indexOf("=")).trim()
                            if (name === globals.constants.AWS_SESSION_COOKIE_NAME) {
                                globals.awsSessionCookie = cookie.substring(cookie.indexOf("=") + 1)
                            }
                            else if (name === globals.constants.OLVID_SESSION_COOKIE_NAME) {
                                globals.olvidSessionCookie = cookie.substring(cookie.indexOf("=") + 1)
                            }
                        }
                    });
                    console.log("OlvidCookie: " + globals.olvidSessionCookie);
                    console.log("AwsCookie: " + globals.awsSessionCookie);

                    // sometimes cookies are not correctly set, in that case try to connect again and it fix issue
                    if (!globals.olvidSessionCookie || !globals.awsSessionCookie || globals.awsSessionCookie === "_remove_") {
                        console.log("Cannot retrieve cookies, restarting process")
                        websocket.add_reconnection_timeout(1000);
                        return;
                    }
                }

                // launch connection to websocket server and set listeners
                this.connection = new WebSocket(globals.constants.serverUrl);
                this.connection.onopen = this.onopen;
                this.connection.onmessage = this.onmessage;
                this.connection.onerror = this.onerror;
                this.connection.onclose = this.onclose;
            });
            setCookieRequest.catch(error => {
                console.log("setCookieRequest failed", error);
                websocket.add_reconnection_timeout(1000);
            });
        }
        // if we are using not scaling server we skip cookies management, and we do not call /setCookie to avoid CORS restrictions
        else {
            this.connection = new WebSocket(globals.constants.serverUrl);
            this.connection.onopen = this.onopen;
            this.connection.onmessage = this.onmessage;
            this.connection.onerror = this.onerror;
            this.connection.onclose = this.onclose;
        }
    },

    onopen() {
        if (globals.connectionIdentifier) {
            registerConnection(globals.connectionIdentifier);
        }

        if (globals.status === globals.STATUS_DISCONNECTED) {
            globals.updateStatus(globals.STATUS_CONNECTED_TO_SERVER);
        }
        else if (globals.status === globals.STATUS_RECONNECTING) {
            // registerConnection response will call registerCorresponding, and response will update Stratus to ready
            console.log("successfulReconnectionHandler: reconnection successful")
            if(this.timeout){
                clearTimeout(this.timeout);
            }
        }
        else {
            console.warn("Opened websocket connection from an invalid status");
        }
    },

    onmessage(event) {
        try {
            serverMessageHandler(event.data);
        }
        catch (e) {
            console.log("onmessage: an exception occurred", e)
        }
    },

    onerror(event) {
        console.log("Websocket error found, trying to reconnect ", event);
        websocket.connection = null;
        websocket.add_reconnection_timeout(200);
    },

    onclose() {
        websocket.connection = null;
        switch (globals.status) {
            case (globals.STATUS_DISCONNECTED):
            case (globals.STATUS_CONNECTED_TO_SERVER):
            case (globals.STATUS_READY):
            case (globals.STATUS_RECONNECTING):
                websocket.add_reconnection_timeout(200);
                saveDraft(globals.currentDiscussion);
                break ;
            case (globals.STATUS_CLOSING_SESSION):
                console.log("Normally closed websocket connection");
                break ;
            case (globals.STATUS_PROTOCOL_IN_PROGRESS):
                // do not try to reconnect if protocol was not finished
                handleProtocolError();
                break ;
            case (globals.STATUS_WAITING_FOR_RECONNECTION):
                console.log("Lost connection to server ");
                handleConnectionToServerLost();
                break ;
            default:
                console.log("Invalid status when websocket closed, exiting");
                handleConnectionToServerLost();
                break ;
        }
    },

    send: function(message) {
        if (!this.connection) {
            console.warn("No websocket connection opened, cannot send a message");
        }
        else if (this.connection.readyState !== 1) {
            console.warn("Websocket connection is in an invalid state: " + this.connection.readyState);
            // switch to reconnecting mode if readyState is closing or closed, and not already in reconnecting state
            if (this.connection.readyState === 2 || this.connection.readyState === 3) {
                if (globals.status === globals.STATUS_READY) {
                    globals.updateStatus(globals.STATUS_RECONNECTING);
                }
            }
        }
        else {
            this.connection.send(message);
        }
    },

    // use this method to reconnect instead of websocket.reconnect to avoid multiple reconnection processes in parallel
    // use small timeouts when reconnection can happen when use is connected, else use bigger timeouts to avoid spamming server
    // if connection issues are server side.
    add_reconnection_timeout(timeout_ms) {
        if (!this.reconnect_call_in_progress) {
            console.log("Added reconnection timeout") // TODO TODEL
            setTimeout(() => websocket.reconnect(), timeout_ms);
            this.reconnect_call_in_progress = true;
        }
        else {
            console.log("ignored reconnection timeout") // TODO TODEL
        }
    },

    reconnect: function() {
        this.reconnect_call_in_progress = false;

        console.log("Trying to reconnect to websocket server")
        if (!this.connection) {
            console.log("No active connection to reconnect, restarting process");
            this.connect();
            return ;
        }
        // Simple reconnection process: on qr code page
        if (globals.status === globals.STATUS_DISCONNECTED || globals.status === globals.STATUS_CONNECTED_TO_SERVER) {
            globals.updateStatus(globals.STATUS_DISCONNECTED);
            this.connect();
        }
        // Lost connection to server during protocol
        else if (globals.status === globals.STATUS_PROTOCOL_IN_PROGRESS) {
            console.warn("Lost connection during protocol, aborting");
            handleProtocolError();
        }
        // Lost connection after protocol, try to reconnect
        else if (globals.status === globals.STATUS_READY || globals.status === globals.STATUS_RECONNECTING) {
            // if first reconnection try, set to reconnection status and launch interval to retry connecting
            if (globals.status === globals.STATUS_READY) {
                globals.updateStatus(globals.STATUS_RECONNECTING);
                this.timeout = setTimeout(() => {
                    if (globals.status === globals.STATUS_RECONNECTING) {
                        console.log("reconnection timed out");
                        handleConnectionToServerLost();//declare server lost after 30 seconds
                    }
                }, 30_000);
            }
            this.connect();
        }
        else {
            console.warn("Reconnection failed, unexpected status");
        }
    },
};
