/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
/* eslint-disable no-undef */
import {bitLength, modInv, square, testBit, toZn} from "@/assets/ext/crypto/tools/bigIntTools";

export class EdwardCurvePoint {
    get curve() {
        return this._curve;
    }
    get y() {
        return this._y;
    }
    get x() {
        return this._x;
    }
    /**
     * @param {bigint} x
     * @param {bigint} y
     * @param {EdwardCurve25519} curve
     * @param {boolean|null} check, check if point is on curve or not if !== false
     */
    constructor(x, y, curve, check) {
        this._x = x;
        this._y = y;
        this._curve = curve;
        if (check !== false) {
            let x2 = (x * x) % curve.p;
            let y2 = (y * y) % curve.p;
            if (!(toZn((x2 + y2), curve.p) === toZn(1n + (curve.d * x2 * y2), curve.p))) {
                throw 'Point is not on Edward curve';
            }
        }
    }
}

export class EdwardCurve25519 {
    constructor() {
        // eslint-disable-next-line no-undef
        this.p = BigInt("0x7fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffed");
        this.G = new EdwardCurvePoint(
            BigInt("0x159a6849e44c3c7f061b3d570fc4ed5b5d14c8ba4253df49cc7edf80f533ad9b"),
            BigInt("0x6666666666666666666666666666666666666666666666666666666666666658"),this, false);
        this.q = BigInt("0x1000000000000000000000000000000014def9dea2f79cd65812631a5cf5d3ed");
        this.d = BigInt("0x2dfc9311d490018c7338bf8688861767ff8ff5b2bebe27548a14b235eca6874a");
        this.nu = BigInt(4);
        this.tonelliNonQR = BigInt(2);
        this.tonelliS = this.p >> 1n;
        this.byteLength = 32n;
    }

    /**
     * Return scalar multiplication of Y and N.
     *
     * @param {bigint} N
     * @param {bigint} Y
     *
     * @returns {bigint}
     */
    scalarMultiplication(N, Y) {
        if (typeof N !== "bigint" || typeof Y !== "bigint") {
            throw 'Invalid arguments sent to scalar multiplication'
        }

        if (N === 0n || Y === 0n) {
            return (1n);
        }
        if (Y === this.p - 1n) {
            if (testBit(N, 0n)) {
                return this.p - 1n;
            } else {
                return 1n;
            }
        }

        let c = modInv(1n - this.d, this.p);
        let uP = (Y + 1n) % this.p;
        let wP = toZn(1n - Y, this.p);
        let uQ = 1n;
        let wQ = 0n;
        let uR = uP;
        let wR = wP;

        for (let i = BigInt(bitLength(N))-1n ; i >= 0n ; i -= 1n) {
            let t1 = toZn((uQ - wQ) * (uR + wR), this.p);
            let t2 = toZn((uQ + wQ) * (uR - wR), this.p);
            let uQplusR = ( wP * ((square(t1 + t2)) % this.p) ) % this.p;
            let wQplusR = ( uP * ((square(t1 - t2)) % this.p) ) % this.p

            if (testBit(N, i)) {
                let t3 = (square(uR + wR)) % this.p;
                let t4 = (square(uR - wR)) % this.p;
                let t5 = (t3 - t4) % this.p;
                let u2R = (t3 * t4) % this.p;
                let w2R = (t5 * (t4 + (c * t5))) % this.p;
                uQ = uQplusR;
                wQ = wQplusR;
                uR = u2R;
                wR = w2R;
            }
            else {
                let t3 = (square(uQ + wQ)) % this.p;
                let t4 = (square(uQ - wQ)) % this.p;
                let t5 = (t3 - t4) % this.p;
                let u2Q = (t3 * t4) % this.p;
                let w2Q = (t5 * (t4 + (c * t5))) % this.p;
                uQ = u2Q;
                wQ = w2Q;
                uR = uQplusR;
                wR = wQplusR;
            }
        }
        return (toZn((uQ - wQ) * modInv(uQ + wQ, this.p), this.p));
    }

    /**
     * scalarMultiplicationWithX
     * @param {bigint} n
     * @param {EdwardCurvePoint} P
     * @returns {EdwardCurvePoint} R
     */
    scalarMultiplicationWithX(n, P) {
        if (n === 0n || P.x === 0n) {
            return (0n, 1n);
        }
        if (P.y === this.p - 1n) {
            if (testBit(n, 0n)) {
                return (0n, this.p - 1n);
            }
            else {
                return (0n, 1n);
            }
        }
        let Q = new EdwardCurvePoint(P.x, P.y, this, false);
        let R = new EdwardCurvePoint(0n, 1n, this, false);
        for (let i=bitLength(n)-1; i>=0; i--) {
            if (testBit(n, BigInt(i))) {
                R = this.pointAddition(R, Q);
                Q = this.pointAddition(Q, Q);
            }
            else {
                Q = this.pointAddition(R, Q);
                R = this.pointAddition(R, R);
            }
        }
        return (R);
    }

    /**
     * pointAddition
     * @param {EdwardCurvePoint} P
     * @param {EdwardCurvePoint} Q
     * @returns {EdwardCurvePoint}
     */
    pointAddition(P, Q) {
        let t = toZn(toZn(toZn(toZn(this.d * P.x, this.p) * (Q.x), this.p) * P.y, this.p) * Q.y, this.p);
        let z = modInv(t + 1n, this.p);
        let X = toZn(z * (P.x * Q.y + P.y * Q.x), this.p);
        z = modInv(1n - t, this.p);
        let Y = toZn(z * (P.y * Q.y - P.x * Q.x), this.p);
        return (new EdwardCurvePoint(X, Y, this, false));
    }
}
