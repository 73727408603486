<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div class="attachment">
        <div class="attachment_actions" @click="executeAction()">
            <div v-if="attachment.readyForDownload() || attachment.isDownloaded()">
                <i class="attachment_action--file--download fa fa-download" aria-hidden="true"></i>
            </div>
            <div v-if="attachment.downloadFailed()">
                <i class="attachment_action--file--redownload fa fa-sync" aria-hidden="true"></i>
            </div>
            <div v-if="attachment.isDownloading()">
                <div class="spinner-border spinner-border-sm attachment_action--file--loading" aria-hidden="true">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div class="attachment_file">
            <div class="attachment_file_icon">
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    viewBox="0 0 24 24" >
                    <path d="M6,2c-1.1,0 -1.99,0.9 -1.99,2L4,20c0,1.1 0.89,2 1.99,2L18,22c1.1,0 2,-0.9 2,-2L20,8l-6,-6L6,2zM13,9L13,3.5L18.5,9L13,9z" />
                </svg>
            </div>
            <p class="text--color attachment_file_name" :title="attachment.name">{{attachment.name}}</p>
        </div>
    </div>
</template>

<script>
import {globals} from "@/assets/ext/globals";
import {requestDownloadAttachment} from "@/assets/ext/messages/messageSender";

export default {
    name: 'FileAttachment',
    props: {
        attachment : {
            type:Object,
            required: true
        },
        realFyle : {
            type:Object,
            required:true
        }
    },
    methods : {
        /**
         * Result of onclick action : decides what action to fire depending on the current state of the attachment and the realFyle.
         */
        executeAction() {
            if(this.realFyle.availableForDownload()){
                this.downloadAttachment();
            } else if(this.realFyle.isDownloaded()) {
                this.attachment.updateStatusDownloadDone(true); //in case this wasn't the attachement that launched the download
                this.openAttachment();
            }
        },

        /**
         * Starts downloading attachment process by changing status and calling appropriate method.
         */
        downloadAttachment() {
            this.realFyle.updateStatusDownload();
            this.attachment.updateStatusDownload();
            this.$set(globals.data.realFyles, this.realFyle.fyleId, this.realFyle); //to update status
            requestDownloadAttachment(this.realFyle.fyleId, this.realFyle.size);
        },
        /**
         * Redirects click to correct function : open Gallery if image, open File in new Tab otherwise (if possible).
         */
        openAttachment() {                
            if(this.realFyle.isOpenableInTab() && this.realFyle.urlInWebClient != null) {
                window.open(this.realFyle.urlInWebClient, '_blank', 'noopener,noreferrer');
            } else {
                if (confirm(this.$i18n.t('attachments.labelDownloadAgain'))) {
                    this.downloadAttachment();
                }
            }
            //ask for redownload if not openable and not in list of downloaded attachments, otherwise show it
        }
    }
}
</script>

<style scoped>

.attachment {
    margin-left:5px;
    margin-right:5px;
    position: relative;
    width: inherit;
    height: inherit;
}

.attachment_actions {
    cursor: pointer;
    position: absolute;
    width: 70px;
    height: 70px;
    left: calc(50% - 35px);
    top: calc(50% - 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    color:var(--light-blue);
    font-size: 1.5rem;
}

.attachment_file {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.attachment_file_icon svg {
    fill:var(--transparent-light-grey);
}

.attachment_action--file--loading {
    width: 1.5rem;
    height: 1.5rem;
    border-width: 5px;
}

.attachment_file_name {
    width: fit-content;
    max-width: 100px;
    margin:0;
    height: fit-content; /* older Safari, Opera, Chrome, Edge */
    height: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    height:-moz-fit-content; /* Firefox */
    font-size: 15px;
    cursor: default;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.fa-download g g path {
  stroke: var(--black);
  stroke-width: 10;
}

.fa-sync g g path {
  stroke: var(--black);
  stroke-width: 10;
}


</style>