/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {EdwardCurve25519} from "@/assets/ext/crypto/kemSuite/curves/EdwardCurve25519";
import {PRNGHmacSHA256} from "@/assets/ext/crypto/PRNGHmacSHA256";
import {bigIntToBytes} from "@/assets/ext/crypto/tools/bigIntTools";
import {KemEcies256Kem512Curve25519} from "@/assets/ext/crypto/kemSuite/kem/KemEcies256Kem512Curve25519"

export class KemKeyPair {
    static ALGORITHM_MDC = 0;
    static ALGORITHM_CURVE_25519 = 1;

    is_initialized = false;
    promise = null;

    /**
     * generate fields for a new KemKeyPair: public key (compact and raw) and private key (raw)
     * @param {number} algorithm
     */
    constructor(algorithm) {
        if (algorithm === KemKeyPair.ALGORITHM_CURVE_25519) {
            this.curve = new EdwardCurve25519();
            this.prng = new PRNGHmacSHA256(null);
            this.algorithm = KemKeyPair.ALGORITHM_CURVE_25519;
        }
        else {
            throw 'Algorithm not implemented';
        }
        this.promise = this.init().then(() => {this.is_initialized = true});
    }

    async init() {
        if (this.algorithm === KemKeyPair.ALGORITHM_CURVE_25519) {
            await this.prng.init();
            let a;
            do {
                a = await this.prng.bigInt(this.curve.q);
            } while (a === 0n || a === 1n)
            const Ay = this.curve.scalarMultiplication(a, this.curve.G.y);
            this.publicRawKey = bigIntToBytes(Ay, Number(this.curve.byteLength));
            this.compactPublicKey = new Uint8Array(this.publicRawKey.length + 1);
            this.compactPublicKey[0] = this.algorithm;
            this.compactPublicKey.set(this.publicRawKey, 1);
            this.privateRawKey = bigIntToBytes(a, Number(this.curve.byteLength));
        }
        else {
            throw 'Init not implemented for this algorithm'
        }
    }

    /**
     * Wait for initialisation to be finished, must be call after constructor to be sure
     * that object finished it's construction.
     * @returns {Promise<void>}
     */
    async awaitInitialisation() {
        if (!this.is_initialized) {
            await this.promise;
        }
    }

    /**
     * Decrypt kemSuite encrypted with raw public key, return promise for payload
     * @param cipherText
     * @returns {Promise<Uint8Array>}
     */
    async kemDecrypt(cipherText) {
        if (!this.is_initialized) {
            throw 'Do not initialized KemKeyPair';
        }
        if (this.algorithm === KemKeyPair.ALGORITHM_CURVE_25519) {
            let kem = new KemEcies256Kem512Curve25519();
            return kem.decrypt(this.getRawPrivateKey(), cipherText);
        }
        else {
            throw 'kemDecrypt not implemented for this algorithm';
        }
    }

    /**
     * Get public key as Uint8Array
     * @returns {Uint8Array}
     */
    getRawPublicKey() {
        if (!this.is_initialized) {
            throw 'Do not initialized KemKeyPair';
        }
        return this.publicRawKey;
    }

    /**
     * Get public key in compact key format, as used in Android App
     * @returns {Uint8Array}
     */
    getCompactPublicKey() {
        if (!this.is_initialized) {
            throw 'Do not initialized KemKeyPair';
        }
        return this.compactPublicKey;
    }

    /**
     * Get private key as Uint8Array
     * @returns {Uint8Array}
     */
    getRawPrivateKey() {
        if (!this.is_initialized) {
            throw 'Do not initialized KemKeyPair';
        }
        return this.privateRawKey;
    }
}
