/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";
import {globals} from "@/assets/ext/globals";
import {sanitize_text} from "@/assets/ext/messages/text_sanitizer";
// import Vue from 'vue';
export class Message {
    constructor(id, discussionId, sortIndex, status, type, contentBody, timestamp, 
                totalAttachmentCount, imageCount, senderIdentifier, senderName, replyMessageId, 
                replyMessageAttachmentCount, replySenderIdentifier, replyAuthor, replyBody, readOnce, 
                visibilityDuration, existenceDuration, wiped, remotelyDeleted, wipeOnRead, edited, reactions, senderIsSelf) {
        this.id = id;
        this.discussionId = discussionId;
        this.sortIndex = sortIndex;
        this.status = status;
        this.type = type;
        this.contentBody = sanitize_text(contentBody);
        this.timestamp = timestamp;
        this.totalAttachmentCount = totalAttachmentCount;
        this.imageCount = imageCount;
        this.senderIdentifier = senderIdentifier;
        this.senderName = sanitize_text(senderName);
        this.replyMessageId = replyMessageId;
        this.replyMessageAttachmentCount = replyMessageAttachmentCount;
        this.replySenderIdentifier = replySenderIdentifier;
        this.replyAuthor = sanitize_text(replyAuthor);
        this.replyBody = sanitize_text(replyBody);
        this.readOnce = readOnce;
        this.visibilityDuration = visibilityDuration;
        this.existenceDuration = existenceDuration;
        this.wiped = wiped;
        this.remotelyDeleted = remotelyDeleted;
        this.wipeOnRead = wipeOnRead;
        this.edited = edited;
        this.senderIsSelf = senderIsSelf;

        this.listAttachments = []; //list of localIds
        //storing reactions as a string (given by App), call method to parse it to show them on screen
        this.reactions = sanitize_text(reactions);
    }

    updateFromProtobuf(messageProto){
        if (messageProto) {
            messageProto.contentBody = sanitize_text(messageProto.contentBody);
            messageProto.senderName = sanitize_text(messageProto.senderName);
            messageProto.replyAuthor = sanitize_text(messageProto.replyAuthor);
            messageProto.replyBody = sanitize_text(messageProto.replyBody);
            messageProto.reactions = sanitize_text(messageProto.reactions);
        }
        this.id = messageProto.id;
        this.discussionId = messageProto.discussionId;
        this.sortIndex = messageProto.sortIndex;
        this.status = messageProto.status;
        this.type = messageProto.type;
        this.contentBody = messageProto.contentBody;
        this.timestamp = messageProto.timestamp;
        if (this.totalAttachmentCount !== messageProto.totalAttachmentCount) {
            if (messageProto.totalAttachmentCount === 0) {
                this.listAttachments = []
            }
            this.totalAttachmentCount = messageProto.totalAttachmentCount;
        }
        this.imageCount = messageProto.imageCount;
        this.senderIdentifier = messageProto.senderIdentifier;
        this.senderName = messageProto.senderName;
        this.replyMessageId = messageProto.replyMessageId;
        this.replyMessageAttachmentCount = messageProto.replyMessageAttachmentCount;
        this.replySenderIdentifier = messageProto.replySenderIdentifier;
        this.replyAuthor = messageProto.replyAuthor;
        this.replyBody = messageProto.replyBody;
        this.readOnce = messageProto.readOnce;
        this.visibilityDuration = messageProto.visibilityDuration;
        this.existenceDuration = messageProto.existenceDuration;
        this.wiped = messageProto.wiped;
        this.remotelyDeleted = messageProto.remotelyDeleted;
        this.wipeOnRead = messageProto.wipeOnRead;
        this.edited = messageProto.edited;
        this.senderIsSelf = messageProto.senderIsSelf;

        // manually handle remotely deleted messages
        // In that case app only send an update for wipe and remotely deleted field, need to manually handle it wc side
        if (this.remotelyDeleted) {
            this.listAttachments = []
            this.totalAttachmentCount = 0
        }

        //storing reactions as a string (given by App), call method to parse it to show them on screen
        this.reactions = sanitize_text(messageProto.reactions);
    }

    /**
     * Adds an attachment to the list of attachments for this message. Only store its identifying localId in listAttachments.
     * @param {number} localId 
     */
    addAttachment(localId) {
        this.listAttachments.push(localId); //push updates DOM
        this.listAttachments.sort(function(a, b){ //sort attachments by fyleIds to have a fixed order
            return globals.data.attachments[a].fyleId - globals.data.attachments[b].fyleId //order by fyleIds, no timestamps
        });
    }

    /**
     * Removes an attachment identified by its localId from the list of attachments.
     * @param {number} localId 
     */
    removeAttachment(localId){
        let index = this.listAttachments.indexOf(localId);
        if(index === -1) { //attachment not in message attachment list
            return;
        }
        this.listAttachments.splice(index,1); //remove attachment from list
        this.totalAttachmentCount -= 1; // manually update total attachment count
    }

    hasReceivedAttachments() {
        return this.listAttachments != null && this.listAttachments.length !== 0;
    }

    numberAttachmentsReceived() {
        return this.listAttachments.length;
    }

    isRead() {
       return this.status === protobuf.MessageStatus.STATUS_DELIVERED_AND_READ || this.status === protobuf.MessageStatus.STATUS_READ ; 
    }

    isDelivered() {
       return this.status === protobuf.MessageStatus.STATUS_DELIVERED || this.status === protobuf.MessageStatus.STATUS_UNREAD; 
    }

    isSent() {
       return this.status === protobuf.MessageStatus.STATUS_SENT; 
    }

    isProcessing() {
       return this.status === protobuf.MessageStatus.STATUS_PROCESSING; 
    }

    /**
     * @returns {string}
     */
    getOwnedReaction() {
        let arrayReactions = this.reactions.split(":");//format of received string : 'emoji:number:'
        let i = 0;
        while (i < arrayReactions.length - 1){
            if (arrayReactions[i][0] === '|') {
                return arrayReactions[i].substr(1);
            }
            i+=2;
        }
        return "";
    }

    /**
     * Returns an array of reactions formatted as : [{'emoji':emoji, 'number':number},...]
     * @returns {Array}
     */
    parseReactions() {
        if(!this.reactions || this.reactions === ""){
            return [];
        }
        let arrayReactions = this.reactions.split(":");//format of received string : 'emoji:number:'
        let finalArray = []; //final array : [{'emoji':'number'}]
        let i = 0;
        while (i < arrayReactions.length - 1){
            if (arrayReactions[i][0] === '|') {
                finalArray.push({'emoji':arrayReactions[i].substr(1), 'number':parseInt(arrayReactions[i+1]), 'owned': true});
            }
            else {
                finalArray.push({'emoji':arrayReactions[i], 'number':parseInt(arrayReactions[i+1]), 'owned': false});
            }
            i+=2;
        }
        return finalArray;
    }
}