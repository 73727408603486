/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import Vue from "vue";
import {globals} from "@/assets/ext/globals";
import {findLocalIdOfAttachment} from "@/assets/ext/attachments";
import * as events from "@/assets/ext/events";

export function notif_delete_attachment(colissimo) {
	if(!colissimo.notifDeleteAttachment) {
		return;
	}
	let attachment = colissimo.notifDeleteAttachment.attachment;
	if(!attachment){
		return;
	}
    let localId = findLocalIdOfAttachment(attachment)
    let discussionId = globals.data.attachments[localId].discussionId;
	//Remove from Gallery first
	let indexInGallery = globals.data.imagesForGallery.indexOf(localId);
	if(indexInGallery !== -1) { //if it was an image saved for gallery, remove it from this list
		//Close gallery before removing if this attachment is currently being shown, handled in Gallery.vue
		if(globals.data.currentDiscussion === discussionId) {
			document.dispatchEvent(events.NotifCloseGallery);
		}
		globals.data.imagesForGallery.splice(indexInGallery, 1);
    }
    //delete from list of attachments in message
    if(globals.data.messagesByDiscussion.has(discussionId) && globals.data.messagesByDiscussion.get(globals.data.attachments[localId].discussionId).has(attachment.messageId)){
        globals.data.messagesByDiscussion.get(discussionId).get(attachment.messageId).removeAttachment(localId);
    }

	if(localId in globals.data.attachments) { //delete from global list of attachments (do not delete file in realFyles, some other attachment could be using it)
		Vue.delete(globals.data.attachments, localId);
		let found = false;
		for (let key in globals.data.attachments) {
			if (globals.data.attachments[key].fyleId === attachment.fyleId) {
				found = true;
				break ;
			}
		}
		if (found === false) {
			Vue.delete(globals.data.realFyles, attachment.fyleId);
		}
	}
}
