<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div class="switch-checkbox">
        <div class="switch">
            <input type="checkbox" :checked="checked">
            <span class="slider"></span>
        </div>
        <span class="switch-label">{{label}}</span>
    </div>
</template>

<script>
export default {
    name : 'CustomSwitch',
    props : {
        checked : {
            type : Boolean,
            required:true
        },
        label : {
            type:String,
            required:true
        }
    }
}
</script>

<style scoped>

.switch-checkbox {
    display: inline-flex;
    align-items: center;
    align-content: center;
}

.switch-label {
    margin-left : 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    min-width: 40px;
    height: 22px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--transparent-light-grey);
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 3px;
    background-color: var(--all-white);
    transition: .3s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--light-blue);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--light-blue);
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

</style>
