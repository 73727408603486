<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div id="conversation" class="main-background">
        <FloatEmojiPicker></FloatEmojiPicker>
        <div id="wrapper">
            <div class="messages_wrapper">
                <div id="conversation_initial-spinner">
                    <div id="initial-spinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <span class="text--color">{{$t('conversation.labelLoadingMessages')}}</span>
                </div>
                <div id="no-messages" class="text--color">
                    {{ $t("conversation.labelNoMessagesInDiscussion") }}
                </div>

                <div id="loading-messages-spinner" class="main-background"><i class="fa fa-refresh fa-spin"></i></div>
                <div id="button-scroll-bottom" @click="scrollToBottom" class="scroll-button--color">
                    <i class="fa fa-chevron-down"></i>
                </div>
                <div id="messages">
                    <div v-if="listMessagesInfo.messagesDiscussions.length > 0">
                        <!-- Show this div if the number of current messages in div equals number of requested messages. Otherwise, means there aren't any more messages. -->
                        <div id="load-more-messages" 
                            @click="loadMoreMessagesCurrentDiscussion()" 
                            class="text--color" 
                            v-show="oc.globals.data.messagesByDiscussion.get(oc.globals.currentDiscussion) && oc.globals.data.messagesByDiscussion.get(oc.globals.currentDiscussion).size === oc.globals.data.discussions.get(oc.globals.currentDiscussion).numberMessagesRequested">
                                &#8634; {{$t('conversation.labelLoadMoreMessages')}}
                        </div>
                        <div class="message-item" v-for="(m,index) in listMessagesInfo.messagesDiscussions" v-bind:key="m.id" :id="isLastOrSecondToLast(index)">
                            <div class="message-item_date-tag" v-if="shouldAddTagForDateChange(m, index)">
                                <hr>
                                <div class="tag date-tag_label">
                                    {{getLongDateFromTimestamp(m.timestamp)}}
                                </div>
                                <hr>
                            </div>
                            <div class="message-item_unread-tag" v-if="shouldAddUnreadTag(m, index)">
                                <hr>
                                <div class="tag unread-tag_label" v-show="oc.globals.data.discussions.get(oc.globals.currentDiscussion).unreadMessagesCount != 0">
                                    {{ getUnreadTag()}}
                                </div>
                                <hr>
                            </div>
                            <div v-if="m.type === oc.protobuf.MessageType.INBOUND_MESSAGE || m.type === oc.protobuf.MessageType.TYPE_INBOUND_EPHEMERAL_MESSAGE">
                                <InboundMessageItem
                                        :id="m.id"
                                        :message="m" 
                                        :senderName="getSenderName(m)" 
                                        :replyMessage="getReplyMessage(m)">
                                </InboundMessageItem>
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.OUTBOUND_MESSAGE">
                                <OutboundMessageItem
                                        :id="m.id"
                                        :message="m" 
                                        :senderName="getSenderName(m)" 
                                        :replyMessage="getReplyMessage(m)">
                                </OutboundMessageItem>
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.GROUP_MEMBER_JOINED" class="message-item_protocol text--color">
                                {{ $t("protocolMessages.protocolMessageMemberJoined", {name:m.senderName}) }}
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.GROUP_MEMBER_LEFT" class="message-item_protocol text--color">
                                {{ $t("protocolMessages.protocolMessageMemberLeft", {name:m.senderName}) }}
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.LEFT_GROUP" class="message-item_protocol text--color">
                                {{ $t("protocolMessages.protocolMessageYouLeftGroup") }}
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.CONTACT_DELETED" class="message-item_protocol text--color">
                                {{ $t("protocolMessages.protocolMessageContactDeleted") }}
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.TYPE_DISCUSSION_SETTINGS_UPDATE" class="message-item_protocol text--color">
                                <div v-html="getDiscussionSettingsUpdatedLabel(m)"></div>
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.TYPE_DISCUSSION_REMOTELY_DELETED" class="message-item_protocol text--color">
                                {{ $t("systemMessages.discussionRemotelyDeleted") }}
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.TYPE_PHONE_CALL" class="message-item_protocol text--color">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <path fill='var(--light-blue)' d="M6.62,10.79c1.44,2.83 3.76,5.14 6.59,6.59l2.2,-2.2c0.27,-0.27 0.67,-0.36 1.02,-0.24 1.12,0.37 2.33,0.57 3.57,0.57 0.55,0 1,0.45 1,1V20c0,0.55 -0.45,1 -1,1 -9.39,0 -17,-7.61 -17,-17 0,-0.55 0.45,-1 1,-1h3.5c0.55,0 1,0.45 1,1 0,1.25 0.2,2.45 0.57,3.57 0.11,0.35 0.03,0.74 -0.25,1.02l-2.2,2.2z"/>
                                    </svg>
                                    {{ $t("systemMessages.phoneCall") }}
                                </div>
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.TYPE_NEW_PUBLISHED_DETAILS && oc.globals.data.discussions.get(oc.globals.currentDiscussion).groupOwnerAndUid && oc.globals.data.discussions.get(oc.globals.currentDiscussion).groupOwnerAndUid.length > 0" class="message-item_protocol text--color">
                                {{ $t("systemMessages.groupDetailsUpdated") }}
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.TYPE_NEW_PUBLISHED_DETAILS" class="message-item_protocol text--color">
                                {{ $t("systemMessages.contactDetailsUpdated") }}
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.TYPE_CONTACT_INACTIVE_REASON && m.body === 'revoked'"  class="message-item_protocol text--color">
                                {{ $t("systemMessages.contactInactiveRevoked") }}
                            </div>
                            <div v-else-if="m.type === oc.protobuf.MessageType.TYPE_CONTACT_INACTIVE_REASON"  class="message-item_protocol text--color">
                                {{ $t("systemMessages.contactInactiveBlocked") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div><SendMessage :currentDiscussion="oc.globals.currentDiscussion"/></div>
        </div>

        <div id="modal-update-message" class="secondary-background text--color">
            <div id="modal-update-message_content">
                <div style="display:none;" id="editing_message_id"></div>
                <div style="display:none;" id="editing_message_initial_content"></div>
                <div id="modal-update-message_content_body">
                    <label for="new-content">{{$t('message.labelUpdateMessage')}}</label>
                    <textarea class="main-background text--color" id="new-content" name="new-content"
                            rows="5" cols="33">
                    </textarea>
                </div>
                <div id="buttons">
                    <div id="modal-update-message_button"><button class="button-discard" @click="discardUpdate()" >{{ $t("message.labelDiscard") }}</button></div>
                    <div id="modal-update-message_button"><button class="button-confirm" @click="confirmUpdate()" >{{ $t("message.labelUpdate") }}</button></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import oc from '@/assets/ext/client.js';
import { datesAreDifferent, getLongDateFromTimestamp } from '@/assets/ext/time.js';
import { getColorForSenderIdentifier} from '@/assets/ext/colors.js';
import SendMessage from "@/components/SendMessage";
import InboundMessageItem from '@/components/InboundMessageItem';
import OutboundMessageItem from '@/components/OutboundMessageItem';
import FloatEmojiPicker from '@/components/FloatEmojiPicker.vue';
import {requestUpdateMessage} from '@/assets/ext/messages/messageSender';

export default {
	name: 'Conversation',
    components: {SendMessage,  InboundMessageItem, OutboundMessageItem, FloatEmojiPicker},
    props: {
        listMessagesInfo : {
            type : Object,
            required : true
        }
    },
	data () {
        return {
            oc:oc,
            getLongDateFromTimestamp:getLongDateFromTimestamp,
            datesAreDifferent:datesAreDifferent,
            getColorForSenderIdentifier:getColorForSenderIdentifier,
            scrollPosition : null,
        }
	},
    methods : {
        /**
         * Dismiss modal for updating a message and clear associated data.
         */
        discardUpdate() {
            document.getElementById("modal-update-message").style.display = "none"; 
            document.getElementById("editing_message_id").innerText = -1;
            document.getElementById("editing_message_initial_content").innerText = "";
            document.getElementById("new-content").value = "";
        },
        /**
         * User clicked on 'Confirm' on modal to update a message : will verify new content and send a request to the App to effectively edit the message.
         */
        confirmUpdate() {
            //check content is not empty and not identical
            let newContent = document.getElementById("new-content").value;
            let messageId = parseInt(document.getElementById("editing_message_id").innerText,10);
            if(!messageId || !newContent || newContent === "" || newContent === document.getElementById('editing_message_initial_content').innerText){
                this.discardUpdate();
                return;
            }
            requestUpdateMessage(messageId,newContent.trim());
            this.discardUpdate();
        },
        /**
         * Returns appropriate label for Discussions Settings Updated message. 
         * @param {Object} m
         * @returns {string}
         */
        getDiscussionSettingsUpdatedLabel(m) {
            let content = "<div>";
            if (m.type === oc.protobuf.MessageType.TYPE_DISCUSSION_SETTINGS_UPDATE) {
                if(m.senderName != null){
                    if(m.senderIsSelf){
                        content += this.$i18n.t('protocolMessages.labelChangedDiscussionSettingsYou'); 
                    } else {
                        content += this.$i18n.t('protocolMessages.labelChangedDiscussionSettings', {name:m.senderName}); 
                    }
                } else {
                    content += this.$i18n.t('protocolMessages.labelChangedDiscussionSettingsNoName'); 
                }
            }
            content += "</div><div>";
            if(m.readOnce && m.readOnce === true){
                content += "<span style='margin:0.5rem;font-size:15px;cursor:default;color:var(--light-red);' title='"+this.$i18n.t('ephemeral.labelReadOnceTitle')+"'><i class='fas fa-fire'></i></span>";
            } 
            if(m.visibilityDuration && m.visibilityDuration != "") {
                content += "<span style='margin:0.5rem;font-size:15px;cursor:default;color:var(--orange);' title='"+this.$i18n.t('ephemeral.labelVisibilityTitle')+"'><i class='fas fa-eye'></i> " + m.visibilityDuration+"</span>";
            }
            if(m.existenceDuration && m.existenceDuration != ""){
                content += "<span style='margin:0.5rem;font-size:15px;cursor:default;color:var(--regular-grey);' title='"+this.$i18n.t('ephemeral.labelExistenceTitle')+"'><i class='fas fa-stopwatch'></i> " + m.existenceDuration+"</span>";
            }
            if(!m.readOnce && !m.visibilityDuration && !m.existenceDuration){
                content += "<span style='font-style:italic;font-size:15px;'>" +this.$i18n.t('ephemeral.labelNotEphemeral')+"</span>"; 
            }
            return content;
        },
        /**
         * Gets template to render a reply message.
         * Could make this a component.
         * @param {Object} m
         * @returns {Object}
         */
        getReplyMessage(m) {
            return {id: m.replyMessageId, author : m.replyAuthor, body : m.replyBody, color:this.getColorForSenderIdentifier(m.replySenderIdentifier), attachments: m.replyMessageAttachmentCount};
        },
        /**
         * Gets template to render the name of the sender of the message in case it's a group conversation.
         * @param {Object} m
         * @returns {string}
         */
        getSenderName(m) {
            if(m.senderName !== 'You' && oc.globals.data.discussions.get(oc.globals.currentDiscussion).groupOwnerAndUid != "") {
                return '<span style="font-weight:bold;color:' + this.getColorForSenderIdentifier(m.senderIdentifier) + ';">'+ m.senderName + '</span>';
            }
        },
        /**
         * Returns text for unread tag in conversation.
         * @returns {string}
         */
        getUnreadTag() {
            let unread = this.unReadCount();
            return this.$i18n.tc('protocolMessages.labelUnreadMessages', unread);
        },
        /**
         * Loads more messages for current discussion by subscribing to STEP_NB_REQUEST_MESSAGES more.
         */
        loadMoreMessagesCurrentDiscussion () {
            let currentNumber = oc.globals.data.discussions.get(oc.globals.currentDiscussion).numberMessagesRequested;
            let step = currentNumber + oc.globals.constants.STEP_NB_REQUEST_MESSAGES;
            //increase number of messages loaded by 'step'
            this.scrollPosition = $("#messages")[0].scrollHeight;
            // oc.globals.data.discussions.get(oc.globals.currentDiscussion).incrementNumberRequested();
            oc.messageSender.subscribeToDiscussion(oc.globals.currentDiscussion, step);
        },
        
        /**
         * Determines whether or not given message should include a 'date tag' to indicate a date change in list of message.
         * @param {Message} m
         * @param {number} index
         * @returns {boolean}
         */
        shouldAddTagForDateChange(m, index) {
            return ((index > 0 && datesAreDifferent(m.timestamp,this.listMessagesInfo.messagesDiscussions[index-1].timestamp)) || index===0);
        },
        /**
         * Determines whether or not given message should include an 'unread tag' to indicate start and number of unread messages.
         * @param {Message} m
         * @param {number} index
         * @returns {boolean}
         */
        shouldAddUnreadTag(m, index) {
            if(this.listMessagesInfo.messagesDiscussions.length - this.unReadCount() === index  && m.type === oc.protobuf.MessageType.INBOUND_MESSAGE){
                if(!oc.globals.variables.isWindowActive){
                    return true;
                } else if(!this.secondToLastisVisible()){
                    return true;
                }
            } 
            return false;
        },
        /**
         * Determines whether message at given index is the last message of the list, the second to last one, or none of the two.
         * @param {number} index
         * @returns {boolean}
         */
        isLastOrSecondToLast(index) {
            return (index === this.listMessagesInfo.messagesDiscussions.length - 1) ? 'last-received-message' : (index === this.listMessagesInfo.messagesDiscussions.length - 2) ? 'second-to-last-received-message' : '';
        },
        /**
         * Function to scroll to bottom of div #messages which contains list of messages for current discussion. Uses JQuery.
         * Updates scrollPosition to 0.
         */
        scrollToBottom() {
            let d = $("#messages");
            $(d).scrollTop( $(d).prop("scrollHeight"));
            this.scrollPosition = 0;
        },
        /**
         * Keeps the scroll bar at the same position (relative to top) as the div's height grows.
         * Used when receiving older messages after scrolling top. 
         */
        keepScrollAtPosition() {
            let d = $("#messages");
            let scrollHeight = $(d).prop("scrollHeight");
            //if scroll position = 0 , then equivalent to scroll to bottom because finalScroll = scrollHeight which scrolls to bottom.
            //otherwise, scrollPosition is the distance from the bottom of div, and scrollTop takes an argument giving distance to top of div.
            //So substract scrollPosition to scrollHeight to find distance from top and not bottom.
            //Final behavior : When scrollHeight grows because of new messages, cursor stays at the same place.
            let finalScroll = scrollHeight - this.scrollPosition;
            $(d).scrollTop(finalScroll);
        },

        /**
         * Determines number of unread messages for this conversation.
         * @returns {number} 
         */
        unReadCount() {
            return oc.globals.data.discussions.get(oc.globals.currentDiscussion).unreadMessagesCount;
        },

        /**
         * Determines whether or not the last message in the list is visible by comparing its bounds with that of div #messages.
         * @returns {boolean}
         */
        lastMessageisVisible() {
            if(document.getElementById("last-received-message") == null) {
                return true; //no last message 
            }
            let rectElement = document.getElementById("last-received-message").getBoundingClientRect();
            let rectMessages = document.getElementById("messages").getBoundingClientRect();
            return rectMessages.bottom > rectElement.top;
        },

        /**
         * Determines whether or not the second to last message in the list is visible by comparing its bounds with that of div #messages.
         * @returns {boolean}
         */
        secondToLastisVisible() {
            if(document.getElementById("second-to-last-received-message") == null) {
                return true;
            }
            let rectElement = document.getElementById("second-to-last-received-message").getBoundingClientRect();
            let rectMessages = document.getElementById("messages").getBoundingClientRect();
            return rectMessages.bottom > rectElement.top;
        },
        /**
         * Show initial spinner.
         */
        showSpinner(){
            if(document.getElementById('conversation_initial-spinner')){
                document.getElementById('conversation_initial-spinner').style.display="flex";
            }
            if(document.getElementById('no-messages')){
                document.getElementById('no-messages').style.display = "none";
            }
        },
        /**
         * Hide initial spinner and show no messages label to indicate empty conversation.
         */
        hideSpinnerNoMessages() {
            if(document.getElementById('conversation_initial-spinner')){
                document.getElementById('conversation_initial-spinner').style.display="none"; 
            }
            if(document.getElementById('no-messages')){
                document.getElementById('no-messages').style.display = "flex";
            }
        },
        /**
         * Hide initial spinner and label for no messages. 
         */
        hideSpinnerAndHideNoMessages() {
            if(document.getElementById('conversation_initial-spinner')){
                document.getElementById('conversation_initial-spinner').style.display="none"; 
            } 
            if(document.getElementById('no-messages')){
                document.getElementById('no-messages').style.display = "none"; 
            }
        }
    },
    created() {
        if(oc.globals.status !== oc.globals.STATUS_READY) {
            oc.globals.updateStatus(oc.globals.STATUS_INVALID_STATE);
            return;
        }
        document.addEventListener(oc.events.NotifNoMessageForCurrentDiscussion.type, this.hideSpinnerNoMessages);
    },
    mounted() {
        //immediately scroll to bottom when rendering
        this.scrollToBottom();
        this.scrollPosition=0;
    
        //watcher on scroll in #messages
        $('#messages').on('scroll', () => {
            //Height in pixels of content that is above top of div (hidden). Top reached when scrollTop=0, no content is hidden above.
            let scrollTop = document.getElementById("messages").scrollTop;
            //Height of the scrollable div if all content could be displayed on screen without scrolling.
            let scrollHeight = document.getElementById("messages").scrollHeight;
            //Height of visible part of the screen on div
            let innerHeight = document.getElementById("messages").clientHeight;
            
            if(!this.lastMessageisVisible()) { //show button to scroll to bottom is last message is not visible, otherwise considered as bottom already
                document.getElementById("button-scroll-bottom").style.display="flex";
            } else {
                document.getElementById("button-scroll-bottom").style.display="none";
            }
            if (scrollTop <= 320 && scrollTop >= 280) { //top is reached (around 300 +-20) ; ok for a minimum of 20 messages
                if(!oc.globals.data.messagesByDiscussion.get(oc.globals.currentDiscussion) ||
                    oc.globals.data.messagesByDiscussion.get(oc.globals.currentDiscussion).size != oc.globals.data.discussions.get(oc.globals.currentDiscussion).numberMessagesRequested) {
                    //not loading older messages if all requested messages haven't been loaded yet or at all
                    return;
                }
                this.scrollPosition = document.getElementById("messages").scrollHeight - document.getElementById("messages").scrollTop; //distance to bottom of div
                this.loadMoreMessagesCurrentDiscussion();
                document.getElementById("loading-messages-spinner").style.display = "flex";
                // this.keepScrollAtPosition();
            }
            
            if(scrollTop + innerHeight == scrollHeight) { //bottom is reached : all invisible part of div (scrollTop) + visible part (innerHeight) equals total height of div
                this.scrollPosition = 0;
                if(oc.globals.data.discussions.get(oc.globals.currentDiscussion).unreadMessagesCount > 0){ //if the discussion had unread messages, mark as read
                    if(oc.globals.variables.isWindowActive){
                        oc.messageSender.markDiscussionAsRead(oc.globals.currentDiscussion);
                    }
                } 
            }
        });
    },
    watch : {
        /**listMessagesInfo contains information about current conversation : list of messages and other data for scrolling.
         * This watcher will react to any change in the object.
         */
        listMessagesInfo : function() {
            if(this.listMessagesInfo.messagesDiscussions.length === 0) { //no messages in discussion : either because first batch not yet arrived or no messages at all
                if(!oc.globals.data.discussions.get(oc.globals.currentDiscussion).receivedFirstBatch){ //first batch not received
                    this.$nextTick(this.showSpinner);//show spinner in case it wasn't already visible
                } else { //first batch already received yet no messages => empty discussion
                    this.$nextTick(this.hideSpinnerNoMessages); //remove spinner and show no messages label
                }
                return;
            }
            this.$nextTick(this.hideSpinnerAndHideNoMessages); //here, this conversation has messages so it's neither empty nor loading, make sure they are not visible.
            
            // this.shouldLoadOlderMessage = !this.listMessagesInfo.shouldScrollToBottom; //shouldScrollToBottom => change name to discussion clicked
            if(this.listMessagesInfo.isResultOfDelete) {
                this.scrollPosition = document.getElementById("messages").scrollHeight - document.getElementById("messages").scrollTop;
                this.$nextTick(this.keepScrollAtPosition);
                return;
            } 

            if(this.listMessagesInfo.receivedBatchOldMessages){
                this.$nextTick(this.keepScrollAtPosition); //keep conversation at same position if batch of older messages
                if(document.getElementById("loading-messages-spinner")){
                    document.getElementById("loading-messages-spinner").style.display = "none";
                }
                return;
            }

            if(this.listMessagesInfo.shouldScrollToBottom === false) {//shouldn't scroll to bottom automatically
                //if older message and not following a deleting action
                if(this.listMessagesInfo.lastMessageReceived.timestamp < this.listMessagesInfo.messagesDiscussions[this.listMessagesInfo.messagesDiscussions.length - 1].timestamp && !this.listMessagesInfo.isResultOfDelete){
                    if(this.listMessagesInfo.messagesDiscussions.length <= oc.globals.constants.STEP_NB_REQUEST_MESSAGES){ //if first STEP_NB_REQUEST_MESSAGES , then scroll to bottom (not really older messages)
                        this.$nextTick(this.scrollToBottom);
                    } else {
                        this.keepScrollAtPosition(); //keep conversation at same position if loading older messages
                        if(document.getElementById("loading-messages-spinner")){
                            document.getElementById("loading-messages-spinner").style.display = "none";
                        }
                    }
                } else { //new message
                    if(this.secondToLastisVisible()) { //if old last message before the one being processed right now is visible, then scroll to bottom (which will mark as read at the same time)
                        this.$nextTick(this.scrollToBottom);
                    } //else do nothing, and discussion will be marked as read when hitting bottom
                }
            } else { //handling click on a discussion, should scroll immediately
                this.$nextTick(this.scrollToBottom);
            }
        }
    },
    beforeDestroy(){
        document.removeEventListener(oc.events.NotifNoMessageForCurrentDiscussion.type, this.hideSpinnerNoMessages);
    }
}

</script>

<style scoped>
#conversation {
    border: none;
}

#wrapper {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

#conversation_initial-spinner {
    display: none;
    width:fit-content;
    position: relative;
    margin : 0 auto;
    top : 10%;
    flex-direction: column;
    align-items: center;
}

#initial-spinner {
    color : var(--light-blue);
    width : 50px;
    height:50px;
    margin-bottom : 15px;
}

#no-messages {
    width:fit-content;
    position: relative;
    margin : 0 auto;
    display:none;
    top:45%;
}

#button-sync {
    cursor: pointer;
    margin-right:10px;
    font-size:1.3rem;
}

#button-settings {
    cursor: pointer;
    margin-right:20px;
    font-size:1.5rem;
}

#button-scroll-bottom {
    border-radius: 50%;
    opacity: 0.9;
    border:none;
    display:none;
    position: absolute;;
    bottom:15px;
    right:2%;
    height: 42px;
    width: 42px;
    cursor:pointer;
    z-index: 1; /*on top of the conversation*/
}

#loading-messages-spinner {
    display: none;
    position: absolute;
    border-radius: 50%;
    z-index: 2;
    height: 40px;
    width: 40px;
    left: calc(50% - 20px);
    top: 1%;
    font-size: 1.6rem;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 1px var(--regular-grey);
    color: var(--text-color);
}

.fa-chevron-down {
    opacity: 0.9;
    margin: auto;
    color:#f5f5f5;
}

.tag {
    padding: 5px;
    margin-top:10px;
    margin-bottom:10px;
    min-width:150px;
    width: fit-content; /* older Safari, Opera, Chrome, Edge */
    width: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    width:-moz-fit-content; /* Firefox */
    border-radius:5px;
    position: relative;
}

.messages_wrapper{
    position: relative;
    min-height: 2em;
    width:inherit;
    height: inherit;
    padding-top: 50px;
}

#messages {
    position: relative;
    min-height: 2em;
    width:inherit;
    flex-grow: 1;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    scroll-snap-type: y;
    padding:0 8% 0 3%;
    height: inherit;
}

#load-more-messages {
    cursor:pointer;
    margin:auto;
    width:100%;
    height:25px; 
    border:1px solid var(--black);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message {
    padding: 1%;
    margin : 10px 0 10px 0;
    padding: 5px 10px 5px 10px;
    min-width:150px;
    width: fit-content; /* older Safari, Opera, Chrome, Edge */
    width: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    width:-moz-fit-content; /* Firefox */
    max-width: 500px;
    border-radius:5px;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align:left;
}

.message-item_outbound {
    margin-left: auto;
}

.unread-tag_label {
    background-color:var(--red);
    text-align: center;
}

.date-tag_label, .message-item_ephemeral{
    background-color:var(--slightly-transparent-dark-grey);
    text-align: center;
    color:var(--text-color);
    z-index: 2;
}

.message-item_protocol {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom:5px;
    margin-top:5px;
    padding:3px 0 3px 0;
    border:1px solid var(--slightly-transparent-dark-grey);
    border-radius: 10px;
    text-align: center;
}

.message-item_date-tag, .message-item_unread-tag{
    display: flex;
    justify-content:center;
    align-items:center;
}

.message-item_date-tag hr {
    border-width:1px;
    border-color:var(--lighter-grey);
    width:30%;
}

.message-item_unread-tag hr {
    border-width:0;
}

.updated_settings_icon {
    margin : 1rem;
}

#modal-update-message {
    display: none;
    z-index : 998; /**Under modals and under settings*/
    height: 100%;
    width: 100%;
    max-height: 13rem;
    max-width: 25rem;
    padding:10px 10px 0 10px;
    position: fixed;
    top:calc(50% - 7rem);
    left:calc(65% - 13rem);
    border-radius: 8px;
    border:1px solid var(--item-highlight);
}

#new-content {
    margin-top:10px;
    resize: none;
    overflow-y: scroll;
    width: -moz-available;          /* Firefox */
    width: -webkit-fill-available;  /* Chrome, Opera */
    width: stretch;
    height: 96px;
}

#buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.button-confirm {
    color:var(--all-white);
    width:90px;
    height:40px;
    border: 1px solid var(--all-white);
    margin-left:10px;
    margin-top:15px;
    transition-duration: 0.4s;
    background-color: var(--green);
    cursor:pointer;
}

.button-discard {
    color:var(--all-white);
    width:90px;
    height:40px;
    border: 1px solid var(--all-white);
    margin-left:10px;
    margin-top:15px;
    background-color: var(--red);
    transition-duration: 0.4s;
    cursor:pointer;
}

#modal-update-message_content_body {
    padding:1rem 0 0 1rem;
    text-align: left;
}

</style>