/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import Vue from "vue";
import {globals} from "@/assets/ext/globals";
import {getThumbnail, isAnImageForThumbnail} from "@/assets/ext/images";

export function notif_update_attachment(colissimo) {
	//updating an attachment can only be because of a path change (defined in Android by function equals), so only modify real fyle, no need for local file
	if(!colissimo.notifUpdateAttachment){
		return;
	}
	let attachment = colissimo.notifUpdateAttachment.attachment;
	if(!attachment || !(attachment.fyleId in globals.data.realFyles)){
		return;
	}
	let originalFyle = globals.data.realFyles[attachment.fyleId];
	originalFyle.path = attachment.path;
	//create new updated attachment
	if(isAnImageForThumbnail(attachment.mime)) { //the path is for the thumbnail, so request it again in case the path was added in this update
		getThumbnail(attachment.path);
	}
	Vue.set(globals.data.realFyles, originalFyle.fyleId, originalFyle);
}
