/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
class Uuid {
    static #hexAlphabet = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f"];

    static new() {
        let uuid = "";
        let bytesIndex = 0;

        const bytes = crypto.getRandomValues(new Uint8Array(16));
        for (let i=0; i<20; i++) {
            if (i === 4 || i === 7 || i === 10 || i === 13) {
                uuid += "-";
            }
            else {
                uuid += Uuid.#hexAlphabet[Math.floor(bytes[bytesIndex] / 16)] + Uuid.#hexAlphabet[bytes[bytesIndex] % 16];
                bytesIndex += 1;
            }
        }
        return uuid;
    }
}

module.exports = {
    Uuid
}
