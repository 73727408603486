/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {AuthEncAes256thenSha256} from "@/assets/ext/crypto/authenc/AuthEncAes256thenSha256";
import {globals} from "@/assets/ext/globals";

/**
 * Encrypt payload.
 * @param uint8ArrayPayload
 * @returns {Uint8Array|null}
 */
export async function encryptMessage(uint8ArrayPayload) {
    return AuthEncAes256thenSha256.encryptWithImportedKey(globals.aesKey, globals.macKey, uint8ArrayPayload);
}

/**
 * Decrypt an encrypted colissimo sent by application.
 * @param uint8ArrayPayload
 * @returns {Uint8Array|null}
 */
export async function decryptMessage(uint8ArrayPayload) {
    return AuthEncAes256thenSha256.decryptWithImportedKeys(globals.aesKey, globals.macKey, uint8ArrayPayload);
}
