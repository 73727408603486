/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import {globals} from "@/assets/ext/globals";
import {i18n} from "@/main"

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
  //catch user clicking on back button when in webapp : ask for confirmation
    if(from.path === "/webapp" && to.path === "/sascode" && globals.status === globals.STATUS_READY){
        if(confirm(i18n.t('modals.labelDoYouWantToLeave'))) {
            next('/'); //redirect to initial card
        } else {
            next(false); //cancel redirection
        }
    } else {
        next(); //just proceed
    }
})

export default router;