/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
export class DraftAttachment {
	/**
	 * @param {number} fyleId
	 * @param {number} localId
	 * @param {number} messageId
	 * @param {number} discussionId
	 * @param {string} name
	 * @param {string} mime
	 * @param {Uint8Array} sha256
	 * @param {string} path
	 * @param {number} size
	 * @param {protobuf.AttachmentStatus} status
	 * @param {File} correspondingFile
	 * @param {number} progress
	 */
	constructor(fyleId, localId, messageId, discussionId, name, mime, sha256, path, size, status, correspondingFile, progress) {
		this.fyleId = fyleId;
		this.localId = localId;
		this.messageId = messageId;
		this.discussionId = discussionId;
		this.name = name;
		this.mime = mime;
		this.sha256 = sha256;
		// might be 'local' if added from browser (=> do not compute thumbnail)
		this.path = path;
		this.size = size;
		this.status = status;
		this.correspondingFile = correspondingFile;
		this.progress = progress;
	}
}
