/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";
import {findAttachmentInListFromSha256} from "@/assets/ext/attachments";
import {getThumbnail, isAnImageForThumbnail} from "@/assets/ext/images";
import {addToDraftAttachmentList} from "@/assets/ext/draft";
import {globals} from "@/assets/ext/globals";
import * as events from "@/assets/ext/events";
import {DraftAttachment} from "@/assets/datatypes/DraftAttachment";

export function notif_new_draft_attachment(colissimo) {
	if(!colissimo.notifNewDraftAttachment){
		return;
	}
	let draftAttachmentFromColissimo = colissimo.notifNewDraftAttachment.draftAttachment;
	if(!draftAttachmentFromColissimo){
		return;
	}
	//when adding a file directly from the App, it has no localId
	//if we can't find the file (null), that means it was added to draft from App or was modified in App (EXIF)
	//otherwise it means webclient added it, so just update the values
	// TODO why use a Int8Array instead of Uint8Array
	let draftAttachment = findAttachmentInListFromSha256(draftAttachmentFromColissimo.discussionId, new Int8Array(draftAttachmentFromColissimo.sha256));
	// file was added from webclient (already in list), only fill missing fields
	if(draftAttachment){
		draftAttachment.fyleId = draftAttachmentFromColissimo.fyleId;
		draftAttachment.messageId = draftAttachmentFromColissimo.messageId;
		draftAttachment.status = protobuf.DraftAttachmentStatus.READY;
	} else {
		//file was already in draft in App, or file was modified for metadata removal and SHA256 was altered so considered a new file
		//create custom DraftAttachment to store all information
		draftAttachment = new DraftAttachment(
			draftAttachmentFromColissimo.fyleId,
			null,
			draftAttachmentFromColissimo.messageId,
			draftAttachmentFromColissimo.discussionId,
			draftAttachmentFromColissimo.name,
			draftAttachmentFromColissimo.mime,
			new Int8Array(draftAttachmentFromColissimo.sha256),
			draftAttachmentFromColissimo.path,
			null,
			protobuf.DraftAttachmentStatus.READY,
			null,
			0);

		if(isAnImageForThumbnail(draftAttachment.mime)) { //require thumbnail if its mime type is supported
			getThumbnail(draftAttachment.path);
		}
		addToDraftAttachmentList(draftAttachmentFromColissimo.discussionId, draftAttachment); //add it in list for render
	}

	if(draftAttachmentFromColissimo.discussionId === globals.currentDiscussion) {
		document.dispatchEvent(events.NotifNewDraftAttachment);//handled in SendMessage.vue
	}
}
