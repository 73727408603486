/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
// Connection events
exports.CorrespondingRegistered = new Event('CorrespondingRegistered');
exports.ConnectionAppIdentifierPkKemCommitSeed = new Event('ConnectionAppIdentifierPkKemCommitSeed');
exports.ConnectionAppDecommitment = new Event('ConnectionAppDecommitment');

// globals.status updated
exports.StatusUpdated = new Event("statusUpdated");

// Websocket events
exports.NewCorresponding = new Event('NewCorresponding');

// Api events
exports.PingReceived = new Event('message_pong');
exports.PongReceived = new Event('message_ping');
exports.SyncRequired = new Event('sync_required');
exports.RequestDiscussionResponse = new Event('request_discussion_response');
exports.RequestMessageResponse = new Event('request_message_response');

// Notifications
exports.NotifDiscussionUpdated = new Event('notif_discussion_updated');
exports.NotifDiscussionDeleted = new Event('notif_discussion_deleted');
exports.NotifNewMessage = new Event('notif_new_message');
exports.NotifNewDiscussion = new Event('notif_new_discussion');
exports.NotifDeleteMessage = new Event('notif_delete_message');
exports.NotifDeleteDiscussion = new Event('notif_delete_discussion');
exports.NotifNewDraft = new Event('notif_new_draft');
exports.NotifNewDraftAttachment = new Event('notif_new_draft_attachment');
exports.NotifDeleteDraftAttachment = new Event('notif_delete_draft_attachment');
exports.NotifNoDraftForDiscussion = new Event('notif_no_draft_for_discussion');
exports.NotifDownloadResult = new Event('notif_download_result');
exports.NotifShowGallery = new Event('notif_show_gallery');
exports.NotifCloseGallery = new Event('notif_close_gallery');
exports.NotifDiscussionLocked = new Event('notif_discussion_locked');
exports.NotifCloseConversation = new Event('notif_close_conversation');
exports.NotifNoMessageForCurrentDiscussion = new Event('notif_no_messages_for_current_discussion');