/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import * as Base64 from "@/assets/ext/libs/Base64";
import * as crypto from "@/assets/ext/crypto.js";
import {globals} from "@/assets/ext/globals";
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";
import {SyncRequired} from "../events";

// handlers
import {connection_ping} from "@/assets/ext/messages/handlers/connection_ping";
import {settings} from "@/assets/ext/messages/handlers/settings";
import {request_discussions_response} from "@/assets/ext/messages/handlers/request_discussions_response";
import {notif_discussion_updated} from "@/assets/ext/messages/handlers/notif_discussion_updated";
import {notif_delete_discussion} from "@/assets/ext/messages/handlers/notif_delete_discussion";
import {notif_message_sent} from "@/assets/ext/messages/handlers/notif_message_sent";
import {request_messages_response} from "@/assets/ext/messages/handlers/request_messages_response";
import {notif_new_message} from "@/assets/ext/messages/handlers/notif_new_message";
import {notif_update_message} from "@/assets/ext/messages/handlers/notif_update_message";
import {notif_delete_message} from "@/assets/ext/messages/handlers/notif_delete_message";
import {request_thumbnail_response} from "@/assets/ext/messages/handlers/request_thumbnail_response";
import {notif_file_already_exists} from "@/assets/ext/messages/handlers/notif_file_already_exists";
import {notif_file_already_attached} from "@/assets/ext/messages/handlers/notif_file_already_attached";
import {notif_upload_file} from "@/assets/ext/messages/handlers/notif_upload_file";
import {notif_upload_result} from "@/assets/ext/messages/handlers/notif_upload_result";
import {notif_new_draft_attachment} from "@/assets/ext/messages/handlers/notif_new_draft_attachment";
import {notif_update_draft_attachment} from "@/assets/ext/messages/handlers/notif_update_draft_attachment";
import {notif_delete_draft_attachment} from "@/assets/ext/messages/handlers/notif_delete_draft_attachment";
import {notif_new_attachment} from "@/assets/ext/messages/handlers/notif_new_attachment";
import {receive_download_attachment_done} from "@/assets/ext/messages/handlers/receive_download_attachment_done";
import {receive_download_attachment_chunk} from "@/assets/ext/messages/handlers/receive_download_attachment_chunk";
import {notif_delete_attachment} from "@/assets/ext/messages/handlers/notif_delete_attachment";
import {notif_update_attachment} from "@/assets/ext/messages/handlers/notif_update_attachment";
import {notif_new_discussion} from "./handlers/notif_new_discussion";

/**
 * Prepare (decrypt) a colissimo to be read by below functions
 * @param {Base64} encryptedColissimo
 * @returns {any}
 */
async function prepareColissimo(encryptedColissimo) {
    try {
        const encryptedColissimoAsUint8Array = Base64.base64ToBytes(encryptedColissimo);
        const decryptedColissimo = await crypto.decryptMessage(encryptedColissimoAsUint8Array);
        if (!decryptedColissimo) {
            console.log("Unable to decrypt colissimo, ignoring");
            return null;
        }
        const colissimo = protobuf.Colissimo.decode(decryptedColissimo);
        return (colissimo);
    } catch (e) {
        console.warn("COLISSIMO ERROR : Unable to decrypt colissimo");
        console.log(e);
        return null;
    }
}

/**
 * Main function : takes an encrypted Colissimo as input and decides what to do in function of its type.
 * @param {Base64} encryptedColissimo 
 */
export async function colissimoHandler(encryptedColissimo) {
    const colissimo = await prepareColissimo(encryptedColissimo);
    if (!colissimo) {
        return;
    }
    switch (colissimo.type) {
        case (protobuf.ColissimoType.BYE): {
            globals.updateStatus(globals.STATUS_CLOSING_SESSION);
            break;
        }

        case (protobuf.ColissimoType.CONNECTION_PING): {
            connection_ping(colissimo);
            break;
        }

        //carries the settings sent from App.
        case (protobuf.ColissimoType.SETTINGS): {
            settings(colissimo);
            break;
        }

        // app ask webclient to start a refresh protocol (used when current identity changed)
        case (protobuf.ColissimoType.REFRESH): {
            document.dispatchEvent(SyncRequired)
            break;
        }

        //list of all discussions with their characteristics
        case (protobuf.ColissimoType.REQUEST_DISCUSSIONS_RESPONSE): {
            request_discussions_response(colissimo);
            break;
        }

        case (protobuf.ColissimoType.NOTIF_DISCUSSION_UPDATED): {
            notif_discussion_updated(colissimo);
            break;
        }

        case (protobuf.ColissimoType.NOTIF_DELETE_DISCUSSION) : {
            notif_delete_discussion(colissimo);
            break;
        }

        //notification that message has effectively been sent : sent back by App after received a CREATE_MESSAGE
        case (protobuf.ColissimoType.NOTIF_MESSAGE_SENT): {
            notif_message_sent(colissimo);
            break;
        }

        // response to subscribeToDiscussion, sends STEP_NB_REQUEST_MESSAGES batched in one message
        // this shall erase previous data to re-sync cache with app in case it was a reconnection
        case (protobuf.ColissimoType.REQUEST_MESSAGES_RESPONSE): {
            request_messages_response(colissimo);
            break ;
        }

        case (protobuf.ColissimoType.NOTIF_NEW_MESSAGE): {
            notif_new_message(colissimo);
            break;
        }

        case (protobuf.ColissimoType.NOTIF_NEW_DISCUSSION): {
            notif_new_discussion(colissimo);
            break;
        }

        case (protobuf.ColissimoType.NOTIF_UPDATE_MESSAGE) : {
            notif_update_message(colissimo);
            break;
        }

        case (protobuf.ColissimoType.NOTIF_DELETE_MESSAGE) : {
            notif_delete_message(colissimo);
            break;
        }

        case (protobuf.ColissimoType.REQUEST_THUMBNAIL_RESPONSE): {
            request_thumbnail_response(colissimo);
            break;
        }

        //received if adding already existing file to draft (no need to upload it again)
        case (protobuf.ColissimoType.NOTIF_FILE_ALREADY_EXISTS) : {
            notif_file_already_exists(colissimo);
            break;
        }

        //received if user tries to add a file that is already in the draft (not permitted)
        case (protobuf.ColissimoType.NOTIF_FILE_ALREADY_ATTACHED): {
            notif_file_already_attached(colissimo);
            break;
        }

        //received instead of NOTIF_FILE_ALREADY_EXISTS if file doesn't already exist in App
        case (protobuf.ColissimoType.NOTIF_UPLOAD_FILE) : {
            notif_upload_file(colissimo);
            break;
        }

        //result of the uploading on App side
        case (protobuf.ColissimoType.NOTIF_UPLOAD_RESULT) : {
            notif_upload_result(colissimo);
            break;
        }

        case (protobuf.ColissimoType.NOTIF_NEW_DRAFT_ATTACHMENT) : {
            notif_new_draft_attachment(colissimo);
            break;
        }

        case (protobuf.ColissimoType.NOTIF_UPDATE_DRAFT_ATTACHMENT) : {
            notif_update_draft_attachment(colissimo);
            break;
        } 

        case (protobuf.ColissimoType.NOTIF_DELETE_DRAFT_ATTACHMENT) : {
            notif_delete_draft_attachment(colissimo);
            break;
        }

        case (protobuf.ColissimoType.NOTIF_NEW_ATTACHMENT) : {
            notif_new_attachment(colissimo);
            break;
        }

        case (protobuf.ColissimoType.NOTIF_UPDATE_ATTACHMENT): {
            notif_update_attachment(colissimo);
            break;
        }

        case (protobuf.ColissimoType.NOTIF_DELETE_ATTACHMENT): {
            notif_delete_attachment(colissimo);
            break;
        }

        //simply receiving a chunk from App for a downloading attachment
        case (protobuf.ColissimoType.RECEIVE_DOWNLOAD_ATTACHMENT_CHUNK) : {
            receive_download_attachment_chunk(colissimo);
            break;
        }

        //sent by App when done sending chunks
        case (protobuf.ColissimoType.RECEIVE_DOWNLOAD_ATTACHMENT_DONE) : {
            receive_download_attachment_done(colissimo);
            break;
        }
        default:
            console.warn("NEW COLISSIMO ERROR : Colissimo type not implemented or invalid: " + colissimo.type);
            break;
    }
}
