/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
const regexAudioFile = RegExp('^audio/(m4a|mp3|mpeg|x-m4a)$'); //regex for mime types we want to request the thumbnail of. If mime type not supported Android side, simply won't receive a response.

/**
 * Checks whether or not given mime corresponds to an audio file.
 * @param {string} mime
 * @returns {boolean}
 */
export function isAudioFile(mime) {
	return regexAudioFile.test(mime);
}
