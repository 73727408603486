/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from '@/assets/ext/globals';
import {requestThumbnail} from "@/assets/ext/messages/messageSender";
const protobuf = require("@/assets/ext/protobuf/protobuf.js").olvid;

const regexImageThumbnail = RegExp('^image/.*$'); //regex for mime types we want to request the thumbnail of. If mime type not supported Android side, simply won't receive a response.
const regexImageGallery = RegExp('^image/(jpeg|png|webp|gif)$'); //regex for mime types of images we currently can show in the browser window. Other types of images will be downloaded as regular files.
const regexGif = RegExp('^image/(gif)$'); //regex for mime types of images we currently can show in the browser window. Other types of images will be downloaded as regular files.
/**
 * Checks whether or not given mime corresponds to an image to be shown in gallery.
 * @param {string} mime 
 * @returns {boolean}
 */
export function isAnImageForGallery(mime) {
    return regexImageGallery.test(mime);
}

/**
 * Checks whether or not given mime corresponds to a gif.
 * @param {string} mime
 * @returns {boolean}
 */export function isGifImage(mime) {
    return regexGif.test(mime);
}

/**
 * Checks whether or not given mime corresponds to an image for requesting thumbnail.
 * @param {string} mime 
 * @returns {boolean}
  */
export function isAnImageForThumbnail(mime) {
    return regexImageThumbnail.test(mime);
}

/**
 * Request a thumbnail if given path isn't null or empty, or already received.
 * @param {string} relativePath
 */
export function getThumbnail(relativePath) {                
    if(relativePath !== undefined && relativePath !== ""){
        if(!(relativePath in globals.data.thumbnails)) { //if this path is not already in the list of thumbnails, request it
            requestThumbnail(relativePath);
        }
    } //else, default image will be used
}

/**
 * Adds image associated to fyleId in the array downloadedImageIds. If there are more downloaded images than MAX_NB_IMAGES_CACHED, then remove oldest downloaded image content.
 * @param {number} fyleId 
 */
export function registerNewImageAndRemoveOldest(fyleId) {
    globals.data.downloadedImageIds.push(fyleId);
    if(globals.data.downloadedImageIds.length > globals.constants.MAX_NB_IMAGES_CACHED) { //if full capacity of images reached, delete first one (remove content from attachment)
        let attachment = globals.data.realFyles[globals.data.downloadedImageIds.shift()];
        if(!attachment){
            return;
        }
        attachment.content = null; //removing content
        attachment.status = protobuf.AttachmentStatus.READY_FOR_DOWNLOAD; //can download again
        globals.data.realFyles[attachment.fyleId] = attachment;
    }//else, do nothing, keep on downloading
}
