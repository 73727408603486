/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {sanitize_text} from "@/assets/ext/messages/text_sanitizer";

export class Draft {
    constructor(id,discussionId, contentBody, senderIdentifier, senderName, replyMessageId, replySenderIdentifier, replyAuthor, replyBody, replyMessageAttachmentCount){
        this.id = id;
        this.discussionId = discussionId;
        // do not sanitize content body: need to keep what user entered, and will not break cause it is in a text input
        this.contentBody = contentBody;
        this.senderIdentifier = senderIdentifier;
        // draft senderName is probably always empty, do not sanitize
        this.senderName = senderName;
        this.replyMessageId = replyMessageId;
        this.replySenderIdentifier = replySenderIdentifier;
        this.replyAuthor = sanitize_text(replyAuthor);
        this.replyBody = sanitize_text(replyBody);
        this.replyMessageAttachmentCount = replyMessageAttachmentCount;
    }

    /**
     * Updates current reply message in draft. Triggered by clicking on the reply arrow next to a message.
     * @param {Object} replyingMessage 
     */
    changeReplyMessage(replyingMessage){
        if (replyingMessage) {
            replyingMessage.senderName = sanitize_text(replyingMessage.senderName)
            replyingMessage.contentBody = sanitize_text(replyingMessage.contentBody)
        }
        this.replyMessageId = replyingMessage.id;
        this.replyAuthor = replyingMessage.senderName;
        this.replySenderIdentifier = replyingMessage.senderIdentifier;
        this.replyBody = replyingMessage.contentBody;
        this.replyMessageAttachmentCount = replyingMessage.totalAttachmentCount;
    }

    /**
     * Remove replying message from draft. 
     */
    stopReplying(){
        this.replyMessageId = 0;
        this.replyAuthor = "";
        this.replyBody = 0;
        this.replySenderIdentifier = "";
        this.replyMessageAttachmentCount = 0;
    }
}