<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
<div>
    <Header/>
    <div class="container">
        <div id="container-sas" class="secondary-background">
            <div class="container-sas_content text--color">
                <span>{{ $t("instructions.labelWaitForSASConfirmation") }}</span>
                <span id="container-sas_content_text">{{sasCode}}</span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import oc from '@/assets/ext/client.js';
import router from '@/router';
import Header from '@/components/Header.vue';
//import {saveSettingsToApp} from "@/assets/ext/settings";

export default {
    name: 'SASCard',
    components : {
        Header
    },
    data () {
        return {
            oc : oc,
            router:router,
            sasCode: null,
        }
    },
    methods : {
        /**
         * Handles ping sent by App to Webclient to finalize connection.
         */
        finalPingHandler() {
            document.removeEventListener(oc.events.PingReceived.type, this.finalPingHandler);
            oc.globals.updateStatus(oc.globals.STATUS_READY);
            //save current settings to App to set language in which messages should be sent. Hopefully this message arrives before other requests for discussions/messages.
            //However, automatically saving settings now just wipes prior settings, so not enabled for now.
            //saveSettingsToApp(); 
        }
    },
    created() {
        if(oc.globals.status !== oc.globals.STATUS_SHOW_SAS) {
            oc.globals.updateStatus(oc.globals.STATUS_INVALID_STATE);
            return;
        }
        this.sasCode = oc.globals.connection.sas;
        document.addEventListener(oc.events.PingReceived.type, this.finalPingHandler);
    },
    beforeDestroy() {
        document.removeEventListener(oc.events.PingReceived.type, this.finalPingHandler);
    }
}
</script>

<style scoped>

#container-sas {
    display: flex;
    flex-direction: column;
    padding : 3%;
    width: 850px;
    height:50vh;
    position: absolute;
    top: 22%;
    margin-left:auto;
    margin-right:auto;
    border-radius:5px;
}

#container-sas_content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 26px;
    border-radius: 10px;
}

#container-sas_content_text {
    font-size:60px;
    letter-spacing: 15px;
    position: absolute;
    top: 40%;
    right: 0;
    left: 0;
    text-align: center;
}

@media screen and (max-width: 1000px) and (min-height:500px) {
    #container-sas {
        width: 600px;
    }
}

@media screen and (max-width: 1000px) and (max-height: 790px) {
    #container-sas {
        max-height: 68vh;
    }
}

@media screen and (max-height: 500px) and (max-width:1200px){
    #container-sas {
        max-height: 50vh;
        top:32%!important;
        width: 600px;
    }
}

@media screen and (max-height: 500px) and (min-width:1500px){
    #container-sas {
        top:5%;
        height: 80%;
    }
}

@media screen and (max-height:700px) {
    #container-sas {
        width: 600px;
        height: 50%;
        top:25%;
    }
    #container-sas_content {
        min-width: 600px;
        min-height : 50%;
    }
}

@media screen and (max-height: 500px) and (max-width:1500px) and (min-width:1200px){
    #container-sas {
        top:5%;
        height: 80%;
        width: 600px;
        max-height: 70vh;
    }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
    #container-sas {
        width: 400px;
        height: 50%;
        top:5%;
    }
    #container-sas_content {
        min-width: 400px;
        min-height : 50%;
    }
}

@media screen and (min-height:850px) {
    #container-sas {
        top: 18%;
    }
}


</style>