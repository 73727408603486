<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div id="float-emoji-picker">
        <div id="message-id"></div>
        <div id="list-emojis">
            <div v-for="emoji in listEmojis" :key="emoji" class="emoji" @click="addReaction(emoji)">
                {{emoji}}
            </div>
            <div class="emoji-picker-icon" @click="showExtension = !showExtension"><i class="fas fa-ellipsis-h"></i></div>
            <div class="emoji-close" @click="close()">&times;</div>
        </div>
        <!--TODO this should mind the edges to place itself-->
        <VEmojiPicker
            id="float-emoji-picker-extension"
            v-show="showExtension"
            labelSearch="Search"
            :dark="globals.parameters.darkModeEnabled"
            lang="pt-BR"
            @select="onSelectEmoji" />
    </div>
</template>


<script>
import * as messageSender from "@/assets/ext/messages/messageSender";
import { VEmojiPicker} from "v-emoji-picker";
import {globals} from "@/assets/ext/globals";
export default {
    name:'FloatEmojiPicker',
    components : {VEmojiPicker},
    data() {
        return {
            globals:globals,
            listEmojis : ['👍', '👎','😂','😡','❤️','😎'],
            showExtension : false,
        }
    },
    methods : {
        close() {
            if (document.getElementById("float-emoji-picker")) {
                document.getElementById("float-emoji-picker").style.display = "none";
                this.showExtension = false;
            }
        },
        addReaction(emoji) {
            let messageId = parseInt(document.getElementById("message-id").innerText);
            let ownedReaction = ""
            if (globals.data.messagesByDiscussion.get(globals.currentDiscussion)) {
              if (globals.data.messagesByDiscussion.get(globals.currentDiscussion).get(messageId)) {
                const message = globals.data.messagesByDiscussion.get(globals.currentDiscussion).get(messageId);
                ownedReaction = message.getOwnedReaction();
              }
            }
            if(messageId != null) {
                // send an empty string to remove current emoji
                messageSender.requestAddReaction(messageId, ownedReaction === emoji ? "" : emoji);
            }
            this.close();
        },
        onSelectEmoji(emoji){
            this.addReaction(emoji.data);
        }
    },
    created() {
        this.$root.$on('clickDiscussion', this.close);
        document.addEventListener("visibilitychange", this.close);
        window.addEventListener('blur', this.close);
    },
    mounted() {
        if (document.getElementById("send-message_message_text")) {
            document.getElementById('send-message_message_text').onfocus = () => {
                this.close();
            }
        }
    },
    beforeDestroy() {
        this.$off('clickDiscussion', this.close);
        document.removeEventListener("visibilitychange", this.close);
        window.removeEventListener('blur', this.close);
    },
}
</script>


<style scoped>

#float-emoji-picker {
    display: none;
    position: absolute;
    width: inherit;
    height: 30px;
    background-color: var(--secondary-background-color);
    border: 1px solid var(--item-highlight);
    z-index: 13;
    padding: 3px;
    border-radius: 18px;
}

#list-emojis {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 23px;
}

.emoji, .emoji-picker-icon, .emoji-close {
    cursor: pointer;
    margin : auto;
    padding:0 2px 0 2px;
}

.emoji-close {
    height: fit-content;
}

#message-id {
    display: none;
}

#float-emoji-picker-extension {
    position: absolute;
    z-index: 100;
}

</style>