/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {
    handleConnectionClosedByApp,
    handleConnectionToServerLost,
    handleProtocolError,
    serverErrorMessage,
    handleVersionsNotMatching, handleAppStoppedProtocol, handleInternalError
} from "@/assets/ext/error";
import {colissimoHandler} from "@/assets/ext/messages/colissimoHandler";
import {
    requestDiscussions,
    pingApp,
    registerCorresponding,
    sendOlvidMessage,
} from "@/assets/ext/messages/messageSender";
import {connectionColissimoHandler} from "@/assets/ext/messages/connectionColissimoHandler";
import {handleAttachmentDoneDownloading} from '@/assets/ext/attachments';
import {cancelAllDraftAttachmentUploads} from "@/assets/ext/draft";
import {globals} from "@/assets/ext/globals";
import {CorrespondingRegistered, NewCorresponding} from "@/assets/ext/events";
import {saveDraft} from '@/assets/ext/draft';

export let serverMessageHandler = function (message) {
    if (!message) {
        return ;
    }
    let jsonMessage;
    let receivedNewCorrespondingMessageWhenReady = false;
    let timeoutNewCorrespondingBeforeDisconnection = null;
    try {
        jsonMessage = JSON.parse(message);
    }
    catch (e) {
        console.log("Unable to parse json server message", message);
        return ;
    }
    if (!jsonMessage || !jsonMessage.action) {
        console.warn("SERVER ERROR : Unable to parse message or action field is not present", jsonMessage);
        return ;
    }

    switch (jsonMessage.action) {
        case "relay": {
            if (!jsonMessage.colissimo) {
                console.warn("SERVER ERROR : Did not find colissimo in relay message");
                return ;
            }
            colissimoHandler(jsonMessage.colissimo);
            break ;
        }
        case "connection": {
            if (!jsonMessage.colissimo) {
                console.warn("SERVER ERROR : Did not find colissimo in connection message");
                return ;
            }
            connectionColissimoHandler(jsonMessage.colissimo);
            break ;
        }
        case "terminating": {
            console.log("Received terminating message from server")
            // if server is terminating reconnect if not currently connected to an android app
            // this will let you use another server instance
            if (globals.status === globals.STATUS_DISCONNECTED
                    || globals.status === globals.STATUS_CONNECTED_TO_SERVER) {
                console.log("Terminating message received when waiting for connection, resetting app")
                globals.updateStatus(globals.STATUS_DISCONNECTED)
            }
            break ;
        }
        case "correspondingDisconnected": {
            console.log("MESSAGE: correspondingDisconnected");
            // ignore this message if a bye message has been previously sent
            if (globals.status === globals.STATUS_CLOSING_SESSION) {
                handleConnectionClosedByApp();
                return ;
            } else if(globals.status === globals.STATUS_RECONNECTING) { //was trying to reconnect and App disconnected
                handleConnectionToServerLost();
            }
            else if  (globals.status === globals.STATUS_WAITING_FOR_RECONNECTION) {
                // ignore
                return ;
            }
            else if(globals.status === globals.STATUS_READY){
                // console.log(receivedNewCorrespondingMessageWhenReady)
                if(!receivedNewCorrespondingMessageWhenReady){
                    globals.updateStatus(globals.STATUS_WAITING_FOR_RECONNECTION);
                    //save draft before disconnection
                    saveDraft(globals.currentDiscussion);
                } else {//the App has already reconnected because we received a newCorresponding just before, ignore and send Ping (if it really is disconnected, it will quit)
                    pingApp();
                    receivedNewCorrespondingMessageWhenReady = false;
                    if(timeoutNewCorrespondingBeforeDisconnection){
                        clearTimeout(timeoutNewCorrespondingBeforeDisconnection);
                        timeoutNewCorrespondingBeforeDisconnection = null;
                    }
                    //state not changing, but uploads and downloads should still be cancelled.
                    for(let key in globals.data.realFyles){
                        let realFyle = globals.data.realFyles[key];
                        if (realFyle.isDownloading()) {
                            handleAttachmentDoneDownloading(realFyle.fyleId, false);
                        }
                    }
                    //cancel draft attachments uploading too (will change status and stop sending the chunks)
                    cancelAllDraftAttachmentUploads();
                }
            }
            else if (globals.status === globals.STATUS_PROTOCOL_IN_PROGRESS || globals.status === globals.STATUS_SHOW_SAS) {
                // if corresponding disconnect during protocol abort protocol and leave
                handleAppStoppedProtocol();
            }
            break ;
        }
        case "connectionRegistered": {
            console.log("SERVER: connection registered");
            if(globals.status === globals.STATUS_RECONNECTING){
                registerCorresponding(globals.correspondingIdentifier); //register corresponding after connection was registered
            }
            break ;
        }
        case "correspondingRegistered": {
            console.log("SERVER: corresponding registered")
            if(globals.status === globals.STATUS_RECONNECTING){
                // launch cache reset procedure by sending request discussions message
                requestDiscussions();
                globals.updateStatus(globals.STATUS_READY);
            }
            document.dispatchEvent(CorrespondingRegistered);
            break ;
        }
        case "newCorresponding": {
            console.log("SERVER : New corresponding event");
            if(jsonMessage.version && jsonMessage.version !== globals.constants.VERSION) {
                handleVersionsNotMatching();
                return;
            }
            // if app is reconnecting, clear cache, and subscribe to discussions, and current conversation
            if (globals.status === globals.STATUS_WAITING_FOR_RECONNECTION) {
                requestDiscussions();
                globals.updateStatus(globals.STATUS_READY);
            }else if (globals.status === globals.STATUS_READY){ //if we are ready and receiving a newCorresponding, means that App disconnected in the mean time and we didn't yet receive the notif
                receivedNewCorrespondingMessageWhenReady = true;
                // pingApp();
                //resend last message if it exists
                if(globals.data.lastMessageSent !== null) {
                    //lastMessage was not processed and App or WC disconnected in between, re send
                    //if ever it was sent correctly and already processed, App will just ignore
                    //as it was the last message, attachments and reply are already in draft if they exist
                    sendOlvidMessage(globals.data.lastMessageSent.discussion, globals.data.lastMessageSent.content, globals.data.lastMessageSent.id);
                }
                timeoutNewCorrespondingBeforeDisconnection = setTimeout(() => { //consider it done after 1 minute
                    receivedNewCorrespondingMessageWhenReady = false;
                    timeoutNewCorrespondingBeforeDisconnection = null;
                }, 60_000);
                //App disconnected, even if it's back, download was stopped 
                for(let key in globals.data.realFyles){
                    let realFyle = globals.data.realFyles[key];
                    if (realFyle.isDownloading()) {
                        handleAttachmentDoneDownloading(realFyle.fyleId, false);
                    }
                }
            }
            document.dispatchEvent(NewCorresponding);
            break ;
        }
        case "error": {
            let error = jsonMessage.error;
            if (error !== undefined && typeof error === "number" && error >= 0 && error <= 4) {
                let errorMessage = serverErrorMessage[error];
                console.log("SERVER: error message received: " + errorMessage);
                // internal error
                if (error === 0) {
                    handleInternalError();
                }
                // message not well formatted
                else if (error === 1) {
                    handleInternalError();
                }
                // corresponding not found (registerCorresponding response)
                else if (error === 2) {
                    console.warn("Corresponding not found, exiting");
                    handleProtocolError();
                }
                // corresponding not connected (relay response)
                else if (error === 3) {
                    if (globals.status === globals.STATUS_PROTOCOL_IN_PROGRESS || globals.status === globals.STATUS_SHOW_SAS) {
                        console.log("Unable to relay message during protocol, aborting");
                        handleProtocolError();
                    }
                    else if (globals.status === globals.STATUS_READY) {
                        console.log("Unable to relay message in READY state, waiting for reconnection");
                        globals.updateStatus(globals.STATUS_WAITING_FOR_RECONNECTION);
                    }
                    else if (globals.status === globals.STATUS_WAITING_FOR_RECONNECTION) {
                        console.log("Unable to relay message in WAITING_FOR_RECONNECTION, ignoring");
                    }
                    else {
                        console.log("Unable to relay message in invalid state, aborting", globals.statusAsString(globals.status));
                        handleConnectionToServerLost();
                    }
                    return ;
                }
                // permission denied (shall only be sent to app)
                else if (error === 4) {
                    handleInternalError();
                }
                return ;
            }
            else {
                console.warn("Server sent an invalid error message:", jsonMessage);
            }
            break ;
        }
        default:
            console.warn("SERVER ERROR : Action found is not implemented");
            break ;
    }
};
