<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
  <div class="attachment">
    <div v-if="attachment.notDownloaded()" class="attachment_actions">
      <div v-if="attachment.readyForDownload()" class="attachment_action--file file_download">
        <i @click="downloadAttachment()" class="attachment_action--file--download fa fa-download" aria-hidden="true"></i>
      </div>
      <div v-if="attachment.downloadFailed()" class="attachment_action--file file_redownload">
        <i @click="downloadAttachment()" class="attachment_action--file--redownload fa fa-sync" aria-hidden="true"></i>
      </div>
      <div v-if="attachment.isDownloading()" class="attachment_action--file file_loading">
        <div class="spinner-border spinner-border-sm attachment_action--file--loading" aria-hidden="true">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div v-if="attachment.notDownloaded()" class="attachment_file">
      <svg xmlns="http://www.w3.org/2000/svg"
           width="70"
           height="70"
           viewBox="0 0 24 24" >
        <path d="M6,2c-1.1,0 -1.99,0.9 -1.99,2L4,20c0,1.1 0.89,2 1.99,2L18,22c1.1,0 2,-0.9 2,-2L20,8l-6,-6L6,2zM13,9L13,3.5L18.5,9L13,9z" />
      </svg>
      <p class="text--color attachment_file_name icon" :title="attachment.name">{{attachment.name}}</p>
    </div>
    <!--    TODO handle case where link is not found ??-->
    <div v-else>
      <audio controls>
        <source :src="this.realFyle.urlInWebClient">
        <a :href="this.realFyle.urlInWebClient">Download</a>
      </audio>
      <!-- <p class="text--color attachment_file_name player" :title="attachment.name">{{attachment.name}}</p> -->
    </div>
  </div>
</template>

<script>
import {globals} from "@/assets/ext/globals";
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";
import {requestDownloadAttachment} from "@/assets/ext/messages/messageSender";

export default {
    name: "AudioAttachment",
    props: {
        attachment : {
            type:Object, // datatypes.LocalAttachment
            required: true
        },
        realFyle : {
            type:Object, // datatypes.RealFyle
            required:true,
        },
    },
    data() {
        return {
            protobuf: protobuf,
            silentDownloading: false
        }
    },
    created() {
        if (this.realFyle.size < globals.constants.MAX_AUDIO_SIZE_FOR_AUTO_DOWNLOAD) {
            this.silentDownloadAttachment();
        }
    },
    methods: {
        /**
         * Result of onclick action : decides what action to fire depending on the current state of the attachment.
         */
        downloadAttachment() {
            if (!this.silentDownloading) {
                this.realFyle.updateStatusDownload();
                this.attachment.updateStatusDownload();
                this.$set(globals.data.realFyles, this.realFyle.fyleId, this.realFyle); // update status
                requestDownloadAttachment(this.realFyle.fyleId, this.realFyle.size);
            }
        },
        /**
         * Triggered automatically if file size is small enough : change status of realFyle, but not attachment so not visible on UI.
         */
        silentDownloadAttachment() {
            if (this.silentDownloading) {
                return ;
            }
            this.silentDownloading = true;
            this.realFyle.updateStatusDownload();
            requestDownloadAttachment(this.realFyle.fyleId, this.realFyle.size);
        },
    },
}
</script>

<style scoped>
.attachment {
    margin-left:5px;
    margin-right:5px;
    position: relative;
    width: inherit;
    height: inherit;
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 1fr;
    padding-top: 3px;
}

.attachment_actions {
    width: 70px;
    height: 70px;
    cursor: pointer;
    position: absolute;
}

.attachment_file svg {
    fill:var(--transparent-light-grey);
}

.attachment_action--file {
    position: absolute;
    color:var(--light-blue);
}

.file_download {
    left: 34%;
    top: 51%;
    font-size:1.4rem;
}

.attachment_action--file--loading {
    width: 1.5rem;
    height: 1.5rem;
    border-width: 5px;
}

.file_loading{
    left: 25%;
    top: 41%;
}

.file_redownload {
    left: 36%;
    top: 41%;
    font-size:1.5rem;
}

.attachment_file_name {
    max-width: 80px;
    width: fit-content;
    margin:0;
    height: fit-content; /* older Safari, Opera, Chrome, Edge */
    height: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    height:-moz-fit-content; /* Firefox */
    font-size: 10px;
    cursor: default;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.icon {
    width: fit-content;
    max-width: 80px;
    text-align: center;
}

.player {
    max-width: inherit;
    width: -moz-available;          /* Firefox */
    width: -webkit-fill-available;  /* Chrome, Opera */
    width: stretch;
    text-align: center;
}

.fa-download g g path {
    stroke: var(--black);
    stroke-width: 10;
}

.fa-sync g g path {
    stroke: var(--black);
    stroke-width: 10;
}

</style>