/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import router from "@/router";
import {globals} from '@/assets/ext/globals'

export let errorMessages = {
    1: "ConnectionClosedByApp",
    2: "ProtocolError",
    3: "ConnectionLost",
    4: "VersionMismatch",
    5: "AppStoppedProtocolBeforeEnd",
    6: "InternalError",
};

export let serverErrorMessage = {
    0: "serverInternalError",
    1: "messageNotWellFormatted",
    2: "correspondingNotFound",
    3: "correspondingNotConnected",
    // used only in app if token sent to server do not allow to use webclient feature
    4: "noPermission"
};

export function handleConnectionClosedByApp() {
    reloadPageWithErrorCode(1);
}

export function handleProtocolError() {
    reloadPageWithErrorCode(2);
}

export function handleConnectionToServerLost() {
    reloadPageWithErrorCode(3);
}

export function handleVersionsNotMatching() {
    reloadPageWithErrorCode(4);
}

export function handleAppStoppedProtocol() {
    reloadPageWithErrorCode(5);
}

export function handleInternalError() {
    reloadPageWithErrorCode(6);
}

/**
 * Reloads page with given error code by changing route. If user is using a custom serverURL, put it back in the query.
 * @param {number} errorCode
 */
function reloadPageWithErrorCode(errorCode) {
    globals.error = errorCode;
    if(globals.variables.customUrl){
        router.push({name:'connecting', query: {serverUrl:globals.variables.customUrl}}).catch( () => {});
    } else {
        router.push({name:'connecting'}).catch( () => {});
    }
}
