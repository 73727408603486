/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {EdwardCurve25519} from "@/assets/ext/crypto/kemSuite/curves/EdwardCurve25519";
import {bigIntToBytes, bytesToBigInt, modInv, toZn} from "@/assets/ext/crypto/tools/bigIntTools";
import {KDFSha256} from "@/assets/ext/crypto/KDFSha256";

export class KemEcies256Kem512Curve25519 {
    constructor() {
        this.curve = new EdwardCurve25519();
    }

    /**
     * Encrypt
     * @param {Uint8Array} publicKey
     * @param {PRNGHmacSHA256} prng
     * @returns {object} cipherTextAndKey with keys key and ciphertext
     */
    async encrypt(publicKey, prng) {
        const Ay = bytesToBigInt(publicKey);
        let l = Number(this.curve.byteLength);
        let r;
        do {
            r = await prng.bigInt(this.curve.q);
        } while (r === 0n)
        let Gy = this.curve.G.y;
        let By = this.curve.scalarMultiplication(r, Gy);
        let biDy = this.curve.scalarMultiplication(r, Ay);
        let Dy = bigIntToBytes(biDy, l);
        let ciphertext = bigIntToBytes(By, l);
        let seedBytes = new Uint8Array(2 * l);
        seedBytes.set(ciphertext);
        seedBytes.set(Dy, l);
        let key = await KDFSha256.gen(seedBytes);
        return {"key": key, "ciphertext": ciphertext};
    }

    /**
     * Decrypt
     * @param {Uint8Array} privateKey
     * @param {Uint8Array} ciphertext
     * @returns {Uint8Array} key
     */
    async decrypt(privateKey, ciphertext) {
        let a = bytesToBigInt(privateKey);
        let l =  Number(this.curve.byteLength);
        if (ciphertext.length !== l) {
            return null;
        }
        let By = bytesToBigInt(ciphertext);
        By = this.curve.scalarMultiplication(this.curve.nu, By);
        if (By === 1n) {
            return null;
        }
        a = toZn(a * modInv(this.curve.nu, this.curve.q), this.curve.q);
        let biDy = this.curve.scalarMultiplication(a, By);
        let Dy = bigIntToBytes(biDy, l);
        let seedBytes = new Uint8Array(2 * l);
        seedBytes.set(ciphertext);
        seedBytes.set(Dy, l);
        return KDFSha256.gen(seedBytes);
    }
}
