/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";
const regexDisplayableImage = RegExp('^image/(jpeg|png|webp|gif)$');
const regexOpen = RegExp('^application/pdf$');

export class realFyle {
	constructor(fyleId, mime, path, status, size, name, urlInWebClient, content, receivedAttachmentDoneDownloading) {
		this.fyleId = fyleId;
		this.mime = mime;
		this.path = path;
		this.status = status; // one of protobuf.AttachmentStatus
		this.size = size;
		this.name = name;
		this.urlInWebClient = urlInWebClient; // Only used if PDF file: url to open in a new tab
		this.content = content;

		this.receivedAttachmentDoneDownloading = receivedAttachmentDoneDownloading;
		// chunk index => chunk data (Int8Array)
		this.chunks = new Map();
		// we count received bytes to determine if attachment was fully received even if we don't know chunk size
		this.receivedSize = 0;
    }

	/**
	 * @param {number} index
	 * @param {Uint8Array} chunk
	 */
	addChunk(index, chunk) {
		this.chunks.set(index, chunk)
		this.receivedSize += chunk.length;
	}

    updateStatusDownload(){
        this.status = protobuf.AttachmentStatus.DOWNLOADING;
        this.receivedAttachmentDoneDownloading = false;
        this.chunks = new Map();
		this.receivedSize = 0;
    }

    updateStatusDownloadDone(success) {
        if(success){
            this.status = protobuf.AttachmentStatus.DOWNLOAD_FINISHED;
        } else {
            this.status = protobuf.AttachmentStatus.DOWNLOAD_FAILED;
        }
    }

    isDownloaded() {
        return this.status === protobuf.AttachmentStatus.DOWNLOAD_FINISHED;
    }

    isDownloading(){
        return this.status === protobuf.AttachmentStatus.DOWNLOADING;
    }

    readyForDownload() {
        return this.status === protobuf.AttachmentStatus.READY_FOR_DOWNLOAD; 
    }

    availableForDownload(){
        return this.status === protobuf.AttachmentStatus.READY_FOR_DOWNLOAD || this.status === protobuf.AttachmentStatus.DOWNLOAD_FAILED;    
    }

    notDownloaded() {
        return this.status !== protobuf.AttachmentStatus.DOWNLOAD_FINISHED;
    }

    isADisplayableImage() {
        return regexDisplayableImage.test(this.mime);
    }
    isOpenableInTab(){
        return regexOpen.test(this.mime);
    }
}
