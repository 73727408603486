/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {PRNGHmacSHA256} from "@/assets/ext/crypto/PRNGHmacSHA256";
import {concatenateUint8Array} from "@/assets/ext/crypto/tools/uint8ArrayTools";

export class AuthEncAes256thenSha256 {
    /**
     * Authenticated encryption using AES256 and HMACSha256 from raw keys
     * @param {Uint8Array} rawAesKey
     * @param {Uint8Array} rawMacKey
     * @param {Uint8Array} payload
     * @param {PRNGHmacSHA256} prng
     * @returns {Uint8Array|null} encryptedPayload
     */
    static async encrypt(rawAesKey, rawMacKey, payload, prng) {
        const aesKey = await crypto.subtle.importKey("raw", rawAesKey, {name: "AES-CTR"}, true, ["encrypt"]);
        const macKey = await crypto.subtle.importKey("raw", rawMacKey, {
            name: "HMAC",
            hash: {name: "SHA-256"}
        }, true, ["sign"]);
        return this.encryptWithImportedKey(aesKey, macKey, payload, prng);
    }

    /**
     * Authenticated encryption using AES256 and HMACSha256 from key already imported by subtle
     * Used to encrypt colissimo with final keys from connection protocol
     * @param {Object} aesKey
     * @param {Object} macKey
     * @param {Uint8Array} payload
     * @param {PRNGHmacSHA256} prng
     * @returns {Uint8Array|null} encryptedPayload
     */
    static async encryptWithImportedKey(aesKey, macKey, payload, prng) {
        if (!(aesKey instanceof CryptoKey) || !(macKey instanceof CryptoKey)) {
            console.log("Invalid crypto key, unable to encrypt", aesKey, macKey)
            return null;
        }
        if (prng === undefined || prng === null) {
            prng = new PRNGHmacSHA256(null);
            await prng.init();
        }
        const counter = await prng.bytes(8);
        const iv = concatenateUint8Array(counter, new Uint8Array(8));
        const encryptedPayload = new Uint8Array(await crypto.subtle.encrypt({
            name: "AES-CTR",
            counter: iv,
            length: 64
        }, aesKey, payload));
        const payloadToSign = concatenateUint8Array(counter, encryptedPayload);
        const mac = new Uint8Array(await crypto.subtle.sign("HMAC", macKey, payloadToSign));
        const payloadToSend = concatenateUint8Array(payloadToSign, mac);
        return (payloadToSend);
    }

    /**
     * Decrypt and check signature of an authenticated encrypted message from raw keys
     * @param {Uint8Array} rawAesKey
     * @param {Uint8Array} rawMacKey
     * @param {Uint8Array} payload
     * @returns {Uint8Array|null} encryptedPayload
     */
    static async decrypt(rawAesKey, rawMacKey, payload) {
        const aesKey = await crypto.subtle.importKey("raw", rawAesKey, {name: "AES-CTR"}, true, ["encrypt"]);
        const macKey = await crypto.subtle.importKey("raw", rawMacKey, {
            name: "HMAC",
            hash: {name: "SHA-256"}
        }, true, ["sign"]);
        return (this.decryptWithImportedKeys(aesKey, macKey, payload));
    }

    static async decryptWithImportedKeys(aesKey, macKey, payload) {
        if (!(aesKey instanceof CryptoKey) || !(macKey instanceof CryptoKey)) {
            console.log("Invalid crypto key, unable to decrypt", aesKey, macKey)
            return null;
        }
        const iv = concatenateUint8Array(payload.slice(0, 8), new Uint8Array(8));
        const encryptedPayload = payload.slice(8, -32);
        const payloadToVerify = payload.slice(0, -32);
        const mac = payload.slice(-32);
        if (!await window.crypto.subtle.verify("HMAC", macKey, mac, payloadToVerify)) {
            throw new Error("message hmac cannot be verified, message might be invalid or corrupted");
        }
        const decryptedPayloadAsBuffer = await window.crypto.subtle.decrypt({
            name: "AES-CTR",
            counter: iv.buffer,
            length: 64
        }, aesKey, encryptedPayload.buffer);
        return (new Uint8Array(decryptedPayloadAsBuffer));
    }
}
