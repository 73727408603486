/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";
import {globals} from "@/assets/ext/globals";
import {Draft} from "@/assets/datatypes/Draft";
import * as events from "@/assets/ext/events";
import { Message } from "@/assets/datatypes/Message";

export function notif_new_message(colissimo) {
	if(!colissimo.notifNewMessage){
		return;
	}

	let updateCurrentDiscussion = false;

	for (let message of colissimo.notifNewMessage.messages) {
		if (message.discussionId === globals.currentDiscussion) {
			updateCurrentDiscussion = true;
		}
		if(message.status === protobuf.MessageStatus.STATUS_DRAFT) { //if this message is a Draft
			if(!globals.data.discussions.has(message.discussionId)){ //draft arrived before discussion
				return;
			}
			if(!globals.data.discussions.get(message.discussionId).draft){ //save the first draft to arrive ; discard all other drafts after the first one
				let draft = new Draft(
					message.id,
					message.discussionId,
					message.contentBody,
					message.senderIdentifier,
					message.senderName,
					message.replyMessageId,
					message.replySenderIdentifier,
					message.replyAuthor,
					message.replyBody,
					message.replyMessageAttachmentCount
				);
				globals.data.discussions.get(message.discussionId).draft = draft;
				if(message.discussionId === globals.currentDiscussion) { //if current discussion's draft is being updated, notify SendMessage to show it in text area.
					document.dispatchEvent(events.NotifNewDraft); //handled in SendMessage.vue
				}
			}
		} else { //regular message
			if(!globals.data.messagesByDiscussion) {
				globals.data.messagesByDiscussion = new Map();
			}
			if (!globals.data.messagesByDiscussion.has(message.discussionId))  { //this discussion has no messages yet
				globals.data.messagesByDiscussion.set(message.discussionId, new Map());
			}
			if (globals.data.messagesByDiscussion.get(message.discussionId).has(message.id)){
				globals.data.messagesByDiscussion.get(message.discussionId).get(message.id).updateFromProtobuf(message);
			} else {
				globals.data.messagesByDiscussion.get(message.discussionId).set(message.id, new Message(
					message.id,
					message.discussionId,
					message.sortIndex,
					message.status,
					message.type,
					message.contentBody,
					message.timestamp,
					message.totalAttachmentCount,
					message.imageCount,
					message.senderIdentifier,
					message.senderName,
					message.replyMessageId,
					message.replyMessageAttachmentCount,
					message.replySenderIdentifier,
					message.replyAuthor,
					message.replyBody,
					message.readOnce,
					message.visibilityDuration,
					message.existenceDuration,
					message.wiped,
					message.remotelyDeleted,
					message.wipeOnRead,
					message.edited,
					message.reactions ? message.reactions : "",
					message.senderIsSelf
				));
			}

			//if it's the in current conversation, then sent a notification to update vue, otherwise do nothing
			if (updateCurrentDiscussion) {
				const event = new CustomEvent(events.NotifNewMessage.type, {
					detail: {
						lastMessage: message //lastMessage will provide all necessary information (e.g. for scrolling)
					}
				});
				document.dispatchEvent(event);//handled in Body.vue
			}
	}
	}
}
