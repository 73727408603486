<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div class="thumbnail_draft_attachment">
        <div v-if="file.status === oc.protobuf.DraftAttachmentStatus.FAILED" class="attachment_action">
            <i @click="$parent.removeFailedUpload()" class="attachment_action--delete delete_thumbnail fa fa-times-circle fa-2x" aria-hidden="true"></i>
            <i @click="$parent.reupload(file)" class="attachment_action--reupload reupload_thumbnail fa fa-sync fa-3x" aria-hidden="true"></i>
        </div>
        <div v-else class="attachment_action">
            <i @click="$parent.removeFile()" class="attachment_action--delete delete_thumbnail fa fa-times-circle fa-2x" aria-hidden="true"></i>
        </div>
        <img
            :src="dataURL"
            :alt="file.name"
            :title="file.name"
            class="draft-attachment_image_thumbnail">
    </div>
</template>

<script>
import oc from '@/assets/ext/client.js';
export default {
    name:'DraftAttachmentThumbnail',
    props:{
        file : {
            type:Object,
            required:true
        }
    },
    data() {
        return {
            oc:oc,
            dataURL : ""
        }
    },
    methods : {
        /**
         * Retrieve image data for given file. Either get it locally (using file descriptor) or from thumbnails array.
         */
        getImageData() {
            if(this.file.path === 'local') {
                this.getLocalThumbnail();
            } else if(this.file.path && this.file.path != '') {
                this.getThumbnailFromURL();
            }
        },

        /**
         * Retrieve thumbnail from computer : for attachments added by user on webclient directly => we have access to actual file.
         * Update imageData in parent to not show name of attachment.
         */
        async getLocalThumbnail() {
            let dataURL = await new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.onload = () => resolve(fileReader.result);
                fileReader.readAsDataURL(this.file.correspondingFile);
            })
            this.$parent.imageData = true;
            this.dataURL = dataURL;
        },

        /**
         * Retrieve thumbnail from thumbnails array : the thumbnail was requested to the App.
         * If this component was mounted, the path is either local or the thumbnail exists in the array.
         * Update imageData in parent to not show name of attachment.
         */
        getThumbnailFromURL() {
            this.$parent.imageData = true;
            this.dataURL="data:image/" +this.file.mime+ ";base64,"+oc.globals.data.thumbnails[this.file.path];
        }
    },
    mounted() {
        this.getImageData();
    }
}
</script>

<style scoped>
.draft-attachment_image_thumbnail {
    height: 110px;
    width: 110px;
    overflow: hidden;
    border-radius: 12px;
    object-fit: cover;
    border:1px solid var(--lighter-grey);
}

.attachment_action {
  text-align: left;
  padding-left: 25px;
}

.attachment_action--delete {
    cursor: pointer;
    position: absolute;
    color:var(--lighter-grey);
}

.delete_thumbnail {
    right:5%;
    top:5%;
    text-shadow: 0 0 4px var(--light-grey);
}

.delete_thumbnail:hover {
    color:var(--red);
}

.attachment_action--reupload {
  cursor: pointer;
  position: absolute;
  color: var(--light-blue);
}

.reupload_thumbnail {
    left:38%;
    top:36%;
}

.reupload_thumbnail:hover {
  text-shadow: 0 0 4px var(--light-grey);
}


</style>