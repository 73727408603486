/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {KemEcies256Kem512Curve25519} from "@/assets/ext/crypto/kemSuite/kem/KemEcies256Kem512Curve25519"

export class KemPublicKey {
    static ALGORITHM_MDC = 0;
    static ALGORITHM_CURVE_25519 = 1;

    /**
     * Extract public key from the compact key format used by Android App
     * Compact key specifies algorithm used by key by adding a byte at the beginning
     * @param rawCompactKey
     */
    constructor(rawCompactKey) {
        if (rawCompactKey[0] === KemPublicKey.ALGORITHM_CURVE_25519) {
            this.algorithm = KemPublicKey.ALGORITHM_CURVE_25519; // TODO shall check length => need to move edward curve fields to static
            this.compactKey = rawCompactKey;
            this.rawKey = new Uint8Array(rawCompactKey.length - 1);
            this.rawKey.set(rawCompactKey.slice(1));
        }
        else {
            throw 'Algorithm not implemented or not recognized';
        }
    }

    /**
     * Create a kem from raw this raw public key, kem algorithm is determining the
     * algorithm specified in compactKey
     * @param prng
     * @returns {Promise<{ciphertext: Uint8Array, key: *}>}
     */
    async kemEncrypt(prng) {
        if (this.algorithm === KemPublicKey.ALGORITHM_CURVE_25519) {
            let kem = new KemEcies256Kem512Curve25519();
            return await kem.encrypt(this.rawKey, prng);
        } else {
            throw 'Kem not implemented for this algorithm';
        }
    }

    getCompactKey() {
        return this.compactKey;
    }

    getRawKey() {
        return this.rawKey;
    }
}
