/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {sendAttachmentByChunks} from "@/assets/ext/attachments";

// app responded to SendAttachmentNotice message, and told webclient to upload this attachment (that was added to draft)
export function notif_upload_file(colissimo) {
	if(!colissimo.notifUploadFile){
		return;
	}
	//all processing is done in this method (sending entire attachment)
	sendAttachmentByChunks(colissimo.notifUploadFile.localId);
}
