<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div id="app" class="main-background">
        <div id="main-content">
            <router-view :key="$route.fullPath"></router-view>
        </div>
        <div id="phone-display" class="main-background">
            <a class="text--color" :href="linkToStore">
                <img :src="imageForStore" :alt="labelLinkToStore" id="phone-display_logo"/>
            </a>
        </div>
        <audio id="notif-sound" controls src="@/assets/audio/notification_bloop.mp3"> </audio>
        <Settings></Settings>
        <!-- <div @click="oc.messageSender.pingApp()" style="z-index:1000;background-color:green;width:50px;height:50px; position:fixed; top : 0; right:100px;cursor:pointer;">Ping App </div> -->
        <!-- <div @click="f()" style="z-index:1000;background-color:red;width:50px;height:50px; position:fixed; top : 0; right:150px;cursor:pointer;">Pong App </div> -->
        <!-- <div @click="oc.websocket.connection.close()" style="z-index:1000;background-color:purple;width:50px;height:50px; position:fixed; top : 0; right:200px;cursor:pointer;">Close WS</div> -->
    </div>
</template>
<script type="text/javascript" src="dist/purify.min.js"></script>
<script>

import oc from '@/assets/ext/client.js';
import router from '@/router';
import {websocket} from "@/assets/ext/websocket";
import Settings from "@/components/Settings.vue"
import {getBrowserLanguage, updateTheme} from "@/assets/ext/settings";
import {cancelAllDraftAttachmentUploads} from "@/assets/ext/draft";
import {handleAttachmentDoneDownloading} from '@/assets/ext/attachments';
import {globals} from "@/assets/ext/globals";
import {handleConnectionClosedByApp} from "./assets/ext/error";

export default {
    name: 'App',
    components:{Settings},
    data() {
        return {
            images :{
                fr_badge_apple : require("@/assets/images/fr_badge_appstore.png"),
                en_badge_apple : require("@/assets/images/en_badge_appstore.png"),
                fr_badge_google : require("@/assets/images/fr_badge_google.png"),
                en_badge_google : require("@/assets/images/en_badge_google.png"),
            },
            oc:oc,
            router:router,
            isReconnecting: false,
            isWaitingForReconnection: false,
            reconnectionInterval: null,
            currentLocation: null
        }
    },
    computed : {
        labelLinkToStore : function () {
            return this.$i18n.t('instructions.labelLinkToStore');
        },
        linkToStore : function () {
            if(/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                return 'https://apps.apple.com/us/app/olvid/id1414865219';
            } else  {
                return 'https://play.google.com/store/apps/details?id=io.olvid.messenger';
            }
        },
        imageForStore : function() {
            if(/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                return this.$i18n.locale === 'fr' ? this.images.fr_badge_apple : this.images.en_badge_apple;
            } else {
                return this.$i18n.locale === 'fr' ? this.images.fr_badge_google : this.images.en_badge_google;
            }
        }
    },
    methods: {
        /**
         * Initialize local parameters (language, theme).
         */
        init : function() {
            /*Handle language initialization */
            getBrowserLanguage();
            /*Handle dark mode initialization*/
            updateTheme('BrowserDefault');
            /*Window size*/
            this.windowResize();
            this.isReconnecting = false;
            this.isWaitingForReconnection = false;
        },
    
        /**
         * Handler for listener on event StatusUpdated. Updates front according to current status.
         */
        async updateStatus() {
            console.log("updating status : " + oc.globals.statusAsString(oc.globals.status))
            this.currentLocation = this.getLocation();
            switch (oc.globals.status) {
                case oc.globals.STATUS_READY:
                    if(oc.globals.data.lastMessageSent !== null) {
                        //lastMessage was not processed and App or WC disconnected in between, re send
                        //if ever it was sent correctly and already processed, App will just ignore
                        //as it was the last message, attachments and reply are already in draft if they exist
                        oc.messageSender.sendOlvidMessage(oc.globals.data.lastMessageSent.discussion, oc.globals.data.lastMessageSent.content, oc.globals.data.lastMessageSent.id);
                    }
                    // hide reconnecting pop up
                    if (this.isReconnecting) {
                        if (document.getElementById("modal-webapp-disconnected")) {
                            document.getElementById("modal-webapp-disconnected").style.display = "none";
                            document.getElementById("blur").style.display = "none";
                        }
                        this.isReconnecting = false;
                    }
                    // hide waiting for reconnection pop up
                    else if (this.isWaitingForReconnection) {
                        if (document.getElementById("modal-phone-disconnected")) {
                            document.getElementById("modal-phone-disconnected").style.display = "none";
                            document.getElementById("blur").style.display = "none";
                        }
                        this.isWaitingForReconnection = false;
                    }
                    else {
                        // go to discussion and message screen
                        router.push({name: 'webapp'}).catch( () => {});
                    }
                    break;
                case oc.globals.STATUS_CONNECTED_TO_SERVER :
                    this.router.push({name:'qrcode'}).catch( () => {});
                    break;
                case oc.globals.STATUS_DISCONNECTED :
                    if(!(this.$route.name === 'connecting')){
                        if(oc.globals.variables.customUrl){
                            router.push({name:'connecting', query: {serverUrl:oc.globals.variables.customUrl}}).catch( () => {});
                        } else {
                            router.push({name:'connecting'}).catch( () => {});
                        }
                    }
                    break;
                case oc.globals.STATUS_WAITING_FOR_RECONNECTION:
                    for(let key in globals.data.realFyles){
                        let realFyle = globals.data.realFyles[key];
                          if (realFyle.isDownloading()) {
                              handleAttachmentDoneDownloading(realFyle.fyleId, false);
                          }
                    }
                    cancelAllDraftAttachmentUploads();
                    
                    this.isWaitingForReconnection = true;
                    document.getElementById('modal-gallery').style.display = "none";
                    document.getElementById("modal-phone-disconnected").style.display = "flex";
                    document.getElementById("blur").style.display = "block";
                    if(document.getElementById('global-settings')){
                        document.getElementById('global-settings').style.display = "none";
                    }
                    break;
                case oc.globals.STATUS_RECONNECTING:
                    for(let key in globals.data.realFyles){
                        let realFyle = globals.data.realFyles[key];
                        if (realFyle.isDownloading()) {
                            handleAttachmentDoneDownloading(realFyle.fyleId, false);
                        }
                    }
                    cancelAllDraftAttachmentUploads();

                    if(this.isReconnecting) {
                      websocket.add_reconnection_timeout(200);
                    }
                    this.isReconnecting = true;
                    document.getElementById('modal-gallery').style.display = "none";
                    document.getElementById("modal-webapp-disconnected").style.display = "flex";
                    document.getElementById("blur").style.display = "block";
                    if(document.getElementById('global-settings')){
                        document.getElementById('global-settings').style.display = "none";
                    }
                break;
                case oc.globals.STATUS_PROTOCOL_IN_PROGRESS:
                    //Maybe here ? fixes a bug where we do the entire protocol in isWaitingForReconnection and get stuck when STATUS becomes READY
                    this.isReconnecting = false;
                    this.isWaitingForReconnection = false;
                    this.router.push({name:'protocol'}).catch( () => {});
                break;
                case oc.globals.STATUS_SHOW_SAS :
                    this.router.push({name :'sascode'}).catch( () => {});
                break;
                case oc.globals.STATUS_INVALID_STATE:
                    if(!(this.$route.name === 'connecting')){
                        if(oc.globals.variables.customUrl){
                            router.push({name:'connecting', query: {serverUrl:oc.globals.variables.customUrl}}).catch( () => {});
                        } else {
                            router.push({name:'connecting'}).catch( () => {});
                        }
                    }
                break;
                case oc.globals.STATUS_CLOSING_SESSION:
                    handleConnectionClosedByApp()
                break;
                default:
                break;
            }
        },
        beforeUnloadHandler(e) {
            if(websocket.connection){
                websocket.connection.onclose = null;
            }
            // this.removeEventListeners();
            //prompt user for confirmation for every route except qrcode
            //browser generated prompt
            if(!['/qrcode'].includes(this.$router.currentRoute.path)){
                e.preventDefault();
                //browser won't actually show this string but the default one
                e.returnValue = this.$i18n.t('modals.labelDoYouWantToLeave');;
            }
        },
        /**
         * Remove all event listeners associated to protocol for connection.
         */
        removeEventListeners() {
            document.removeEventListener(oc.events.PingReceived.type, this.finalPingHandler);
            document.removeEventListener(oc.events.StatusUpdated.type, this.updateStatus);
            document.removeEventListener("visibilitychange", this.switchVisibilityState);
            window.removeEventListener("beforeunload", this.beforeUnloadHandler);
        },

        /**
         * Switches boolean isWindowActive according to current visibilityState. Triggered by event 'visibilitychange'.
         */
        switchVisibilityState(){
            if (document.visibilityState === 'visible') {
                oc.globals.variables.isWindowActive = true;
            } else {
                oc.globals.variables.isWindowActive = false;
            }
        },
        /**Changes screen layout if window is under a certain size and in a proper state. */
        windowResize() {
            if(['/qrcode','/sascode','/connecting','/protocol'].includes(this.$router.currentRoute.path)){
                if(window.innerWidth < 500) {
                    document.getElementById('main-content').style.display="none";
                    document.getElementById('phone-display').style.display = "block";
                } else {
                    document.getElementById('main-content').style.display="block";
                    document.getElementById('phone-display').style.display = "none";
                }
            }
        },
        getLocation() {
            return location.pathname + location.search;
        }
    },
    created() {
        document.addEventListener(oc.events.ConnectionAppIdentifierPkKemCommitSeed.type, this.handlerConnectionAppIdentifierPkKemCommitSeed);

        //track window visibility
        document.addEventListener("visibilitychange", this.switchVisibilityState);
        window.onblur = function() { oc.globals.variables.isWindowActive = false; }
        window.onfocus = function() { oc.globals.variables.isWindowActive = true; }

        document.addEventListener(oc.events.StatusUpdated.type, this.updateStatus);
        //user leaving page (closing tab, reload button...)
        window.addEventListener("beforeunload", this.beforeUnloadHandler);
        window.addEventListener("unload", this.removeEventListeners);

        //watch for theme change
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            if(oc.globals.parameters.darkModeSysPref) { //user preference
                if(e.matches) {
                    document.documentElement.setAttribute('data-theme', 'dark');
                    oc.globals.parameters.darkModeEnabled = true;
                } else {
                    document.documentElement.setAttribute('data-theme', 'light');
                    oc.globals.parameters.darkModeEnabled = false;
                }
            }
        });
        window.matchMedia('(max-width: 768px)').addEventListener('change', this.windowResize);
    },
    mounted() {
        this.init(); //local JS
    },
    beforeDestroy() {
        this.removeEventListeners();
    }
}
</script>

<style>

/* define colors */
:root {
  --lighter-grey:lightgray;
  --darker-light-grey : darkgrey;
  --regular-grey : grey;
  --bluish-grey : lightslategray;
  --light-grey : #424242;
  --dark-grey : #303030;
  --transparent-light-grey :#88808080;
  --slightly-transparent-dark-grey : rgba(145, 139, 139, 0.452);
  --transparent-dark-grey : rgba(98, 98, 105, 0.3);
  --transparent-gallery-background : rgba(20, 20, 20, 0.95);
  --dark-blue : #0044c9;
  --light-blue : #2f65f5;
  --title-blue : #154271;
  --all-white : #ffffff;
  --almost-white : #f5f5f5;
  --black : #000000;
  --transparent-black : rgba(0,0,0,0.5);
  --red : #cc4141;
  --transparent-red : rgba(204,65,65,0.8);
  --green : #67AE55;
  --orange : #ff9800;
  --light-red : #FF4500;
  --transparent-green : rgba(103,174,85,0.8);
  --group-yellow : lightgoldenrodyellow;
  --drag-drop-window-color : rgba(130,130,130,0.8);
}

/*For all pages : darkMode CSS*/
[data-theme="dark"] {
  --main-background-color : var(--dark-grey);
  --secondary-background-color : var(--light-grey);
  --discussion-text-color : var(--almost-white);
  --text-color:var(--almost-white);
  --background-message-item_outbound : rgba(43, 55, 87, 0.6);
  --background-message-item_inbound : var(--light-grey);/*rgba(47,101,245, 0.6);*/
  --svg-color : var(--almost-white);
  --reply-color : var(--almost-white); 
  --scroll-button-color : var(--light-grey);
  --scrollbar-thumb-color : var(--light-grey);
  --item-highlight : var(--transparent-light-grey);
  --tooltip-date-and-time : var(--light-grey);
  --edited-color : var(--black);
  --reaction-border: rgba(66, 66, 66, 0.3);
  --reaction-background: var(--background-message-item_inbound);
  --owned-reaction-background: #909090;
}

[data-theme="light"] {
  --main-background-color : var(--almost-white);
  --secondary-background-color : var(--all-white);
  --discussion-text-color : var(--title-blue);
  --text-color:var(--black);
  --background-message-item_outbound : rgba(106, 137, 223, 0.3);
  --background-message-item_inbound : rgba(12, 38, 67, 0.1);
  --svg-color : var(--black); 
  --reply-color : var(--regular-grey); 
  --scroll-button-color : var(--dark-grey);
  --scrollbar-thumb-color : var(--regular-grey);
  --item-highlight : var(--lighter-grey);
  --tooltip-date-and-time : var(--slightly-transparent-dark-grey);
  --edited-color : var(--all-white);
  --reaction-border: rgba(12, 38, 67, 0.4) ;
  --reaction-background: var(--background-message-item_inbound);
  --owned-reaction-background: #808080;
}

.main-background {
  background-color: var(--main-background-color);
}

.secondary-background {
  background-color: var(--secondary-background-color);
}

.message-item_outbound--background {
  background-color: var(--background-message-item_outbound);
}

.message-item_inbound--background {
  background-color: var(--background-message-item_inbound);
}

.discussion_item--highlight {
  background-color : var(--item-highlight);
}

.svg--color {
  fill: var(--svg-color);
}

.discussion-text--color {
  color:var(--discussion-text-color);
}

.text--color {
  color:var(--text-color);
}

.edited-color {
    color:var(--edited-color);
}

.scroll-button--color {
  background-color: var(--scroll-button-color);
}

.reply-attachment--background {
  background-color: var(--regular-grey);
}

.tooltip-background {
  background-color: var(--tooltip-date-and-time);
}

::-webkit-scrollbar {
  background-color:var(--main-background-color);
  width:10px;
}

::-webkit-scrollbar-thumb {
  background-color:var(--scrollbar-thumb-color);
  border-radius:20px;
}

.blueGradient {
  background-image:-webkit-linear-gradient(top, var(--light-blue) 0, var(--dark-blue) 100%);
  background-image:-o-linear-gradient(top, var(--light-blue) 0, var(--dark-blue) 100%);
  background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, var(--light-blue)), to(var(--dark-blue)));
  background-image:linear-gradient(to bottom, var(--light-blue) 0, var(--dark-blue) 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=var(--light-blue), endColorstr=var(--dark-blue), GradientType=0);
  background-repeat:repeat-x;
}

.greyGradient {
  background-image:-webkit-linear-gradient(top, var(--lighter-grey) 0, var(--darker-light-grey) 100%);
  background-image:-o-linear-gradient(top, var(--lighter-grey) 0, var(--darker-light-grey)  100%);
  background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, var(--lighter-grey)), to(var(--darker-light-grey) ));
  background-image:linear-gradient(to bottom, var(--lighter-grey) 0, var(--darker-light-grey) 100%);
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=var(--lighter-grey), endColorstr=var(--darker-light-grey), GradientType=0);
  background-repeat:repeat-x;
}

.reply--color {
  color : var(--reply-color);
}

body {
  margin:0;
  padding:0;
  height:100vh !important;
  width:100%;
  overflow: hidden;
}
html {
  height: 100vh !important;
  width:100%;
}
      
#app {
    font-family: Muli, Helvetica, Arial, sans-serif;
    text-align: center;
    min-height:100% !important;
    /**Fonts will appear sharper if these rules are supported */
    -webkit-font-smoothing: antialiased; /** MacOS only ; Opera, Chrome, all webkit compatible browsers */
    -moz-osx-font-smoothing: grayscale; /** MacOS only ; Firefox   */
}

*:focus {
    outline: 0;
}

#main-content {
  min-height:98vh;
}

#phone-display {
  display: none;
  margin-top:10%;
}

#phone-display_logo {
  width:200px;
}

/*For cards Components*/
.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

#notif-sound {
    display: none;
}

/* less than ipad vertically, could be other dimension : show phone display, no support for using web client on a phone
@media screen and (max-width: 768px) {
  #main-content {
    display: none!important;
  }
  #phone-display {
    display: block;
  }
} */

</style>
