<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div id="send-message" class="secondary-background">
        <div id="discussion-locked"><i class="fa fa-lock"></i> {{$t('conversation.labelDiscussionLocked')}} </div>
        <div id="drag-drop-window" class="text--color">{{$t('attachments.labelDragAndDrop')}}<div id="dragDropAttachments"></div></div>
        <div id="send-message_draft-attachments">
            <div class="draft-fyle-item" v-for="(file,index) in files" :key="index">
                <DraftAttachmentComponent :file="file" :key="file.fyleId"></DraftAttachmentComponent>
            </div>
        </div>
        <div v-show="globals.variables.replyMessageId != 0" class="text--color main-background " id="send-message_replying-message">
            <div id="send-message_replying-message_content" @click="scrollToMessage(globals.variables.replyMessageId)">
                <span id="send-message_replying-message_author" v-html="getReplyingAuthor()"></span>
                <div id="send-message_replying-message_content_row">
                    <span class="text--color" id="send-message_replying-message_body" v-html="getReplyingMessage()"></span>
                    <span class="text--color reply-attachment--background" id="send-message_replying-message_attachments" v-show="getReplyingAttachmentCount(globals.variables.replyMessageId)">{{getReplyingAttachmentCount(globals.variables.replyMessageId)}}</span>
                </div>
            </div>
            <div id="send-message_stop-replying" @click="stopReplying()">&times;</div>
        </div>
        <div id="send-message_input">
            <button class="secondary-background text--color" id="button-emojis" @click="toggleEmojis()" :title="$t('attachments.labelAddEmoji')"><i class="far fa-smile"></i></button>
            <input type="file" id="send-message_input_files" @change="saveFiles()" multiple>
            <button id="send-message_input_add-file" @click="openFileDialog()" class="svg--color" :title="$t('attachments.labelAddAttachment')">
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24" >
                    <path d="M16.5,6v11.5c0,2.21 -1.79,4 -4,4s-4,-1.79 -4,-4V5c0,-1.38 1.12,-2.5 2.5,-2.5s2.5,1.12 2.5,2.5v10.5c0,0.55 -0.45,1 -1,1s-1,-0.45 -1,-1V6H10v9.5c0,1.38 1.12,2.5 2.5,2.5s2.5,-1.12 2.5,-2.5V5c0,-2.21 -1.79,-4 -4,-4S7,2.79 7,5v12.5c0,3.04 2.46,5.5 5.5,5.5s5.5,-2.46 5.5,-5.5V6h-1.5z" />
                </svg>
            </button>
            <div id="send-message_message_text_container">
                <div id="send-message_message_text" class="text--color secondary-background" :placeholder="$i18n.t('conversation.labelDraftWriteMessage')" @click="focusin()" contenteditable="true" tabindex="1"></div> 
                <div v-show="globals.parameters.showSaveDraftButton" @click="triggerSendDraftToApp()" id="button-save-draft"><button :title="$t('conversation.labelSaveDraft')" class="main-background reply--color"><i class="fas fa-save"></i></button></div>
            </div>
            <button @click="sendMessage()" id="send-message_submit" class="secondary-background" :title="$t('conversation.labelSendMessage')">
                <svg :class="isSendingEnabled ? 'send-enable' : 'send-disable'" xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24" >
                    <path d="M2.01,21L23,12 2.01,3 2,10l15,2 -15,2z" />
                </svg>
            </button>

            <VEmojiPicker
                id="emoji-picker"
                v-show="showEmojis"
                labelSearch="Search"
                :dark="globals.parameters.darkModeEnabled"
                lang="pt-BR"
                @select="onSelectEmoji" />
        </div>
    </div>
</template>

<script>

import {DraftAttachment} from '@/assets/datatypes/DraftAttachment';
// modified name to avoid name superposition with class datatype/DraftAttachment
import DraftAttachmentComponent from "@/components/DraftAttachment";
import {calculateNbChunks} from '@/assets/ext/attachments.js';
import { getColorForSenderIdentifier} from '@/assets/ext/colors.js';
import {areAttachmentsBeingAddedToDraft, saveDraft, sendDraftToApp, stopReplying} from '@/assets/ext/draft';
import $ from 'jquery';
import { VEmojiPicker} from "v-emoji-picker";
import {globals} from "@/assets/ext/globals";
import * as messageSender from "@/assets/ext/messages/messageSender";
import * as events from "@/assets/ext/events";
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";

export default {
    name: 'DiscussionList',
    components:{DraftAttachmentComponent, VEmojiPicker},
    props:{
        currentDiscussion : {
            type:Number,
            required:true
        }
    },
    data () {
        return {
            draft:null,//local variable for storing draft value
            files:new Array(),//local array for storing draft attachments
            previousConversation:null, //discussionId of previous discussion (current discussion most of the time)
            isSendingEnabled:false,
            showEmojis:false,
            stopReplying:stopReplying,
            globals:globals,
        }
    },
    watch : {
        /**
         * Watcher for value currentDiscussion. Updates draft when changing discussions.
         */
        currentDiscussion : function() {
            this.updateDraftForClickedDiscussion();
            this.$parent.discardUpdate(); //when changing discussions, discard modal to edit messages
        }
    },
    methods: {
        /**
         * Set replyMessageId to 0 : no render for a reply message.
         */
        hideReply() {
            globals.variables.replyMessageId = 0;
        },
        /**
         * Set replyMessageId to given Id : render reply message for this message.
         * @param {number} id
         */
        showReply(id) {
            globals.variables.replyMessageId = id;
        },
        /**
         * Fired when clicking on an emoji in the emoji picker. Adds it to text input.
         * @param {Object} emoji
         */
        onSelectEmoji(emoji) {
            document.getElementById('send-message_message_text').focus(); //focus first to insert emoji in case it wasn't in focus
            document.execCommand("insertText", false, emoji.data); //will fill active DOM element
            this.setIsSendingEnable();
        },
        /**
         * Show/hide the emoji picker view.
         */
        toggleEmojis() {
            this.showEmojis = !this.showEmojis;
        },
        /**
         * Method called when clicking on a reply in a message or in the draft : scrolls to original message if it has been loaded in conversation, otherwise does nothing.
         */
        scrollToMessage(replyingId) {
            if(replyingId === 0){
                return;
            }
            if(globals.data.messagesByDiscussion.get(globals.currentDiscussion).has(replyingId)){ //message exists in conversation
                document.getElementById(replyingId).scrollIntoView({behavior: "smooth", block: "end"});
            }//if we don't have the message loaded, do nothing.
        },
        /**
         * Load draft for new current conversation. Method called in reaction to change of conversation.
         * Handles changing discussion => updating draft ; draft attachments ; replying.
         * Because of variable previousConversation, we still have access to conversation before clicking in order to save draft.
         */
        updateDraftForClickedDiscussion() {
            //if there are draft attachments, show them
            if(globals.data.discussions.has(globals.currentDiscussion) && globals.data.discussions.get(globals.currentDiscussion).draftAttachments){
                this.files = globals.data.discussions.get(globals.currentDiscussion).draftAttachments;
            } else {
                this.files=null;
            }
            //save current draft message for previous conversation if there is one
            if(this.previousConversation && this.previousConversation !== globals.currentDiscussion) {
                saveDraft(this.previousConversation);
            }
            //update draft for current discussion's one
            this.fillInputAreaFromDraft(globals.currentDiscussion);
            //also check if discussion is locked or not
            this.isDiscussionLocked();
            //if there is a draft then enable sending
            this.setIsSendingEnable();

            this.previousConversation = globals.currentDiscussion;
        },

        /**
         * Set value of text in input area and eventually renders the replying messsage for the given discussion.
         * @param {number} discussionId
         */
        fillInputAreaFromDraft(discussionId){
            if(!globals.data.discussions.has(globals.currentDiscussion)){
                return;
            }
            if(globals.data.discussions.get(discussionId).draft != null) {
                this.showReply(globals.data.discussions.get(discussionId).draft.replyMessageId);
                document.getElementById('send-message_message_text').innerText = globals.data.discussions.get(discussionId).draft.contentBody;
                let sel;
                if (document.selection) { //Opera and IE
                    sel = document.selection.createRange();
                    sel.moveEnd('character', 0);
                    sel.select();
                } else { //Firefox, Safari, Chrome
                    sel = window.getSelection();
                    try {
                        sel.collapse(document.getElementById('send-message_message_text').lastChild, document.getElementById('send-message_message_text').innerText.length);
                    } catch (error) {
                        //do nothing
                    }
                }
            } else {
                this.hideReply();
                document.getElementById('send-message_message_text').innerText = "";
            }
        },
        /**
         * Returns author of message currently replying to from its id.
         * @param {number} id
         * @returns {string}
         */
        getReplyingAuthor() {
            if(!globals.data.discussions.has(globals.currentDiscussion) || !globals.data.discussions.get(globals.currentDiscussion).draft){
                return "";
            }
            let author = globals.data.discussions.get(globals.currentDiscussion).draft.replyAuthor;
            let senderId = globals.data.discussions.get(globals.currentDiscussion).draft.replySenderIdentifier;
            if(document.getElementById('send-message_replying-message') != null){
                document.getElementById('send-message_replying-message').style.borderLeftColor = getColorForSenderIdentifier(senderId); //this.$parent.nameColorAssociation.get(author);
                document.getElementById('send-message_replying-message_author').style.color = getColorForSenderIdentifier(senderId); //this.$parent.nameColorAssociation.get(author);
            }
            return author;
        },

        /**
         * Returns content of message currently replying to from its id.
         * @returns {string}
         */
        getReplyingMessage() {
            if(!globals.data.discussions.has(globals.currentDiscussion) || !globals.data.discussions.get(globals.currentDiscussion).draft){
                return "";
            } else {
                return globals.data.discussions.get(globals.currentDiscussion).draft.replyBody;
            }
        },

        /**
         * Returns number of message currently replying to from its id.s
         * @param {number} id
         * @returns {string}
         */
        getReplyingAttachmentCount() {
            if(!globals.data.discussions.has(globals.currentDiscussion) || !globals.data.discussions.get(globals.currentDiscussion).draft){
                return "";
            }
            if(globals.data.discussions.get(globals.currentDiscussion).draft.replyMessageAttachmentCount === 0){
                return false;
            } else {
                return this.$i18n.tc('attachments.labelLastMessageNoTextAttachments', globals.data.discussions.get(globals.currentDiscussion).draft.replyMessageAttachmentCount);
            }
        },
        /**
         * Determines whether or not the current discussion is locked by checking if both the contact identity and group Uid are null.
         */
        isDiscussionLocked() {
            let discussion = globals.data.discussions.get(globals.currentDiscussion);
            if((!discussion.contactIdentity || discussion.contactIdentity.length === 0) && (!discussion.groupOwnerAndUid || discussion.groupOwnerAndUid.length === 0)){
                document.getElementById('discussion-locked').style.display = "flex";
            } else {
                document.getElementById('discussion-locked').style.display = "none";
            }
        },

        /**
         * In Opera, content editable div will add <div><br></div> whe hitting Enter (not when hitting shift+enter) ; and this will cause 
         * the final message to have 2 times more line breaks than it should.
         * Replacing this sequence by a single <br> fixes it. Does not happen in Firefox or Safari.
         * Safeguard : should not happen with current way of handling line breaks.
         */
        clearLineBreaks : function () {
            let regex = /<div><br><\/div>/gi; //this handles multiple line breaks without content
            document.getElementById('send-message_message_text').innerHTML = document.getElementById('send-message_message_text').innerHTML.replace(regex, '<br>'); 
        },
        /**
         * Method to send a message through websocket. Checks all needed values and cleans draft attachment list.
         */
        sendMessage : function() {
            this.clearLineBreaks();
            let message = document.getElementById('send-message_message_text').innerText;//textContent
            message = message.trim();
            if (!message && (!this.files || this.files.length === 0)) { //message empty and no attachments
                return;
            }
            if (!globals.currentDiscussion) {//no current discussion
                return;
            }
            if(globals.data.lastMessageSent !== null){//last message not yet processed by App, can't send another
                return;
            }
            let currentDraftAttachments = globals.data.discussions.get(globals.currentDiscussion).draftAttachments;
            if(message || (currentDraftAttachments && currentDraftAttachments.length > 0)){ //message not null or attachments
                //check that no attachments are currently being added in the draft
                if(areAttachmentsBeingAddedToDraft()){
                    return;
                }
                if(!message) { //only attachments, empty message
                    message="";
                }
                let localId = globals.index.localMessageId++;
                messageSender.sendOlvidMessage(globals.currentDiscussion, message, localId, globals.variables.replyMessageId);
                globals.data.lastMessageSent = {id : localId, content : message, discussion : globals.currentDiscussion};
                
                //clear draft for this discussion
                document.getElementById('send-message_message_text').innerText="";
                this.hideReply();
                globals.data.discussions.get(globals.currentDiscussion).draft = null;
                
                //reinitialize files
                globals.data.discussions.get(globals.currentDiscussion).draftAttachments = new Array();

                this.focusin();
                this.isSendingEnabled = false;
                this.showEmojis = false;
            }
        },
        /**
         * Display toast signaling that this type of attachment is not supported in case the user tries to upload something else than a file (e.g folder)
         */
        showAttachmentTypeNotSupported() {
            this.$toasted.global.attachment_type_not_supported(this.$i18n.t('attachments.labelAttachmentNotSupported'));
        },

        /**
         * Upload an attachment after adding it to WebClient by either selecting in file explorer or dragging/dropping.
         * Calculates SHA256 and sends notice to App. Determines number of chunks to send and indicates it in notice.
         * @param {File} file
         */
        uploadAttachment : function(file) {
            let fileBuffer;
            let size = file.size;
            let fileReader = new FileReader();
            let sha;
            let localId = Math.floor(Math.random() * 100000);

            fileReader.onerror = () => { //mainly for drag and drop fails, for exemple trying to upload a folder
                this.showAttachmentTypeNotSupported();
            }

            fileReader.onloadend = async () => {
                fileBuffer = fileReader.result;
                sha = await crypto.subtle.digest('SHA-256', fileBuffer);
                
                //creating new Attachment Object 
                let newDraftAttachment = new DraftAttachment(
                    null,
                    localId,
                    null,
                    globals.currentDiscussion,
                    file.name,
                    file.type,
                    new Int8Array(sha),
                    'local',
                    size,
                    protobuf.DraftAttachmentStatus.LOADING,
                    file,
                    0);

                //add to list of draft attachments
                globals.data.discussions.get(globals.currentDiscussion).addDraftAttachment(newDraftAttachment);
                this.setIsSendingEnable();
                //add it to local variable files for rendering
                this.files = globals.data.discussions.get(globals.currentDiscussion).draftAttachments;

                //sending sendAttachmentNotice to App
                if(size <= globals.CHUNK_SIZE_SENDING) { //file smaller than 1 chunk
                    messageSender.sendAttachmentNotice(localId, Array.from(new Int8Array(sha)), size, 1, file.type, file.name, globals.currentDiscussion);
                } else {
                    let nbChunks = calculateNbChunks(file);
                    messageSender.sendAttachmentNotice(localId, Array.from(new Int8Array(sha)), size, nbChunks, file.type, file.name, globals.currentDiscussion);
                }
                //waiting for notification from App now to either do nothing & simply add it if file already exists or get ready to send chunks
            }
            fileReader.readAsArrayBuffer(file);
        },

        /**
         * Reacting to 'focusin' event in textarea. 
         */
        focusin : function() {
            document.getElementById("send-message_message_text").style.borderBottomColor="var(--bluish-grey)";
            if(document.getElementById('send-message_message_text').textContent === ""){ //remove buggy <br> tag added in firefox
                document.getElementById('send-message_message_text').innerText = "";
            }
        },
        /**
         * Reacting to 'focusout' event in textarea. 
         */
        focusout : function() {
            document.getElementById("send-message_message_text").style.borderBottomColor="var(--lighter-grey)";
            if($('#send-message_message_text').text().length === 0) {
                this.setIsSendingEnable();
            }
        },
        /**
         * Updates value of isSendingEnabled according to content of message and list of attachments. True if there is a message or at least 1 attachment, False otherwise
         */
        setIsSendingEnable() {
            if(!$('#send-message_message_text')){
                this.isSendingEnabled = false;
                return;
            }
            if(globals.data.lastMessageSent !== null) { //last message has not yet been processed by App, can't send any other in case there is a problem
                return;
            }
            if($('#send-message_message_text').text().trim().length !== 0 || 
                (globals.data.discussions.get(globals.currentDiscussion).hasDraftAttachments() &&
                !areAttachmentsBeingAddedToDraft())) {
                
                this.isSendingEnabled = true;
            } else {
                this.isSendingEnabled = false;
            }
        },
        /**
         * Open file selector by virtually clicking appropriate element.
         */
        openFileDialog : () => {
            document.getElementById("send-message_input_files").click();
        },

        /**
         * Handles user selecting file(s) in file selector.
         */
        saveFiles : function() {
            let element;
            for(element of document.getElementById("send-message_input_files").files){
                this.saveFile(element);
            }
            //clear value for dialog box so that you can reselect the same files
            document.getElementById("send-message_input_files").value=null;
        },

        /**
         * Save file (attachment) by uploading it, which adds it to appropriate lists.
         * @param {File} file
         */
        saveFile : function(file) {
            if(!this.files){
                this.files = new Array();
            }
            this.uploadAttachment(file);
            this.focusin();
        },
    
        /**
         * Handles user drag&dropping file(s) in window. Code adapted from Mozilla Drag_and_Drop_API.
         */
        dropHandler : function(event) {
            if (event.originalEvent.dataTransfer.items) {
                for (let i = 0; i < event.originalEvent.dataTransfer.items.length; i++) {
                    let entry = event.originalEvent.dataTransfer.items[i].webkitGetAsEntry();
                    if(!entry){ return; }
                    if(entry.isFile) {
                        let file = event.originalEvent.dataTransfer.items[i].getAsFile();
                        this.saveFile(file);
                    } else {
                        this.showAttachmentTypeNotSupported();
                    }
                }
            } else {
                for (let i = 0; i < event.originalEvent.dataTransfer.files.length; i++) {
                    let entry = event.originalEvent.dataTransfer.files[i].webkitGetAsEntry();
                    if(!entry){ return; }
                    if(entry.isFile) {
                        this.saveFile(event.originalEvent.dataTransfer.files[i]);
                    } else {
                        this.showAttachmentTypeNotSupported();
                    }
                }
            }
            event.originalEvent.dataTransfer.clearData(); //ERROR IN FIREFOX APRES 
        },

        /* Draft Handling */

        /**
         * Reacts to clicking on button to send draft to App and then re render draft. sendDraftToApp will clear draft for current discussion.
         */
        triggerSendDraftToApp() {
            sendDraftToApp(globals.currentDiscussion);
            this.fillInputAreaFromDraft(globals.currentDiscussion);
            document.getElementById('send-message_message_text').focus();
        },

        /**
         * Handler for listener on colissimo NOTIF_NEW_DRAFT.
         */
        _notifNewDraft() { //arriving draft for this discussion only
            //if notification arrived, we want to change the current draft => check done in colissimoHandler
            this.fillInputAreaFromDraft(globals.currentDiscussion);
            this.isSendingEnabled=true;
        },
        /**
         * Handler for listeners NOTIF_NEW_DRAFT_ATTACHMENT and NOTIF_NEW_DRAFT_ATTACHMENT. Updates list on screen. 
         */
        _updateDraftAttachments(){
            this.setIsSendingEnable();
            this.files = globals.data.discussions.get(globals.currentDiscussion).draftAttachments;
        },

        /**
         * Adding all specific event listeners for DOM elements.
         * This is meant to be in mounted hook, but not DOM not ready when mounted hook called so this is executed on nextTick.
         */
        _componentMounted() {
            //register first conversation clicked on startup
            this.updateDraftForClickedDiscussion();

            /**Events */
            let dragCounter=0;
            /*drag and drop handling with JQuery*/
            $('#conversation').bind("dragenter", (e) => {
                e.preventDefault();
                dragCounter++;
                document.getElementById("drag-drop-window").style.display="flex";
            });
            $('#conversation').bind("dragleave", (e) => {
                e.preventDefault();
                dragCounter--;
                if(dragCounter===0)
                    document.getElementById("drag-drop-window").style.display="none";
            });
            $('#conversation').bind("dragover",false); //for event drop to work (need dragover bind)
            $('#conversation').bind("drop", (e) => {
                e.preventDefault();
                e.stopPropagation();
                dragCounter=0;
                document.getElementById("drag-drop-window").style.display="none";
                this.dropHandler(e);
            });

            //event when clicking outside emoji picker
            $(document).on('click.blurPicker', (e) => {
                if($('#button-emojis' + ':hover').length ){//|| $('#send-message_message_text' + ':hover').length){
                    return;
                }
                if ($(e.target).closest("#emoji-picker").length === 0) {
                    this.showEmojis = false;
                }
            });

            /*Handling message events focusin() and focusout() */
            const input = document.getElementById('send-message_message_text');

            input.addEventListener('focusin', () => { //focus
                this.focusin();
            }, true);

            input.addEventListener('focusout', () => { //blur
                this.focusout();
                if (!$("#send-message_message_text").text().replace(" ", "").length) {
                    $("#send-message_message_text").empty();
                }
            }, true);

            input.addEventListener("paste", (e) => {
                // cancel paste
                e.preventDefault();
                let data = (e.originalEvent || e);
                if (data.clipboardData && data.clipboardData.items) {
                    var items = data.clipboardData.items;
                    for (var i = 0; i < items.length; i++) {
                        if ((items[i].kind == 'file') && (items[i].type.match('^image/'))) { //can paste images
                            let file = items[i].getAsFile();
                            this.saveFile(file);
                        } else if ((items[i].kind == 'string') && (items[i].type.match('^text/plain'))) { //and plain text
                            items[i].getAsString((s) => {
                                document.execCommand("insertText", false, s); //will fill active DOM element
                                this.setIsSendingEnable();
                            });
                        }
                    }
                }
            });

            /*Send message on enter, and skip line on shift+enter */
            /**Skipping lines is buggy : div content editable is buggy and line breaks are handled differently depending on browser.*/
            document.getElementById("send-message_message_text").addEventListener("keydown", (e) => {
                if (e.keyCode === 13 && !e.shiftKey) {  //only "Enter" pressed
                    if(globals.parameters.sendOnEnter) { //if user wants send on enter (default behavior)
                        this.sendMessage();
                        e.preventDefault();
                    }
                    //document.execCommand("insertText", false, '\n'); //manually insert line break (could be a solution in case it breaks)
                }
            });
            document.getElementById("send-message_message_text").addEventListener("keyup", () => {
                document.getElementById('send-message_message_text').focus(); //attempt at fixing bug where div if focused bu can't type unless we click outside then inside again
                this.setIsSendingEnable();  
            });
        }
    },
    created() {
        document.addEventListener(events.NotifNewDraft.type, this._notifNewDraft);
        document.addEventListener(events.NotifNewDraftAttachment.type, this._updateDraftAttachments);
        document.addEventListener(events.NotifDeleteDraftAttachment.type, this._updateDraftAttachments);
        document.addEventListener(events.NotifNoDraftForDiscussion.type, this._notifNoDraft);
        document.addEventListener(events.NotifDiscussionLocked.type, this.isDiscussionLocked);
    },
    beforeDestroy() {
        document.removeEventListener(events.NotifNewDraft.type, this._notifNewDraft);
        document.removeEventListener(events.NotifNewDraftAttachment.type, this._updateDraftAttachments);
        document.removeEventListener(events.NotifDeleteDraftAttachment.type, this._updateDraftAttachments);
        document.removeEventListener(events.NotifNoDraftForDiscussion.type, this._notifNoDraft);   
        document.removeEventListener(events.NotifDiscussionLocked.type, this.isDiscussionLocked);
        $(document).off('click.blurPicker');
    },
    mounted() {
        this.$nextTick(this._componentMounted);
        //this will cause line breaks to be translated as a <br> tag when skipping a line inside the content editable div.
        document.execCommand("defaultParagraphSeparator", false, "br");
    }
}

</script>

<style scoped>

#send-message{
    display: flex;
    flex-direction: column;
    position: relative;
}

#discussion-locked {
    color: var(--black);
    position: absolute;
    background-color: var(--darker-light-grey);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.fa-lock {
    margin-right:10px;
}

#button-emojis {
    border-radius: 50%;
    padding: 2px;
    font-size: 25px;
    width: 34px;
    line-height: 27px;
    border:none;
    cursor: pointer;
    padding:5px;
    margin: 0 5px 10px 10px;
}

#button-emojis:hover {
    background-color: var(--item-highlight);
}

#emoji-picker {
    position: absolute;
    bottom: 100px;
    z-index: 100;
    left: 2px;
}

#send-message_input {
    margin:5px 10px 5px 10px;
    border-radius: 10px;
    width:inherit;
    padding:5px;
    display: flex;
    justify-content: space-evenly;
    overflow: hidden;
    align-items: flex-end;
}

#send-message_input_add-file {
    border:none;
    border-radius: 50%;
    float:left;
    cursor: pointer;
    padding:5px;
    margin: 0 10px 10px 5px;
    background-color: var(--secondary-background-color);
}

#send-message_input_add-file:hover {
    background-color: var(--item-highlight);
}

#send-message_input_files {
    display:none;
}

#send-message_message_text_container {
    margin: 10px;
    width: 82%;
    position: relative;
    max-height:250px;
    flex: 1; /*for firefox*/
}

#send-message_message_text {
    padding-right: 25px;
    min-height: 26px;
    border-bottom: 1px solid var(--lighter-grey);
    text-align: left;
    cursor: text;
    overflow-x: hidden;
    overflow-y: scroll;
    width:-moz-available;
    width: -webkit-fill-available;
    width: stretch;
    scrollbar-width: none; /* newer Firefox */
    -ms-overflow-style: none;  /*Internet Explorer 10+*/
}

/**This display is necessary for line breaks : inline-block won't add unnecessary tags when breaking a line.*/
*[contenteditable="true"]{
    display: inline-block;
}

#send-message_message_text::-webkit-scrollbar {
    width: 0;
    height: 0;
}

#send-message_message_text[placeholder]:empty:before {
    content: attr(placeholder);
    color : var(--regular-grey);
}

#send-message_message_text[placeholder]:empty:focus:before {
    content: "";
}

[contenteditable] {
    outline: 0px solid transparent;
}

#button-save-draft > button {
    position: absolute;
    right :0;
    bottom: 4px;
    background-color: transparent;
    font-size:1rem;
    height:23px;
    width: 23px;
    border-radius: 20%;
    border-style: none;
    display: flex;
    justify-content: center;
    align-items : center;
    cursor: pointer;
}

#button-save-draft > button:hover {
    background-color:var(--item-highlight);
}

#send-message_submit {
    border:none;
    padding:5px;
    float:right;
    margin: 0 15px 10px 15px;
}

.send-disable {
    fill:var(--regular-grey);
}

.send-enable {
    fill:var(--dark-blue);
    cursor: pointer;
}

#send-message_draft-attachments {
    width: inherit;
    position: relative;
    margin : 5px 10px 10px 10px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
}

#send-message_draft-attachments::-webkit-scrollbar {
    height:8px;
}

.draft-fyle-item {
    width:110px;
    min-width: 110px;
    max-width: 110px;
    font-size: 10px;
    margin-left:10px;
    margin-right:10px;
    word-break: break-all;
    position:relative;
}

/* this div takes up whole conversation height for drag and drop*/
#drag-drop-window {
    z-index: 199; /**under gallery but above everything else in conversation*/
    position:fixed;
    justify-content: center;
    align-items:flex-end;
    top:80px;
    width: 55%;
    height: 50%;
    background-color: var(--drag-drop-window-color);
    margin:0 4% 0 4%;
    padding-bottom:6%;
    font-size: 30px;
    border : 2px dashed var(--light-blue);
    border-radius: 10px;
    display: none;
}

#send-message_replying-message {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 70%;
    border-radius:5px;
    border-left: 5px var(--lighter-grey) solid;
    text-align: left;
    left: 15vh;
    padding: 1%;
}

#send-message_replying-message_content_row {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 4px;
}

#send-message_replying-message_content {
    display: flex;
    flex-direction: column;
    width:100%;
}

#send-message_replying-message_author {
  font-weight: bold;
  color:var(--light-grey);
}

#send-message_replying-message_body {
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    padding-right: 5px;
}

#send-message_replying-message_attachments {
    border-radius:5px;
    padding:2px;
    color:var(--all-white);
    font-size: 10px;
    margin-left: 10px;
}

#send-message_stop-replying {
    position: absolute;
    cursor:pointer;
    font-size: 1.3rem;
    right: 0;
    top: 0;
    width: 20px;
    height:20px;
    margin: 5px 5px 5px 5px;
    padding: 0 1px 3px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

#send-message_stop-replying:hover {
    background-color: var(--item-highlight);
}

textarea {
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
}

@media screen and (max-width:1000px) {
    #send-message_replying-message {
        width:60%;
    }
    #send-message_replying-message_attachments{
        display: none;
    }
}


</style>