/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
import {getThumbnail, isAnImageForThumbnail} from "@/assets/ext/images";

export function notif_update_draft_attachment(colissimo) {
	//updating a draft attachment can only be because of a path change (defined in Android by function equals), so just change path variable
	if(!colissimo.notifUpdateDraftAttachment){
		return;
	}
	let draftAttachment = colissimo.notifUpdateDraftAttachment.draftAttachment;
	if(!draftAttachment){
		return;
	}
    globals.data.discussions.get(draftAttachment.discussionId).updatePathDraftAttachment(draftAttachment);
	// check if attachment shall have a thumbnail and ask for it if path is set
	if(isAnImageForThumbnail(draftAttachment.mime)) { //the path is for the thumbnail, so request it again in case the path was added in this update
		getThumbnail(draftAttachment.path);
	}
}
