/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {removeDraftAttachmentInListFromLocalId} from "@/assets/ext/draft";
import Vue from 'vue';
import {i18n} from "@/main";

export function notif_file_already_attached(colissimo) {
	if(!colissimo.notifFileAlreadyAttached){
		return;
	}
	let attachmentId = colissimo.notifFileAlreadyAttached.localId;
	let discussionId = colissimo.notifFileAlreadyAttached.discussionId;
	removeDraftAttachmentInListFromLocalId(discussionId, attachmentId); //remove from the draft attachment list (not added to draft)
	Vue.toasted.global.notif_file_already_attached(i18n.t('attachments.fileAlreadyAttached')); //show a message to user
}
