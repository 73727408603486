/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {bitLength, bytesToBigInt} from "@/assets/ext/crypto/tools/bigIntTools";
import {MACHmacSha256} from "@/assets/ext/crypto/MACHmacSha256";

export class PRNGHmacSHA256 {
    static OUTPUT_LENGTH = 32;
    static SEED_LENGTH = 32;

    /**
     * Constructor.
     * @param {Uint8Array|null} seed, randomly generated if null
     */
    constructor(seed) {
        this.state_k = new Uint8Array(32).fill(0);
        this.state_v = new Uint8Array(32).fill(1);
        if (seed != null) {
            this.seed = seed;
        }
        else {
            this.seed = crypto.getRandomValues(new Uint8Array(PRNGHmacSHA256.SEED_LENGTH));
        }
        this.initialized = false;
    }

    // TODO build init as in KemKeyPair, with a wait for initialisation function
    async init() {
        if (this.initialized === true) {
            return;
        }
        await this.update(this.seed);
        this.initialized = true;
    }

    /**
     * Update prng state
     *
     * @param {Uint8Array} data
     */
    async update(data) {
        let In = new Uint8Array(this.state_v.length + 1 + data.length);
        In.set(this.state_v);
        In[this.state_v.length] = 0;
        In.set(data, this.state_v.length + 1);
        this.state_k = await MACHmacSha256.digest(this.state_k, In);
        this.state_v = await MACHmacSha256.digest(this.state_k, this.state_v);
        if (data.length > 0) {
            In.set(this.state_v);
            In[this.state_v.length] = 1;
            In.set(data, this.state_v.length + 1);
            this.state_k = await MACHmacSha256.digest(this.state_k, In);
            this.state_v = await MACHmacSha256.digest(this.state_k, this.state_v);
        }
    }

    /**
     * Return bytes generated;
     *
     * @param {int} length
     *
     * @returns {Uint8Array} output
     */
    async bytes(length) {
        if (this.initialized !== true) {
            throw 'PRNG not initialized, run `await prng.init()` before using it';
        }
        let output = new Uint8Array(length);

        for (let i = 0; i < 1 + Math.trunc((length - 1) / PRNGHmacSHA256.OUTPUT_LENGTH); i++) {
            this.state_v = await MACHmacSha256.digest(this.state_k, this.state_v);
            output.set(this.state_v.subarray(0, Math.min(PRNGHmacSHA256.OUTPUT_LENGTH, length - i * PRNGHmacSHA256.OUTPUT_LENGTH)), i * PRNGHmacSHA256.OUTPUT_LENGTH);
        }
        await this.update(new Uint8Array(0));
        return (output);
    }

    /**
     * Generate bigint;
     * @param {bigint} n
     * @returns {bigint} bigint
     */
    async bigInt(n) {
        if (this.initialized !== true) {
            throw 'PRNG not initialized, run `await prng.init()` before using it';
        }
        let n_minus_one = n - 1n;
        let l = bitLength(n_minus_one);
        let ell = Math.floor(1 + (l - 1) / 8);
        let mask = (1 << (l - 8 * (ell - 1))) - 1;
        const var_useless = true;
        while (var_useless) {
            let rand = await this.bytes(ell);
            rand[0] = rand[0] & mask;
            let r = bytesToBigInt(rand);
            if (r < n) {
                return (r);
            }
        }
    }
}
