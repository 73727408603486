<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div>
        <Header/>
        <div class="container">
            <div id="container-qrcode" class="secondary-background">
                <div id="container-qrcode_error-message"></div>
                <div id="container-qrcode_success-message"></div>
                <ul id="container-qrcode_instructions" class="text--color">
                    <li id="container-qrcode_instructions_subtitle">{{ $t("instructions.useOlvidInBrowserLabel") }} </li>
                    <div id="container-qrcode_instructions_subsections">
                        <li>{{ $t("instructions.instruction1OpenOlvidApp") }}</li>
                        <li>
                            <div id="container-qrcode_instructions_subsections_scan">
                            {{ $t("instructions.instruction2TapPlusInApp") }}
                            <img src="@/assets/images/plus_icon.png" alt="plusIcon" id="container-qrcode_instructions_plus-icon"/>
                            {{ $t("instructions.instruction2TapScanInAppToLaunch") }}
                            </div>
                        </li>
                        <li>{{ $t("instructions.instruction3ScanQR") }}</li>
                    </div>
                </ul>
                <div id="container-qrcode_qrcode" v-if="valueQR != null">
                    <qrcode-vue
                        :value="valueQR"
                        :size="size"
                        level="M">
                    </qrcode-vue>
                    <img :src="images.qrcodeLogo" alt="" id="container-qrcode_qrcode_logo"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import oc from '@/assets/ext/client.js';
import router from '@/router';
import Header from '@/components/Header.vue';
import QrcodeVue from 'qrcode.vue';
import {createQrCodeMessage} from "@/assets/ext/libs/QRCode";

export default {
    name: 'QRCodeCard',
    components : {
      QrcodeVue,
      Header
    },
    data () {
        return {
            images :{
                plusIcon : require("@/assets/images/plus_icon.png"),
                qrcodeLogo : require("@/assets/images/logo_qrcode.png")
            },
            oc : oc,
            router:router,
            valueQR: null,
            size: 250
        }
    },
    methods : {
        /**
         * Assigns the value to be put in the QRCode.
         * @param {string} data
         */
        getData: function (data) {
            console.log(data)
            this.valueQR = data;
        },
        /**
         * Handles event NEW_CORRESPONDING. Updates status to move on to next step.
         */
        handlerNewCorresponding() {
            oc.globals.updateStatus(oc.globals.STATUS_PROTOCOL_IN_PROGRESS);
        },
    },
    async created() {
        if(oc.globals.status !== oc.globals.STATUS_CONNECTED_TO_SERVER) {
            oc.globals.updateStatus(oc.globals.STATUS_INVALID_STATE);
            return;
        }
        //get QRCode data
        createQrCodeMessage(oc.globals.connection.webKemKeyPair.getCompactPublicKey()).then((data) => {
            this.getData(data);
        });
        document.addEventListener(oc.events.NewCorresponding.type, this.handlerNewCorresponding);
    },
    beforeDestroy() {
        document.removeEventListener(oc.events.NewCorresponding.type, this.handlerNewCorresponding);
    },
    mounted() {
        if (oc.globals.error !== 0) {
            let errorMessage = document.getElementById("container-qrcode_error-message");
            errorMessage.style.backgroundColor = "var(--red)";
            errorMessage.style.display = "flex";
            switch (oc.globals.error) {
                case (1):
                    errorMessage.style.backgroundColor = "var(--green)";
                    errorMessage.innerText = this.$i18n.t('errors.errorConnectionClosedByApp');
                break;
                case (2):
                    errorMessage.innerText = this.$i18n.t('errors.errorDuringEstablishment');
                break;
                case (3):
                    errorMessage.innerText = this.$i18n.t('errors.errorConnectionLost');
                break;
                case (4) :
                    errorMessage.innerText = this.$i18n.t('errors.errorVersionsDontMatch');
                    break;
                case (5): 
                    errorMessage.innerText = this.$i18n.t('errors.errorProtocolStoppedByApp');
                    break;
                case (6):
                    errorMessage.innerText = this.$i18n.t('errors.errorInternalError');
                    break ;
                default:
                    errorMessage.innerText = this.$i18n.t('errors.unknownError');
                break;
            }
            oc.globals.error = 0;
        }
    }
}
</script>

<style scoped>

#container-qrcode_error-message {
    display: none;
    position: absolute;
    color: var(--all-white);
    width: 100%;
    height: fit-content; /* older Safari, Opera, Chrome, Edge */
    height: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    height:-moz-fit-content; /* Firefox */
    padding: 4px 0 4px 0;
    top: 0;
    left: 0;
    text-align: center;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
}


#container-qrcode {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding : 3%;
    width: 850px;
    height:50vh;
    position: absolute;
    top: 22%;
    margin-left:auto;
    margin-right:auto;
    border-radius:5px;
}

#container-qrcode_instructions {
    display:flex;
    flex-direction: column;
    text-align: left;
    font-size: 22px;
    line-height: 1.5;
    list-style-type: circle;
    margin-top:0;
}

#container-qrcode_instructions li {
  margin-top: 3vh;
  margin-bottom: 3vh;
}

#container-qrcode_instructions_subsections_scan {
    display: flex;
    align-items: center;
}

#container-qrcode_instructions_plus-icon {
    width:40px;
    padding : 0 8px 0 8px;
}

#container-qrcode_instructions_subtitle {
    margin-left: -30px;
    font-size:28px;
    margin-bottom:10px;
    list-style: none;
}

#container-qrcode_qrcode {
    border: 20px solid var(--all-white);
    height: fit-content; /* older Safari, Opera, Chrome, Edge */
    height: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    height:-moz-fit-content; /* Firefox */
    width: fit-content; /* older Safari, Opera, Chrome, Edge */
    width: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    width:-moz-fit-content; /* Firefox */
    position:relative;
    margin-left: 20px;
}

#container-qrcode_qrcode_logo {
    position: absolute;
    height: 40px;
    margin: auto;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
}

.fa-plus-circle {
    color:var(--dark-blue);
}

/* Responsiveness to adapt for all screen sizes*/
@media screen and (max-width: 1000px) and (min-height:500px) {
    #container-qrcode {
        flex-direction: column;
        height: fit-content; /* older Safari, Opera, Chrome, Edge */
        height: -webkit-fit-content; /* newer Safari, Opera, Chrome */
        height:-moz-fit-content; /* Firefox */
        width: 600px;
    }
}

@media screen and (max-width: 1000px) and (max-height: 790px) {
    #container-qrcode {
        overflow-y: scroll;
        max-height: 68vh;
    }
    #container-qrcode_instructions {
        margin-top:5%;
    }
}

@media screen and (max-height: 500px) and (max-width:1200px){
    #container-qrcode {
        overflow-y: scroll;
        max-height: 50vh;
        top:32%!important;
        flex-direction: column;
        width: 600px;
    }
}

@media screen and (max-height: 500px) and (min-width:1500px){
    #container-qrcode {
        top:5%;
        height: 80%;
    }
}

@media screen and (max-height: 500px) and (max-width:1500px) and (min-width:1200px){
    #container-qrcode {
        top:5%;
        height: 80%;
        flex-direction: column;
        height: fit-content;
        height: -webkit-fit-content;
        height:-moz-fit-content;
        width: 600px;
        overflow-y: scroll;
        max-height: 70vh;
    }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
    #container-qrcode {
        top:5%;
        height: 60%;
    }
}

@media screen and (min-height:850px) {
    #container-qrcode {
        top: 18%;
    }
}

</style>