/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
const {globals} = require("@/assets/ext/globals");
const {websocket} = require("@/assets/ext/websocket.js");

const protobuf = require("@/assets/ext/protobuf/protobuf.js").olvid;
import * as Base64 from "@/assets/ext/libs/Base64";
const events = require("@/assets/ext/events.js");

const messageSender = require("@/assets/ext/messages/messageSender.js");
const messageHandler = require("@/assets/ext/messages/serverMessageHandler.js");
const colissimoHandler = require("@/assets/ext/messages/colissimoHandler.js");

const oc = {
    // export modules
    globals: globals,
    Base64: Base64,
    protobuf: protobuf,
    websocket: websocket,
    events: events,

    messageSender: messageSender,
    messageHandler: messageHandler,
    colissimoHandler: colissimoHandler,
};

export {oc as default};
