/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {olvid as protobuf} from "@/assets/ext/protobuf/protobuf";
import * as Base64 from "@/assets/ext/libs/Base64";
import {globals} from "@/assets/ext/globals";
import {base64ToBytes} from "@/assets/ext/libs/Base64";

/**
 * Generates content for QRCode : contains the connection identifier, the url of the relay server and the public key.
 * @param {Uint8Array} publicKey
 * @returns {Promise<string>}
 */
export async function createQrCodeMessage(publicKey) {
	const QRCodeInfo = protobuf.QrCodeInfo.create({
		identifier: globals.connectionIdentifier,
		serverUrl: globals.constants.serverUrl,
		publicKey: publicKey,
		awsSessionCookieName: globals.constants.AWS_SESSION_COOKIE_NAME,
		awsSessionCookie: base64ToBytes(globals.awsSessionCookie), // we decode base64 to save space in QR Code
	});

	const encodedMessage = protobuf.QrCodeInfo.encode(QRCodeInfo).finish();
	let base64EncodeMessage = Base64.bytesToBase64(encodedMessage);
	base64EncodeMessage = base64EncodeMessage.replace(/\+/g, "-");
	base64EncodeMessage = base64EncodeMessage.replace(/\//g, "_");
	base64EncodeMessage = base64EncodeMessage.replace(/=/g, ".");
	return "https://web.olvid.io/#" + base64EncodeMessage;
}
