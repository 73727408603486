import { render, staticRenderFns } from "./DraftAttachmentThumbnail.vue?vue&type=template&id=fba624dc&scoped=true&"
import script from "./DraftAttachmentThumbnail.vue?vue&type=script&lang=js&"
export * from "./DraftAttachmentThumbnail.vue?vue&type=script&lang=js&"
import style0 from "./DraftAttachmentThumbnail.vue?vue&type=style&index=0&id=fba624dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fba624dc",
  null
  
)

export default component.exports