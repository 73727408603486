/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {AuthEncAes256thenSha256} from "@/assets/ext/crypto/authenc/AuthEncAes256thenSha256";
import {PRNGHmacSHA256} from "@/assets/ext/crypto/PRNGHmacSHA256";
import {KDFSha256} from "@/assets/ext/crypto/KDFSha256";

/**
 * @param {Uint8Array} keyApp
 * @param {Uint8Array} keyWeb
 * @returns {Object} AuthEncKey (fields aesKey and macKey as Uint8Array)
 */
export async function deriveAuthEncKey (keyApp, keyWeb) {
    let zeroSeed = new Uint8Array(32);
    let prng = new PRNGHmacSHA256(zeroSeed);
    await prng.init();

    if (keyApp.aesKey === undefined || keyApp.macKey === undefined) {
        console.log("Invalid keyApp:", keyApp)
        return null;
    }
    if (keyWeb.aesKey === undefined || keyWeb.macKey === undefined) {
        console.log("Invalid keyWeb:", keyWeb)
        return null;
    }

    let appCipher = await AuthEncAes256thenSha256.encrypt(keyApp.aesKey, keyApp.macKey, new Uint8Array(32), prng);
    let webCipher = await AuthEncAes256thenSha256.encrypt(keyWeb.aesKey, keyWeb.macKey, new Uint8Array(32), prng);
    let hashInput = new Uint8Array(appCipher.length + webCipher.length);
    hashInput.set(appCipher);
    hashInput.set(webCipher, appCipher.length);
    let finalSeed = new Uint8Array(await crypto.subtle.digest("SHA-256", hashInput));

    // seeding a prng with bytes from a prng seeded by final seed to feat android behaviour
    prng = new PRNGHmacSHA256(finalSeed);
    await prng.init();
    return KDFSha256.gen(await prng.bytes(32));
}
