/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import * as Base64 from "@/assets/ext/libs/Base64";
const protobuf = require("@/assets/ext/protobuf/protobuf").olvid;
const events = require("@/assets/ext/events");
/**
 *
 * @param encodedColissimo
 * @returns {ConnectionColissimo|null}
 */
async function prepareColissimo(encodedColissimo) {
    try {
        const colissimoAsUint8Array = Base64.base64ToBytes(encodedColissimo);
        const colissimo = await protobuf.ConnectionColissimo.decode(colissimoAsUint8Array);
        return (colissimo)
    }
    catch(e) {
        console.warn("CONNECTION COLISSIMO: unable to decode colissimo");
        return null;
    }
}

export async function connectionColissimoHandler(encodedColissimo) {
    const colissimo = await prepareColissimo(encodedColissimo);
    if (!colissimo) {
        return ;
    }

    switch (colissimo.type) {
        case (protobuf.ConnectionColissimoType.CONNECTION_APP_IDENTIFIER_PK_KEM_COMMIT_SEED): {
            console.log("connectionColissimo received: connectionAppIdentifierPkKemCommitSeed");

            const event = new CustomEvent(events.ConnectionAppIdentifierPkKemCommitSeed.type, {
                detail: {
                    identifier: colissimo.connectionAppIdentifierPkKemCommitSeed.identifier,
                    kemCipher: colissimo.connectionAppIdentifierPkKemCommitSeed.kemCipher,
                    commit: colissimo.connectionAppIdentifierPkKemCommitSeed.commit,
                    publicKey: colissimo.connectionAppIdentifierPkKemCommitSeed.publicKey,
                }
            });
            document.dispatchEvent(event);
            break ;
        }
        case (protobuf.ConnectionColissimoType.CONNECTION_APP_DECOMMITMENT): {
            console.log("connectionColissimo received: ConnectionAppDecommitment");
            const event = new CustomEvent(events.ConnectionAppDecommitment.type, {
                detail: {
                    decommitment: colissimo.ConnectionAppDecommitment.decommitment
                }
            })
            document.dispatchEvent(event);
            break ;
        }
        default: {
            console.log("CONNECTION COLISSIMO: invalid colissimo type");
        }
    }
}
