<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
<div id="global-settings">
    <div id="settings-blur"></div>
    <div id="settings" class="secondary-background text--color">
        <div id="settings_header">
            <p>{{$t('settings.labelSettings')}}<button id="settings_save" class="text--color" @click="saveSettings()">{{$t('settings.labelSaveSettings')}}</button></p>
            <p id="settings_close" @click="closeSettings()">&times;</p>
        </div>
        <div id="settings_body">
            <div id="settings_categories">
                <div v-for="(cat, index) in categories" 
                    :key="index" class="settings_categories_item" 
                    @click="openCategory(index)" :class="{'discussion_item--highlight':index === selectedSection}">
                    {{$t('settings.labelSetting'+cat)}}
                </div>
            </div>

            <div id="settings_content">
                <div v-if="selectedSection === 0" id="settings_content_language-theme">
                    <p id="language-picker_label">{{$t('settings.labelChooseLanguage')}}</p>
                    <select id="language-picker" name="lang" @change="changeLocale()">
                        <option 
                            v-for="(lang,index) in globals.constants.SUPPORTED_LANGUAGES" 
                            :key="index" 
                            :value="lang"
                            :selected="isSelected(lang)">
                            {{globals.constants.SUPPORTED_LANGUAGES_FULL_NAME[index]}}
                        </option>
                        <option :selected="globals.parameters.isLanguageBrowserDefault" key="BrowserDefault" value="BrowserDefault">{{$t('settings.labelBrowserDefaultLanguage')}}</option>
                        <option :disabled="globals.parameters.receivedSettings == null" :selected="globals.parameters.isLanguageAppDefault" key="AppDefault" value="AppDefault">{{$t('settings.labelAppDefaultLanguage')}}</option>
                    </select>
                    <div id="settings_content_theme">
                        <div id="settings_content_theme_title">{{$t('settings.labelChooseTheme')}}</div>
                        <div class="theme_option"><input type="radio" id="dark" name="dark" value="dark" @change="updateThemeSettings('dark')"><label for="Dark">{{$t('settings.darkTheme')}}</label></div>
                        <div class="theme_option"><input type="radio" id="light" name="light" value="light" @change="updateThemeSettings('light')"><label for="Light">{{$t('settings.lightTheme')}}</label></div>
                        <div class="theme_option"><input type="radio" id="syspref" name="syspref" value="BrowserDefault" @change="updateThemeSettings('BrowserDefault')"><label for="BrowserDefault">{{$t('settings.labelSettingSystemPreferenceTheme')}}</label></div>
                        <div class="theme_option"><input type="radio" :disabled="globals.parameters.receivedSettings == null" id="apppref" name="apppref" value="AppDefault" @change="updateThemeSettings('AppDefault')"><label>{{$t('settings.labelSettingsAppPreference')}}</label></div>
                    </div>
					<div class="settings-content-separator"></div>
					<div class="settings-content-version" v-if="version">Version: {{version}}</div>
                </div>
                <div v-else-if="selectedSection === 1" id="settings_content_input">
                    <CustomSwitch @click.native="switchSendOnEnter()" :checked="globals.parameters.sendOnEnter" :label="$t('settings.labelSendMessageOnEnter')"></CustomSwitch>
                    <CustomSwitch @click.native="changeSaveDraftButton()" :checked="globals.parameters.showSaveDraftButton" :label="$t('settings.labelShowSaveDraftButton')"></CustomSwitch>
                </div>
                <div v-else-if="selectedSection === 2" id="settings_content_notifications">
                    <div id="notification-on-screen">
                        <CustomSwitch @click.native="switchShowNotificationOnScreen()" :checked="globals.parameters.showNotificationOnScreen" :label="$t('settings.labelNotificationsOnScreen')"></CustomSwitch>
                    </div>
                    {{$t('settings.labelSoundNotifications')}}
                    <br>
                    <div id="notification-sound">
                        <div>
                            <i class="fa fa-volume-up button-mute" @click="switchSound()" v-show="globals.parameters.notificationsSoundEnabled" :title="$t('settings.labelTitleSoundTurnOff')"></i> 
                            <i class="fa fa-volume-mute button-mute" @click="switchSound()" v-show="!globals.parameters.notificationsSoundEnabled" :title="$t('settings.labelTitleSoundTurnOn')"></i>
                            <input :disabled="!globals.parameters.notificationsSoundEnabled" @change="setNotificationSoundVolume()" type="range" id="volume" name="volume" min="0" max="100" value="100">
                        </div>
                        <button id="sound-test" class="text--color" @click="soundTest()">{{$t('settings.labelSoundTest')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import $ from 'jquery';
import {updateLanguage, saveSettingsToApp, updateTheme} from "@/assets/ext/settings";
import CustomSwitch from '@/components/CustomSwitch.vue';
import {globals} from "@/assets/ext/globals";

export default {
    name:'Settings',
    data () {
        return {
			version: process.env.VUE_APP_VERSION,
            globals:globals,
            selectedSection:0,
            categories : ['LangTheme', 'Input', 'Notifications'],
        }
    },
    components : {
        CustomSwitch
    },
    methods : {
        updateThemeSettings(color) {
            updateTheme(color);
            this.changeCheckedTheme();
            this.showSaveButton();
        },
        saveSettings(){
            if(['/qrcode','/sascode','/connecting','/protocol'].includes(this.$router.currentRoute.path)){//can't save settings in non-connected phone : temporary fix, shouldn't be able to see button at all.
                return;
            }
            saveSettingsToApp();
            document.getElementById('settings_save').style.display = "none";
        },
        closeSettings(){
            document.getElementById('global-settings').style.display="none";
        },
        openCategory(index) {
            this.selectedSection = index;
            if(index === this.categories.indexOf('LangTheme')){
               this.$nextTick(this.changeCheckedTheme);
            }
        },
        isThemeDark() {
            return (!globals.parameters.darkModeSysPref && globals.parameters.darkModeEnabled);
        },
        isThemeLight() {
            return (!globals.parameters.darkModeSysPref && !globals.parameters.darkModeEnabled);
        },
        isThemeSysPref() {
            return globals.parameters.darkModeSysPref;
        },
        changeLocale(){
            let lang = document.getElementById("language-picker").value;
            updateLanguage(lang);
            this.showSaveButton();
        },
        isSelected(lang){
            if(lang === this.$i18n.locale){
                return true;
            } else if(lang === "BrowserDefault" && globals.parameters.isLanguageBrowserDefault){
                return true;
            } else if (lang === "AppDefault" && globals.parameters.isLanguageAppDefault){
                return true;
            }
        },
        changeCheckedTheme() {
            if(globals.parameters.darkModeSysPref){
                document.getElementById('dark').checked = false;
                document.getElementById('light').checked = false;
                document.getElementById('syspref').checked = true; 
                document.getElementById('apppref').checked = false;
            } else if(globals.parameters.darkModeAppPref) {
                document.getElementById('dark').checked = false;
                document.getElementById('light').checked = false;
                document.getElementById('syspref').checked = false; 
                document.getElementById('apppref').checked = true;
            } else if(globals.parameters.darkModeEnabled) {
                document.getElementById('dark').checked = true;
                document.getElementById('light').checked = false;
                document.getElementById('syspref').checked = false; 
                document.getElementById('apppref').checked = false;
            } else {
                document.getElementById('dark').checked = false;
                document.getElementById('light').checked = true;
                document.getElementById('syspref').checked = false; 
                document.getElementById('apppref').checked = false;
            }
        },
        switchSendOnEnter() {
            this.showSaveButton();
            globals.parameters.sendOnEnter = !globals.parameters.sendOnEnter;
        },
        
        switchSound() {
            this.showSaveButton();
            globals.parameters.notificationsSoundEnabled = !globals.parameters.notificationsSoundEnabled;
        },
        setNotificationSoundVolume() {
            let value = document.getElementById('volume').value;
            document.getElementById('notif-sound').volume = value/100; //in decimal
        },
        soundTest() {
            if(globals.parameters.notificationsSoundEnabled){
               document.getElementById('notif-sound').play(); 
            }
        },
        changeSaveDraftButton() {
            globals.parameters.showSaveDraftButton = !globals.parameters.showSaveDraftButton;
        },
        switchShowNotificationOnScreen(){
            this.showSaveButton();
            globals.parameters.showNotificationOnScreen = !globals.parameters.showNotificationOnScreen;
        },
        showSaveButton(){
            if(!['/qrcode','/sascode','/connecting','/protocol'].includes(this.$router.currentRoute.path)){
                document.getElementById('settings_save').style.display = "inline-block";
            }
        },
    },
    mounted() {
        this.$nextTick(this.changeCheckedTheme);
        $('#select-theme').change(() => {
            if(document.getElementById('dark').checked) {
                updateTheme('dark');
            } else if(document.getElementById('light').checked) {
                updateTheme('light');
            } else if(document.getElementById('syspref').checked) {
                updateTheme('BrowserDefault');
            } else if(document.getElementById('apppref').checked) {
                updateTheme('AppDefault');
            }
        });

        $('#settings-blur').click((e) => { //close when clicking outside settings
            let settingsHeight = document.getElementById('settings').clientHeight;
            let settingsWidth = document.getElementById('settings').clientWidth;
            let posX = e.clientX;
            let posY = e.clientY;
            let limitleft = (window.innerWidth/2) - (settingsWidth/2);
            let limitright = (window.innerWidth/2) + (settingsWidth/2); 
            let limittop = (window.innerHeight/2) - (settingsHeight/2);
            let limitbottom = (window.innerHeight/2) + (settingsHeight/2);
            if(posX < limitleft || posX > limitright || posY < limittop || posY > limitbottom) {
                this.closeSettings();
            }
        });
        //Prevent user from typing in input for draft frequency
        $("[type='number']").keypress(function (e) {
            e.preventDefault();
        });
    }
}
</script>

<style scoped>

#global-settings {
    display: none;
}

#settings {
    z-index : 999; /**Under modals*/
    height: 100%;
    width: 100%;
    max-height: 35rem;
    max-width: 50rem;
    padding:0 10px 10px 0;
    position: fixed;
    top:calc(50% - 20rem);
    left:calc(50% - 25rem);
    border-radius: 8px;
    border:1px solid var(--item-highlight);
}

#settings-blur {
    position: fixed;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    background-color: var(--black);
    opacity: 0.8;
    z-index: 500; 
}

#settings_header {
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding : 0 20px 0 30px;
}

#settings_save {
    margin-left:30px;
    display: none;
}

#settings_close {
    cursor: pointer;
    font-size: 1.8rem;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

#settings_close:hover {
    background-color: var(--item-highdivght);
}

#settings_body {
    display: flex;
    flex-direction: row;
    height: 82%;
}

#settings_categories {
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 100%;
    min-height: 100%;
    width: 30%;
    border-right:1px solid var(--item-highlight);
    padding-right:10px;
}

.settings_categories_item {
    cursor:pointer;
    padding : 13px 10px 13px 30px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.settings_categories_item:hover {
    background-color: var(--item-highlight);
}

#settings_content {
    width:70%;
    text-align:left;
    margin:0 20px 0 30px;
	display: flex;
	flex-direction: column;
}

#language-picker_label {
    margin-right:10px;
}

#settings_content_theme {
    margin-top:3rem;
}

#settings_content_theme_title {
    padding-bottom:0.5rem;
}

#settings_content_language-theme {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.theme_option {
    display: flex;
    align-items: baseline;
    margin: 5px;
}

.settings-content-separator {
	display: flex;
	flex-grow: 1;
	flex-basis: max-content;
}

.settings-content-version {
	display: flex;
	align-self: flex-end;
}
#send-on-enter {
    margin-right:10px;
}

#notification-sound {
    margin:20px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.button-mute {
    margin : 10px 25px 10px 5px;
    padding:5px;
    font-size: 1.8rem;
    cursor: pointer;
}

button {
    height: 38px;
    width: fit-content; /* older Safari, Opera, Chrome, Edge */
    width: -webkit-fit-content; /* newer Safari, Opera, Chrome */
    width:-moz-fit-content; /* Firefox */
    border: 1px solid var(--text-color);
    background-color: inherit;
    border-radius: 8px;
    cursor: pointer;
}

#sound-test {
    float:right;
    margin-right:10%;
}

button:hover {
    box-shadow: 5px 5px 5px var(--item-highlight);
}

#settings_content_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#settings_content_input > * {
    margin : 20px 0 20px 0;
}

#save-draft-frequency {
    margin:10px 0 0 50px;
    display: flex;
    align-items: center;
}

#frequency {
    height:25px;
    font-size: 1rem;
    margin-right:5px;
    width: 50px;
}

#default-frequency {
    margin-left:30px;
}

#notification-on-screen {
    margin-bottom:30px;
}

@media screen and (max-height:750px) {
    #settings {
        top:calc(50% - 13rem);
        max-height:25rem;
    }
    #settings_body {
        height: 76%;
    }
}

@media screen and (max-height:750px) {
    #settings {
        left:calc(50% - 20rem);
        max-width:40rem;
    }
}


</style>
