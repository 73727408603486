/***
 * This file is part of Olvid Web.
 * Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
 * 
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 ***/
import {globals} from "@/assets/ext/globals";
import * as events from "@/assets/ext/events";
import {Message} from "@/assets/datatypes/Message";

export function request_messages_response(colissimo) {
	if (!colissimo.requestMessageResponse) {
		return ;
	}
	if(!globals.data.messagesByDiscussion) {
		globals.data.messagesByDiscussion = new Map();
	}
	let discussionId = colissimo.requestMessageResponse.discussionId;

	if (globals.data.discussions.get(discussionId)) {
		globals.data.discussions.get(discussionId).receivedFirstBatch = true;
	}

	if (colissimo.requestMessageResponse.messages.length === 0) {
		globals.data.messagesByDiscussion.set(discussionId, new Map());
		//change visual state of conversation (spinner => no messages)
		if(globals.currentDiscussion === discussionId) {
			const event = new CustomEvent(events.NotifNoMessageForCurrentDiscussion.type, {
				detail: {
					discussionId: discussionId
				}
			});
			//handled in Conversation.vue
			document.dispatchEvent(event);
		}
		return ;
	}

    if(!globals.data.messagesByDiscussion.has(discussionId)){
        globals.data.messagesByDiscussion.set(discussionId, new Map());
    }
	//iterate over all messages in batch
	colissimo.requestMessageResponse.messages.forEach(function(message) {
        if(globals.data.messagesByDiscussion.get(discussionId).has(message.id)){ //message already exists, just change all fields except listAttachments
            globals.data.messagesByDiscussion.get(discussionId).get(message.id).updateFromProtobuf(message);
        } else {
            globals.data.messagesByDiscussion.get(discussionId).set(message.id, new Message(
                message.id,
                message.discussionId,
                message.sortIndex,
                message.status,
                message.type,
                message.contentBody,
                message.timestamp,
                message.totalAttachmentCount,
                message.imageCount,
                message.senderIdentifier,
                message.senderName,
                message.replyMessageId,
                message.replyMessageAttachmentCount,
                message.replySenderIdentifier,
                message.replyAuthor,
                message.replyBody,
                message.readOnce,
                message.visibilityDuration,
                message.existenceDuration,
                message.wiped,
                message.remotelyDeleted,
                message.wipeOnRead,
                message.edited,
                message.reactions ? message.reactions : "",
                message.senderIsSelf
            )); //add message
        }
    });

	if (globals.data.discussions.has(discussionId)) {
		globals.data.discussions.get(discussionId).incrementNumberRequested();
	}

	const sortedMessages = Array.from(globals.data.messagesByDiscussion.get(discussionId).values());
	sortedMessages.sort((a, b) => {return (a.sortIndex - b.sortIndex);});

	if(globals.currentDiscussion === discussionId) {//notify Body.vue if it is the active discussion to update visible content
		const event = new CustomEvent(events.RequestMessageResponse.type, {
			detail: {
				//lastMessage will provide all necessary information (e.g. for scrolling)
				lastMessage: sortedMessages[sortedMessages.length - 1]
			}
		});
		//handled in Body.vue
		document.dispatchEvent(event);
	}
}
