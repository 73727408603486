let specialChars = new Map([
    ['<', "&lt;"],
    ['>', "&gt;"],
    ['"', "&quot;"],
    ['`',"&#96;"],
    ['\'',"&#x27;"]
]);

// import DOMPurify from 'dompurify';

// replace any interpretable character by html code, and use domPurify to clean content
export function sanitize_text(text) {
    if (!text) {
        return text;
    }
    //replace some HTML special chars by their entity equivalent (displayed, not interpreted)
    for (const [key, value] of specialChars) {
        text = text.replaceAll(key, value);
    }
    // text = DOMPurify.sanitize(text);
    return text.trim();
}