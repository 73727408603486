<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div class="draft-attachment">
        <div class="draft-attachment_image" v-if="isAnImage()" :title="file.name">
            <!-- DraftAttachment added from webclient : can get thumbnail directly from file on computer OR added from App :  request thumbnail to App -->
            <div v-if="file.path === 'local' || oc.globals.data.thumbnails[file.path]">
                <DraftAttachmentThumbnail :file="file"/>
            </div>
            <!-- No thumbnail : file is an image, but thumbnail not received yet from App or not retrievable -->
            <div v-else class="no-thumbnail_draft_attachment">
                <div v-if="file.status === oc.protobuf.DraftAttachmentStatus.FAILED" class="attachment_action">
                    <i @click="removeFailedUpload()" class="attachment_action--delete delete_no-thumbnail fa fa-times-circle fa-2x" aria-hidden="true"></i>
                    <i @click="reupload(file)" class="attachment_action--reupload reupload_no-thumbnail fa fa-sync fa-3x" aria-hidden="true"></i>
                </div>
                <div v-else class="attachment_action">
                    <i @click="removeFile()" class="attachment_action--delete delete_no-thumbnail reply--color fa fa-times-circle fa-2x" aria-hidden="true"></i>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    viewBox="0 0 24 24" >
                <path d="M21,19V5c0,-1.1 -0.9,-2 -2,-2H5c-1.1,0 -2,0.9 -2,2v14c0,1.1 0.9,2 2,2h14c1.1,0 2,-0.9 2,-2zM8.5,13.5l2.5,3.01L14.5,12l4.5,6H5l3.5,-4.5z" />
                </svg>
            </div>
        </div>
        <div v-else class="draft-attachment_file" :title="file.name">
            <div v-if="file.status === oc.protobuf.DraftAttachmentStatus.FAILED" class="attachment_action">
                <i @click="removeFailedUpload()" class="attachment_action--delete delete_file fa fa-times-circle fa-2x" aria-hidden="true"></i>
                <i @click="reupload(file)" class="attachment_action--reupload reupload_file fa fa-sync fa-3x" aria-hidden="true"></i>
            </div>
            <div v-else class="attachment_action">
                <i @click="removeFile()" class="attachment_action--delete delete_file reply--color fa fa-times-circle fa-2x" aria-hidden="true"></i>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 24 24" >
                <path d="M6,2c-1.1,0 -1.99,0.9 -1.99,2L4,20c0,1.1 0.89,2 1.99,2L18,22c1.1,0 2,-0.9 2,-2L20,8l-6,-6L6,2zM13,9L13,3.5L18.5,9L13,9z" />
            </svg>
        </div>
        <!-- Show name only if image has no thumbnail or is a file. imageData updated by DraftAttachmentThumbnail -->
        <span v-show="!imageData" class="draft-attachment_name text--color">{{file.name}}</span>
        <div v-if="file.status === oc.protobuf.DraftAttachmentStatus.LOADING">
            <b-progress 
                class="progress-bar" 
                height="5px" 
                variant="info" 
                striped="striped" 
                :value="file.progress" 
                :max="100" 
                animated>
            </b-progress>
        </div>
    </div>
</template>

<script>
import oc from '@/assets/ext/client.js';
import DraftAttachmentThumbnail from '@/components/DraftAttachmentThumbnail';
import {calculateNbChunks} from '@/assets/ext/attachments.js';
import {removeDraftAttachmentInListFromLocalId} from '@/assets/ext/draft';

export default {
  // modified name to avoid name superposition with class datatype/DraftAttachment
    name: 'DraftAttachmentComponent',
    components : {DraftAttachmentThumbnail},
    props: {
        file : {
            type:Object,
            required: true
        }
    },
    data () {
        return {
            oc:oc,
            imageData:false,
            regexImage:RegExp('^image/(jpeg|png|webp|gif)$'), //regex for gallery images (openable in browser)
            calculateNbChunks:calculateNbChunks
        }
    },
    methods : {
        /**
         * First step for reuploading an attachment to App. Sends sendAttachmentNotice to App with details about the attachment and SHA256.
         * @param {File} file
         */
        reupload(file) {
            let fileReader = new FileReader();
            let fileBuffer;
            let sha;
            
            fileReader.onloadend = async () => {
                fileBuffer = fileReader.result;
                sha = await crypto.subtle.digest('SHA-256', fileBuffer);
                this.file.status = oc.protobuf.DraftAttachmentStatus.LOADING;
                this.file.progress = 0;
                if(file.size <= oc.globals.CHUNK_SIZE_SENDING) { //file smaller than 1 chunk
                    oc.messageSender.sendAttachmentNotice(file.localId, Array.from(new Int8Array(sha)), file.size, 1, file.type, file.name, oc.globals.currentDiscussion);
                } else {
                    let nbChunks = this.calculateNbChunks(file);
                    oc.messageSender.sendAttachmentNotice(file.localId, Array.from(new Int8Array(sha)), file.size, nbChunks, file.type, file.name, oc.globals.currentDiscussion);
                }
            }
            fileReader.readAsArrayBuffer(file.correspondingFile);
        },

        /**
         * Process of removing a draft attachment (reacting to a click). Notify App and delete in local array. 
         */
        removeFile() {
            if(this.file.status === oc.protobuf.DraftAttachmentStatus.LOADING){
                if(this.file.localId != null) { //should never be null
                    oc.messageSender.requestStopDraftAttachmentUpload(this.file.localId);
                    removeDraftAttachmentInListFromLocalId(oc.globals.currentDiscussion, this.file.localId);
                }
            } else if(this.file.status === oc.protobuf.DraftAttachmentStatus.READY){
                oc.messageSender.requestDeleteDraftAttachment(this.file.fyleId, this.file.messageId);
            }
        }, 

        /**
         * Remove file for which upload failed. No need to notify App as upload failed.
         */
        removeFailedUpload() {
            //remove from draft attachment list for this discussion
            //could break
            oc.globals.data.discussions.get(oc.globals.currentDiscussion).removeFromDraftAttachmentsUsingLocalId(this.file.localId);
        },
        /**
         * Determines whether specific mimeType corresponds to an 'image' openable in Gallery.
         * @returns {boolean}
         */
        isAnImage() {
            return this.regexImage.test(this.file.mime);
        }
    }
}
</script>

<style scoped>

.draft-attachment_image svg, .draft-attachment_file svg {
  fill:var(--transparent-light-grey);
}

.attachment_action {
  text-align: left;
}

.attachment_action--delete {
  cursor: pointer;
  position: absolute;
  color:var(--regular-grey);
}

.attachment_action--delete:hover {
  color:var(--red);
}

.delete_no-thumbnail {
  top: 2%;
  right: 17%;
}

.delete_file {
  top: 4%;
  right: 8%;
}

.attachment_action--reupload {
  cursor: pointer;
  position: absolute;
  color: var(--regular-grey);
}

.attachment_action--reupload:hover {
    color: var(--light-blue);
}

.reupload_no-thumbnail {
  left: 37%;
  top: 27%;
}

.reupload_file {
  left: 37%;
  top: 37%;
}

.draft-attachment_name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.progress-bar {
  position: relative;
  width: 110px;
  margin-top:5px;
  /* margin-left: 5px; */
}

</style>