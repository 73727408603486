<!----
   This file is part of Olvid Web.
   Copyright (C) 2021 Lise Jolicoeur, Jérémie Martel
   
   This program is free software: you can redistribute it and/or modify
   it under the terms of the GNU Affero General Public License as published by
   the Free Software Foundation, either version 3 of the License, or
   (at your option) any later version.
   
   This program is distributed in the hope that it will be useful,
   but WITHOUT ANY WARRANTY; without even the implied warranty of
   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
   GNU Affero General Public License for more details.
   You should have received a copy of the GNU Affero General Public License
   along with this program. If not, see <https://www.gnu.org/licenses/>.
---->
<template>
    <div>
        <Header/>
        <div class="container">
            <div id="container-waiting-for-server" class="secondary-background">
                <div id="wait-for-server-connection_content">
                    <div id="spinner" class="spinner-border spinner-border-sm text--color" role="status" aria-hidden="true">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p class="text--color">{{ $t('instructions.labelWaitingForServerConnection') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import Header from '@/components/Header.vue';
import {websocket} from "@/assets/ext/websocket";
import {globals} from "@/assets/ext/globals";

export default {
    name: 'LoadingCard',
    components : {
        Header
    },
    data () {
        return{
            router:router,
        }
    },
    async created() {
        await globals.reset();
        globals.favico.reset();//clear favicon notification badge
        //search for new server URL in query eventually 
        let newServerUrl = this.$route.query.serverUrl;
        if(newServerUrl && newServerUrl !== "") {
            //if missing ws:// , add it
            if (newServerUrl.slice(0, 2) !== 'ws') {
                newServerUrl = 'ws://'+newServerUrl;
            }
            globals.variables.customUrl = newServerUrl;
            globals.constants.serverUrl = newServerUrl;
        } else {  
            globals.variables.customUrl = false;
        }
        console.log("Use query parameter 'serverURL' to define the server you wish to connect to. Exemple : ?serverUrl=wss://mylocalserver:8002");

        websocket.connect();
    }
}
</script>

<style scoped>
#container-waiting-for-server{
    display: flex;
    flex-direction: column;
    padding : 3%;
    width: 850px;
    height:50vh;
    position: absolute;
    top: 22%;
    margin-left:auto;
    margin-right:auto;
    border-radius:5px;
    align-items: center;
}

#wait-for-server-connection_content {
    position: absolute;
    margin: auto;
    width: fit-content;
    min-height : 180px;
    top: calc(50% - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

#spinner {
    width:60px;
    height:60px;
    margin-bottom: 40px;
}

@media screen and (max-width: 1000px) and (min-height:500px) {
    #container-waiting-for-server {
        width: 600px;
    }
}

@media screen and (max-width: 1000px) and (max-height: 790px) {
    #container-waiting-for-server {
        max-height: 68vh;
    }
}

@media screen and (max-height: 500px) and (max-width:1200px){
    #container-waiting-for-server {
        max-height: 50vh;
        top:32%!important;
        width: 600px;
    }
}

@media screen and (max-height: 500px) and (min-width:1500px){
    #container-waiting-for-server {
        top:5%;
        height: 80%;
    }
}

@media screen and (max-height:700px) {
    #container-waiting-for-server {
        width: 600px;
        height: 50%;
        top:25%;
    }
    #wait-for-server-connection_content {
        min-width: 600px;
        min-height : 50%;
    }
}

@media screen and (max-height: 500px) and (max-width:1500px) and (min-width:1200px){
    #container-waiting-for-server {
        top:5%;
        height: 80%;
        width: 600px;
        max-height: 70vh;
    }
}

@media screen and (max-width: 1000px) and (max-height: 500px) {
    #container-waiting-for-server {
        width: 400px;
        height: 50%;
        top:5%;
    }
    #wait-for-server-connection_content {
        min-width: 400px;
        min-height : 50%;
    }
}

@media screen and (min-height:850px) {
    #container-waiting-for-server {
        top: 18%;
    }
}

</style>
